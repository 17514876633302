import { Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { createOptionsList } from '../../services/common/miscUtils';
import { useGetters } from '../../services/redux';
import { useGetAddressQuery } from '../../services/redux/features/api/searchAddress.api-slice';
import AutoFillAddress from '../AutofillAdress/AutfillAddress';
import { AddressSearchFormField } from '../Fieldset/AddressSearchFormField';
import { EircodeFormField } from '../Fieldset/EircodeFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { AddressFormGenericModel, AddressFormGenericModelFields, CountyEnum } from './addressFormGeneric.model';

const createFormInputs = ((
  formTranslationKey: string,
  shouldRenderCheckFn: (fieldName: AddressFormGenericModelFields) => boolean,
  disableAllFormControls: boolean
) => {
  return {
    addressLine1: () =>
      shouldRenderCheckFn('addressLine1') && (
        <TextInputFormField
          fieldName="addressLine1"
          isDisabled={disableAllFormControls}
          labelTranslationKey={`${formTranslationKey}:fields.${'addressLine1'}.label`}
          placeholderTranslationKey={`${formTranslationKey}:fields.${'addressLine1'}.placeholder`}
        />
      ),
    addressLine2: () =>
      shouldRenderCheckFn('addressLine2') && (
        <TextInputFormField
          fieldName="addressLine2"
          isDisabled={disableAllFormControls}
          labelTranslationKey={`${formTranslationKey}:fields.${'addressLine2'}.label`}
          placeholderTranslationKey={`${formTranslationKey}:fields.${'addressLine2'}.placeholder`}
        />
      ),
    addressLine3: () =>
      shouldRenderCheckFn('addressLine3') && (
        <TextInputFormField
          fieldName="addressLine3"
          isDisabled={disableAllFormControls}
          labelTranslationKey={`${formTranslationKey}:fields.${'addressLine3'}.label`}
          placeholderTranslationKey={`${formTranslationKey}:fields.${'addressLine3'}.placeholder`}
        />
      ),
    eirCode: () =>
      shouldRenderCheckFn('eirCode') && (
        <EircodeFormField
          fieldName="eirCode"
          isDisabled={disableAllFormControls}
          labelTranslationKey={`${formTranslationKey}:fields.${'eirCode'}.label`}
          placeholderTranslationKey={`${formTranslationKey}:fields.${'eirCode'}.placeholder`}
        />
      ),
    town: () =>
      shouldRenderCheckFn('town') && (
        <TextInputFormField
          fieldName="town"
          isDisabled={disableAllFormControls}
          labelTranslationKey={`${formTranslationKey}:fields.${'town'}.label`}
          placeholderTranslationKey={`${formTranslationKey}:fields.${'town'}.placeholder`}
        />
      ),
    county: () =>
      shouldRenderCheckFn('county') && (
        <SelectFormField
          fieldName="county"
          isDisabled={disableAllFormControls}
          labelTranslationKey={`${formTranslationKey}:fields.${'county'}.label`}
          placeholderTranslationKey={`${formTranslationKey}:fields.${'county'}.placeholder`}
          optionList={createOptionsList(CountyEnum, formTranslationKey, 'county')}
        />
      ),
    residentialStatus: () =>
      shouldRenderCheckFn('residentialStatus') && (
        <SelectFormField
          fieldName="residentialStatus"
          isDisabled={disableAllFormControls}
          labelTranslationKey={`${formTranslationKey}:fields.${'residentialStatus'}.label`}
          placeholderTranslationKey={`${formTranslationKey}:fields.${'residentialStatus'}.placeholder`}
          optionList={[
            { label: t('formControls:select.noSelection'), value: '' },
            {
              label: t(`${formTranslationKey}:fields.${'residentialStatus'}.options.owner`),
              value: 'owner',
            },
            {
              label: t(`${formTranslationKey}:fields.${'residentialStatus'}.options.privateTenant`),
              value: 'privateTenant',
            },
            {
              label: t(`${formTranslationKey}:fields.${'residentialStatus'}.options.livingWithParents`),
              value: 'livingWithParents',
            },
          ]}
        />
      ),
    timeAtAddressYears: () =>
      shouldRenderCheckFn('timeAtAddressYears') && (
        <SelectFormField
          fieldName="timeAtAddressYears"
          isDisabled={disableAllFormControls}
          labelTranslationKey={`${formTranslationKey}:fields.${'timeAtAddressYears'}.label`}
          placeholderTranslationKey={`${formTranslationKey}:fields.${'timeAtAddressYears'}.placeholder`}
          optionList={[
            { label: t('formControls:select.noSelection'), value: '' },
            {
              label: '0',
              value: '0',
            },
            {
              label: '1',
              value: '1',
            },
            {
              label: '2',
              value: '2',
            },
            {
              label: '3',
              value: '3',
            },
            {
              label: '4',
              value: '4',
            },
            {
              label: '5',
              value: '5',
            },
          ]}
        />
      ),
    timeAtAddressMonths: () =>
      shouldRenderCheckFn('timeAtAddressMonths') && (
        <SelectFormField
          fieldName="timeAtAddressMonths"
          isDisabled={disableAllFormControls}
          labelTranslationKey={`${formTranslationKey}:fields.${'timeAtAddressMonths'}.label`}
          placeholderTranslationKey={`${formTranslationKey}:fields.${'timeAtAddressMonths'}.placeholder`}
          optionList={[
            { label: t('formControls:select.noSelection'), value: '' },
            {
              label: '0',
              value: '0',
            },
            {
              label: '1',
              value: '1',
            },
            {
              label: '2',
              value: '2',
            },
            {
              label: '3',
              value: '3',
            },
            {
              label: '4',
              value: '4',
            },
            {
              label: '5',
              value: '5',
            },
            {
              label: '6',
              value: '6',
            },
            {
              label: '7',
              value: '7',
            },
            {
              label: '8',
              value: '8',
            },
            {
              label: '9',
              value: '9',
            },
            {
              label: '10',
              value: '10',
            },
            {
              label: '11',
              value: '11',
            },
          ]}
        />
      ),
  };
}) satisfies (...args: any[]) => Record<AddressFormGenericModelFields, (...args: any[]) => ReactElement | false>;

type FieldsToRender = Array<Partial<AddressFormGenericModelFields>>;

interface Props {
  initialFormValue: Partial<AddressFormGenericModel>;
  formTranslationKey: string;
  fieldsToRender: FieldsToRender;
}

export const createFieldsToRenderMap = (
  allFields: Array<AddressFormGenericModelFields>,
  fieldsToRender: FieldsToRender
) => {
  return allFields.reduce(
    (map, fieldName) => {
      return {
        ...map,
        [fieldName]: fieldsToRender.includes(fieldName),
      };
    },
    {} as Record<AddressFormGenericModelFields, boolean>
  );
};

export const AddressFormGeneric: FunctionComponent<Props> = (props) => {
  const { initialFormValue, formTranslationKey } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const shouldRenderField = useCallback(
    (fieldName: AddressFormGenericModelFields) => {
      return props.fieldsToRender.includes(fieldName);
    },
    [props.fieldsToRender]
  );

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const [inputs, updateInputs] = useState<ReturnType<typeof createFormInputs>>(() =>
    createFormInputs(formTranslationKey, shouldRenderField, disableAllFormControls)
  );

  const [fieldsToRenderMap, setFieldsToRenderMap] = useState(() =>
    createFieldsToRenderMap(Object.keys(initialFormValue) as AddressFormGenericModelFields[], props.fieldsToRender)
  );

  useEffect(() => {
    updateInputs(createFormInputs(formTranslationKey, shouldRenderField, disableAllFormControls));
    setFieldsToRenderMap(
      createFieldsToRenderMap(Object.keys(initialFormValue) as AddressFormGenericModelFields[], props.fieldsToRender)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formTranslationKey, shouldRenderField, disableAllFormControls]);

  const {
    data: addressData,
    isFetching: isSearchAddressFetching,
    isError: isSearchAddressError,
  } = useGetAddressQuery({ query: searchQuery }, { skip: searchQuery === '' });

  /* Disable prettier to keep template compact and readable. */
  /* prettier-ignore */
  return (
    <>
      <AutoFillAddress
        address={addressData}
        isSearchAddressError={isSearchAddressError}
        errorTranslationKey={`${formTranslationKey}:fields.searchAddress.error`}
      />
      <Fieldset.Row>
        <Layout>
          <Layout.Item default="1/2" s="1/1">
            <AddressSearchFormField
              isLoading={isSearchAddressFetching}
              searchCallback={(query) => setSearchQuery(query)}
              placeholderTranslationKey={`${formTranslationKey}:fields.searchAddress.placeholder`}
              labelTranslationKey={`${formTranslationKey}:fields.searchAddress.label`}
              fieldName="search"
              isDisabled={disableAllFormControls}
            />
          </Layout.Item>
          <Layout.Item default="1/2" s="1/1">{inputs.addressLine1()}</Layout.Item>
        </Layout>
      </Fieldset.Row>

      {(fieldsToRenderMap.addressLine2 || fieldsToRenderMap.addressLine3) && (
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.addressLine2()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.addressLine3()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
      )}

      {(fieldsToRenderMap.town || fieldsToRenderMap.county) && (
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.town()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.county()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
      )}

      {(fieldsToRenderMap.eirCode || fieldsToRenderMap.residentialStatus) && (
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.eirCode()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.residentialStatus()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
      )}

      {(fieldsToRenderMap.timeAtAddressYears || fieldsToRenderMap.timeAtAddressMonths) && (
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">
              <Layout>
                <Layout.Item default="1/2" s="1/1">{inputs.timeAtAddressYears()}</Layout.Item>
                <Layout.Item default="1/2" s="1/1">{inputs.timeAtAddressMonths()}</Layout.Item>
              </Layout>
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
      )}
    </>
  );
};
