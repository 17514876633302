import { BASE_RESOURCE, brandType, bronsonBrand } from '../../config';

export interface Page {
  name: string;
  url: string;
  target: string;
  file?: string;
}

export const getPages = () =>
  import(`../../resources/${brandType}/pages/index.json`).catch(
    () => import(`../../resources/${BASE_RESOURCE}/pages/index.json`)
  );

export const getModels = () =>
  import(`../../resources/${brandType}/models`).catch(() => import(`../../resources/${BASE_RESOURCE}/models`));

export const getStyles = () =>
  import(`@vwfs-bronson/bronson-${bronsonBrand}/dist/css/style.min.css`).then(() => import('../../styles.scss'));

export const getLogosSrc = async () => {
  let logoDefault;
  let logoSmall;

  switch (brandType) {
    case 'skoda':
      logoDefault = await import(`@vwfs-bronson/bronson-${bronsonBrand}/dist/img/logo.png`);
      logoSmall = await import(`@vwfs-bronson/bronson-${bronsonBrand}/dist/img/logo-small-screen.png`);
      break;
    case 'vw':
    case 'seat':
    case 'audi':
      logoDefault = await import(`@vwfs-bronson/bronson-${bronsonBrand}/dist/img/logo.svg`);
      break;
    case 'vwfs':
      logoDefault = await import(`@vwfs-bronson/bronson-${bronsonBrand}/dist/img/logo.svg`);
      logoSmall = await import(`@vwfs-bronson/bronson-${bronsonBrand}/dist/img/logo-small-screen.svg`);
      break;
    case 'vwn':
    default:
      logoDefault = await import(`@vwfs-bronson/bronson-${bronsonBrand}/dist/img/logo.png`);
      logoSmall = await import(`@vwfs-bronson/bronson-${bronsonBrand}/dist/img/logo-small-screen.png`);
      break;
  }

  return {
    logoDefault: logoDefault.default,
    logoSmall: logoSmall.default,
  };
};

export function shouldUseVerticalBar(brandName: string = brandType) {
  return ['vw', 'vwcv'].includes(brandName);
}
