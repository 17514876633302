import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { FinancialDetailsFormModel } from './financialDetailsForm.model';

export interface FinancialDetailsFormState
  extends ReduxStateWithForms<{
    financialDetails1: ReduxTrackedForm<FinancialDetailsFormModel>;
    financialDetails2: ReduxTrackedForm<FinancialDetailsFormModel>;
  }> {}

const financialDetailsInitialValues: FinancialDetailsFormModel = {
  frequencyOfNetIncome: undefined,
  netMonthlyIncome: undefined,
  additionalIncome: [],
  financialObligations: undefined,
};

const initialState: FinancialDetailsFormState = {
  forms: {
    financialDetails1: {
      ...createReduxTrackedFormDefaults<FinancialDetailsFormModel>({ initialValues: financialDetailsInitialValues }),
      values: {
        ...financialDetailsInitialValues,
      },
    },
    financialDetails2: {
      ...createReduxTrackedFormDefaults<FinancialDetailsFormModel>({
        excluded: true,
        initialValues: financialDetailsInitialValues,
      }),
      values: {
        ...financialDetailsInitialValues,
      },
    },
  },
};

const name = 'financialDetailsForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<FinancialDetailsFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    financialDetailsForm1Triggers({ financialDetailsForm }) {
      return financialDetailsForm.forms.financialDetails1.triggers;
    },
    financialDetailsForm2Triggers({ financialDetailsForm }) {
      return financialDetailsForm.forms.financialDetails2.triggers;
    },
    financialDetails1Values({ financialDetailsForm }) {
      return financialDetailsForm.forms.financialDetails1.values;
    },
    financialDetails2Values({ financialDetailsForm }) {
      return financialDetailsForm.forms.financialDetails2.values;
    },
    forms({ financialDetailsForm }) {
      return financialDetailsForm.forms;
    },
  },
});

export const financialDetailsFormSlice = slice;
export const financialDetailsFormGettersDef = gettersDef;
