import { Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import { formAutocomplete } from '../../config';
import { createComboboxList, createOptionsList } from '../../services/common/miscUtils';
import { actions } from '../../services/redux';
import { useGetAddressQuery } from '../../services/redux/features/api/searchAddress.api-slice';
import { CountyEnum } from '../AddressFormGeneric/addressFormGeneric.model';
import AutoFillAddress from '../AutofillAdress/AutfillAddress';
import {
  OccupationEnum,
  TimeAtEmployerMonthsEnum,
  TimeAtEmployerYearsEnum,
} from '../EmploymentDetailsForm/employmentDetailsForm.model';
import { EmploymentDetailsFormWithPreviousFormState } from '../EmploymentDetailsFormWithPreviousForm/employmentDetailsFormWithPreviousForm.slice';
import { AddressSearchFormField } from '../Fieldset/AddressSearchFormField';
import { EircodeFormField } from '../Fieldset/EircodeFormField';
import { SearchableSelectFormField } from '../Fieldset/SearchableSelectFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import {
  EmploymentDetailsFormModelFields,
  PreviousEmploymentDetailsFormModel,
} from './previousEmploymentDetailsForm.model';
import { previousEmploymentDetailsFormSchema } from './previousEmploymentDetailsForm.validator';

const createFormInputs = (() => {
  return {
    employer: () => (
      <TextInputFormField
        fieldName="employer"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.employer.label"
      />
    ),
    occupation: () => (
      <SearchableSelectFormField
        fieldName="occupation"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.occupation.label"
        placeholderTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.occupation.placeholder"
        optionList={createComboboxList(OccupationEnum, 'employmentDetailsForm', 'occupation')}
      />
    ),
    occupationNotes: () => (
      <TextInputFormField
        fieldName="occupationNotes"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.occupationNotes.label"
      />
    ),
    searchAddress: (isSearchAddressFetching: boolean, setSearchQuery: React.Dispatch<React.SetStateAction<string>>) => (
      <AddressSearchFormField
        isLoading={isSearchAddressFetching}
        searchCallback={(query) => setSearchQuery(query)}
        placeholderTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.searchAddress.placeholder"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.searchAddress.label"
        fieldName="search"
      />
    ),
    addressLine1: () => (
      <TextInputFormField
        fieldName="addressLine1"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.addressLine1.label"
      />
    ),
    addressLine2: () => (
      <TextInputFormField
        fieldName="addressLine2"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.addressLine2.label"
      />
    ),
    addressLine3: () => (
      <TextInputFormField
        fieldName="addressLine3"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.addressLine3.label"
      />
    ),
    eirCode: () => (
      <EircodeFormField
        fieldName="eirCode"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.eirCode.label"
      />
    ),
    town: () => (
      <TextInputFormField
        fieldName="town"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.town.label"
      />
    ),
    county: () => (
      <SelectFormField
        fieldName="county"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.county.label"
        placeholderTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.county.placeholder"
        optionList={createOptionsList(
          CountyEnum,
          'previousEmploymentDetailsForm',
          'county',
          'currentEmploymentDetailsForm'
        )}
      />
    ),
    timeAtEmployerYears: () => (
      <SelectFormField
        fieldName="timeAtEmployerYears"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.timeAtEmployerYears.label"
        optionList={createOptionsList(
          TimeAtEmployerYearsEnum,
          'employmentDetailsForm',
          'timeAtEmployerYears',
          'currentEmploymentDetailsForm'
        )}
      />
    ),
    timeAtEmployerMonths: () => (
      <SelectFormField
        fieldName="timeAtEmployerMonths"
        labelTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.timeAtEmployerMonths.label"
        optionList={createOptionsList(
          TimeAtEmployerMonthsEnum,
          'employmentDetailsForm',
          'timeAtEmployerMonths',
          'currentEmploymentDetailsForm'
        )}
      />
    ),
  };
}) satisfies () => Record<EmploymentDetailsFormModelFields, (...args: any[]) => ReactElement>;

interface PreviousEmploymentDetailsFormProps {
  initialValues: PreviousEmploymentDetailsFormModel;
  formName: keyof EmploymentDetailsFormWithPreviousFormState['forms'];
  shouldValidate: boolean;
  shouldReset: boolean;
  onReset: () => void;
}

export const PreviousEmploymentDetailsForm: FunctionComponent<PreviousEmploymentDetailsFormProps> = ({
  initialValues,
  formName,
  shouldValidate,
  shouldReset,
  onReset,
}) => {
  const [inputs /* , updateInputs */] = useState<ReturnType<typeof createFormInputs>>(() => createFormInputs());

  const [searchQuery, setSearchQuery] = useState('');
  const {
    data: addressData,
    isFetching: isSearchAddressFetching,
    isError: isSearchAddressError,
  } = useGetAddressQuery({ query: searchQuery }, { skip: searchQuery === '' });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={previousEmploymentDetailsFormSchema()}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      onReset={onReset}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      <>
        <PersistFormInState<EmploymentDetailsFormWithPreviousFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          updateForm={actions.employmentDetailsFormWithPreviousForm.updateForm}
          shouldReset={shouldReset}
        />

        <Form autoComplete={formAutocomplete}>
          <AutoFillAddress
            address={addressData}
            isSearchAddressError={isSearchAddressError}
            errorTranslationKey="previousEmploymentDetailsForm:currentEmploymentDetailsForm.searchAddress.error"
          />

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.employer()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.occupation()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.occupationNotes()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.searchAddress(isSearchAddressFetching, setSearchQuery)}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.addressLine1()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.town()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.addressLine2()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.county()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.addressLine3()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.eirCode()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.timeAtEmployerYears()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.timeAtEmployerMonths()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
        </Form>
      </>
    </Formik>
  );
};
