import { UsedCarSelectionFormFields } from '../../../../components/UsedCarSelectionForm/UsedCarSelectionFormModels';
import { apiSlice } from './api.api-slice';

export interface NewTransactionResponse {
  token: string;
  data: string;
  id: string;
}

export const usedCarSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchUsedCar: builder.mutation<UsedCarSelectionFormFields, { registrationNumber: string }>({
      query: ({ registrationNumber }) => ({
        url: '/vehicleConfigurator/usedCar/search',
        method: 'POST',
        body: { registrationNumber },
      }),
      extraOptions: {
        secondaryPath: '',
        applyProductPath: false,
      },
    }),
  }),
});

export const { useSearchUsedCarMutation } = usedCarSlice;
