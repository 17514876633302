import { ObjectSchema } from 'yup';
import Yup from '../../services/common/validation';
import { DriverDetailsFormModel } from './driverDetailsForm.model';

export const driverDetailsFormSchema = (): ObjectSchema<DriverDetailsFormModel> => {
  return Yup.object({
    firstName: Yup.string().requiredWithMsg().unaccentedLetters().lengthBetween(0, 64),
    lastName: Yup.string().requiredWithMsg().unaccentedLetters().lengthBetween(0, 64),
  });
};
