import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../../types/reduxTrackedForm';
import { CustomerTypeSelectionFormStateModel } from './CustomerTypeSelectionPageModels';

interface CustomerTypeSelectionFormState
  extends ReduxStateWithForms<{
    customerTypeSelection: ReduxTrackedForm<CustomerTypeSelectionFormStateModel>;
  }> {}

const customerTypeSelectionInitialValues: CustomerTypeSelectionFormStateModel = {
  customerType: null,
};

const initialState: CustomerTypeSelectionFormState = {
  forms: {
    customerTypeSelection: {
      ...createReduxTrackedFormDefaults<CustomerTypeSelectionFormStateModel>({
        initialValues: customerTypeSelectionInitialValues,
      }),
      values: {
        ...customerTypeSelectionInitialValues,
      },
    },
  },
};
const name = 'customerTypeSelectionPage';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<CustomerTypeSelectionFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    customerTypeSelectionForm({ customerTypeSelectionPage }) {
      return customerTypeSelectionPage.forms.customerTypeSelection;
    },
  },
});

export const customerTypeSelectionPageSlice = slice;
export const customerTypeSelectionPageGettersDef = gettersDef;
