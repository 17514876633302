import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { RootState } from '../../services/redux';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { AdditionalServicePlanFormModel } from './additionalServicePlan.model';

interface AdditionalServicePlanState
  extends ReduxStateWithForms<{
    additionalServicePlan: ReduxTrackedForm<AdditionalServicePlanFormModel>;
  }> {
  isAdditionalServicePlanAdded: boolean;
}

const additionalServicePlanInitialValues: AdditionalServicePlanFormModel = {
  isQuoteValid: false,
  paymentType: 'monthly',
  contractDuration: '',
  firstRegistrationDate: '',
  payment: null,
  paymentTerm: '',
  recordedKilometers: null,
  servicePlanName: '',
};

const initialState: AdditionalServicePlanState = {
  isAdditionalServicePlanAdded: true,
  forms: {
    additionalServicePlan: {
      ...createReduxTrackedFormDefaults<AdditionalServicePlanFormModel>(),
      values: {
        ...additionalServicePlanInitialValues,
      },
    },
  },
};

const name = 'additionalServicePlan';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<AdditionalServicePlanState>(),
    toggleAdditionalServicePlan: (state) => {
      state.isAdditionalServicePlanAdded = !state.isAdditionalServicePlanAdded;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

export const selectIsAdditionalServicePlanAdded = (state: RootState) =>
  state.additionalServicePlan.isAdditionalServicePlanAdded;

export const { toggleAdditionalServicePlan } = slice.actions;

const gettersDef = createGettersDefinition({
  reactive: {
    additionalServicePlanValidationTrigger({ additionalServicePlan }) {
      return additionalServicePlan.forms.additionalServicePlan.triggers.validation;
    },
    additionalServicePlanValues({ additionalServicePlan }) {
      return additionalServicePlan.forms.additionalServicePlan.values;
    },
    forms({ additionalServicePlan }) {
      return additionalServicePlan.forms;
    },
  },
});

export const additionalServicePlanSlice = slice;
export const additionalServicePlanGettersDef = gettersDef;
