import { FormikComputedProps, FormikState } from 'formik';

type MutableFormikComputedProps<Values> = {
  -readonly [K in keyof FormikComputedProps<Values>]: FormikComputedProps<Values>[K];
};

export interface ReduxTrackedForm<T>
  extends Pick<FormikState<T>, 'values'>,
    Pick<MutableFormikComputedProps<T>, 'dirty' | 'isValid'> {
  isValidating: boolean;
  isSubmitting: boolean;
  submitCount: number;
  /**
   * - Whether form is excluded from application flow (should not
   * be submitted).
   */
  excluded: boolean;
  triggers: {
    validation: boolean;
    reset: boolean;
  };
  initialValues?: FormikState<T>['values'];
  /**
   * - As long as set to true exclusion won't reset form values.
   */
  freezeValues?: boolean;
}

export interface TrackedFormInfo<TF extends ReduxTrackedForm<any> = any>
  extends Pick<
    TF,
    'isValid' | 'dirty' | 'isSubmitting' | 'isValidating' | 'excluded' | 'submitCount' | 'freezeValues'
  > {}

export interface ReduxStateWithForms<TForms extends Record<string, ReduxTrackedForm<any>>> {
  forms: TForms;
}

export function createReduxTrackedFormDefaults<T>(config?: {
  excluded?: boolean;
  initialValues?: ReduxTrackedForm<T>['values'];
}): Omit<ReduxTrackedForm<T>, 'values'> {
  return {
    dirty: false,
    isValid: false,
    isValidating: false,
    isSubmitting: false,
    submitCount: 0,
    excluded: config?.excluded ?? false,
    triggers: {
      /**
       * - Forms integrated with store through PersistFromInState2 will listen for
       * the flag below to trigger their submission and the same validation.
       */
      validation: false,
      /**
       - Forms integrated with store through PersistFromInState2 will listen for
       * the flag below to trigger reset of form values to their initial ones.
       */
      reset: false,
    },
    initialValues: config?.initialValues,
    freezeValues: false,
  };
}
