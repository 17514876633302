/**
 * - Country numeric code according to ISO-3166.
 * - Hardcoded as Ireland.
 * - It's not the same as CountryCodeType in BUS models.
 * - In UI we show this enum as mix of country Alpha-2 code and Numeric code according to ISO-3166 (IE 353).
 */
export enum CountryCodeEnum {
  ie = '353',
  mockOption = '01',
}

export enum CorrespondenceMethodEnum {
  email = 'email',
  post = 'post',
  portal = 'portal',
}

export enum TelephoneTypeEnum {
  landline = 'Landline',
  mobile = 'Mobile',
}

/**
 * - Values are both display text and submission values - no translation needed.
 */
export enum EntityFormEnum {
  ltd = 'Private company limited by shares (LTD company)',
  dacShares = 'Designated activity company (DAC) - (limited by shares)',
  dacGuarantee = 'Designated activity company limited by guarantee (DAC) -(limited by guarantee)',
  clg = 'Company limited by guarantee (CLG) (limited by guarantee, not having a share capital)',
  plc = 'Public limited company (PLC)',
  singleMember = 'Single member company',
  unlimited = 'Unlimited company',
  specialFund = 'Special fund',
  ucits = 'Undertakings for collective investment in transferable securities (UCITS)',
  eeig = 'European economic interest groupings (EEIG)',
  se = 'Societas Europaea (SE)',
  indAndProvSociety = 'Industrial and Provident Society',
  friendlySociety = 'Friendly society',
  tradeUnion = 'Trade union',
  llp = 'Limited liability partnership (LLP)',
  otherRoi = 'Other legal form (ROI)',
  otherNonRoi = 'Other legal form (non ROI)',
  partnership = 'Partnership',
  club = 'Club',
  association = 'Association',
  charity = 'Unincorporated charity',
  otherNonLegal = 'Other non legal form',
}

export enum InstitutionalTypeEnum {
  'type1' = '1 Non-financial corporations ',
  'type2' = '2 Deposit-taking corporations other than credit institutions ',
  'type3' = '3 Money market funds (MMF) ',
  'type4' = '4 Non-MMF investment funds ',
  'type5' = '5 Financial vehicle corporations (FVCs) engaged in securitisation transactions ',
  'type6' = '6 Other financial intermediaries, except: insurance corporations, pension funds and financial vehicle corporations engaged in securitisation transactions ',
  'type7' = '7 Financial auxiliaries ',
  'type8' = '8 Captive financial institutions and money lenders ',
  'type9' = '9 Insurance corporations ',
  'type10' = '10 Pension funds ',
  'type11' = '11 Social security funds ',
  'type12' = '12 Non-profit institutions serving households ',
  'type13' = '13 Households other than sole proprietors ',
  'type14' = '14 Sole proprietorships and partnerships without legal status',
}

export enum EnterpriseSizeEnum {
  micro = 'Micro',
  small = 'Small',
  medium = 'Medium',
  large = 'Large',
}

export enum NoYesEnum {
  no = 'no',
  yes = 'yes',
}

export enum ProductType {
  'hirePurchase' = 'hirePurchase',
  'hirePurchaseBalloon' = 'hirePurchaseBalloon',
  'pcp' = 'pcp',
  'servicePlan' = 'servicePlan',
}

export enum PaymentType {
  'Monthly' = 'Monthly',
  'Upfront' = 'Upfront',
}

/**
 * - Application flow type depending on certain choices user
 * makes along the way.
 */
export enum FlowTypeEnum {
  business = 'business',
  consumer = 'consumer',
  ltdPlc = 'ltdPlc',
  soleTrader = 'soleTrader',
  partnership = 'partnership',
  other = 'other',
  servicePlan = 'servicePlan',
  financialProduct = 'financialProduct',
}

export enum CreditCheckStatusEnum {
  manualUnderwriting = 'manualUnderwriting',
  moreInformationRequired = 'moreInformationRequired',
  conditionallyApproved = 'conditionallyApproved',
  approved = 'approved',
  declined = 'declined',
}
