import { Lookup } from '../../../../types/lookup';
import { apiSlice } from './api.api-slice';

export const lookupSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLookup: builder.query<Array<Lookup>, { group: string; resource: string }>({
      query: ({ group, resource }) => `/lookup/${group}/${resource}`,
      extraOptions: {
        applyProductPath: false,
      },
      keepUnusedDataFor: 60 * 15,
      transformResponse: (rawResponse: Array<Lookup>): Array<Lookup> => {
        const workingCopy = [...rawResponse];
        workingCopy.unshift({
          label: 'Please Select',
          value: '',
        });

        return workingCopy;
      },
    }),
  }),
});

export const { useGetLookupQuery } = lookupSlice;
