import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, memo, useEffect, useState } from 'react';
import { ObjectSchema } from 'yup';
import { formAutocomplete } from '../../config';
import {
  FlowTypeCheckerFn,
  createOptionsList,
  getPlaceholderOption,
  useFlowTypeChecker,
} from '../../services/common/miscUtils';
import {
  getNaceCodesOptionsListBySection,
  naceCodesOptionsList,
  naceSectionsOptionsList,
} from '../../services/common/naceCodes';
import {
  checkIfEnterpriseSizeIsOptional,
  getMaxDateOfIncorporationDate,
  landlineNumberMask,
  maxLandlineNumberLength,
  maxMobileNumberLength,
  mobileNumberMask,
  unknownNumberTypeMask,
} from '../../services/common/validation';
import { actions, getters, useGetters } from '../../services/redux';
import {
  CorrespondenceMethodEnum,
  CountryCodeEnum,
  EnterpriseSizeEnum,
  EntityFormEnum,
  InstitutionalTypeEnum,
  TelephoneTypeEnum,
} from '../../types/forms';
import { AddressFormGeneric } from '../AddressFormGeneric/AddressFormGeneric';
import { getAddressFormValidationSchema } from '../AddressFormGeneric/addressFormGeneric.validator';
import { DatePickerFormField } from '../Fieldset/DatePickerFormField';
import { NumericInputFormField } from '../Fieldset/NumericInputFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { TextareaInputFormField } from '../Fieldset/TextareaInputFormField';
import { PersistFormInState, setFieldValueInitial } from '../PersistFormInState/PersistFormInState';
import { DropdownOption } from '../UsedCarSelectionForm/UsedCarSelectionFormModels';
import {
  CompanyDetailsFormAddressModel,
  CompanyDetailsFormAddressModelFields,
  CompanyDetailsFormModel,
  CompanyDetailsFormModelFields,
  IdentityTypeEnum,
} from './companyDetailsForm.model';
import { CompanyDetailsFormState } from './companyDetailsForm.slice';
import { createCompanyDetailsFormValidator } from './companyDetailsForm.validator';

const createFormInputs = (() => {
  return {
    identityType: () => (
      <SelectFormField
        fieldName="identityType"
        labelTranslationKey="companyDetailsForm:fields:identityType:label"
        placeholderTranslationKey="companyDetailsForm:fields:identityType:placeholder"
        optionList={createOptionsList(IdentityTypeEnum, 'companyDetailsForm', 'identityType')}
      />
    ),
    identityNumber: () => (
      <TextInputFormField
        fieldName="identityNumber"
        labelTranslationKey="companyDetailsForm:fields:identityNumber:label"
        placeholderTranslationKey="companyDetailsForm:fields:identityNumber:placeholder"
      />
    ),
    companyRegistrationNumber: () => (
      <TextInputFormField
        fieldName="companyRegistrationNumber"
        labelTranslationKey="companyDetailsForm:fields:companyRegistrationNumber:label"
        placeholderTranslationKey="companyDetailsForm:fields:companyRegistrationNumber:placeholder"
      />
    ),
    registeredPartnershipName: (flowTypeChecker: FlowTypeCheckerFn) => {
      let labelTranslationKey: string = 'companyDetailsForm:fields:registeredPartnershipName:label';

      if (flowTypeChecker('ltdPlc')) {
        labelTranslationKey = 'customerDataBusinessLtdPlc:fields:registeredPartnershipName:label';
      }
      if (flowTypeChecker('other')) {
        labelTranslationKey = 'customerDataBusinessOther:fields:registeredPartnershipName:label';
      }

      return (
        <TextInputFormField
          fieldName="registeredPartnershipName"
          labelTranslationKey={labelTranslationKey}
          placeholderTranslationKey="companyDetailsForm:fields:registeredPartnershipName:placeholder"
        />
      );
    },
    entityForm: () => (
      <SelectFormField
        fieldName="entityForm"
        labelTranslationKey="companyDetailsForm:fields:entityForm:label"
        placeholderTranslationKey="companyDetailsForm:fields:entityForm:placeholder"
        optionList={createOptionsList(EntityFormEnum, 'companyDetailsForm', 'entityForm')}
      />
    ),
    businessType: (options: Array<DropdownOption>) => (
      <SelectFormField
        fieldName="businessType"
        labelTranslationKey="companyDetailsForm:fields:businessType:label"
        placeholderTranslationKey="companyDetailsForm:fields:businessType:placeholder"
        optionList={options}
      />
    ),
    businessSubType: (options: Array<DropdownOption>) => (
      <SelectFormField
        fieldName="businessSubType"
        labelTranslationKey="companyDetailsForm:fields:businessSubType:label"
        placeholderTranslationKey="companyDetailsForm:fields:businessSubType:placeholder"
        optionList={options}
      />
    ),
    institutionalType: () => (
      <SelectFormField
        fieldName="institutionalType"
        labelTranslationKey="companyDetailsForm:fields:institutionalType:label"
        placeholderTranslationKey="companyDetailsForm:fields:institutionalType:placeholder"
        optionList={createOptionsList(InstitutionalTypeEnum, 'companyDetailsForm', 'institutionalType')}
      />
    ),
    enterpriseSize: (optional: boolean) => (
      <SelectFormField
        fieldName="enterpriseSize"
        labelTranslationKey="companyDetailsForm:fields:enterpriseSize:label"
        placeholderTranslationKey="companyDetailsForm:fields:enterpriseSize:placeholder"
        optionList={createOptionsList(EnterpriseSizeEnum, 'companyDetailsForm', 'enterpriseSize')}
        optional={optional}
      />
    ),
    correspondenceMethod: () => (
      <SelectFormField
        fieldName="correspondenceMethod"
        labelTranslationKey="companyDetailsForm:fields:correspondenceMethod:label"
        placeholderTranslationKey="companyDetailsForm:fields:correspondenceMethod:placeholder"
        optionList={createOptionsList(CorrespondenceMethodEnum, 'companyDetailsForm', 'correspondenceMethod')}
      />
    ),
    telephoneType: () => (
      <SelectFormField
        fieldName="telephoneType"
        labelTranslationKey="companyDetailsForm:fields:telephoneType:label"
        placeholderTranslationKey="companyDetailsForm:fields:telephoneType:placeholder"
        optionList={createOptionsList(TelephoneTypeEnum, 'companyDetailsForm', 'telephoneType')}
      />
    ),
    countryCode: () => (
      <SelectFormField
        fieldName="countryCode"
        labelTranslationKey="companyDetailsForm:fields:countryCode:label"
        placeholderTranslationKey="companyDetailsForm:fields:countryCode:placeholder"
        optionList={createOptionsList(CountryCodeEnum, 'companyDetailsForm', 'countryCode')}
      />
    ),
    dateOfIncorporation: () => (
      <DatePickerFormField
        fieldName="dateOfIncorporation"
        labelTranslationKey="companyDetailsForm:fields:dateOfIncorporation:label"
        placeholderTranslationKey="companyDetailsForm:fields:dateOfIncorporation:placeholder"
        maxDate={getMaxDateOfIncorporationDate()}
      />
    ),
    vatNumber: () => (
      <TextInputFormField
        fieldName="vatNumber"
        labelTranslationKey="companyDetailsForm:fields:vatNumber:label"
        placeholderTranslationKey="companyDetailsForm:fields:vatNumber:placeholder"
        optional
      />
    ),
    annualProfitCurrentYear: () => (
      <NumericInputFormField
        fieldName="annualProfitCurrentYear"
        labelTranslationKey="companyDetailsForm:fields:annualProfitCurrentYear:label"
        placeholderTranslationKey="companyDetailsForm:fields:annualProfitCurrentYear:placeholder"
        type="money"
        maximumFractionDigits={2}
      />
    ),
    annualProfitPreviousYear: () => (
      <NumericInputFormField
        fieldName="annualProfitPreviousYear"
        labelTranslationKey="companyDetailsForm:fields:annualProfitPreviousYear:label"
        placeholderTranslationKey="companyDetailsForm:fields:annualProfitPreviousYear:placeholder"
        type="money"
        maximumFractionDigits={2}
      />
    ),
    currentBusinessContracts: () => (
      <TextareaInputFormField
        fieldName="currentBusinessContracts"
        labelTranslationKey="companyDetailsForm:fields:currentBusinessContracts:label"
        placeholderTranslationKey="companyDetailsForm:fields:currentBusinessContracts:placeholder"
        maxCounter={255}
        charCounter
      />
    ),
    businessHistory: () => (
      <TextareaInputFormField
        fieldName="businessHistory"
        labelTranslationKey="companyDetailsForm:fields:businessHistory:label"
        placeholderTranslationKey="companyDetailsForm:fields:businessHistory:placeholder"
        maxCounter={255}
        charCounter
      />
    ),
    phoneNumber: (mobilePhoneType: boolean) => {
      return mobilePhoneType ? (
        <TextInputFormField
          fieldName="phoneNumber"
          labelTranslationKey="customerDetailsForm:fields:mobileNumber:label"
          placeholderTranslationKey="customerDetailsForm:fields:mobileNumber:placeholder"
          mask={mobileNumberMask}
          maskedValueToFormValue={(maskedValue) => maskedValue.replace(/[^\d]/g, '')}
        />
      ) : (
        <TextInputFormField
          fieldName="phoneNumber"
          labelTranslationKey="customerDetailsForm:fields:telephoneNumber:label"
          placeholderTranslationKey="customerDetailsForm:fields:telephoneNumber:placeholder"
          mask={landlineNumberMask}
          maskedValueToFormValue={(maskedValue) => maskedValue.replace(/[^\d]/g, '')}
        />
      );
    },
    companyEmail: () => (
      <TextInputFormField
        fieldName="companyEmail"
        labelTranslationKey="companyDetailsForm:fields:companyEmail:label"
        placeholderTranslationKey="companyDetailsForm:fields:companyEmail:placeholder"
      />
    ),
    contactFirstName: () => (
      <TextInputFormField
        fieldName="contactFirstName"
        labelTranslationKey="companyDetailsForm:fields:contactFirstName:label"
        placeholderTranslationKey="companyDetailsForm:fields:contactFirstName:placeholder"
        optional
      />
    ),
    contactLastName: () => (
      <TextInputFormField
        fieldName="contactLastName"
        labelTranslationKey="companyDetailsForm:fields:contactLastName:label"
        placeholderTranslationKey="companyDetailsForm:fields:contactLastName:placeholder"
        optional
      />
    ),
    contactPosition: () => (
      <TextInputFormField
        fieldName="contactPosition"
        labelTranslationKey="companyDetailsForm:fields:contactPosition:label"
        placeholderTranslationKey="companyDetailsForm:fields:contactPosition:placeholder"
        optional
      />
    ),
    contactCountryCode: () => (
      <SelectFormField
        fieldName="contactCountryCode"
        labelTranslationKey="companyDetailsForm:fields:contactCountryCode:label"
        placeholderTranslationKey="companyDetailsForm:fields:contactCountryCode:placeholder"
        optionList={createOptionsList(CountryCodeEnum, 'companyDetailsForm', 'countryCode')}
      />
    ),
    contactPhoneNumber: () => (
      <TextInputFormField
        fieldName="contactPhoneNumber"
        labelTranslationKey="companyDetailsForm:fields:contactPhoneNumber:label"
        placeholderTranslationKey="companyDetailsForm:fields:contactPhoneNumber:placeholder"
        mask={unknownNumberTypeMask}
        maskedValueToFormValue={(maskedValue) => maskedValue.replace(/[^\d]/g, '')}
        optional
      />
    ),
  };
}) satisfies () => Record<
  Exclude<CompanyDetailsFormModelFields, CompanyDetailsFormAddressModelFields>,
  (...args: any[]) => ReactElement
>;

const formName = 'companyDetails';

const addressFormFieldsToUse: CompanyDetailsFormAddressModelFields[] = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'town',
  'county',
  'eirCode',
];

// eslint-disable-next-line react/display-name
export const CompanyDetailsForm: FunctionComponent = memo(() => {
  const [initialValues /* , setInitialValues */] = useState(() => getters.companyDetailsForm.companyDetailsValues);
  const [_setFieldValue, _setSetFieldValue] = useState(setFieldValueInitial<CompanyDetailsFormModel>);

  const currentValues = useGetters().companyDetailsForm.companyDetailsValues;
  const shouldValidate = useGetters().companyDetailsForm.companyDetailsValidationTrigger;
  const [businessTypeOptions] = useState(() => [
    getPlaceholderOption('companyDetailsForm', 'businessType'),
    ...naceSectionsOptionsList,
  ]);
  const [businessSubTypeOptions, setBusinessSubTypeOptions] = useState(() =>
    currentValues.businessType
      ? [
          getPlaceholderOption('companyDetailsForm', 'businessSubType'),
          ...getNaceCodesOptionsListBySection(currentValues.businessType),
        ]
      : [getPlaceholderOption('companyDetailsForm', 'businessSubType'), ...naceCodesOptionsList]
  );
  const flowTypeIs = useFlowTypeChecker();
  const [addressValidationSchema] = useState<ObjectSchema<CompanyDetailsFormAddressModel>>(() =>
    getAddressFormValidationSchema(addressFormFieldsToUse)
  );
  const [validationSchema, setValidationSchema] = useState<ObjectSchema<CompanyDetailsFormModel>>(() =>
    createCompanyDetailsFormValidator(flowTypeIs).concat(addressValidationSchema)
  );

  const [inputs /* , updateInputs */] = useState<ReturnType<typeof createFormInputs>>(() => createFormInputs());

  useEffect(() => {
    setValidationSchema(createCompanyDetailsFormValidator(flowTypeIs).concat(addressValidationSchema));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowTypeIs]);

  const selectedMobileTelephoneType = currentValues.telephoneType === TelephoneTypeEnum.mobile;
  const selectedLandlineTelephoneType = currentValues.telephoneType === TelephoneTypeEnum.landline;

  // Reset phone number then phone type changed.
  useEffect(() => {
    if (selectedMobileTelephoneType) {
      _setFieldValue('phoneNumber', currentValues.phoneNumber?.slice(0, maxMobileNumberLength));
    } else if (selectedLandlineTelephoneType) {
      _setFieldValue('phoneNumber', currentValues.phoneNumber?.slice(0, maxLandlineNumberLength));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues.telephoneType]);

  // Reset business sub type if business type changed, and sub type is no longer valid.
  useEffect(() => {
    const newBusinessSubTypeOptions = currentValues.businessType
      ? [
          getPlaceholderOption('companyDetailsForm', 'businessSubType'),
          ...getNaceCodesOptionsListBySection(currentValues.businessType),
        ]
      : [getPlaceholderOption('companyDetailsForm', 'businessSubType'), ...naceCodesOptionsList];

    setBusinessSubTypeOptions(newBusinessSubTypeOptions);

    if (newBusinessSubTypeOptions.some(({ value }) => value === currentValues.businessSubType)) return;
    _setFieldValue('businessSubType', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues.businessType]);

  const enterpriseSizeOptional = checkIfEnterpriseSizeIsOptional(currentValues.institutionalType);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      {/* Disable prettier to keep template compact and readable. */}
      {/* prettier-ignore */}
      <Form  autoComplete={formAutocomplete}>
        <PersistFormInState<CompanyDetailsFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          updateForm={actions.companyDetailsForm.updateForm}
          setFormFieldSetter={_setSetFieldValue}
        />

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/4" s="1/1">{inputs.identityType()}</Layout.Item>
            <Layout.Item default="1/4" s="1/1">{inputs.identityNumber()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.companyRegistrationNumber()}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.registeredPartnershipName(flowTypeIs)}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">
              <br /> {/* Necessary spacing */}
              <Layout>
                <Layout.Item default="1/2">
                  <Button type="button" full secondary icon="address-book" ariaLabel="Search existing customer" onClick={() => ''}>
                    {t('companyDetailsForm:searchExistingCustomer')}
                  </Button>
                </Layout.Item>
                <Layout.Item default="1/2">
                  <Button type="button" full secondary icon="address-book" ariaLabel="Search CRO" onClick={() => ''}>
                    {t('companyDetailsForm:searchCro')}
                  </Button>
                </Layout.Item>
              </Layout>
            </Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.businessType(businessTypeOptions)}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.businessSubType(businessSubTypeOptions)}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.institutionalType()}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.entityForm()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.dateOfIncorporation()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.enterpriseSize(enterpriseSizeOptional)}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        {flowTypeIs('partnership') && (
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/3" s="1/1">{inputs.vatNumber()}</Layout.Item>
              <Layout.Item default="1/3" s="1/1">{inputs.annualProfitCurrentYear()}</Layout.Item>
              <Layout.Item default="1/3" s="1/1">{inputs.annualProfitPreviousYear()}</Layout.Item>
            </Layout>
          </Fieldset.Row>
        )}

        {flowTypeIs('ltdPlc') && (
          <>
            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/3" s="1/1">{inputs.vatNumber()}</Layout.Item>
              </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/3" s="1/1">{inputs.annualProfitCurrentYear()}</Layout.Item>
                <Layout.Item default="1/3" s="1/1">{inputs.annualProfitPreviousYear()}</Layout.Item>
              </Layout>
            </Fieldset.Row>
          </>
        )}

        {flowTypeIs('partnership', 'ltdPlc') && (
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">{inputs.businessHistory()}</Layout.Item>
              <Layout.Item default="1/2" s="1/1">{inputs.currentBusinessContracts()}</Layout.Item>
            </Layout>
          </Fieldset.Row>
        )}

        {flowTypeIs('other') && (
          <>
            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/3" s="1/1">{inputs.vatNumber()}</Layout.Item>
              </Layout>
            </Fieldset.Row>
            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/2" s="1/1">{inputs.businessHistory()}</Layout.Item>
                <Layout.Item default="1/2" s="1/1">{inputs.currentBusinessContracts()}</Layout.Item>
              </Layout>
            </Fieldset.Row>
          </>
        )}

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.telephoneType()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.countryCode()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.phoneNumber(selectedMobileTelephoneType)}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.correspondenceMethod()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{/* Empty */}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.companyEmail()}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.contactFirstName()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.contactLastName()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.contactPosition()}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.contactCountryCode()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{/* Empty */}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.contactPhoneNumber()}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        <AddressFormGeneric
          initialFormValue={currentValues}
          formTranslationKey='companyDetailsForm'
          fieldsToRender={addressFormFieldsToUse}
        />
      </Form>
    </Formik>
  );
});
