import { currency } from '../../config';
import { QuoteModel } from '../../screens/NewQuotation/FinancialProductPage/financialProductPage.slice';
import { VehicleType } from '../../screens/VehicleSelectPage/vehicleTypeSelectPage.model';
import { SearchVehicleResponse } from '../../services/redux/features/api/search-vehicle.slice';
import { formatMoney } from '../../utils/formatMoney/formatMoney';
import { CompareQuoteTableDataModel } from './compareQuotes.model';

const sanitize = <T>(value: T | null | undefined): T | string => value ?? '';

export const quotesMapper = (
  quotes: Array<QuoteModel>,
  isAdditionalServicePlanAdded: boolean,
  vehicle: SearchVehicleResponse,
  vehicleType: VehicleType
): Array<CompareQuoteTableDataModel> => {
  return quotes.map((quote) => {
    const productValues = quote.forms.financialProduct.values;
    const productSpecificationValues = quote.forms.financialProductSpecification.values;
    const servicePlanValues = quote.forms.additionalServicePlan.values;
    const { vehicleData } = vehicle;

    return {
      quoteNumber: sanitize(quote.id),
      quoteVersion: sanitize(quote.version),
      invoicePrice: formatMoney(productSpecificationValues.invoicePrice ?? -1, currency),
      financeProduct: sanitize(productValues.productType),
      campaignName: sanitize(productValues.campaign),
      totalDepositPercentage: `${sanitize(productSpecificationValues.totalDepositPercentage)} %`,
      totalDepositEuro: formatMoney(productSpecificationValues.totalDeposit ?? -1, currency),
      amountFinanced: formatMoney(productSpecificationValues.amountFinanced ?? -1, currency),
      apr: `${sanitize(productSpecificationValues.totalApr)} %`,
      term: sanitize(productValues.term),
      monthlyPayment: formatMoney(productSpecificationValues.monthlyPayment ?? -1, currency),
      monthlyPaymentValid: productSpecificationValues.isQuoteValid || false,
      brand: sanitize(vehicleData.model?.manufacturer),
      model: sanitize(vehicleData.model?.name),
      variant: sanitize(vehicleData.model?.description),
      newUsed: vehicleType,
      servicePlanPrice: isAdditionalServicePlanAdded ? formatMoney(servicePlanValues.payment ?? -1, currency) : '-',
      servicePlanPriceValid: servicePlanValues.isQuoteValid || false,
      servicePlan: isAdditionalServicePlanAdded ? sanitize(servicePlanValues.servicePlanName) : '-',
      contractDuration: isAdditionalServicePlanAdded ? sanitize(servicePlanValues.contractDuration) : '-',
      paymentType: isAdditionalServicePlanAdded ? sanitize(servicePlanValues.paymentType) : '-',
      paymentTerm: isAdditionalServicePlanAdded ? sanitize(servicePlanValues.paymentTerm) : '-',
    };
  });
};
