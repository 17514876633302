import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../storeUtils';

interface TransactionState {
  transactionId: string | null;
}

const initialState: TransactionState = {
  transactionId: null,
};

const name = 'transaction';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    setTransactionId: (state, action: PayloadAction<string>) => {
      state.transactionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

export const gettersDefinition = createGettersDefinition({
  reactive: {
    transactionId({ transaction }) {
      return transaction.transactionId;
    },
  },
});

export const transactionGettersDef = gettersDefinition;
export const transactionSlice = slice;
