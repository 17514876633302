import {
  Button,
  ButtonContainer,
  Card,
  Icon,
  InstructionStepper,
  Layout,
  Paragraph,
  SectionHeading,
} from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ApplicationSummary from '../../../components/ApplicationSummary/ApplicationSummary';
import { getters } from '../../../services/redux';
import { NavigateType, useNavigate } from '../../../services/routing';
import { CreditCheckStatusEnum } from '../../../types/forms';
import { WizardPagesEnum } from '../newQuotation.slice';

const StaticStepper = () => (
  <>
    <Layout.Item>{/* Break */}</Layout.Item>
    <Layout.Item default="2/3" s="1/1">
      <SectionHeading level={3}>{t('creditCheckPage:stepper:header')}</SectionHeading>

      <InstructionStepper small>
        <InstructionStepper.Step state="is-active" icon="semantic-checkmark">
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: t('creditCheckPage:stepper:step1') }} />
        </InstructionStepper.Step>
        <InstructionStepper.Step icon="document-lines-pen">
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: t('creditCheckPage:stepper:step2') }} />
        </InstructionStepper.Step>
        <InstructionStepper.Step icon="upload">
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: t('creditCheckPage:stepper:step3') }} />
        </InstructionStepper.Step>
        <InstructionStepper.Step icon="semantic-car">
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: t('creditCheckPage:stepper:step4') }} />
        </InstructionStepper.Step>
      </InstructionStepper>

      <br />
      <br />

      <Paragraph className="u-text-center">
        <b>{t('creditCheckPage:stepper:footer')}</b>
      </Paragraph>
    </Layout.Item>
  </>
);

const shouldShowStepper = (status: CreditCheckStatusEnum) => {
  return [CreditCheckStatusEnum.conditionallyApproved, CreditCheckStatusEnum.approved].includes(status);
};

const isButtonSecondary = {
  [CreditCheckStatusEnum.manualUnderwriting]: true,
  [CreditCheckStatusEnum.moreInformationRequired]: false,
  [CreditCheckStatusEnum.conditionallyApproved]: false,
  [CreditCheckStatusEnum.approved]: false,
  [CreditCheckStatusEnum.declined]: true,
};

const onButtonClick = (status: CreditCheckStatusEnum, navigate: NavigateType) => {
  switch (status) {
    case CreditCheckStatusEnum.manualUnderwriting:
      return window.open('https://www.vwfs.com/en.html', '_self');
    case CreditCheckStatusEnum.moreInformationRequired:
      return navigate.newQuote(`/${WizardPagesEnum.amlDocuments}`);
    case CreditCheckStatusEnum.conditionallyApproved:
      return navigate.newQuote(`/${WizardPagesEnum.empty}`);
    case CreditCheckStatusEnum.approved:
      return navigate.newQuote(`/${WizardPagesEnum.empty}`);
    case CreditCheckStatusEnum.declined:
      return window.open('https://www.vwfs.com/en.html', '_self');
    default:
      return null;
  }
};

export const CreditCheckPage: FunctionComponent = () => {
  const { creditCheckStatus: creditCheckStatusParam } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  /* Handle invalid path param */
  const invalidStatusParam = !creditCheckStatusParam || !(creditCheckStatusParam in CreditCheckStatusEnum);
  const navigationInProgress = !location.pathname.includes(getters.newQuotation.currentPageName as string);

  useEffect(() => {
    if (invalidStatusParam && !navigationInProgress) {
      navigate.newQuote(`/${WizardPagesEnum.empty}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (invalidStatusParam) return null;
  /* Finish handling invalid path param */

  const creditCheckStatus = creditCheckStatusParam as CreditCheckStatusEnum;

  return (
    <>
      <Card element="div" className="u-mb">
        <ApplicationSummary isDefaultOpen={false} />
      </Card>

      <Card element="div">
        <br />

        <SectionHeading level={3}>{t(`creditCheckPage:header:${creditCheckStatus}`)}</SectionHeading>
        <Icon size="large" name={t(`creditCheckPage:icon:${creditCheckStatus}`)} />

        <Layout center className="u-mt-xsmall">
          <Layout.Item default="2/3" s="1/1">
            <Paragraph
              className="u-text-left"
              dangerouslySetInnerHTML={{ __html: t(`creditCheckPage:description:${creditCheckStatus}`) }}
            />
          </Layout.Item>

          {shouldShowStepper(creditCheckStatus) && <StaticStepper />}
        </Layout>
      </Card>

      <Layout center className="u-mt-xsmall">
        <Layout.Item default="1/2">
          <ButtonContainer center>
            <Button
              type="button"
              testId="button_goBack"
              secondary={isButtonSecondary[creditCheckStatus]}
              onClick={() => onButtonClick(creditCheckStatus, navigate)}
            >
              {t(`creditCheckPage:button:${creditCheckStatus}`)}
            </Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </>
  );
};
