import { useFormikContext } from 'formik';
import i18n from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { FormField } from '..';
import { FormFieldPropsBase } from './fieldset.model';

export interface RegistrationNumberFormFieldProps extends FormFieldPropsBase {}

export const RegistrationNumberFormField: FunctionComponent<RegistrationNumberFormFieldProps> = ({
  fieldName,
  labelTranslationKey = '',
  placeholderTranslationKey = '',
  testId,
  isDisabled,
}) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext<any>();
  const [inputValue, setInputValue] = useState('');

  const registrationNumberMask = (rawValue) => {
    // Remove non-alphanumeric characters
    const cleanValue = rawValue.replace(/[^a-zA-Z0-9]/g, '');

    let mask: Array<RegExp | string> = [];

    // First part: 2 or 3 digits
    if (/^\d{3}/.test(cleanValue)) {
      mask = [/\d/, /\d/, /\d/, '-']; // For three digits at the beginning
    } else {
      mask = [/\d/, /\d/, '-']; // For two digits at the beginning
    }

    // Second part: 1 or 2 letters
    const letterPart = cleanValue.replace(/^\d{2,3}/, ''); // Remove the first 2 or 3 digits
    if (/^[a-zA-Z]{2}/.test(letterPart)) {
      mask.push(/[a-zA-Z]/, /[a-zA-Z]/, '-'); // For two letters
    } else {
      mask.push(/[a-zA-Z]/, '-'); // For one letter
    }

    // Third part: up to 10 digits
    const remainingDigits = cleanValue.replace(/^\d{2,3}[a-zA-Z]{1,2}/, '').length;
    for (let i = 0; i < Math.min(remainingDigits, 10); i++) {
      mask.push(/\d/);
    }

    return mask;
  };

  const applyMask = (value, maskFunction) => {
    const mask = maskFunction(value);
    let maskedValue = '';
    let valueIndex = 0;

    mask.forEach((maskChar) => {
      if (valueIndex >= value.length) {
        return;
      }

      if (maskChar instanceof RegExp) {
        while (valueIndex < value.length && !maskChar.test(value[valueIndex])) {
          valueIndex++;
        }
        if (valueIndex < value.length) {
          maskedValue += value[valueIndex++];
        }
      } else {
        maskedValue += maskChar;
      }
    });

    return maskedValue;
  };

  const handleChange = (event) => {
    const newValue = event.target.value.toUpperCase();

    setInputValue(newValue);
    setFieldValue(fieldName, newValue);
  };

  const handleBlur = () => {
    setFieldTouched(fieldName, true, true);
  };

  useEffect(() => {
    if (values[fieldName]) {
      const value = values[fieldName];

      const newValue = value.toString().toUpperCase();
      const maskedValue = applyMask(newValue, registrationNumberMask);

      setInputValue(maskedValue);
    }
  }, [values, fieldName]);

  return (
    <FormField
      type="input"
      name={fieldName}
      testId={testId}
      labelText={i18n.t(labelTranslationKey)}
      render={(fieldProps) => (
        <MaskedInput
          {...fieldProps}
          className="c-input__input"
          guide={false}
          keepCharPositions
          disabled={isDisabled}
          mask={(value) => registrationNumberMask(value)}
          name={fieldName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={i18n.t(placeholderTranslationKey)}
          placeholderChar={'\u2000'}
          value={inputValue}
        />
      )}
    />
  );
};
