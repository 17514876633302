import { Header as BrHeader } from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent } from 'react';
import { ROUTES } from '../../services/routing/routes';
import { HeaderLogo } from '../HeaderLogo';

const Header: FunctionComponent = () => {
  return <BrHeader testId="appHeader" staticHeader logo={<HeaderLogo navigationTarget={ROUTES.dashboardHome} />} />;
};

export default Header;
