import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, memo, useMemo, useState } from 'react';
import { ObjectSchema } from 'yup';
import { formAutocomplete } from '../../config';
import { createOptionsList } from '../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../services/redux';
import { NoYesEnum } from '../../types/forms';
import { AddressFormGeneric } from '../AddressFormGeneric/AddressFormGeneric';
import { getAddressFormValidationSchema } from '../AddressFormGeneric/addressFormGeneric.validator';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState, setFieldValueInitial } from '../PersistFormInState/PersistFormInState';
import {
  TradingDetailsSIBusinessFormAddressModelFields,
  TradingDetailsSIBusinessFormModel,
  TradingDetailsSIBusinessFormModelFields,
} from './tradingDetailsSIBusinessForm.model';
import { TradingDetailsSIBusinessFormState } from './tradingDetailsSIBusinessForm.slice';
import { createTradingDetailsSIBusinessFormValidator } from './tradingDetailsSIBusinessForm.validator';

const createFormInputs = ((disableAllFormControls: boolean) => {
  return {
    tradingAs: () => (
      <TextInputFormField
        fieldName="tradingAs"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsSIBusinessForm:fields:tradingAs:label"
        placeholderTranslationKey="tradingDetailsSIBusinessForm:fields:tradingAs:placeholder"
      />
    ),
    legalName: () => (
      <TextInputFormField
        fieldName="legalName"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsSIBusinessForm:fields:legalName:label"
        placeholderTranslationKey="tradingDetailsSIBusinessForm:fields:legalName:placeholder"
      />
    ),
    vatLiable: () => (
      <SelectFormField
        fieldName="vatLiable"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsSIBusinessForm:fields:vatLiable:label"
        optionList={createOptionsList(NoYesEnum, 'tradingDetailsSIBusinessForm', 'noYes', null)}
      />
    ),
    vatNumber: () => (
      <TextInputFormField
        fieldName="vatNumber"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsSIBusinessForm:fields:vatNumber:label"
        placeholderTranslationKey="tradingDetailsSIBusinessForm:fields:vatNumber:placeholder"
      />
    ),
    companyRegistrationNumber: () => (
      <TextInputFormField
        fieldName="companyRegistrationNumber"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsSIBusinessForm:fields:companyRegistrationNumber:label"
        placeholderTranslationKey="tradingDetailsSIBusinessForm:fields:companyRegistrationNumber:placeholder"
      />
    ),
  };
}) satisfies (
  disableAllFormControls: boolean
) => Record<
  Exclude<TradingDetailsSIBusinessFormModelFields, TradingDetailsSIBusinessFormAddressModelFields>,
  (...args: any[]) => ReactElement
>;

const formName = 'tradingDetailsSIBusiness';

const addressFormFieldsToUse: TradingDetailsSIBusinessFormAddressModelFields[] = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'town',
  'county',
  'eirCode',
];

// eslint-disable-next-line react/display-name
export const TradingDetailsSIBusinessForm: FunctionComponent = memo(() => {
  const [initialValues /* , setInitialValues */] = useState(
    () => getters.tradingDetailsSIBusinessForm.tradingDetailsValues
  );
  const [_setFieldValue, _setSetFieldValue] = useState(setFieldValueInitial<TradingDetailsSIBusinessFormModel>);
  const currentValues = useGetters().tradingDetailsSIBusinessForm.tradingDetailsValues;
  const shouldValidate = useGetters().tradingDetailsSIBusinessForm.tradingDetailsTriggers.validation;
  const shouldReset = useGetters().tradingDetailsSIBusinessForm.tradingDetailsTriggers.reset;
  const valuesToResetTo = getters.tradingDetailsSIBusinessForm.forms.tradingDetailsSIBusiness.initialValues;

  const [validationSchema] = useState<ObjectSchema<TradingDetailsSIBusinessFormModel>>(() =>
    createTradingDetailsSIBusinessFormValidator().concat(getAddressFormValidationSchema(addressFormFieldsToUse))
  );

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const inputs = useMemo(() => createFormInputs(disableAllFormControls), [disableAllFormControls]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      {/* Disable prettier to keep template compact and readable. */}
      {/* prettier-ignore */}
      <Form autoComplete={formAutocomplete}>
        <PersistFormInState<TradingDetailsSIBusinessFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          shouldReset={shouldReset}
          updateForm={actions.tradingDetailsSIBusinessForm.updateForm}
          setFormFieldSetter={_setSetFieldValue}
          valuesToResetTo={valuesToResetTo}
        />

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3">
            <Button disabled={disableAllFormControls} type="button" secondary icon="address-book" ariaLabel="Search existing customer" onClick={() => ''}>
              {t('tradingDetailsSIBusinessForm:searchExistingCustomer')}
            </Button>
            </Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.tradingAs()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.legalName()}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.vatLiable()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.vatNumber()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.companyRegistrationNumber()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        
        <AddressFormGeneric
          initialFormValue={currentValues}
          formTranslationKey='tradingDetailsSIBusinessForm'
          fieldsToRender={addressFormFieldsToUse}
        />
      </Form>
    </Formik>
  );
});
