import { continueLaterLocalization } from '@vwfs-its/sf-sac-frontend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { BASE_RESOURCE /* , brandType */ } from '../../config';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {},
    lng: 'ie',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });

const localization = continueLaterLocalization({
  locale: 'ie',
  brandName: { BRAND_NAME: process.env.REACT_APP_WEBSITE_BRAND },
  // dataProtectionLink: 'https://www.vwfs.ie/LegalNotice.html',
});

export default (): Promise<any> =>
  new Promise((res) => {
    const namespaces: Array<string> = [
      'app',
      'error',
      'landingPage',
      'validation',
      'dashboard',
      'loginPage',
      'passwordUpcomingExpirationNotification',
      'mockDashboard',
      'changePassword',
      'outletSelectionPopup',
      'vehicleTypeSelectPage',
      'usedCarSelectionForm',
      'usedCarSearchForm',
      'usedCarFormSelector',
      'newQuotation',
      'standaloneServicePlan',
      'applicationSummary',
      'financialProduct',
      'usedCarFormSelectorPage',
      'financialProductSpecification',
      'additionalServicePlan',
      'formControls',
      'compareQuotes',
      'customerTypeSelectionPage',
      'productFamilySelectionPage',
      'customerDataPage',
      'documentUploadPage',
      'documentUploadItem',
      'customerDetailsForm',
      'financialDetailsForm',
      'addressForm',
      'employmentDetailsForm',
      'previousEmploymentDetailsForm',
      'employmentDetailsFormWithPreviousForm',
      'linkedRelationshipsForm',
      'tradingDetailsForm',
      'tradingDetailsSIForm',
      'contactAddressForm',
      'bankDetailsForm',
      'companyDetailsForm',
      'customerDataPrivate',
      'addressWithPreviousForm',
      'driverDetailsForm',
      'customerDataBusinessOther',
      'customerDataBusinessLtdPlc',
      'customerDataBusinessPartnership',
      'financialProductPage',
      'creditCheckPage',
      'enum',
      'snCWelcomeBack',
      'snCTanValidation',
      'snCExpired',
      'snCInitialization',
      'tradingDetailsSIBusinessForm',
      'contactDetailsForm',
      'personalDetailsForm',
      'customerDataSIBusinessPartnership',
      'customerDataSIBusinessOther',
      'customerDataSIBusinessLtdPlc',
    ];
    Promise.all(
      namespaces.map(
        (item) =>
          import(`../../resources/${BASE_RESOURCE}/content/${item}.json`).then((module) => {
            i18n.addResourceBundle('ie', item, module.default, true, true);
          })

        // TODO uncomment below code when multibrand will be implemented

        // .then(() => {
        //   /**
        //    * Request brand-specific resources and override (using deep) the
        //    * resources for specific brand. Make sure to chain those to avoid
        //    * race conditions.
        //    */
        //   import(`../../resources/${brandType}/content/${item}.json`).then((module) => {
        //     i18n.addResourceBundle('es', item, module.default, true, true);
        //   });
        // })
      )
    ).then(res);

    i18n.addResourceBundle('ie', 'sf-sac', localization, true, true);
  });
