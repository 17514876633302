import { useFormikContext } from 'formik';
import { t } from 'i18next';
import { FunctionComponent, useEffect } from 'react';
import { SearchAddressResponse } from '../../services/redux/features/api/searchAddress.api-slice';
import { AddressFormModel } from '../AddressForm/addressForm.model';
import { addressMapper } from '../AddressFormGeneric/adress.mapper';

interface AutoFillAddressProps {
  address: SearchAddressResponse | undefined;
  isSearchAddressError: boolean;
  errorTranslationKey: string;
}

const AutoFillAddress: FunctionComponent<AutoFillAddressProps> = ({
  address,
  isSearchAddressError,
  errorTranslationKey,
}) => {
  const { setFieldError, setFieldValue, values } = useFormikContext<AddressFormModel>();

  useEffect(() => {
    if (isSearchAddressError) {
      setFieldError('search', t(errorTranslationKey));
    }
  }, [setFieldError, isSearchAddressError, errorTranslationKey]);

  useEffect(() => {
    if (address) {
      const mappedFields = addressMapper(address, values);

      setFieldValue('addressLine1', mappedFields.addressLine1);
      setFieldValue('addressLine2', mappedFields.addressLine2);
      setFieldValue('addressLine3', mappedFields.addressLine3);
      setFieldValue('eirCode', mappedFields.eirCode);
      setFieldValue('town', mappedFields.town);
      setFieldValue('county', mappedFields.county);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, address]);

  return null;
};

export default AutoFillAddress;
