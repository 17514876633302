import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { saveAndContinueRehydrateSlice } from '../storeUtils';
import { Outlet } from './api/outlet.api-slice';

interface OutletState {
  selectedOutlet: Outlet | null;
}

const initialState: OutletState = {
  selectedOutlet: null,
};

const name = 'outlets';

export const outletSlice = createSlice({
  name,
  initialState,
  reducers: {
    saveSelectedOutlet: (state, action: PayloadAction<Outlet>) => {
      state.selectedOutlet = action.payload;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

export const getSelectedOutlet = (state: RootState) => state.outlets.selectedOutlet;

export const { saveSelectedOutlet } = outletSlice.actions;

export default outletSlice.reducer;
