import { DatePicker } from '@vwfs-bronson/bronson-react';
import { useFormikContext } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormField } from '..';
import { FormFieldPropsBase } from './fieldset.model';

interface DatePickerFormFieldProps extends FormFieldPropsBase {
  minDate?: Date | string;
  maxDate?: Date | string;
  dateFormat?: string;
}

export const DatePickerFormField: FunctionComponent<DatePickerFormFieldProps> = ({
  labelTranslationKey = '',
  placeholderTranslationKey = '',
  isDisabled,
  fieldName,
  testId,
  minDate,
  maxDate,
  dateFormat = 'd/m/Y',
  optional,
}) => {
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const [datePickerInputId] = useState(() => `datePicker_${Math.random()}`);

  // Manually set placeholder attribute on the input element.
  useEffect(() => {
    const input = document.getElementById(datePickerInputId) as HTMLInputElement;
    if ('placeholder' in input) {
      input.placeholder = t(placeholderTranslationKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeholderTranslationKey]);

  const setValueTouchAndValidate = useCallback(
    async (value: string) => {
      await setFieldValue(fieldName, value);
      setFieldTouched(fieldName, true, true);
    },
    [fieldName, setFieldTouched, setFieldValue]
  );

  return (
    <FormField
      name={fieldName}
      type="other"
      testId={testId ?? fieldName}
      labelText={`${t(labelTranslationKey)}${optional ? t('formControls:optionalLabelAffix') : ''}`}
      isDisabled={isDisabled}
      render={(fieldProps) => (
        <DatePicker
          {...fieldProps}
          id={datePickerInputId}
          name={fieldName}
          testId={testId}
          maxDate={maxDate}
          minDate={minDate}
          dateFormat={dateFormat}
          locale="ie"
          mode="single"
          value={(values as any)[fieldName]}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          // DatePickers' built-in placeholder will not work correctly
          // with it's own built-in input masking (based on dateFormat).
          // We want to use input masking so placeholder is set directly on input element.
          // Don't try setting placeholder like shown below:
          // placeholder={t(placeholderTranslationKey)}
          onChange={(e) => setValueTouchAndValidate(e.split('/').join('/'))}
          onBlur={(e) => setValueTouchAndValidate(e.split('/').join('/'))}
          onError={() => setValueTouchAndValidate('')}
        />
      )}
    />
  );
};
