import React, { FunctionComponent, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OutletSelectionPopup } from '../../components/OutletSelectionPopup';
import { getSelectedOutlet } from '../../services/redux/features/outlet.slice';

const MockDashboardPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const selectedOutlet = useSelector(getSelectedOutlet);

  useEffect(() => {
    document.title = t('mockDashboard:title');
  }, [t]);

  return <>{!selectedOutlet && <OutletSelectionPopup isShown />}</>;
};

export default MockDashboardPage;
