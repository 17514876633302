import { Button, Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, memo, useMemo, useState } from 'react';
import { formAutocomplete } from '../../config';
import { createOptionsList } from '../../services/common/miscUtils';
import { getMaxBirthDate, getMinBirthDate, mobileNumberMask } from '../../services/common/validation';
import { actions, getters, useGetters } from '../../services/redux';
import { CorrespondenceMethodEnum, CountryCodeEnum } from '../../types/forms';
import { TitleEnum } from '../CustomerDetailsForm/customerDetailsForm.model';
import { DatePickerFormField } from '../Fieldset/DatePickerFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import { ContactDetailsFormModel, ContactDetailsFormModelFields } from './contactDetailsForm.model';
import { ContactDetailsFormState } from './contactDetailsForm.slice';
import { contactDetailsFormSchema } from './contactDetailsForm.validator';

const typeSafeFormFieldName = (formFieldName: keyof ContactDetailsFormModel) => formFieldName;

const createFormInputs = ((disableAllFormControls: boolean) => {
  return {
    title: () => (
      <SelectFormField
        fieldName={typeSafeFormFieldName('title')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="contactDetailsForm:fields:title:label"
        optionList={createOptionsList(TitleEnum, 'customerDetailsForm', 'title')}
      />
    ),
    firstName: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('firstName')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="contactDetailsForm:fields:firstName:label"
        placeholderTranslationKey="contactDetailsForm:fields:firstName:placeholder"
      />
    ),
    lastName: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('lastName')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="contactDetailsForm:fields:lastName:label"
        placeholderTranslationKey="contactDetailsForm:fields:lastName:placeholder"
      />
    ),
    dateOfBirth: () => (
      <DatePickerFormField
        fieldName={typeSafeFormFieldName('dateOfBirth')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="contactDetailsForm:fields:dateOfBirth:label"
        placeholderTranslationKey="contactDetailsForm:fields:dateOfBirth:placeholder"
        maxDate={getMaxBirthDate()}
        minDate={getMinBirthDate()}
      />
    ),
    countryCode: () => (
      <SelectFormField
        fieldName={typeSafeFormFieldName('countryCode')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="contactDetailsForm:fields:countryCode:label"
        placeholderTranslationKey="contactDetailsForm:fields:countryCode:placeholder"
        optionList={createOptionsList(CountryCodeEnum, 'companyDetailsForm', 'countryCode')}
      />
    ),
    email: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('email')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="contactDetailsForm:fields:email:label"
        placeholderTranslationKey="contactDetailsForm:fields:email:placeholder"
        validateOnChange
      />
    ),
    invoiceEmailAddress: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('invoiceEmailAddress')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="contactDetailsForm:fields:invoiceEmailAddress:label"
        placeholderTranslationKey="contactDetailsForm:fields:invoiceEmailAddress:placeholder"
        validateOnChange
      />
    ),
    mobileNumber: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('mobileNumber')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="contactDetailsForm:fields:mobileNumber:label"
        placeholderTranslationKey="contactDetailsForm:fields:mobileNumber:placeholder"
        mask={mobileNumberMask}
        maskedValueToFormValue={(maskedValue) => maskedValue.replace(/[^\d]/g, '')}
      />
    ),
    correspondenceMethod: () => (
      <SelectFormField
        fieldName={typeSafeFormFieldName('correspondenceMethod')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="contactDetailsForm:fields:correspondenceMethod:label"
        placeholderTranslationKey="contactDetailsForm:fields:correspondenceMethod:placeholder"
        optionList={createOptionsList(CorrespondenceMethodEnum, 'companyDetailsForm', 'correspondenceMethod')}
      />
    ),
  };
}) satisfies (
  disableAllFormControls: boolean
) => Record<ContactDetailsFormModelFields, (...args: any[]) => ReactElement>;

const formName: keyof ContactDetailsFormState['forms'] = 'contactDetailsForm';

// eslint-disable-next-line react/display-name
export const ContactDetailsForm: FunctionComponent = memo(() => {
  const [initialValues /* , setInitialValues */] = useState(() => getters.contactDetailsForm.contactDetailsFormValues);
  const shouldValidate = useGetters().contactDetailsForm.contactDetailsFormTriggers.validation;
  const shouldReset = useGetters().contactDetailsForm.contactDetailsFormTriggers.reset;

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const inputs = useMemo(() => createFormInputs(disableAllFormControls), [disableAllFormControls]);

  return (
    <Formik
      validationSchema={contactDetailsFormSchema()}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      <>
        <PersistFormInState<ContactDetailsFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          updateForm={actions.contactDetailsForm.updateForm}
          shouldReset={shouldReset}
          valuesToResetTo={getters.contactDetailsForm.forms.contactDetailsForm.initialValues}
        />

        <Form autoComplete={formAutocomplete}>
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/3">
                <Button
                  type="button"
                  secondary
                  icon="address-book"
                  ariaLabel="Search existing customer"
                  onClick={() => ''}
                >
                  {t('contactDetailsForm:searchExistingCustomer')}
                </Button>
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/3" s="1/1">
                {inputs.title()}
              </Layout.Item>
              <Layout.Item default="1/3" s="1/1">
                {inputs.firstName()}
              </Layout.Item>
              <Layout.Item default="1/3" s="1/1">
                {inputs.lastName()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/3" s="1/1">
                {inputs.dateOfBirth()}
              </Layout.Item>
              <Layout.Item default="1/3" s="1/1">
                {inputs.email()}
              </Layout.Item>
              <Layout.Item default="1/3" s="1/1">
                {inputs.invoiceEmailAddress()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/3" s="1/1">
                {inputs.countryCode()}
              </Layout.Item>
              <Layout.Item default="1/3" s="1/1">
                {inputs.mobileNumber()}
              </Layout.Item>
              <Layout.Item default="1/3" s="1/1">
                {inputs.correspondenceMethod()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
        </Form>
      </>
    </Formik>
  );
});
