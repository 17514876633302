import { t } from 'i18next';
import React from 'react';

/**
 * - Apply this class to any FormSection that requires title button and needs
 * it to be aligned to the right side.
 */
export const sectionWithTitleClass = 'formSectionWithTitleButton';

export const FormSectionDpnButton = () => (
  <span
    onClickCapture={(e: React.MouseEvent) => {
      if (e.stopPropagation) {
        e.stopPropagation();
      }
    }}
    className="c-btn c-btn--secondary u-float-right"
  >
    <span className="c-btn__text">{t('customerDataPrivate:viewDpn')}</span>
    <i className="c-btn__icon c-icon c-icon--[semantic-forward]" aria-hidden="true" role="img" />
  </span>
);
