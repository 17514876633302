import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, memo, useMemo, useState } from 'react';
import { ObjectSchema } from 'yup';
import { formAutocomplete } from '../../config';
import { createOptionsList } from '../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../services/redux';
import { NoYesEnum } from '../../types/forms';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState, setFieldValueInitial } from '../PersistFormInState/PersistFormInState';
import { TradingDetailsSIFormModel, TradingDetailsSIFormModelFields } from './tradingDetailsSIForm.model';
import { TradingDetailsSIFormState } from './tradingDetailsSIForm.slice';
import { createTradingDetailsSIFormValidator } from './tradingDetailsSIForm.validator';

const createFormInputs = ((disableAllFormControls: boolean) => {
  return {
    legalName: () => (
      <TextInputFormField
        fieldName="legalName"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsSIForm:fields:legalName:label"
        placeholderTranslationKey="tradingDetailsSIForm:fields:legalName:placeholder"
      />
    ),
    vatLiable: () => (
      <SelectFormField
        fieldName="vatLiable"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsSIForm:fields:vatLiable:label"
        placeholderTranslationKey="tradingDetailsSIForm:fields:vatLiable:placeholder"
        optionList={createOptionsList(NoYesEnum, 'tradingDetailsSIForm', 'noYes', null)}
      />
    ),
    tradingAs: () => (
      <TextInputFormField
        fieldName="tradingAs"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsSIForm:fields:tradingAs:label"
        placeholderTranslationKey="tradingDetailsSIForm:fields:tradingAs:placeholder"
      />
    ),
    vatNumber: () => (
      <TextInputFormField
        fieldName="vatNumber"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsSIForm:fields:vatNumber:label"
        placeholderTranslationKey="tradingDetailsSIForm:fields:vatNumber:placeholder"
      />
    ),
  };
}) satisfies (
  disableAllFormControls: boolean
) => Record<TradingDetailsSIFormModelFields, (...args: any[]) => ReactElement>;

const formName = 'tradingDetailsSIForm';

// eslint-disable-next-line react/display-name
export const TradingDetailsSIForm: FunctionComponent = memo(() => {
  const [initialValues /* , setInitialValues */] = useState(() => getters.tradingDetailsSIForm.tradingDetailsValues);
  const [_setFieldValue, _setSetFieldValue] = useState(setFieldValueInitial<TradingDetailsSIFormModel>);
  const shouldValidate = useGetters().tradingDetailsSIForm.tradingDetailsTriggers.validation;
  const shouldReset = useGetters().tradingDetailsSIForm.tradingDetailsTriggers.reset;
  // const valuesToResetTo = getters.tradingDetailsSIForm.forms.tradingDetailsSI.initialValues;

  const [validationSchema] = useState<ObjectSchema<TradingDetailsSIFormModel>>(() =>
    createTradingDetailsSIFormValidator()
  );

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const inputs = useMemo(() => createFormInputs(disableAllFormControls), [disableAllFormControls]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      {/* Disable prettier to keep template compact and readable. */}
      {/* prettier-ignore */}
      <Form autoComplete={formAutocomplete}>
        <PersistFormInState<TradingDetailsSIFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          shouldReset={shouldReset}
          updateForm={actions.tradingDetailsSIForm.updateForm}
          setFormFieldSetter={_setSetFieldValue}
          // valuesToResetTo={valuesToResetTo}
        />
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3">
            <Button disabled={disableAllFormControls} type="button" secondary icon="address-book" ariaLabel="Search existing customer" onClick={() => ''}>
              {t('tradingDetailsSIForm:searchExistingCustomer')}
            </Button>
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.tradingAs()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.legalName()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.vatLiable()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.vatNumber()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
      </Form>
    </Formik>
  );
});
