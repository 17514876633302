import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import { getEnumValues } from '../../services/common/miscUtils';
import Yup, { maxMobileNumberLength, minMobileNumberLength, regExEmail } from '../../services/common/validation';
import { CorrespondenceMethodEnum, CountryCodeEnum } from '../../types/forms';
import { TitleEnum } from '../CustomerDetailsForm/customerDetailsForm.model';
import { ContactDetailsFormModel } from './contactDetailsForm.model';

export const contactDetailsFormSchema = (): ObjectSchema<ContactDetailsFormModel> => {
  return Yup.object({
    title: Yup.string().required(t('validation:required')).oneOf(getEnumValues(TitleEnum)),
    firstName: Yup.string().requiredWithMsg().unaccentedLetters().lengthBetween(0, 64),
    lastName: Yup.string().requiredWithMsg().unaccentedLetters().lengthBetween(0, 64),
    dateOfBirth: Yup.string().required(t('validation:required')),
    email: Yup.string().required(t('validation:required')).matches(regExEmail, t('validation:invalidEmail')),
    invoiceEmailAddress: Yup.string().notRequired().matches(regExEmail, t('validation:invalidEmail')),
    countryCode: Yup.string().requiredWithMsg().oneOf(getEnumValues(CountryCodeEnum)),
    mobileNumber: Yup.string().requiredWithMsg().lengthBetween(minMobileNumberLength, maxMobileNumberLength),
    correspondenceMethod: Yup.string().requiredWithMsg().oneOf(getEnumValues(CorrespondenceMethodEnum)),
  });
};
