import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ProductType } from '../../types/forms';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { FinancialProductFormModel } from './financialProductForm.model';

export interface FinancialProductFormState
  extends ReduxStateWithForms<{
    financialProduct: ReduxTrackedForm<FinancialProductFormModel>;
  }> {}

const financialProductInitialValues: FinancialProductFormModel = {
  productType: ProductType.pcp,
  campaign: '',
  term: '',
  cString: '0000000000000',
  vatApplicable: 'yes',
  lastProposalDate: '31/07/2023',
  lastPayoutDate: '30/09/2023',
};

const initialState: FinancialProductFormState = {
  forms: {
    financialProduct: {
      ...createReduxTrackedFormDefaults<FinancialProductFormModel>(),
      values: {
        ...financialProductInitialValues,
      },
    },
  },
};

const name = 'financialProductForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<FinancialProductFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    financialProductValidationTrigger({ financialProductForm }) {
      return financialProductForm.forms.financialProduct.triggers.validation;
    },
    financialProductValues({ financialProductForm }) {
      return financialProductForm.forms.financialProduct.values;
    },
    forms({ financialProductForm }) {
      return financialProductForm.forms;
    },
  },
});

export const financialProductFormSlice = slice;
export const financialProductFormGettersDef = gettersDef;
