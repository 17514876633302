import { ContentSection, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  statusCode?: number;
  error?: string;
}

const ErrorPage: FunctionComponent<ComponentProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContentSection pageWrapSize="small">
        <SectionHeading center level={3}>
          {t('error:heading')}
        </SectionHeading>
      </ContentSection>
      <ContentSection pageWrapSize="medium">
        <Paragraph>{t('error:p-1')}</Paragraph>
        <Paragraph>{t('error:p-2')}</Paragraph>
        <Paragraph>{t('error:p-3')}</Paragraph>
        <Paragraph>{t('error:p-4')}</Paragraph>
        <Paragraph>{t('error:p-5')}</Paragraph>
      </ContentSection>
      <ContentSection pageWrapSize="small">
        <div className="u-text-center">
          <a className="c-btn" href={t('error:mainLink')}>
            {t('error:backToMain')}
          </a>
        </div>
      </ContentSection>
    </>
  );
};

export default ErrorPage;
