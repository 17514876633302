import { localeIdentifier } from '../../config';

export const formatNumber = (value: number | undefined, style = 'decimal'): string => {
  if (typeof value === 'number') {
    const formatter = new Intl.NumberFormat(localeIdentifier, {
      style,
    });

    return formatter.format(value);
  }

  return '';
};
