import { AxiosResponse } from 'axios';
import { UploadedFile } from '../../../../types/files';
import { axiosInstance } from '../../../api/fesRequest/axios';
import { fileToBase64 } from '../../../common/fileToBase64';

export interface AmlDocumentUploadRequestModel {
  file: {
    name: string;
    mimeType: string;
    base64: string;
  };
}

export interface AmlDocumentUploadResponseModel {
  id: string;
}

export interface AmlDocumentUpdateRequestModel {
  documentType: string;
  documentDescription?: string;
}

export interface AmlDocumentUpdateResponseModel {
  id: string;
}

export interface AmlDocumentDeleteResponseModel {
  id: string;
}

export const uploadAmlDocument = async (
  file: UploadedFile,
  onUploadProgress: (percentCompleted: number, bytesSent: number, totalBytes: number) => void
): Promise<AxiosResponse<AmlDocumentUploadResponseModel>> => {
  const b64data = await fileToBase64(file);

  const config = {
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      onUploadProgress(percentCompleted, progressEvent.loaded, progressEvent.total);
    },
  };

  return axiosInstance.post<AmlDocumentUploadResponseModel>(
    '/amlDocument',
    {
      file: {
        name: file.name,
        mimeType: file.type,
        base64: b64data,
      },
    },
    config
  );
};

export const deleteAmlDocument = (fileId: string): Promise<AxiosResponse<AmlDocumentDeleteResponseModel>> => {
  return axiosInstance.delete<AmlDocumentUploadResponseModel>(`/amlDocument/${fileId}`);
};

export const updateAmlDocument = (
  fileId: string,
  metadata: AmlDocumentUpdateRequestModel
): Promise<AxiosResponse<AmlDocumentUpdateResponseModel>> => {
  return axiosInstance.patch<AmlDocumentUploadResponseModel>(`/amlDocument/${fileId}`, metadata);
};
