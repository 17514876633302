import { differenceInYears, format as fnsFormat, format, isFuture, sub } from 'date-fns';
import { t } from 'i18next';
import * as Yup from 'yup';
import { AnyObject, Maybe } from 'yup';
import { InstitutionalTypeEnum } from '../../types/forms';

export const regExEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,61}))$/;

export const regexPhone = /^[0-9 +]+$/;

export const regexVat = /^[0-9a-zA-Z]+$/;

// `(?=[ -~]+)` - we check if all chars match 32 - 126 ASCII codes, so we exclude all accented chars.
// `[a-zA-Z]` - and if check above is fine, we consume all letters.
export const regexUnaccentedLetters = /^(?=[ -~]+)[a-zA-Z]+$/;

export const regexNumbers = /^[0-9]+$/;

export const maxMobileNumberLength = 15;
export const minMobileNumberLength = 9;
export const maxLandlineNumberLength = 15;
export const minLandlineNumberLength = 7;

export const maxTextAreaLength = 255;

// prettier-ignore
export const mobileNumberMask = [ /[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
// prettier-ignore
export const landlineNumberMask = [ /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
// prettier-ignore
export const unknownNumberTypeMask = [ /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const countTruthyValues = (arr: boolean[]) => arr.filter(Boolean).length;

export const passwordValidator = () =>
  Yup.string()
    .required(t('validation:required'))
    .test('valid-characters', t('validation:passwordRequirements'), (value) => {
      // eslint-disable-next-line no-useless-escape
      const regex = /^[A-Za-z0-9~´`!@#$%^&*()\-_+={}\[\]\/\\|:;'<>.?"£§»«¨]+$/;
      return regex.test(value || '');
    })
    .test('criteria-count', t('validation:passwordRequirements'), (value) => {
      if (!value) return false;

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumbers = /\d/.test(value);
      // eslint-disable-next-line no-useless-escape
      const hasSpecialChar = /[~´`!@#$%^&*()\-_+={}\[\]\/\\|:;'<>.?"£§»«¨]/.test(value);

      const criteriaCount = countTruthyValues([hasUpperCase, hasLowerCase, hasNumbers, hasSpecialChar]);
      return criteriaCount >= 3;
    });

const eircodeRegex = /^[AC-FHKNPRTV-Y0-9]{1}[0-9]{1}[0-9W]{1} ?[0-9AC-FHKNPRTV-Y]{4}$/;

export const checkIfEnterpriseSizeIsOptional = (institutionalType?: InstitutionalTypeEnum) => {
  return [InstitutionalTypeEnum.type12, InstitutionalTypeEnum.type13, InstitutionalTypeEnum.type14].includes(
    institutionalType! /* Can be undefined but it's safe to ignore it */
  );
};

const dateFormat = 'dd/MM/yyyy';

/**
 * - Pass years, months and/or days in the past.
 */
export const getFormattedDateInPast = ({
  years,
  months,
  days,
}: { years?: number; months?: number; days?: number } = {}) => {
  return format(
    sub(Date.now(), {
      years,
      months,
      days,
    }),
    dateFormat
  );
};

export const getMaxFirstRegistrationDate = () => {
  return getFormattedDateInPast();
};

export const getMaxDateOfIncorporationDate = () => {
  return getFormattedDateInPast();
};

export const getMaxLastActivationDate = () => {
  return getFormattedDateInPast();
};

export const getMaxLastProposalDate = () => {
  return getFormattedDateInPast();
};

export const getMaxLastPayoutDate = () => {
  return getFormattedDateInPast();
};

export const getMaxBirthDate = () => {
  return getFormattedDateInPast({ years: 18 });
};

export const getMinBirthDate = () => {
  return getFormattedDateInPast({ years: 120 });
};

/**
 *
 * Yup extension methods.
 *
 */

Yup.addMethod<Yup.StringSchema>(Yup.string, 'dateFormat', function format(formats, dateSeparator, message) {
  // need to be string for custom error message
  return this.test('testFormat', message, (value: string | null | undefined): boolean => {
    if (value) {
      const date = value.split(dateSeparator);
      return (
        value === fnsFormat(new Date(parseInt(date[2], 10), parseInt(date[1], 10) - 1, parseInt(date[0], 10)), formats)
      );
    }
    return true;
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'fullAge', function fullAge(years, dateSeparator, message) {
  // need to be string for custom error message
  return this.test('testAge', message, (value: string | null | undefined): boolean => {
    if (value) {
      const date = value.split(dateSeparator);
      return (
        differenceInYears(
          new Date(),
          new Date(parseInt(date[2], 10), parseInt(date[1], 10) - 1, parseInt(date[0], 10))
        ) >= years
      );
    }
    return true;
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'notInFuture', function notInFuture(dateSeparator, message) {
  // need to be string for custom error message
  return this.test('testFuture', message, (value: string | null | undefined): boolean => {
    if (value) {
      const date = value.split(dateSeparator);
      return !isFuture(new Date(parseInt(date[2], 10), parseInt(date[1], 10) - 1, parseInt(date[0], 10)));
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'elderAge', function elderAge(years, message) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const diff = differenceInYears(new Date(), new Date(value)) <= years;
      return diff;
    }
    return true;
  });
});

Yup.addMethod(Yup.string, 'eirCode', function eirCode(message) {
  return this.matches(eircodeRegex, message);
});

Yup.addMethod(Yup.number, 'maxFraction', function maxFraction(maxFraction, message) {
  return this.test('maxDigitsAfterDecimal', message, function test(number) {
    if (number !== undefined && number !== null) {
      const fractionPart = (number.toString().split('.')[1] || '').length;
      return !(fractionPart > maxFraction);
    }

    return true;
  });
});

Yup.addMethod(Yup.number, 'maxTotalDigitLength', function maxTotalDigitLength(maxLength, message) {
  return this.test('maxTotalDigitLength', message, function test(value) {
    return !(String(value).replace('.', '').length > maxLength);
  });
});

/**
 * - Conditional required with baked in validation error translation.
 */
function requiredWhen(this: Yup.StringSchema | Yup.NumberSchema, condition: boolean) {
  return condition ? this.required(t('validation:required')) : this;
}

Yup.addMethod(Yup.string, 'requiredWhen', requiredWhen);
Yup.addMethod(Yup.number, 'requiredWhen', requiredWhen);

function numberValidator(this: Yup.NumberSchema, maxLength: number, maxFraction?: number) {
  let validator = this.maxTotalDigitLength(maxLength, t('validation:maxLength', { value: maxLength })).transform(
    (_, val) => (val === Number(val) ? val : undefined)
  );

  if (!(maxFraction === undefined) && maxFraction !== 0 && Number.isInteger(maxFraction)) {
    validator = validator.maxFraction(maxFraction, t('validation:maximumFraction', { value: maxFraction }));
  } else if (maxFraction === 0) {
    validator = validator.integer(t('validation:numberInteger'));
  }

  return validator;
}

Yup.addMethod(Yup.number, 'validateNumber', numberValidator);

function shouldBeEmpty<ReturnSchema extends Yup.Schema>(this: Yup.StringSchema | Yup.NumberSchema) {
  return (this as Yup.Schema).test(
    'shouldBeEmpty',
    (value) => value === undefined || value === '' || value === null
  ) as unknown as ReturnSchema;
}

Yup.addMethod(Yup.string, 'shouldBeEmpty', shouldBeEmpty as any);
Yup.addMethod(Yup.number, 'shouldBeEmpty', shouldBeEmpty as any);

function exactLength(this: Yup.StringSchema, length: number) {
  return this.min(length, t('validation:minLength', { value: length })).max(
    length,
    t('validation:maxLength', { value: length })
  );
}

Yup.addMethod(Yup.string, 'exactLength', exactLength);

function lengthBetween(this: Yup.StringSchema, minLength: number, maxLength: number) {
  return this.min(minLength, t('validation:minLength', { value: minLength })).max(
    maxLength,
    t('validation:maxLength', { value: maxLength })
  );
}

Yup.addMethod(Yup.string, 'lengthBetween', lengthBetween);

/**
 * - Required with default validation error message.
 */
function requiredWithMsg<ReturnSchema extends Yup.Schema>(
  this: Yup.StringSchema | Yup.NumberSchema,
  errorMessage?: string
) {
  return this.required(errorMessage || t('validation:required')) as unknown as ReturnSchema;
}

Yup.addMethod(Yup.string, 'requiredWithMsg', requiredWithMsg as any);
Yup.addMethod(Yup.number, 'requiredWithMsg', requiredWithMsg as any);

/**
 * - Accept only unaccented letters.
 */
function unaccentedLetters<ReturnSchema extends Yup.Schema>(this: Yup.StringSchema) {
  return this.matches(regexUnaccentedLetters, t('validation:invalidCharacters')) as unknown as ReturnSchema;
}

Yup.addMethod(Yup.string, 'unaccentedLetters', unaccentedLetters as any);

function fullAge(this: Yup.StringSchema, years = 18) {
  // need to be string for custom error message
  const message = t('validation:fullAge');

  return this.test('testAge', message, (value) => {
    if (value !== undefined) {
      const date = value.split('/').map(Number);

      return (
        differenceInYears(
          new Date(),
          new Date(
            date[2], // Year
            date[1] - 1, // Month index (e.g. January === 0)
            date[0] // Day
          )
        ) >= years
      );
    }
    return true;
  });
}

Yup.addMethod(Yup.string, 'fullAge', fullAge);

function onlyNumbers(this: Yup.StringSchema) {
  return this.matches(regexNumbers, t('validation:onlyNumbers'));
}

Yup.addMethod(Yup.string, 'onlyNumbers', onlyNumbers);

/**
 * - Helper type for functions handling multiple schemas like for example `requiredWhen`.
 */
type FnWithReturn<FN extends (...args: any[]) => any, RT> = (...args: Parameters<FN>) => RT;

declare module 'yup' {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = Yup.AnyObject,
    TDefault = undefined,
    TFlags extends Yup.Flags = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TOut extends TType = TType,
  > extends Yup.Schema<TType, TContext, TDefault, TFlags> {
    dateFormat(formats, dateSeperator, message): StringSchema<TType, TContext>;
    fullAge(formats, dateSeperator, message): StringSchema<TType, TContext>;
    notInFuture(formats, message): StringSchema<TType, TContext>;
    elderAge(formats, message): StringSchema<TType, TContext>;
    eirCode(message): StringSchema<TType, TContext>;
    /**
     * @see {@link requiredWhen} <- implementation
     */
    requiredWhen: FnWithReturn<typeof requiredWhen, Yup.StringSchema>;
    shouldBeEmpty: FnWithReturn<typeof shouldBeEmpty, Yup.StringSchema>;
    exactLength: FnWithReturn<typeof exactLength, Yup.StringSchema>;
    lengthBetween: FnWithReturn<typeof lengthBetween, StringSchema<TType, TContext, TDefault, TFlags>>;
    requiredWithMsg: FnWithReturn<typeof requiredWithMsg, StringSchema<NonNullable<TType>, TContext, TDefault, TFlags>>;
    unaccentedLetters: FnWithReturn<typeof unaccentedLetters, StringSchema<TType, TContext>>;
    onlyNumbers: FnWithReturn<typeof onlyNumbers, StringSchema<TType, TContext, TDefault, TFlags>>;
  }

  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = Yup.AnyObject,
    TDefault = undefined,
    TFlags extends Yup.Flags = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    TOut extends TType = TType,
  > extends Yup.Schema<TType, TContext, TDefault, TFlags> {
    maxFraction(maxFraction: number, message: string): NumberSchema<TType, TContext>;
    maxTotalDigitLength(maxTotalDigitLength: number, message: string): NumberSchema<TType, TContext>;
    /**
     * @see {@link requiredWhen} <- implementation
     */
    requiredWhen: FnWithReturn<typeof requiredWhen, Yup.NumberSchema>;
    requiredWithMsg: FnWithReturn<typeof requiredWithMsg, NumberSchema<NonNullable<TType>, TContext, TDefault, TFlags>>;
    validateNumber: typeof numberValidator;
    shouldBeEmpty: FnWithReturn<typeof shouldBeEmpty, NumberSchema<TType, TContext, TDefault, TFlags>>;
  }
}

export default Yup;
