import {
  Accordion,
  Button,
  ButtonContainer,
  Card,
  ContentSection,
  Fieldset,
  Form,
  Heading,
  Icon,
  Layout,
  Spinner,
} from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formAutocomplete } from '../../config';
import { WizardPagesEnum } from '../../screens/NewQuotation/newQuotation.slice';
import { VehicleType } from '../../screens/VehicleSelectPage/vehicleTypeSelectPage.model';
import {
  getMaxFirstRegistrationDate,
  getMaxLastActivationDate,
  getMaxLastProposalDate,
} from '../../services/common/validation';
import {
  QuoteCalculationServicePlanResponseModel,
  useQuoteCalculateMutation,
} from '../../services/redux/features/api/quoteCalculate.api-slice';
import { useSendStandaloneServicePlanMutation } from '../../services/redux/features/api/standaloneServicePlan.api-slice';
import { actions, useGetters } from '../../services/redux/index';
import { useNavigate } from '../../services/routing/index';
import { servicePlanData } from '../AdditionalServicePlan/AdditionalServicePlan';
import { DatePickerFormField } from '../Fieldset/DatePickerFormField';
import { NumericInputFormField } from '../Fieldset/NumericInputFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import { StandaloneServicePlanFormState } from './standaloneServicePlanForm.slice';
import { standaloneServicePlanFormValidationSchema } from './standaloneServicePlanForm.validator';

const StandaloneServicePlanForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isBrandApproval, _] = useState<boolean>(false);
  const formName = 'standaloneServicePlan';
  const { standaloneServicePlanValues } = useGetters().standaloneServicePlanForm;
  const shouldValidate = useGetters().standaloneServicePlanForm.standaloneServicePlanValidationTrigger;

  const vehicleType: VehicleType = useGetters().vehicleTypeSelectPage.vehicleTypeSelectForm.values.selectedType;
  const isNewVehicle = vehicleType === 'newBto';

  const [calculate, calculateParams] = useQuoteCalculateMutation();
  const isLoadingCalculation = calculateParams.isLoading;
  const calculationData = calculateParams.data;

  const [sendStandaloneServicePlan, sendStandaloneServicePlanParams] = useSendStandaloneServicePlanMutation();
  const isLoadingSave = sendStandaloneServicePlanParams.isLoading;

  const servicePlanList = servicePlanData.map((servicePlan) => ({ label: servicePlan.label, value: servicePlan.key }));
  servicePlanList.unshift({ label: t('formControls:select.noSelection'), value: '' });

  return (
    <Spinner busy={isLoadingCalculation || isLoadingSave} section>
      <Formik
        validationSchema={standaloneServicePlanFormValidationSchema(isNewVehicle)}
        validateOnMount
        validateOnBlur
        initialValues={standaloneServicePlanValues}
        onSubmit={() => {
          sendStandaloneServicePlan({ standaloneServicePlanValues, isNewVehicle }).then(() => {
            actions.newQuotation.wizardNavigateToPage(WizardPagesEnum.customerTypeSelection);
          });
        }}
      >
        {({ values, setFieldValue, handleSubmit, isValid }) => {
          const paymentTermList = [
            { label: t('formControls:select.noSelection'), value: '' },
            { label: '12', value: '12' },
            { label: '24', value: '24' },
            { label: '36', value: '36' },
          ].filter((term) => {
            if (Number(values.paymentTerm) > Number(values.contractDuration)) {
              setFieldValue('paymentTerm', undefined);
            }

            return Number(term.value) <= Number(values.contractDuration);
          });

          return (
            <>
              <ContentSection pageWrapSize="small">
                <Card element="div">
                  <Accordion className="u-m">
                    <Accordion.Item defaultOpen title={t('standaloneServicePlan:financialProduct')}>
                      <PersistFormInState<StandaloneServicePlanFormState>
                        formName={formName}
                        shouldValidate={shouldValidate}
                        updateForm={actions.standaloneServicePlanForm.updateForm}
                      />
                      <Form autoComplete={formAutocomplete}>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              <SelectFormField
                                fieldName="productType"
                                labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.productType.label"
                                isDisabled
                                optionList={[
                                  {
                                    label: 'Service Plan',
                                    value: 'servicePlan',
                                  },
                                ]}
                                testId="productType"
                              />
                            </Layout.Item>
                            <Layout.Item default="1/3" s="1/1">
                              <SelectFormField
                                fieldName="servicePlan"
                                labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.servicePlan.label"
                                optionList={servicePlanList}
                                testId="servicePlan"
                              />
                            </Layout.Item>
                            <Layout.Item default="1/3" s="1/1">
                              <Layout>
                                <Layout.Item default="3/5">
                                  {/* todo: implement search button action */}
                                  <Button type="button" secondary full className="u-mt">
                                    {t('standaloneServicePlan:searchButton')}
                                  </Button>
                                </Layout.Item>
                                <Layout.Item default="1/4">
                                  {isBrandApproval && <Icon className="u-mt" name="semantic-lock" />}
                                </Layout.Item>
                              </Layout>
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              <SelectFormField
                                fieldName="vatApplicable"
                                labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.vatApplicable.label"
                                optionList={[
                                  { label: t('formControls:select.noSelection'), value: '' },
                                  { label: 'Yes', value: 'yes' },
                                  { label: 'No', value: 'no' },
                                ]}
                                isDisabled
                                testId="vatApplicable"
                              />
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              <DatePickerFormField
                                fieldName="lastProposalDate"
                                labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.lastProposalDate.label"
                                testId="lastProposalDate"
                                isDisabled
                                maxDate={getMaxLastProposalDate()}
                              />
                            </Layout.Item>
                            <Layout.Item default="1/3" s="1/1">
                              <DatePickerFormField
                                fieldName="lastActivationDate"
                                labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.lastActivationDate.label"
                                testId="lastActivationDate"
                                isDisabled
                                maxDate={getMaxLastActivationDate()}
                              />
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                      </Form>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion className="u-m">
                    <Accordion.Item defaultOpen title={t('standaloneServicePlan:servicePlan')}>
                      <Form autoComplete={formAutocomplete}>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              <DatePickerFormField
                                fieldName="firstRegistrationDate"
                                labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.firstRegistrationDate.label"
                                testId="firstRegistrationDate"
                                maxDate={getMaxFirstRegistrationDate()}
                              />
                            </Layout.Item>
                            <Layout.Item default="1/3" s="1/1">
                              <NumericInputFormField
                                type="km"
                                fieldName="recordedKilometres"
                                labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.recordedKilometres.label"
                                testId="recordedKilometres"
                              />
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              <SelectFormField
                                fieldName="paymentType"
                                labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.paymentType.label"
                                optionList={[
                                  { label: t('formControls:select.noSelection'), value: '' },
                                  { label: 'Monthly', value: 'Monthly' },
                                  { label: 'Upfront', value: 'Upfront' },
                                ]}
                                testId="paymentType"
                              />
                            </Layout.Item>
                            <Layout.Item default="1/3" s="1/1">
                              <SelectFormField
                                fieldName="contractDuration"
                                labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.contractDuration.label"
                                optionList={[
                                  { label: t('formControls:select.noSelection'), value: '' },
                                  { label: '12', value: '12' },
                                  { label: '36', value: '36' },
                                  { label: '48', value: '48' },
                                ]}
                                testId="contractDuration"
                              />
                            </Layout.Item>
                            {values.paymentType !== 'Upfront' && (
                              <Layout.Item default="1/3" s="1/1">
                                <SelectFormField
                                  fieldName="paymentTerm"
                                  labelTranslationKey="standaloneServicePlan:standaloneServicePlanForm.paymentTerm.label"
                                  optionList={paymentTermList}
                                  testId="paymentTerm"
                                />
                              </Layout.Item>
                            )}
                          </Layout>
                        </Fieldset.Row>
                        {values.paymentType && (
                          <Fieldset.Row className="u-mt">
                            <Layout>
                              <Layout.Item default="1/3" s="1/1">
                                <Heading level={6}>
                                  {values.paymentType === 'Monthly' && t('standaloneServicePlan:monthlyPayment')}
                                  {values.paymentType === 'Upfront' && t('standaloneServicePlan:upfrontPayment')}
                                </Heading>
                              </Layout.Item>
                              <Layout.Item default="1/3" s="1/1" className="input-with-icon">
                                <NumericInputFormField
                                  fieldName="calculation"
                                  testId="calculation"
                                  type="money"
                                  isDisabled
                                  isSuccess={calculationData?.isQuoteValid === true}
                                  isError={!calculationData?.isQuoteValid === false}
                                />
                              </Layout.Item>
                              <Layout.Item default="1/3" s="1/1">
                                <Button
                                  type="button"
                                  secondary
                                  full
                                  onClick={async () => {
                                    const data = (await calculate({
                                      productType: 'servicePlan',
                                      paymentType: values.paymentType?.toLowerCase(),
                                    }).unwrap()) as QuoteCalculationServicePlanResponseModel;
                                    setFieldValue('calculation', data.payment);
                                  }}
                                >
                                  {t('standaloneServicePlan:calculateButton')}
                                </Button>
                              </Layout.Item>
                            </Layout>
                          </Fieldset.Row>
                        )}
                      </Form>
                    </Accordion.Item>
                  </Accordion>
                </Card>
              </ContentSection>

              <ContentSection pageWrapSize="small">
                <ButtonContainer center>
                  <Button
                    testId="button_goBack"
                    secondary
                    onClick={() => {
                      if (isNewVehicle) {
                        navigate.vehicleSelect();
                      } else {
                        navigate.newQuote(`/${WizardPagesEnum.vehicle}`);
                      }
                    }}
                  >
                    {t('standaloneServicePlan:back')}
                  </Button>
                  <Button
                    type="submit"
                    testId="button_continue"
                    disabled={!isValid}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {t('standaloneServicePlan:continue')}
                  </Button>
                </ButtonContainer>
              </ContentSection>
            </>
          );
        }}
      </Formik>
    </Spinner>
  );
};

export default StandaloneServicePlanForm;
