/* eslint-disable react/no-danger */
import { Button, Notification, NotificationsGroup } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from '../../services/routing';

const PasswordUpcomingExpirationNotification: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(true);

  return (
    <NotificationsGroup>
      <Notification
        status="warning"
        title={t('passwordUpcomingExpirationNotification:title')}
        visible={isVisible}
        onCloseClick={() => setIsVisible(false)}
        buttons={[
          <Button key="1" element="a" onClick={() => navigate.changePassword()} secondary>
            {t('passwordUpcomingExpirationNotification:resetPasswordButton')}
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: t('passwordUpcomingExpirationNotification:content') }} />
      </Notification>
    </NotificationsGroup>
  );
};

export default PasswordUpcomingExpirationNotification;
