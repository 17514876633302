import { apiSlice } from './api.api-slice';

export interface Outlet {
  companyRegistrationNumber: string;
  companyName: string;
}

export const outletSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    outletList: builder.query<Array<Outlet>, void>({
      query: () => '/outlets',
      extraOptions: {
        applyProductPath: false,
      },
      transformResponse: (rawResponse: { outletList: Array<Outlet> }) => {
        return rawResponse.outletList;
      },
    }),
  }),
});

export const { useOutletListQuery } = outletSlice;
