import { Accordion, Button, Fieldset, Form, Heading, Layout, Spinner } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formAutocomplete } from '../../config';
import { VehicleType } from '../../screens/VehicleSelectPage/vehicleTypeSelectPage.model';
import { actions, getters, useGetters } from '../../services/redux';
import {
  QuoteCalculateResponse,
  useQuoteCalculateMutation,
} from '../../services/redux/features/api/quoteCalculate.api-slice';
import { ProductType } from '../../types/forms';
import { NumericInputFormField } from '../Fieldset/NumericInputFormField';
import { RegistrationNumberFormField } from '../Fieldset/RegistrationNumberFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextareaInputFormField } from '../Fieldset/TextareaInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import GoalSeekRadioButtonFormField from './GoalSeekRadioButtonFormField';
import {
  FinancialProductSpecificationFormModel,
  FinancialProductSpecificationFormModelFields,
  GoalSeekFieldName,
} from './financialProductSpecificationForm.model';
import { FinancialProductSpecificationFormState } from './financialProductSpecificationForm.slice';
import { financialProductSpecificationValidationSchema } from './financialProductSpecificationForm.validator';
import './style.scss';

const typeSafeFormFieldName = (formFieldName: keyof FinancialProductSpecificationFormModel) => formFieldName;

const createFormInputs = (() => {
  return {
    invoicePrice: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('invoicePrice')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.invoicePrice.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.invoicePrice.placeholder"
        type="money"
      />
    ),
    tradeInValue: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('tradeInValue')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.tradeInValue.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.tradeInValue.placeholder"
        type="money"
      />
    ),
    regNumberTradeInVehicle: () => (
      <RegistrationNumberFormField
        fieldName={typeSafeFormFieldName('regNumberTradeInVehicle')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.regNumberTradeInVehicle.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.regNumberTradeInVehicle.placeholder"
      />
    ),
    settlement: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('settlement')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.settlement.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.settlement.placeholder"
        type="money"
      />
    ),
    brandContribution: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('brandContribution')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.brandContribution.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.brandContribution.placeholder"
        type="money"
      />
    ),
    cashDeposit: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('cashDeposit')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.cashDeposit.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.cashDeposit.placeholder"
        type="money"
      />
    ),
    cashDepositSource: () => (
      <SelectFormField
        fieldName={typeSafeFormFieldName('cashDepositSource')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.cashDepositSource.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.cashDepositSource.placeholder"
        optionList={[
          { label: t('formControls:select.noSelection'), value: '' },
          {
            label: 'Bonus',
            value: 'bonus',
          },
          {
            label: 'Business Income',
            value: 'businessIncome',
          },
          {
            label: 'Dividend',
            value: 'dividend',
          },
          {
            label: 'Gift',
            value: 'gift',
          },
          {
            label: 'Inheritance',
            value: 'inheritance',
          },
          {
            label: 'Insurance Claim',
            value: 'insuranceClaim',
          },
          {
            label: 'Interest',
            value: 'interest',
          },
          {
            label: 'Redundancy',
            value: 'redundancy',
          },
          {
            label: 'Retirement',
            value: 'retirement',
          },
          {
            label: 'Salary',
            value: 'salary',
          },
          {
            label: 'Sale of Asset',
            value: 'saleOfAsset',
          },
          {
            label: 'Savings',
            value: 'savings',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ]}
        testId="cashDepositSource"
      />
    ),
    cashDepositForm: () => (
      <SelectFormField
        fieldName={typeSafeFormFieldName('cashDepositForm')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.cashDepositForm.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.cashDepositForm.placeholder"
        optionList={[
          { label: t('formControls:select.noSelection'), value: '' },
          {
            label: 'Debit / Credit Card',
            value: 'debitCreditCard',
          },
          {
            label: 'EFT',
            value: 'eft',
          },
          {
            label: 'Other',
            value: 'other',
          },
        ]}
        testId="cashDepositForm"
      />
    ),
    cashDepositSourceNotes: () => (
      <TextareaInputFormField
        fieldName={typeSafeFormFieldName('cashDepositSourceNotes')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.cashDepositSourceNotes.label"
      />
    ),
    cashDepositFormNotes: () => (
      <TextareaInputFormField
        fieldName={typeSafeFormFieldName('cashDepositFormNotes')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.cashDepositFormNotes.label"
      />
    ),
    totalDeposit: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('totalDeposit')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.totalDeposit.label"
        type="money"
        isDisabled
      />
    ),
    totalDepositPercentage: (goalSeek: GoalSeekFieldName) => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('totalDepositPercentage')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.totalDepositPercentage.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.totalDepositPercentage.placeholder"
        type="percentage"
        isDisabled={goalSeek !== 'totalDepositPercentage'}
      />
    ),
    recordedKm: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('recordedKm')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.recordedKm.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.recordedKm.placeholder"
        type="km"
      />
    ),
    annualKm: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('annualKm')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.annualKm.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.annualKm.placeholder"
        type="km"
      />
    ),
    totalFinalKm: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('totalFinalKm')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.totalFinalKm.label"
        type="km"
        isDisabled
      />
    ),
    excessKmCharge: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('excessKmCharge')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.excessKmCharge.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.excessKmCharge.placeholder"
        type="money"
        isDisabled
      />
    ),
    grantIfaSeai: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('grantIfaSeai')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.grantIfaSeai.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.grantIfaSeai.placeholder"
        type="money"
      />
    ),
    vrtAmount: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('vrtAmount')}
        labelTranslationKey="financialProductSpecification:financialProductSpecificationForm.vrtAmount.label"
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.vrtAmount.placeholder"
        type="money"
      />
    ),
    amountFinanced: () => (
      <NumericInputFormField fieldName={typeSafeFormFieldName('amountFinanced')} type="money" isDisabled />
    ),
    gmfvAmount: () => <NumericInputFormField fieldName={typeSafeFormFieldName('gmfvAmount')} type="money" isDisabled />,
    monthlyPayment: (goalSeek: GoalSeekFieldName, isQuoteValid?: boolean) => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('monthlyPayment')}
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.monthlyPayment.placeholder"
        type="money"
        isDisabled={goalSeek !== 'monthlyPayment'}
        isSuccess={isQuoteValid}
        isError={isQuoteValid === false}
      />
    ),
    totalApr: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('totalApr')}
        placeholderTranslationKey="financialProductSpecification:financialProductSpecificationForm.totalApr.placeholder"
        type="percentage"
        isDisabled
      />
    ),
    finalPayment: (goalSeek: GoalSeekFieldName) => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('finalPayment')}
        type="money"
        isDisabled={goalSeek !== 'finalPayment'}
      />
    ),
  };
}) satisfies () => Record<FinancialProductSpecificationFormModelFields, (...args: any[]) => ReactElement>;

interface FinancialProductSpecificationFormProps {
  financialProductType: ProductType | undefined;
  vehicleType: VehicleType;
}

const FinancialProductSpecificationForm: FunctionComponent<FinancialProductSpecificationFormProps> = ({
  financialProductType,
  vehicleType,
}) => {
  const { t } = useTranslation();
  const formName = 'financialProductSpecification';

  const [calculate, { isLoading, data }] = useQuoteCalculateMutation();
  const [isQuoteValid, setIsQuoteValid] = useState<boolean | undefined>();

  const [initialValues, setInitialValues] = useState(
    () => getters.financialProductSpecificationForm.financialProductSpecificationValues
  );
  const shouldValidate = useGetters().financialProductSpecificationForm.financialProductSpecificationValidationTrigger;
  const { isValid } = useGetters().financialProductSpecificationForm.forms.financialProductSpecification;

  const [goalSeek, setGoalSeek] = useState<GoalSeekFieldName>('monthlyPayment');

  const [inputs /* , updateInputs */] = useState<ReturnType<typeof createFormInputs>>(() => createFormInputs());

  const [isCalculationPending, setIsCalculationPending] = useState(false);

  useEffect(() => {
    setIsQuoteValid(data?.isQuoteValid);
  }, [data]);

  useEffect(() => {
    setIsQuoteValid(undefined);
  }, [goalSeek]);

  useEffect(() => {
    const performCalculation = async () => {
      const { values } = getters.financialProductSpecificationForm.forms.financialProductSpecification;

      const data = (await calculate({
        productType: financialProductType || '',
      }).unwrap()) as QuoteCalculateResponse;

      actions.financialProductPage.setCString({ cString: data.cString });

      const updatedFields: FinancialProductSpecificationFormModel = { ...values, ...data };

      setInitialValues(updatedFields);
    };

    if (isCalculationPending && isValid) {
      performCalculation();
    }

    setIsCalculationPending(false);
  }, [calculate, financialProductType, isCalculationPending, isValid, setInitialValues]);

  return (
    <>
      <Spinner busy={isLoading} section>
        <Formik
          validationSchema={financialProductSpecificationValidationSchema(vehicleType)}
          initialValues={initialValues}
          validateOnMount
          validateOnChange={false}
          validateOnBlur
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ values }) => {
            const isCashDepositAboveThreshold = (values['cashDeposit'] ? values['cashDeposit'] : 0) >= 10000;

            return (
              <Accordion className="u-m">
                <Accordion.Item
                  defaultOpen
                  title={t('financialProductSpecification:financialProductSpecificationForm.title')}
                >
                  <Form autoComplete={formAutocomplete}>
                    <PersistFormInState<FinancialProductSpecificationFormState>
                      formName={formName}
                      updateForm={actions.financialProductSpecificationForm.updateForm}
                      shouldValidate={shouldValidate}
                    />

                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="2/3" s="1/1">
                          <Layout>
                            <Layout.Item default="1/2" s="4/5">
                              {inputs.invoicePrice()}
                            </Layout.Item>
                            <Layout.Item default="1/2" s="1/5">
                              <GoalSeekRadioButtonFormField
                                className="goalseek-radio-normalize-label"
                                currentSelection={goalSeek}
                                name="invoicePrice"
                                onChange={setGoalSeek}
                              />
                            </Layout.Item>
                          </Layout>
                        </Layout.Item>
                        {vehicleType === 'newBto' && (
                          <Layout.Item default="1/3" s="1/5">
                            {inputs.grantIfaSeai()}
                          </Layout.Item>
                        )}
                      </Layout>
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="1/3" s="1/1">
                          {inputs.tradeInValue()}
                        </Layout.Item>
                        <Layout.Item default="1/3" className="u-hide@s" />

                        {vehicleType === 'newBto' && (
                          <Layout.Item default="1/3" s="1/1">
                            {inputs.vrtAmount()}
                          </Layout.Item>
                        )}
                      </Layout>
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="1/3" s="1/1">
                          {inputs.regNumberTradeInVehicle()}
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>
                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="2/3" s="1/1">
                          <Layout>
                            <Layout.Item default="1/2" s="1/1">
                              {inputs.settlement()}
                            </Layout.Item>
                            <Layout.Item default="1/2" s="1/1">
                              <Button secondary full className="u-mt u-mt-none@s" type="button">
                                {t(
                                  'financialProductSpecification:financialProductSpecificationForm.getSettlementButton'
                                )}
                              </Button>
                            </Layout.Item>
                          </Layout>
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="1/3" s="1/1">
                          {inputs.brandContribution()}
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="2/3" s="1/1">
                          <Layout>
                            <Layout.Item default="1/2" s="4/5">
                              {inputs.cashDeposit()}
                            </Layout.Item>
                            <Layout.Item default="1/2" s="1/5">
                              <GoalSeekRadioButtonFormField
                                className="goalseek-radio-normalize-label"
                                currentSelection={goalSeek}
                                name="cashDeposit"
                                onChange={setGoalSeek}
                              />
                            </Layout.Item>
                          </Layout>
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>
                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="1/3" s="1/1">
                          {inputs.totalDeposit()}
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="2/3" s="1/1">
                          <Layout>
                            <Layout.Item default="1/2" s="4/5">
                              {inputs.totalDepositPercentage(goalSeek)}
                            </Layout.Item>
                            <Layout.Item default="1/2" s="1/5">
                              <GoalSeekRadioButtonFormField
                                className="goalseek-radio-normalize-label"
                                currentSelection={goalSeek}
                                name="totalDepositPercentage"
                                onChange={setGoalSeek}
                              />
                            </Layout.Item>
                          </Layout>
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>

                    {isCashDepositAboveThreshold && (
                      <>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.cashDepositSource()}
                            </Layout.Item>
                            <Layout.Item default="1/3" className="u-hide@s" />
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.cashDepositForm()}
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                          <Layout>
                            {values.cashDepositSource === 'other' && (
                              <Layout.Item default="1/2" s="1/1">
                                {inputs.cashDepositSourceNotes()}
                              </Layout.Item>
                            )}
                            {values.cashDepositForm === 'other' && (
                              <Layout.Item default="1/2" s="1/1">
                                {inputs.cashDepositFormNotes()}
                              </Layout.Item>
                            )}
                          </Layout>
                        </Fieldset.Row>
                      </>
                    )}

                    {(financialProductType === 'pcp' || financialProductType === 'hirePurchaseBalloon') && (
                      <>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.recordedKm()}
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                      </>
                    )}
                    {financialProductType === 'pcp' && (
                      <>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.annualKm()}
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>

                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.totalFinalKm()}
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>

                        <Fieldset.Row>
                          <Layout className="u-mb-large">
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.excessKmCharge()}
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                      </>
                    )}
                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="2/3" m="1/1" s="1/1">
                          <Layout>
                            <Layout.Item default="2/5">
                              <Heading className="u-text-left u-m-none u-mt-small" level={6}>
                                {t(
                                  'financialProductSpecification:financialProductSpecificationForm.amountFinanced.label'
                                )}
                              </Heading>
                            </Layout.Item>
                            <Layout.Item default="3/5">
                              <Layout>
                                <Layout.Item default="5/6">{inputs.amountFinanced()}</Layout.Item>
                              </Layout>
                            </Layout.Item>
                          </Layout>
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="2/3" m="1/1" s="1/1">
                          {financialProductType === 'pcp' && (
                            <Layout>
                              <Layout.Item default="2/5">
                                <Heading className="u-text-left u-m-none u-mt-small" level={6}>
                                  {t(
                                    'financialProductSpecification:financialProductSpecificationForm.gmfvAmount.label'
                                  )}
                                </Heading>
                              </Layout.Item>
                              <Layout.Item default="3/5">
                                <Layout>
                                  <Layout.Item default="5/6">{inputs.gmfvAmount()}</Layout.Item>
                                </Layout>
                              </Layout.Item>
                            </Layout>
                          )}

                          {financialProductType === 'hirePurchaseBalloon' && (
                            <Layout>
                              <Layout.Item default="2/5">
                                <Heading className="u-text-left u-m-none u-mt-small" level={6}>
                                  {t(
                                    'financialProductSpecification:financialProductSpecificationForm.finalPayment.label'
                                  )}
                                </Heading>
                              </Layout.Item>
                              <Layout.Item default="3/5">
                                <Layout>
                                  <Layout.Item default="5/6">{inputs.finalPayment(goalSeek)}</Layout.Item>
                                  <Layout.Item default="1/6">
                                    <GoalSeekRadioButtonFormField
                                      className="goalseek-radio"
                                      currentSelection={goalSeek}
                                      name="finalPayment"
                                      onChange={setGoalSeek}
                                    />
                                  </Layout.Item>
                                </Layout>
                              </Layout.Item>
                            </Layout>
                          )}
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="2/3" m="1/1" s="1/1">
                          <Layout>
                            <Layout.Item default="2/5">
                              <Heading className="u-text-left u-m-none u-mt-small" level={6}>
                                {t(
                                  'financialProductSpecification:financialProductSpecificationForm.monthlyPayment.label'
                                )}
                              </Heading>
                            </Layout.Item>
                            <Layout.Item default="3/5">
                              <Layout>
                                <Layout.Item default="5/6">{inputs.monthlyPayment(goalSeek, isQuoteValid)}</Layout.Item>
                                <Layout.Item default="1/6">
                                  <GoalSeekRadioButtonFormField
                                    className="goalseek-radio"
                                    currentSelection={goalSeek}
                                    name="monthlyPayment"
                                    onChange={setGoalSeek}
                                  />
                                </Layout.Item>
                              </Layout>
                            </Layout.Item>
                          </Layout>
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="2/3" m="1/1" s="1/1">
                          <Layout>
                            <Layout.Item default="2/5">
                              <Heading className="u-text-left u-m-none u-mt-small" level={6}>
                                {t('financialProductSpecification:financialProductSpecificationForm.totalApr.label')}
                              </Heading>
                            </Layout.Item>
                            <Layout.Item default="3/5">
                              <Layout>
                                <Layout.Item default="5/6">{inputs.totalApr()}</Layout.Item>
                              </Layout>
                            </Layout.Item>
                          </Layout>
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <Layout className="o-layout--right u-mt">
                        <Layout.Item default="1/2" m="1/1" s="1/1">
                          <Layout>
                            <Layout.Item default="4/5" m="1/1" s="1/1">
                              <Button
                                secondary
                                full
                                type="button"
                                onClick={() => {
                                  actions.financialProductSpecificationForm.updateForm({
                                    triggerValidation: true,
                                    formName: 'financialProductSpecification',
                                  });
                                  setIsCalculationPending(true);
                                }}
                              >
                                {t('financialProductSpecification:financialProductSpecificationForm.calculateButton')}
                              </Button>
                            </Layout.Item>
                          </Layout>
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>
                  </Form>
                </Accordion.Item>
              </Accordion>
            );
          }}
        </Formik>
      </Spinner>
    </>
  );
};

export default FinancialProductSpecificationForm;
