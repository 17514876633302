import { ObjectSchema } from 'yup';
import { getEnumValues } from '../../services/common/miscUtils';
import Yup from '../../services/common/validation';
import { NoYesEnum } from '../../types/forms';
import { TradingDetailsSIFormModel } from './tradingDetailsSIForm.model';

export const createTradingDetailsSIFormValidator = (): ObjectSchema<TradingDetailsSIFormModel> => {
  return Yup.object({
    legalName: Yup.string().requiredWithMsg(),
    vatLiable: Yup.string().requiredWithMsg().oneOf(getEnumValues(NoYesEnum)),
    tradingAs: Yup.string().requiredWithMsg(),
    vatNumber: Yup.string().when(['vatLiable'], {
      is: (vatLiable: NoYesEnum) => {
        return vatLiable === NoYesEnum.yes;
      },
      then: (schema) => schema.requiredWithMsg(),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
};
