import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { TradingDetailsSIFormModel } from './tradingDetailsSIForm.model';
import { NoYesEnum } from '../../types/forms';

export interface TradingDetailsSIFormState
  extends ReduxStateWithForms<{
    tradingDetailsSIForm: ReduxTrackedForm<TradingDetailsSIFormModel>;
  }> {}

const tradingDetailsSIInitialValues: TradingDetailsSIFormModel = {
  legalName: '',
  vatLiable: NoYesEnum.yes,
  tradingAs: '',
  vatNumber: '',
};

const initialState: TradingDetailsSIFormState = {
  forms: {
    tradingDetailsSIForm: {
      ...createReduxTrackedFormDefaults<TradingDetailsSIFormModel>({ initialValues: tradingDetailsSIInitialValues }),
      values: {
        ...tradingDetailsSIInitialValues,
      },
    },
  },
};

const name = 'tradingDetailsSIForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<TradingDetailsSIFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    tradingDetailsTriggers({ tradingDetailsSIForm }) {
      return tradingDetailsSIForm.forms.tradingDetailsSIForm.triggers;
    },
    tradingDetailsValues({ tradingDetailsSIForm }) {
      return tradingDetailsSIForm.forms.tradingDetailsSIForm.values;
    },
    forms({ tradingDetailsSIForm }) {
      return tradingDetailsSIForm.forms;
    },
  },
});

export const tradingDetailsSIFormSlice = slice;
export const tradingDetailsSIFormGettersDef = gettersDef;
