/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';
import { ArrayHelpers, FieldArrayRenderProps, FormikProps } from 'formik';
import i18next, { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { BusinessCustomerTypesEnum } from '../../screens/NewQuotation/CustomerDataPage/customerDataPage.model';
import { CustomerTypeEnum } from '../../screens/NewQuotation/CustomerTypeSelectionPage/CustomerTypeSelectionPageModels';
import { ProductFamilyEnum } from '../../screens/ProductFamilySelectionPage/productFamilySelectionPageModel';
import { FlowTypeEnum, NoYesEnum } from '../../types/forms';
import { ReduxTrackedForm, TrackedFormInfo } from '../../types/reduxTrackedForm';
import { useGetters } from '../redux';

export type FormUpdateActionPayload<
  FormState extends { forms: Record<any, ReduxTrackedForm<Record<string, any>>> },
  FormName = keyof FormState['forms'],
> = {
  formName: FormName;
  values?: ReduxTrackedForm<any>['values'];
  isValid?: ReduxTrackedForm<any>['isValid'];
  dirty?: ReduxTrackedForm<any>['dirty'];
  isValidating?: ReduxTrackedForm<any>['isValidating'];
  isSubmitting?: ReduxTrackedForm<any>['isSubmitting'];
  triggerValidation?: boolean;
  triggerReset?: boolean;
  submitCount?: ReduxTrackedForm<any>['submitCount'];
  excluded?: ReduxTrackedForm<any>['excluded'];
  reset?: boolean;
  /**
   * - Prevent excluded form from loosing current values.
   * - Works only for this action.
   * - Use freezeValues on form to prevent any exclusion action
   * from changing the values.
   */
  keepValues?: boolean;
  /**
   * - As long as set to true exclusion won't reset form values.
   */
  freezeValues?: ReduxTrackedForm<any>['freezeValues'];
};

export type FormUpdateActionType<FormState extends { forms: Record<any, ReduxTrackedForm<Record<string, any>>> }> =
  PayloadAction<FormUpdateActionPayload<FormState>>;

export type FormUpdateActionTypeGeneric<
  FormState extends { forms: Record<any, ReduxTrackedForm<Record<string, any>>> } = any,
> = PayloadAction<Omit<FormUpdateActionPayload<FormState>, 'formName'>>;

/**
 * - Form updater action that each form integrated with redux should have.
 */
export const getFormUpdater = <FormState extends { forms: Record<any, ReduxTrackedForm<Record<string, any>>> }>() => {
  return (state: FormState, action: FormUpdateActionType<FormState>) => {
    if (action.payload.values !== undefined && !state.forms[action.payload.formName].excluded) {
      state.forms[action.payload.formName].values = action.payload.values;
    }
    if (action.payload.isValid !== undefined) {
      state.forms[action.payload.formName].isValid = action.payload.isValid;
    }
    if (action.payload.dirty !== undefined) {
      state.forms[action.payload.formName].dirty = action.payload.dirty;
    }
    if (action.payload.isValidating !== undefined) {
      state.forms[action.payload.formName].isValidating = action.payload.isValidating;
    }
    if (action.payload.isSubmitting !== undefined) {
      state.forms[action.payload.formName].isSubmitting = action.payload.isSubmitting;
    }
    if (action.payload.submitCount !== undefined) {
      state.forms[action.payload.formName].submitCount = action.payload.submitCount;
    }
    if (action.payload.triggerValidation !== undefined && !state.forms[action.payload.formName].excluded) {
      state.forms[action.payload.formName].triggers.validation = action.payload.triggerValidation;

      // If validation should be triggered deem it as started right away.
      if (action.payload.triggerValidation) {
        state.forms[action.payload.formName].isValidating = true;
      }
    }
    if (action.payload.triggerReset !== undefined) {
      state.forms[action.payload.formName].triggers.reset = action.payload.triggerReset;

      if (!action.payload.triggerReset) {
        /*  */
      } else if (state.forms[action.payload.formName].initialValues) {
        state.forms[action.payload.formName].values = {
          ...state.forms[action.payload.formName].initialValues,
        };
      } else {
        throw new Error(
          `Form ${String(
            action.payload.formName
          )} has no initialValues to reset to. Check createReduxTrackedFormDefaults and if initialValues are passed.`
        );
      }
    }
    if (action.payload.freezeValues !== undefined) {
      state.forms[action.payload.formName].freezeValues = action.payload.freezeValues;
    }
    if (action.payload.excluded !== undefined) {
      state.forms[action.payload.formName].excluded = action.payload.excluded;

      if (action.payload.excluded) {
        state.forms[action.payload.formName].excluded = true;
        state.forms[action.payload.formName].isValid = true;
        state.forms[action.payload.formName].isSubmitting = false;
        state.forms[action.payload.formName].isValidating = false;
        state.forms[action.payload.formName].dirty = false;
        state.forms[action.payload.formName].submitCount = 0;
      }
    }
  };
};

export const getFormExcluder = <FormState extends { forms: Record<any, ReduxTrackedForm<Record<string, any>>> }>() => {
  return (
    state: FormState,
    action: PayloadAction<Pick<FormUpdateActionPayload<FormState>, 'formName' | 'keepValues'>>
  ) => {
    state.forms[action.payload.formName].excluded = true;
    state.forms[action.payload.formName].isValid = true;
    state.forms[action.payload.formName].isSubmitting = false;
    state.forms[action.payload.formName].isValidating = false;
    state.forms[action.payload.formName].dirty = false;
    state.forms[action.payload.formName].submitCount = 0;

    if (action.payload.keepValues) return;
    if (state.forms[action.payload.formName].initialValues) {
      state.forms[action.payload.formName].values = {
        ...state.forms[action.payload.formName].initialValues,
      };
    } else {
      throw new Error(
        `Form ${String(
          action.payload.formName
        )} has no initialValues to reset to. Check createReduxTrackedFormDefaults and if initialValues are passed.`
      );
    }
  };
};

export const getFormsState = <FTO extends Record<string, ReduxTrackedForm<Record<string, any>>>>(
  formsToObserve: FTO
) => {
  return Object.entries(formsToObserve).reduce(
    (stateSummary, [formName, trackedForm]) => {
      return {
        ...stateSummary,
        [formName]: {
          isSubmitting: trackedForm.isSubmitting,
          isValidating: trackedForm.isValidating,
          isValid: trackedForm.isValid,
          dirty: trackedForm.dirty,
          submitCount: trackedForm.submitCount,
          excluded: trackedForm.excluded,
        },
      };
    },
    {} as { [k in keyof FTO]: TrackedFormInfo<FTO[k]> }
  );
};

/**
 * - Pass form state and receive dictionary of field names.
 * - Example return: `{ firstName: 'firstName' }`
 */
export const getFormFieldNames = <FormModel extends Record<string, any>>(formState: ReduxTrackedForm<FormModel>) => {
  return Object.keys(formState.values).reduce(
    (namesObject, name) => {
      return {
        ...namesObject,
        [name]: name,
      };
    },
    // Without Required<...> type will result in 'someFieldName' | undefined values.
    {} as { [k in keyof Required<FormModel>]: k }
  );
};

/**
 * - Objects representing TS enums can have some extra keys for fields with numeric values,
 * so we filter them out.
 */
export const getEnumKeys = (optionsEnum: Record<any, any>) => {
  return Object.keys(optionsEnum).filter((key) => Number.isNaN(Number(key)));
};

export const getEnumValues = <E extends Record<any, any>>(optionsEnum: E): Array<E[keyof E]> => {
  return getEnumKeys(optionsEnum).map((key) => optionsEnum[key]);
};

export const getEnumValuesNullable = <E extends Record<any, any>>(
  optionsEnum: E
): Array<E[keyof E] | null | undefined> => {
  return [...getEnumKeys(optionsEnum).map((key) => optionsEnum[key]), '', null, undefined];
};

export const getPlaceholderOption = (translationFile: string, fieldName: string, fieldsGroupKey: string = 'fields') => {
  const placeholderKey = `${translationFile}:${fieldsGroupKey}:${fieldName}:placeholder`;
  const placeholderLabel = t(placeholderKey);

  if (!i18next.exists(placeholderKey)) {
    throw new Error(`No placeholder translation for the select input was defined (${placeholderKey} not resolved). `);
  }

  return {
    label: placeholderLabel,
    value: '',
  };
};

/**
 * - Provide harmonized* translation and options enum to receive list of options for SelectFormField.
 * - Harmonized meaning that each enum field maps 1:1 to specific translation key defined under translationFile:options:filedName.
 * For example:
 * - `model.ts -> enum FieldAEnum { value1: 'Value 1' }`
 * - `myFile.json -> { options: { 'fieldA': { value1: 'Value 1 Label' } } }`
 * - You then pass `FieldAEnum` as optionsEnum, `myFile` as translationFile and 'fieldA' as fieldName.
 */
export const createOptionsList = <OE extends Record<any, any>>(
  optionsEnum: OE,
  translationFile: string,
  fieldName: string,
  placeholderGroupKey: string | null = 'fields'
): Array<{ label: string; value: any }> => {
  // Objects representing TS enums can have some extra keys for fields with numeric values,
  // so we filter them out.
  const keys = getEnumKeys(optionsEnum);

  const getTranslation = (key: string) => {
    // Looking for enum translations in enum.json.
    let translationKey = `enum:${fieldName}:${key}`;
    let text = t(translationKey);
    // i18n will return translationKey as translation value if key was not found.
    if (i18next.exists(translationKey)) {
      return text;
    }

    // Look for enum translation in dedicated file
    translationKey = `${translationFile}:options:${fieldName}:${key}`;
    text = t(translationKey);

    if (!i18next.exists(translationKey)) {
      throw new Error(`Translation key (${translationKey}) not resolved. `);
    }

    return text;
  };

  const options = keys.map((key) => {
    const label = getTranslation(key);

    return {
      label,
      value: optionsEnum[key],
    };
  });

  if (placeholderGroupKey === null) {
    return options;
  }

  return [getPlaceholderOption(translationFile, fieldName, placeholderGroupKey), ...options];
};

export const createYesNoOptionsList = (
  translationFile: string,
  fieldName: string,
  placeholderGroupKey: string | null = 'fields'
): ReturnType<typeof createOptionsList> => {
  return createOptionsList(NoYesEnum, translationFile, fieldName, placeholderGroupKey);
};

/**
 * - Create simple options for SelectFormField input based on array.
 * - This method assumes that each value of array is both value and translation for given item.
 * - If you have proper enum use `createOptionsList` and create suitable translation texts in json file.
 */
export const createOptionsListFromArray = <T extends ReadonlyArray<string | number> | Array<string | number>>(
  arr: T
): Array<{ label: string; value: any }> => {
  return arr.map((value) => {
    return { label: String(value), value };
  });
};

/**
 * Similar to createOptionsList, but in format suitable for combobox component and without placeholder
 */
export const createComboboxList = <OE extends Record<any, any>>(
  optionsEnum: OE,
  translationFile: string,
  fieldName: string
) => {
  // Objects representing TS enums can have some extra keys for fields with numeric values,
  // so we filter them out.
  const keys = getEnumKeys(optionsEnum);

  const options = keys.map((key) => {
    const translationKey = `${translationFile}:options:${fieldName}:${key}`;
    const label = t(translationKey);

    if (!i18next.exists(translationKey)) {
      throw new Error(`Translation key (${translationKey}) not resolved. `);
    }

    return {
      value: label,
      key: optionsEnum[key],
    };
  });

  return options;
};

const createCustomerCheckerObj = (
  customerType: CustomerTypeEnum | null,
  businessType: BusinessCustomerTypesEnum | undefined,
  productFamily: ProductFamilyEnum | null
): Record<FlowTypeEnum, boolean> => {
  return {
    business: customerType === CustomerTypeEnum.business,
    consumer: customerType === CustomerTypeEnum.consumer,
    ltdPlc: businessType === BusinessCustomerTypesEnum.ltdPlc,
    soleTrader: businessType === BusinessCustomerTypesEnum.soleTrader,
    partnership: businessType === BusinessCustomerTypesEnum.partnership,
    other: businessType === BusinessCustomerTypesEnum.other,
    servicePlan: productFamily === ProductFamilyEnum.servicePlan,
    financialProduct: productFamily === ProductFamilyEnum.financialProduct,
  };
};

export type FlowTypeCheckerFn = (...args: Array<keyof typeof FlowTypeEnum>) => boolean;

/**
 * - Creates function for easy checking which FlowType we are in.
 * @see {@link FlowTypeEnum}
 */
export const useFlowTypeChecker = (): FlowTypeCheckerFn => {
  const { customerType } = useGetters().customerTypeSelectionPage.customerTypeSelectionForm.values;
  const businessType = useGetters().customerDataPage.businessCustomerTypeForm.values.selectedBusinessCustomerType;
  const { productFamily } = useGetters().productFamilySelectionPage;

  const [checkerObject, setCheckerObject] = useState(() =>
    createCustomerCheckerObj(customerType, businessType, productFamily)
  );

  useEffect(() => {
    setCheckerObject(createCustomerCheckerObj(customerType, businessType, productFamily));
  }, [customerType, businessType, productFamily]);

  const checkerFn = useCallback(
    (...args: Parameters<FlowTypeCheckerFn>) => {
      return args.some((t) => checkerObject[t]);
    },
    [checkerObject]
  );

  return checkerFn;
};

/**
 * - Wrapper for FieldArray render function that will make your `arrayHelpers.form.values` typed
 * according to provided model.
 * - E.g.:
 * `<FieldArray render={renderWithArrayHelpers<MyModel>((arrayHelpers) => {...}) />`
 */
export const renderWithArrayHelpers = <FormikState extends Record<string, any>>(
  handler: (arrayHelpers: ArrayHelpers<any> & { form: FormikProps<FormikState>; name: string }) => React.ReactNode
) => {
  return handler as (arrayHelpers: FieldArrayRenderProps) => React.ReactNode;
};

export const getDisabledFromFormState = (formInfo: TrackedFormInfo) => {
  return formInfo.excluded ? false : formInfo.isValidating || formInfo.isSubmitting;
};

export const getSuccessFromFormState = (formInfo: TrackedFormInfo) => {
  return formInfo.excluded ? true : formInfo.isValid && formInfo.submitCount > 0;
};

export const getErrorFromFormState = (formInfo: TrackedFormInfo) => {
  return formInfo.excluded ? false : !formInfo.isValid && formInfo.submitCount > 0;
};

/**
 * - Standard props for FormSection component to display
 * `disabled`, `success` and `error` states.
 */
export const getFormSectionStatusProps = (formInfo: TrackedFormInfo[] | TrackedFormInfo) => {
  if (Array.isArray(formInfo)) {
    return {
      disabled: formInfo.some((info) => getDisabledFromFormState(info)),
      success: formInfo.every((info) => getSuccessFromFormState(info)),
      error: formInfo.some((info) => getErrorFromFormState(info)),
    };
  }

  return {
    disabled: getDisabledFromFormState(formInfo),
    success: getSuccessFromFormState(formInfo),
    error: getErrorFromFormState(formInfo),
  };
};

const isSectionReady = (formInfo: ReduxTrackedForm<any> | TrackedFormInfo<any>) => {
  return formInfo.excluded || !(formInfo.isSubmitting || formInfo.isValidating);
};
const isSectionValid = (formInfo: ReduxTrackedForm<any> | TrackedFormInfo<any>) => {
  return formInfo.excluded || formInfo.isValid;
};

/**
 * - Helper function for components containing many FormSections allowing to
 * easily extract information about current section.
 * - See example usage in CustomerDataBusinessSoleTrader.tsx.
 */
export const checkIfSectionIsReadyAndValid = <SectionsEnum extends Record<any, any>>(
  sectionsEnum: SectionsEnum,
  currentSection: SectionsEnum[keyof SectionsEnum],
  config: Record<
    SectionsEnum[keyof SectionsEnum],
    ReduxTrackedForm<any> | TrackedFormInfo<any> | Array<ReduxTrackedForm<any>> | Array<TrackedFormInfo<any>>
  >
) => {
  if (Array.isArray(config[currentSection])) {
    return {
      currentSectionIsReady: (
        config[currentSection] as Array<ReduxTrackedForm<any>> | Array<TrackedFormInfo<any>>
      ).every(isSectionReady),
      currentSectionIsValid: (
        config[currentSection] as Array<ReduxTrackedForm<any>> | Array<TrackedFormInfo<any>>
      ).every(isSectionValid),
    };
  }

  return {
    currentSectionIsReady: isSectionReady(config[currentSection] as ReduxTrackedForm<any> | TrackedFormInfo<any>),
    currentSectionIsValid: isSectionValid(config[currentSection] as ReduxTrackedForm<any> | TrackedFormInfo<any>),
  };
};

export const omitExcludedForms = <Forms extends Record<any, ReduxTrackedForm<any>>>(forms: Forms) => {
  return Object.entries(forms).reduce((filteredForms, [formName, form]) => {
    if (form.excluded) {
      return {
        ...filteredForms,
      };
    }
    return {
      ...filteredForms,
      [formName]: form,
    };
  }, {} as Forms);
};

export const isTestStage = (() => ['dev', 'int', 'cons'].includes(process.env.REACT_APP_STAGE as any))();

export const scrollIntoSection = (sectionId: string) => {
  setTimeout(() => {
    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth',
      });
    }
    // Wait for animation end, then scroll to section,
    // 250ms is time of foldOut animation
  }, 251);
};

export const getFormSectionId = <T>(pageDesignator: string, section: T): string => {
  return `${pageDesignator}_${section}`;
};

export const setAllFoldToFalse = <T extends Record<number, boolean>>(foldStatus: T) => {
  return Object.keys(foldStatus).reduce(
    (acc, key) => {
      const sectionKey = key;
      acc[sectionKey] = false;
      return acc;
    },
    {} as { T }
  );
};
