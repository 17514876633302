import { ContentSection } from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent } from 'react';
import StandaloneServicePlanForm from './StandaloneServicePlanForm';

const StandaloneServicePlan: FunctionComponent = () => {
  return (
    <ContentSection pageWrapSize="small">
      <StandaloneServicePlanForm />
    </ContentSection>
  );
};

export default StandaloneServicePlan;
