import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { omitExcludedForms } from '../../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../../services/redux/storeUtils';

export interface CustomerDataServicePlanState {
  allFormsValid: boolean;
}

const initialState: CustomerDataServicePlanState = {
  allFormsValid: false,
};

const name = 'customerDataServicePlan';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAllFormsValid: (state, action: PayloadAction<{ allFormsValid: boolean }>) => {
      state.allFormsValid = action.payload.allFormsValid;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    areAllFormsValid({ customerDataServicePlan }) {
      return customerDataServicePlan.allFormsValid;
    },
    getDataForSubmit(state) {
      return omitExcludedForms({
        ...state.customerDetailsForm.forms,
        ...state.addressWithPreviousForm.forms,
        ...state.bankDetailsForm.forms,
      });
    },
  },
});

export const customerDataServicePlanSlice = slice;
export const customerDataServicePlanGettersDef = gettersDef;
