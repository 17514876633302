import { Button, Fieldset, Form, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { FieldArray, Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, memo, useMemo, useState } from 'react';
import { formAutocomplete } from '../../config';
import { createOptionsList, renderWithArrayHelpers } from '../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../services/redux';
import { NumericInputFormField } from '../Fieldset/NumericInputFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { TextareaInputFormField } from '../Fieldset/TextareaInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import {
  AdditionalIncome,
  FinancialDetailsFormModel,
  FinancialDetailsFormModelFields,
  FrequencyOfNetIncomeEnum,
} from './financialDetailsForm.model';
import { FinancialDetailsFormState } from './financialDetailsForm.slice';
import { createFinancialDetailsFormValidator } from './financialDetailsForm.validator';

const createFormInputs = ((disableAllFormControls: boolean) => {
  return {
    frequencyOfNetIncome: () => (
      <SelectFormField
        fieldName="frequencyOfNetIncome"
        isDisabled={disableAllFormControls}
        labelTranslationKey="financialDetailsForm:fields:frequencyOfNetIncome:label"
        optionList={createOptionsList(FrequencyOfNetIncomeEnum, 'financialDetailsForm', 'frequencyOfNetIncome')}
      />
    ),
    netMonthlyIncome: () => (
      <NumericInputFormField
        fieldName="netMonthlyIncome"
        isDisabled={disableAllFormControls}
        labelTranslationKey="financialDetailsForm:fields:netMonthlyIncome:label"
        placeholderTranslationKey="financialDetailsForm:fields:netMonthlyIncome:placeholder"
        type="money"
        maximumFractionDigits={2}
      />
    ),
    /* additionalIncome array fields START */
    otherIncomeSource: (index: number) => (
      <TextInputFormField
        fieldName={`additionalIncome.${index}.otherIncomeSource`}
        labelTranslationKey="financialDetailsForm:fields:otherIncomeSource:label"
        placeholderTranslationKey="financialDetailsForm:fields:otherIncomeSource:placeholder"
      />
    ),
    otherFrequencyOfNetIncome: (index: number) => (
      <SelectFormField
        fieldName={`additionalIncome.${index}.otherFrequencyOfNetIncome`}
        labelTranslationKey="financialDetailsForm:fields:otherFrequencyOfNetIncome:label"
        optionList={createOptionsList(FrequencyOfNetIncomeEnum, 'financialDetailsForm', 'frequencyOfNetIncome')}
      />
    ),
    otherNetAnnualIncome: (index: number) => (
      <NumericInputFormField
        fieldName={`additionalIncome.${index}.otherNetAnnualIncome`}
        labelTranslationKey="financialDetailsForm:fields:otherNetAnnualIncome:label"
        placeholderTranslationKey="financialDetailsForm:fields:otherNetAnnualIncome:placeholder"
        type="money"
        maximumFractionDigits={2}
      />
    ),
    /* additionalIncome array fields STOP */
    financialObligations: () => (
      <TextareaInputFormField
        fieldName="financialObligations"
        isDisabled={disableAllFormControls}
        labelTranslationKey="financialDetailsForm:fields:financialObligations:label"
        placeholderTranslationKey="financialDetailsForm:fields:financialObligations:placeholder"
        charCounter
        maxCounter={255}
      />
    ),
  };
}) satisfies (
  disableAllFormControls: boolean
) => Record<FinancialDetailsFormModelFields, (...args: any[]) => ReactElement>;

const createFormElements = ((disableAllFormControls: boolean) => {
  return {
    removeOtherIncomeBtn: (handler: () => void) => (
      <Button
        className="u-mt u-mt-none@s"
        type="button"
        ariaLabel="Remove additional income"
        icon="semantic-delete"
        secondary
        onClick={handler}
        disabled={disableAllFormControls}
      />
    ),
    addAnotherIncomeBtn: (handler: () => void) => (
      <Button
        className="u-mt u-mt-none@s"
        type="button"
        ariaLabel="Remove additional income"
        icon="semantic-add"
        iconReversed
        secondary
        onClick={handler}
        disabled={disableAllFormControls}
      >
        {t('financialDetailsForm:addAnotherIncome')}
      </Button>
    ),
  };
}) satisfies (disableAllFormControls: boolean) => Record<string, (...args: any[]) => ReactElement>;

/**
 * - Holds keys valid for use in map where elements can only
 * change order by virtue of some other being removed.
 * - Works together with addNewItemToKeyMap, getListItemKey and removeListItemKey.
 */
const _indexKeyMap = [] as string[];

const addNewItemToKeyMap = (indexToAdd: number) => {
  _indexKeyMap[indexToAdd] = `${indexToAdd}_${Math.random()}`;
};

const getListItemKey = (index: number) => {
  if (!_indexKeyMap[index]) {
    addNewItemToKeyMap(index);
  }

  return _indexKeyMap[index];
};

const removeListItemKey = (index: number) => {
  _indexKeyMap.splice(index, 1);
};

function _financialDetailsForm<FormName extends keyof FinancialDetailsFormState['forms']>(props: {
  formName: FormName;
  initialValues: FinancialDetailsFormModel;
  currentValues: FinancialDetailsFormModel;
  valuesToResetTo: FinancialDetailsFormModel;
  shouldValidate: boolean;
  shouldReset: boolean;
}) {
  const [initialValues /* , setInitialValues */] = useState(() => props.initialValues);
  const { shouldValidate, formName, shouldReset, valuesToResetTo } = props;

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const inputs = useMemo(() => createFormInputs(disableAllFormControls), [disableAllFormControls]);
  const elements = useMemo(() => createFormElements(disableAllFormControls), [disableAllFormControls]);

  const createIncomeSourceItem = (): AdditionalIncome => ({
    otherIncomeSource: '',
    otherFrequencyOfNetIncome: '' as any,
    otherNetAnnualIncome: '' as any,
  });

  return (
    <Formik
      validationSchema={createFinancialDetailsFormValidator}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      {/* Disable prettier to keep template compact and readable. */}
      {/* prettier-ignore */}
      <Form autoComplete={formAutocomplete}>
        <PersistFormInState<FinancialDetailsFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          shouldReset={shouldReset}
          updateForm={actions.financialDetailsForm.updateForm}
          valuesToResetTo={valuesToResetTo}
        />
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="11/12" s="1/1">
              <Layout>
                <Layout.Item default="1/3" s="1/1">{inputs.frequencyOfNetIncome()}</Layout.Item>
                <Layout.Item default="1/3" s="1/1">{inputs.netMonthlyIncome()}</Layout.Item>
              </Layout>
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Heading level={5} className="u-mt u-mb-small u-text-left">
          {t('financialDetailsForm:additionalIncomeHeader')}
        </Heading>
        <FieldArray name="additionalIncome" >
          {
            renderWithArrayHelpers<FinancialDetailsFormModel>((arrayHelpers) => (
              arrayHelpers.form.values.additionalIncome.map((value, index) => (
                  <Fieldset.Row key={getListItemKey(index)}>
                    <Layout>
                      <Layout.Item default="11/12" s="1/1">
                        <Layout>
                          <Layout.Item default="1/3" s="1/1">{inputs.otherIncomeSource(index)}</Layout.Item>
                          <Layout.Item default="1/3" s="1/1">{inputs.otherFrequencyOfNetIncome(index)}</Layout.Item>
                          <Layout.Item default="1/3" s="1/1">{inputs.otherNetAnnualIncome(index)}</Layout.Item>
                        </Layout>
                      </Layout.Item>
                      <Layout.Item default="1/12" s="1/1">
                        {elements.removeOtherIncomeBtn(() => {
                          removeListItemKey(index);
                          arrayHelpers.remove(index);
                        })}
                      </Layout.Item>
                    </Layout>
                    {
                      // Render after last element.
                      (arrayHelpers.form.values.additionalIncome.length - 1) === index
                      &&
                      // addAnotherIncomeBtn rendered within <Fieldset.Row/> of the last item
                      // to ensure proper spacing.
                      <Layout>
                        <Layout.Item default="1/1" s="1/1">
                          {elements.addAnotherIncomeBtn(() => {
                            addNewItemToKeyMap(index + 1);
                            arrayHelpers.push(createIncomeSourceItem());
                          })}
                        </Layout.Item>
                      </Layout>
                    }
                  </Fieldset.Row>
                )
              ).concat([
                // addAnotherIncomeBtn this time rendered when no elements are present.
                <React.Fragment key="last">
                  {
                    arrayHelpers.form.values.additionalIncome.length === 0 &&
                    <Fieldset.Row>
                      <Layout>
                        <Layout.Item default="1/1" s="1/1">
                          {elements.addAnotherIncomeBtn(() => {
                            addNewItemToKeyMap(0);
                            arrayHelpers.push(createIncomeSourceItem());
                          })}
                        </Layout.Item>
                      </Layout>
                    </Fieldset.Row>
                  }
                </React.Fragment>
              ])
            ))
          }
        </FieldArray>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/1" s="1/1">{inputs.financialObligations()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
      </Form>
    </Formik>
  );
}

// eslint-disable-next-line react/display-name
export const FinancialDetailsForm: FunctionComponent<{ isMainApplicant: boolean }> = memo(({ isMainApplicant }) => {
  let formName;
  let initialValues;
  let currentValues;
  let shouldValidate;
  let shouldReset;
  let valuesToResetTo;

  if (isMainApplicant) {
    formName = 'financialDetails1';
    initialValues = getters.financialDetailsForm.financialDetails1Values;
    valuesToResetTo = getters.financialDetailsForm.forms.financialDetails1.initialValues;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    currentValues = useGetters().financialDetailsForm.financialDetails1Values;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldValidate = useGetters().financialDetailsForm.financialDetailsForm1Triggers.validation;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldReset = useGetters().financialDetailsForm.financialDetailsForm1Triggers.reset;
  } else {
    formName = 'financialDetails2';
    initialValues = getters.financialDetailsForm.financialDetails2Values;
    valuesToResetTo = getters.financialDetailsForm.forms.financialDetails2.initialValues;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    currentValues = useGetters().financialDetailsForm.financialDetails2Values;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldValidate = useGetters().financialDetailsForm.financialDetailsForm2Triggers.validation;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldReset = useGetters().financialDetailsForm.financialDetailsForm2Triggers.reset;
  }

  return _financialDetailsForm({
    formName,
    initialValues,
    currentValues,
    shouldValidate,
    shouldReset,
    valuesToResetTo,
  });
});
