import { RadioButton } from '@vwfs-bronson/bronson-react';
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { GoalSeekFieldName } from './financialProductSpecificationForm.model';

interface GoalSeekRadioButtonFormFieldProps {
  onChange: Dispatch<SetStateAction<GoalSeekFieldName>>;
  name: GoalSeekFieldName;
  currentSelection: string;
  className?: string;
}

const GoalSeekRadioButtonFormField: FunctionComponent<GoalSeekRadioButtonFormFieldProps> = ({
  onChange,
  name,
  currentSelection,
  className,
}) => {
  return (
    <RadioButton
      className={className}
      checked={currentSelection === name}
      onChange={() => onChange(name)}
      name={`goalSeek_${name}`}
    />
  );
};

export default GoalSeekRadioButtonFormField;
