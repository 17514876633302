import { useFormikContext } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { FormField } from '..';
import { FormFieldPropsBase } from './fieldset.model';

const eircodeMask = [
  /[A-Za-z]/, // First character: any letter
  /\d/, // Second character: any digit
  /\d/, // Third character: any digit
  ' ', // Space
  /[A-Za-z0-9]/, // Fourth character: any letter or digit
  /[A-Za-z0-9]/, // Fifth character: any letter or digit
  /[A-Za-z0-9]/, // Sixth character: any letter or digit
  /[A-Za-z0-9]/, // Seventh character: any letter or digit
];

interface EircodeFormFieldProps extends FormFieldPropsBase {}

export const EircodeFormField: FunctionComponent<EircodeFormFieldProps> = ({
  labelTranslationKey = '',
  placeholderTranslationKey = '',
  fieldName,
  isDisabled,
}): React.JSX.Element => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext<any>();
  const [inputValue, setInputValue] = useState(values[fieldName] || '');

  const applyMask = (value, mask) => {
    let maskedValue = '';
    let valueIndex = 0;

    mask.forEach((maskChar) => {
      if (valueIndex >= value.length) {
        return;
      }

      if (maskChar instanceof RegExp) {
        while (valueIndex < value.length && !maskChar.test(value[valueIndex])) {
          valueIndex++;
        }
        if (valueIndex < value.length) {
          maskedValue += value[valueIndex++];
        }
      } else {
        maskedValue += maskChar;
      }
    });

    return maskedValue;
  };

  const handleChange = (event) => {
    const newValue = event.target.value.toUpperCase();

    setInputValue(newValue);
    setFieldValue(fieldName, newValue);
  };

  const handleBlur = () => {
    setFieldTouched(fieldName, true, true);
  };

  useEffect(() => {
    if (values[fieldName]) {
      const value = values[fieldName];

      const newValue = value.toString().toUpperCase();
      const maskedValue = applyMask(newValue, eircodeMask);

      setInputValue(maskedValue);
    }
  }, [values, fieldName]);

  return (
    <FormField
      type="input"
      name={fieldName}
      testId={fieldName}
      labelText={t(labelTranslationKey)}
      isDisabled={isDisabled}
      render={(fieldProps) => (
        <MaskedInput
          {...fieldProps}
          className="c-input__input"
          guide={false}
          keepCharPositions
          disabled={isDisabled}
          mask={eircodeMask}
          name={fieldName}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t(placeholderTranslationKey)}
          placeholderChar={'\u2000'}
          value={inputValue}
        />
      )}
    />
  );
};
