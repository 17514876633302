import { AdditionalProductType, DeliveryCostType, IdentifierSourceType, VehicleOptionType } from '@bus/models';
import { mockKeyValuesLookup } from '../../services/common/mockKeyValuesLookup';
import { SearchVehicleResponse } from '../../services/redux/features/api/search-vehicle.slice';
import { formatMoney } from '../../utils/formatMoney/formatMoney';
import { formatNumber } from '../../utils/formatNumber/formatNumber';
import { ApplicationSummaryData } from './applicationSummary.model';

const sanitize = <T>(value: T | null | undefined): T | string => value ?? '';

const applicationSummaryMapper = (searchVehicleResponse: SearchVehicleResponse): ApplicationSummaryData => {
  const { financialProduct, vehicleData, customerData, transaction } = searchVehicleResponse;

  const colorExteriorOption = vehicleData.model?.options?.find(
    (option) => option.type === VehicleOptionType.COLOR_EXTERIOR
  );
  const colorInteriorOption = vehicleData.model?.options?.find(
    (option) => option.type === VehicleOptionType.COLOR_INTERIOR
  );
  const firstContract = financialProduct.contracts?.[0];
  const deliveryCostComponent = firstContract?.deliveryData?.deliveryCostComponents?.find(
    (dc) => dc.costType === DeliveryCostType.TRANSPORTATION
  );
  const optionsAccessoriesPrice = vehicleData.model?.options?.find(
    (opt) => opt.type === VehicleOptionType.ACCESSORY_ORIGINAL
  ); // TODO GO - what accessory type should be here?
  const customerFullName = `${sanitize(customerData.firstName)} ${sanitize(customerData.middleNames)} ${sanitize(
    customerData.lastName
  )}`;
  const firstOffer = financialProduct.offers?.[0];
  const includedServices = financialProduct.additionalProducts
    ?.find((ap) => ap.additionalProductType === AdditionalProductType.SERVICE_PACKAGE_S)
    ?.qualifiers?.map((qualifier) => qualifier.stringValue);

  const applicationSummaryData: ApplicationSummaryData = {
    brand: sanitize(vehicleData.model?.manufacturer),
    vehicleModel: sanitize(vehicleData.model?.description),
    fuelType: sanitize(vehicleData.model?.engine.fuelType),
    horsePower: formatMoney(
      vehicleData.model?.engine.powerDetails?.power?.value,
      vehicleData.model?.engine.powerDetails?.power?.unit
    ),
    gear: mockKeyValuesLookup(vehicleData.model?.transmissionType),
    colorExterior: sanitize(colorExteriorOption?.description),
    vehicleShortDescription: sanitize(vehicleData.model?.description),
    vehicleCode: sanitize(
      vehicleData.externalVehicleIdentifiers?.find((identifier) => identifier.type === IdentifierSourceType.LOCAL)?.id
    ), // TODO GO - what type should be here?
    pff: formatMoney(vehicleData.baseModelPriceAmount?.amount, vehicleData.baseModelPriceAmount?.currency), // TODO GO - here in CS we have array that selecting only EUR price, but bff propose single value
    colorExteriorPrice: formatMoney(
      colorExteriorOption?.optionPriceAmount?.amount,
      colorExteriorOption?.optionPriceAmount?.currency
    ),
    colorInterior: sanitize(colorInteriorOption?.description),
    colorInteriorPrice: formatMoney(
      colorInteriorOption?.optionPriceAmount?.amount,
      colorInteriorOption?.optionPriceAmount?.currency
    ),
    transportationCosts: formatMoney(
      deliveryCostComponent?.deliveryCostAmount?.amount,
      deliveryCostComponent?.deliveryCostAmount?.currency
    ), // TODO GO - here in CS we have array that selecting only EUR price, but bff propose single value
    optionsAccessories: formatMoney(
      optionsAccessoriesPrice?.optionPriceAmount?.amount,
      optionsAccessoriesPrice?.optionPriceAmount?.currency
    ),
    total: formatMoney(financialProduct.totalAmount?.grossAmount, financialProduct.totalAmount?.currency),
    client: customerFullName,
    financialProduct: sanitize(transaction.product),
    terms: `${sanitize(firstOffer?.calculation?.duration)} ${sanitize(
      mockKeyValuesLookup(firstOffer?.calculation?.durationUnit)
    )}`,
    mileage: `${formatNumber(firstOffer?.calculation?.contractMileage)} ${sanitize(
      mockKeyValuesLookup(firstOffer?.calculation?.mileageUnit)
    )}`,
    includedServices: includedServices ? includedServices.filter(Boolean).map(sanitize) : [],
    downPayment: formatMoney(
      firstOffer?.calculation?.downPaymentAmount?.amount,
      firstOffer?.calculation?.downPaymentAmount?.currency
    ),
    deposit: formatMoney(
      firstOffer?.calculation?.firstInstallmentAmount?.amount,
      firstOffer?.calculation?.firstInstallmentAmount?.currency
    ),
    monthlyPayment: formatMoney(2000, 'eur'),
    totalVat: formatMoney(8465, 'eur'),
  };

  return applicationSummaryData;
};

export default applicationSummaryMapper;
