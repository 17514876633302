import { t } from 'i18next';
import Yup from '../../services/common/validation';
import { UsedCarSelectionFormFields } from './UsedCarSelectionFormModels';

type FieldsToValidate = {
  [key in keyof Pick<
    UsedCarSelectionFormFields,
    'brand' | 'model' | 'modelYear' | 'trim' | 'variant' | 'chassisNumber' | 'vehicleClass'
  >]: Yup.Schema<any>;
};

const usedCarSelectionFormValidationSchema = () =>
  Yup.object().shape<FieldsToValidate>({
    brand: Yup.string().required(t('validation:required')),
    model: Yup.string().required(t('validation:required')),
    modelYear: Yup.string().required(t('validation:required')),
    trim: Yup.string().required(t('validation:required')),
    variant: Yup.string().required(t('validation:required')),
    chassisNumber: Yup.string().min(17, t('validation:invalidVIN', { value: 17 })),
    vehicleClass: Yup.string().required(t('validation:required')),
  });

export default usedCarSelectionFormValidationSchema;
