import { Input, Spinner } from '@vwfs-bronson/bronson-react';
import i18n from 'i18next';
import { debounce } from 'lodash';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormField } from '..';
import { FormFieldPropsBase } from './fieldset.model';

const debouncedSearchCallback = debounce((query, callback) => {
  callback(query);
}, 500);

interface AddressSearchFormFieldProps extends FormFieldPropsBase {
  searchCallback: (query: string) => void;
  isLoading: boolean;
}

export const AddressSearchFormField: FunctionComponent<AddressSearchFormFieldProps> = ({
  labelTranslationKey = '',
  placeholderTranslationKey = '',
  fieldName,
  isDisabled,
  isReadonly,
  isLoading,
  searchCallback,
}): React.JSX.Element => {
  const [inputValue, setInputValue] = useState('');
  const debouncedQuery = useCallback(
    (query: string) => {
      debouncedSearchCallback(query, searchCallback);
    },
    [searchCallback]
  );

  useEffect(() => {
    if (inputValue) {
      debouncedQuery(inputValue);
    }
  }, [inputValue, debouncedQuery]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    return () => {
      debouncedSearchCallback.cancel();
    };
  }, []);

  return (
    <FormField
      type="input"
      name={fieldName}
      testId={fieldName}
      labelText={i18n.t(labelTranslationKey)}
      isDisabled={isDisabled}
      render={(fieldProps) => (
        <Input
          {...fieldProps}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          placeholder={i18n.t(placeholderTranslationKey)}
          readOnly={isReadonly}
          addonIcon={!isLoading && 'semantic-search'}
          onChange={handleChange}
          addonSpinner={isLoading && <Spinner small />}
        />
      )}
    />
  );
};
