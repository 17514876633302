import React, { FunctionComponent, PropsWithChildren } from 'react';

import { Main } from '@vwfs-bronson/bronson-react';
import { useSelector } from 'react-redux';
import { PasswordUpcomingExpirationNotification } from '..';
import { getIsPasswordCloseToExpiration } from '../../services/redux/features/auth.slice';
import { Page } from '../../types/footer';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';

export type DefaultLayoutProps = {
  pages: Page[];
};

const DefaultLayout: FunctionComponent<PropsWithChildren<DefaultLayoutProps>> = ({ children, pages }) => {
  const isPasswordCloseToExpiration = useSelector(getIsPasswordCloseToExpiration);

  return (
    <>
      {isPasswordCloseToExpiration && <PasswordUpcomingExpirationNotification />}
      <Header />
      <Main>{children}</Main>
      <Footer pages={pages} />
    </>
  );
};

export default DefaultLayout;
