import { Textarea } from '@vwfs-bronson/bronson-react';
import i18n from 'i18next';
import React, { FunctionComponent } from 'react';
import { FormField } from '..';
import { FormFieldPropsBase } from './fieldset.model';

interface TextareaInputFormFieldProps extends FormFieldPropsBase {
  rows?: number;
  charCounter?: boolean;
  maxCounter?: number;
}

export const TextareaInputFormField: FunctionComponent<TextareaInputFormFieldProps> = ({
  labelTranslationKey = '',
  placeholderTranslationKey = '',
  fieldName,
  isDisabled,
  isReadonly,
  rows = 3,
  charCounter,
  maxCounter,
}): React.JSX.Element => {
  return (
    <FormField
      type="input"
      name={fieldName}
      testId={fieldName}
      labelText={i18n.t(labelTranslationKey)}
      isDisabled={isDisabled}
      render={(fieldProps) => (
        <Textarea
          {...fieldProps}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          placeholder={i18n.t(placeholderTranslationKey)}
          readOnly={isReadonly}
          rows={rows}
          charCounter={charCounter}
          maxCounter={maxCounter}
        />
      )}
    />
  );
};
