import { Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { UsedCarFormSelectorPageState } from '../../screens/NewQuotation/UsedCarFormSelectorPage/UsedCarFormSelectorPage.slice';
import { getMaxFirstRegistrationDate } from '../../services/common/validation';
import { actions, useGetters } from '../../services/redux';
import { useGetLookupQuery } from '../../services/redux/features/api/lookup.api-slice';
import { DatePickerFormField } from '../Fieldset/DatePickerFormField';
import { NumericInputFormField } from '../Fieldset/NumericInputFormField';
import { RegistrationNumberFormField } from '../Fieldset/RegistrationNumberFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import PersistFormInState from '../PersistFormInState/PersistFormInState';
import { ChassisNumberFormField, typeSafeFormFieldName } from './UsedCarSelectionFormFieldset';
import { DropdownOption } from './UsedCarSelectionFormModels';
import usedCarSelectionFormValidationSchema from './UsedCarSelectionFormValidationSchema';

interface UsedCarSelectionFormProps {
  dropdownData?: {
    vehicleClass: Array<DropdownOption>;
    vatApplicable: Array<DropdownOption>;
    brand: Array<DropdownOption>;
    model: Array<DropdownOption>;
    modelYear: Array<DropdownOption>;
    trim: Array<DropdownOption>;
    variant: Array<DropdownOption>;
  };
}

const UsedCarSelectionForm: FunctionComponent<UsedCarSelectionFormProps> = () => {
  const { t } = useTranslation();

  const { data: brandLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'brand' });
  const { data: modelLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'model' });
  const { data: modelYearLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'modelYear' });
  const { data: trimLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'trim' });
  const { data: variantLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'variant' });
  const { data: vatApplicableLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'vatApplicable' });
  const { data: vehicleClassLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'vehicleClass' });

  const initialFormValueFromState = useGetters().usedCarFormSelectorPage.usedCarSelectionForm.values;

  return (
    <>
      <Formik
        initialValues={initialFormValueFromState}
        validationSchema={usedCarSelectionFormValidationSchema()}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => {}}
      >
        {() => {
          return (
            <>
              <PersistFormInState<UsedCarFormSelectorPageState>
                formName="usedCarSelection"
                updateForm={actions.usedCarFormSelectorPage.updateForm}
              />

              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <Layout.Item default="1/2" s="1/1">
                      <SelectFormField
                        optionList={vehicleClassLookups ?? []}
                        fieldName="vehicleClass"
                        labelTranslationKey="usedCarSelectionForm:form.formFields.vehicleClass.label"
                        testId="dropdown_vehicleClass"
                      />
                    </Layout.Item>

                    <Layout.Item default="1/2" s="1/1">
                      <SelectFormField
                        optionList={vatApplicableLookups ?? []}
                        fieldName="vatApplicable"
                        labelTranslationKey="usedCarSelectionForm:form.formFields.vatApplicable.label"
                        isDisabled
                        testId="dropdown_vat"
                      />
                    </Layout.Item>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>

              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <Layout.Item default="1/2" s="1/1">
                      <SelectFormField
                        optionList={brandLookups ?? []}
                        fieldName="brand"
                        labelTranslationKey="usedCarSelectionForm:form.formFields.brand.label"
                        testId="dropdown_brand"
                      />
                    </Layout.Item>

                    <Layout.Item default="1/2" s="1/1">
                      <SelectFormField
                        optionList={modelLookups ?? []}
                        fieldName="model"
                        labelTranslationKey="usedCarSelectionForm:form.formFields.model.label"
                        testId="dropdown_model"
                      />
                    </Layout.Item>
                  </Layout.Item>

                  <Layout.Item default="1/2" s="1/1">
                    <Layout.Item default="1/2" s="1/1">
                      <SelectFormField
                        optionList={modelYearLookups ?? []}
                        fieldName="modelYear"
                        labelTranslationKey="usedCarSelectionForm:form.formFields.modelYear.label"
                        testId="dropdown_year"
                      />
                    </Layout.Item>

                    <Layout.Item default="1/2" s="1/1">
                      <SelectFormField
                        optionList={trimLookups ?? []}
                        fieldName="trim"
                        labelTranslationKey="usedCarSelectionForm:form.formFields.trim.label"
                        testId="dropdown_trim"
                      />
                    </Layout.Item>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>

              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <Layout.Item default="1/1">
                      <SelectFormField
                        optionList={variantLookups ?? []}
                        fieldName="variant"
                        labelTranslationKey="usedCarSelectionForm:form.formFields.variant.label"
                        testId="drodpown_variant"
                      />
                    </Layout.Item>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>

              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <Layout.Item default="1/1">
                      <RegistrationNumberFormField
                        labelTranslationKey="usedCarSelectionForm:form.formFields.registrationNumber.label"
                        placeholderTranslationKey="usedCarSelectionForm:form.formFields.registrationNumber.placeholder"
                        testId="inputField_registrationNumber"
                        fieldName={typeSafeFormFieldName('registrationNumber')}
                      />
                    </Layout.Item>
                  </Layout.Item>

                  <Layout.Item default="1/2" s="1/1">
                    <Layout.Item default="1/1">
                      <ChassisNumberFormField testId="inputField_chassisNumber" />
                    </Layout.Item>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>

              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    <Layout.Item default="1/1">
                      <DatePickerFormField
                        fieldName={typeSafeFormFieldName('firstRegistration')}
                        labelTranslationKey={t(`usedCarSelectionForm:form.formFields.firstRegistration.label`)}
                        placeholderTranslationKey="usedCarSelectionForm:form.formFields.firstRegistration.placeholder"
                        testId="inputField_firstRegistration"
                        maxDate={getMaxFirstRegistrationDate()}
                      />
                    </Layout.Item>
                  </Layout.Item>

                  <Layout.Item default="1/2" s="1/1">
                    <Layout.Item default="1/1">
                      <NumericInputFormField
                        fieldName={typeSafeFormFieldName('recordedMileage')}
                        labelTranslationKey={t(`usedCarSelectionForm:form.formFields.recordedMileage.label`)}
                        placeholderTranslationKey="usedCarSelectionForm:form.formFields.recordedMileage.placeholder"
                        type="km"
                        testId="inputField_recordedMilage"
                      />
                    </Layout.Item>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default UsedCarSelectionForm;
