import { Button, Fieldset, Form, Heading, Icon, Layout, Paragraph, Spinner } from '@vwfs-bronson/bronson-react';
import { addHours, format } from 'date-fns';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formAutocomplete } from '../../../config';
import { mobileNumberMask } from '../../../services/common/validation';
import { actions, store, useGetters } from '../../../services/redux';
import { useSaveSessionWithContinueLinkMutation } from '../../../services/redux/features/api/saveAndContinue.api-slice';
import { SelectFormField } from '../../Fieldset/SelectFormField';
import { TextInputFormField } from '../../Fieldset/TextInputFormField';
import PersistFormInState from '../../PersistFormInState/PersistFormInState';
import { SnCInitializationState, snCInitializationFormInitialValues } from './SnCInitialization.slice';
import { snCInitializationFormSchema } from './SnCInitialization.validator';

const createFormInputs = () => {
  return {
    email: () => (
      <TextInputFormField
        fieldName="email"
        labelTranslationKey="snCInitialization:init:fields:email:label"
        placeholderTranslationKey="snCInitialization:init:fields:email:placeholder"
      />
    ),
    countryCode: () => (
      <SelectFormField
        fieldName="countryCode"
        labelTranslationKey="snCInitialization:init:fields:countryCode:label"
        placeholderTranslationKey="snCInitialization:init:fields:countryCode:placeholder"
        optionList={[
          {
            label: '+353',
            value: '+353',
          },
          {
            label: '+48',
            value: '+48',
          },
        ]}
      />
    ),
    mobileNumber: () => (
      <TextInputFormField
        fieldName="mobileNumber"
        labelTranslationKey="snCInitialization:init:fields:mobileNumber:label"
        placeholderTranslationKey="snCInitialization:init:fields:mobileNumber:placeholder"
        infoIconTranslationKey="snCInitialization:init:fields:mobileNumber:infoIcon"
        mask={mobileNumberMask}
        maskedValueToFormValue={(maskedValue) => maskedValue.replace(/[^\d]/g, '')}
      />
    ),
  };
};

const SnCInitializationLead: FunctionComponent = () => {
  const { t } = useTranslation();

  const validUntilFormattedDate = format(addHours(new Date(), 48), 'dd/MM/yyy');

  const [inputs /* , updateInputs */] = useState<ReturnType<typeof createFormInputs>>(() => createFormInputs());
  const [saveSessionWithContinueLink, { isLoading, isSuccess, isError }] = useSaveSessionWithContinueLinkMutation();

  useEffect(() => {
    if (isSuccess) {
      actions.snCInitialization.snCSubmitted();
    }
  }, [isSuccess]);

  return (
    <Spinner busy={isLoading} section>
      <Heading level={3}>{t('snCInitialization:init.heading')}</Heading>
      <Layout className="u-mb">
        <Layout.Item>
          <Paragraph>{t('snCInitialization:init.description.line1')}</Paragraph>
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: t('snCInitialization:init.description.line2', { validUntil: validUntilFormattedDate }),
            }}
          />
        </Layout.Item>
      </Layout>
      <Formik
        initialValues={snCInitializationFormInitialValues}
        onSubmit={async (formValues) => {
          const contactNumber = `${formValues.countryCode}${formValues.mobileNumber}`;

          // This prevent to have snc panel opened on snc restore.
          const state = cloneDeep(store.getState());
          state.snCInitialization = {
            ...state.snCInitialization,
            snCClosed: true,
            snCSubmitted: true,
          };

          const response = await saveSessionWithContinueLink({
            receiverEmail: formValues.email,
            receiverPhoneNumber: contactNumber,
            frontendModel: state,
          }).unwrap();

          actions.auth.setToken(response.token);
          actions.transaction.setTransactionId(response.transactionId);
        }}
        validateOnMount
        validateOnChange
        validateOnBlur
        validationSchema={snCInitializationFormSchema}
      >
        {({ isValid, handleSubmit }) => {
          return (
            <Form autoComplete={formAutocomplete} onSubmit={handleSubmit}>
              <PersistFormInState<SnCInitializationState>
                formName="snCInitializationForm"
                updateForm={actions.snCInitialization.updateForm}
              />
              <Fieldset.Row>
                <Layout>
                  <Layout.Item>{inputs.email()}</Layout.Item>
                </Layout>
              </Fieldset.Row>

              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/2" s="1/1">
                    {inputs.countryCode()}
                  </Layout.Item>

                  <Layout.Item default="1/2" s="1/1">
                    {inputs.mobileNumber()}
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>

              <Layout className="u-mb">
                <Layout.Item>
                  <Paragraph>{t('snCInitialization:init.confirmationDescription')}</Paragraph>
                </Layout.Item>
              </Layout>

              <Layout center>
                <Layout.Item default="1/4" s="1/1">
                  <Button full disabled={!isValid} type="submit">
                    {t('snCInitialization:init.continueButton')}
                  </Button>
                </Layout.Item>
              </Layout>

              {isError && (
                <Layout className="u-mt-xxsmall">
                  <Layout.Item>
                    <Paragraph className="u-text-center c-error-message">
                      {t('snCInitialization:init.requestError')}
                    </Paragraph>
                  </Layout.Item>
                </Layout>
              )}
            </Form>
          );
        }}
      </Formik>
    </Spinner>
  );
};

const SnCInitializationSuccess: FunctionComponent = () => {
  const { t } = useTranslation();
  const { snCEmail } = useGetters().snCInitialization;

  const [saveSessionWithContinueLink, { isLoading, isError }] = useSaveSessionWithContinueLinkMutation();
  const sncFormValues = useGetters().snCInitialization.snCFormValues;

  return (
    <>
      <Heading level={3}>{t('snCInitialization:initSuccess.heading')}</Heading>

      <Icon className="u-mb" name="semantic-checkmark" />

      <Layout className="u-mb">
        <Layout.Item>
          <Paragraph>{t('snCInitialization:initSuccess.description.line1')}</Paragraph>
          <Paragraph>
            <b>{snCEmail}</b>
          </Paragraph>
          <Paragraph>{t('snCInitialization:initSuccess.description.line2')}</Paragraph>
        </Layout.Item>
      </Layout>

      <Layout center className="u-mb">
        <Layout.Item default="1/4" s="1/1">
          <Button
            full
            type="button"
            disabled={isLoading}
            onClick={async () => {
              const contactNumber = `${sncFormValues.countryCode}${sncFormValues.mobileNumber}`;

              // This prevent to have snc panel opened on snc restore.
              const state = cloneDeep(store.getState());
              state.snCInitialization = {
                ...state.snCInitialization,
                snCClosed: true,
                snCSubmitted: true,
              };

              const response = await saveSessionWithContinueLink({
                receiverEmail: sncFormValues.email,
                receiverPhoneNumber: contactNumber,
                frontendModel: state,
              }).unwrap();

              actions.auth.setToken(response.token);
              actions.transaction.setTransactionId(response.transactionId);
            }}
          >
            {t('snCInitialization:initSuccess.sendAgainButton')}
          </Button>

          {isError && (
            <Layout className="u-mt-xxsmall">
              <Layout.Item>
                <Paragraph className="u-text-center c-error-message">
                  {t('snCInitialization:init.requestError')}
                </Paragraph>
              </Layout.Item>
            </Layout>
          )}
        </Layout.Item>
      </Layout>

      <Layout center>
        <Layout.Item default="1/4" s="1/1">
          <Button
            full
            type="button"
            onClick={() => {
              actions.snCInitialization.snCClosed();
            }}
          >
            {t('snCInitialization:initSuccess.closeButton')}
          </Button>
        </Layout.Item>
      </Layout>
    </>
  );
};

const SnCInitialization: FunctionComponent = () => {
  const { snCSubmitted: snCLinkSent } = useGetters().snCInitialization;

  return (
    <>
      {!snCLinkSent && <SnCInitializationLead />}
      {snCLinkSent && <SnCInitializationSuccess />}
    </>
  );
};

export default SnCInitialization;
