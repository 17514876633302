import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import { getEnumValues } from '../../services/common/miscUtils';
import { allNaceCodes, allNaceSections } from '../../services/common/naceCodes';
import Yup, {
  checkIfEnterpriseSizeIsOptional,
  maxLandlineNumberLength,
  maxMobileNumberLength,
  minLandlineNumberLength,
  minMobileNumberLength,
  regExEmail,
  regexVat,
} from '../../services/common/validation';
import {
  CorrespondenceMethodEnum,
  CountryCodeEnum,
  EnterpriseSizeEnum,
  EntityFormEnum,
  InstitutionalTypeEnum,
  TelephoneTypeEnum,
} from '../../types/forms';
import { TradingDetailsFormAddressModelFields, TradingDetailsFormModel } from './tradingDetailsForm.model';

export const createTradingDetailsFormValidator = (): ObjectSchema<
  Omit<TradingDetailsFormModel, TradingDetailsFormAddressModelFields>
> => {
  return Yup.object({
    entityForm: Yup.string().requiredWithMsg().oneOf(getEnumValues(EntityFormEnum)),
    businessType: Yup.string().requiredWithMsg().oneOf(allNaceSections),
    businessSubType: Yup.string().requiredWithMsg().oneOf(allNaceCodes),
    institutionalType: Yup.string().requiredWithMsg().oneOf(getEnumValues(InstitutionalTypeEnum)),
    enterpriseSize: Yup.string()
      .when(['institutionalType'], {
        is: (institutionalType: InstitutionalTypeEnum) => {
          return checkIfEnterpriseSizeIsOptional(institutionalType);
        },
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required(t('validation:required')),
      })
      .oneOf(getEnumValues(EnterpriseSizeEnum)),
    telephoneType: Yup.string().requiredWithMsg().oneOf(getEnumValues(TelephoneTypeEnum)),
    countryCode: Yup.string().requiredWithMsg().oneOf(getEnumValues(CountryCodeEnum)),
    correspondenceMethod: Yup.string().requiredWithMsg().oneOf(getEnumValues(CorrespondenceMethodEnum)),
    tradingAs: Yup.string().requiredWithMsg(),
    dateOfIncorporation: Yup.string().requiredWithMsg(),
    vatNumber: Yup.string().matches(regexVat, t('validation:invalidVat')).lengthBetween(0, 16).notRequired(),
    annualProfitCurrentYear: Yup.number().requiredWithMsg().validateNumber(16, 2),
    annualProfitPreviousYear: Yup.number().requiredWithMsg().validateNumber(16, 2),
    currentBusinessContracts: Yup.string().requiredWithMsg().lengthBetween(0, 255),
    currentFinancialObligations: Yup.string().requiredWithMsg().lengthBetween(0, 255),
    businessHistory: Yup.string().requiredWithMsg().lengthBetween(0, 255),
    phoneNumber: Yup.string().when(['telephoneType'], {
      is: (telephoneType: TelephoneTypeEnum) => {
        return telephoneType === TelephoneTypeEnum.mobile;
      },
      then: (schema) => schema.requiredWithMsg().lengthBetween(minMobileNumberLength, maxMobileNumberLength),
      otherwise: (schema) => schema.notRequired().lengthBetween(minLandlineNumberLength, maxLandlineNumberLength),
    }),
    companyEmail: Yup.string().requiredWithMsg().matches(regExEmail, t('validation:invalidEmail')),
  });
};
