import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { omitExcludedForms } from '../../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../../services/redux/storeUtils';

export interface customerDataSIBusinessSoleTraderState {
  allFormsValid: boolean;
}

const initialState: customerDataSIBusinessSoleTraderState = {
  allFormsValid: false,
};

const name = 'customerDataBusinessSISoleTrader';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAllFormsValid: (state, action: PayloadAction<{ allFormsValid: boolean }>) => {
      state.allFormsValid = action.payload.allFormsValid;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    areAllFormsValid({ customerDataSIBusinessSoleTrader }) {
      return customerDataSIBusinessSoleTrader.allFormsValid;
    },
    getDataForSubmit(state) {
      return omitExcludedForms({
        ...state.tradingDetailsSIForm.forms,
        ...state.contactAddressForm.forms,
        ...state.contactDetailsForm.forms,
        ...state.bankDetailsForm.forms,
      });
    },
  },
});

export const customerDataSIBusinessSoleTraderSlice = slice;
export const customerDataSIBusinessSoleTraderGettersDef = gettersDef;
