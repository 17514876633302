import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { PersonalDetailsFormModel } from './personalDetailsForm.model';

export interface PersonalDetailsFormState
  extends ReduxStateWithForms<{
    personalDetails1: ReduxTrackedForm<PersonalDetailsFormModel>;
    personalDetails2: ReduxTrackedForm<PersonalDetailsFormModel>;
  }> {}

const personalDetailsInitialValues: PersonalDetailsFormModel = {
  title: undefined,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  eirCode: '',
  town: '',
  county: '',
};

const initialState: PersonalDetailsFormState = {
  forms: {
    personalDetails1: {
      ...createReduxTrackedFormDefaults<PersonalDetailsFormModel>({
        excluded: true,
        initialValues: personalDetailsInitialValues,
      }),
      values: {
        ...personalDetailsInitialValues,
      },
    },
    personalDetails2: {
      ...createReduxTrackedFormDefaults<PersonalDetailsFormModel>({
        excluded: true,
        initialValues: personalDetailsInitialValues,
      }),
      values: {
        ...personalDetailsInitialValues,
      },
    },
  },
};

const name = 'personalDetailsForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<PersonalDetailsFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    personalDetails1Triggers({ personalDetailsForm }) {
      return personalDetailsForm.forms.personalDetails1.triggers;
    },
    personalDetails1Values({ personalDetailsForm }) {
      return personalDetailsForm.forms.personalDetails1.values;
    },
    personalDetails2Triggers({ personalDetailsForm }) {
      return personalDetailsForm.forms.personalDetails2.triggers;
    },
    personalDetails2Values({ personalDetailsForm }) {
      return personalDetailsForm.forms.personalDetails2.values;
    },
    forms({ personalDetailsForm }) {
      return personalDetailsForm.forms;
    },
  },
});

export const personalDetailsFormSlice = slice;
export const personalDetailsFormGettersDef = gettersDef;
