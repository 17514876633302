export enum MockLoginStateEnum {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  SERVER_ERROR = 'SERVER_ERROR',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  TEMPORARY_PASSWORD = 'TEMPORARY_PASSWORD',
  UPCOMING_EXPIRATION = 'UPCOMING_EXPIRATION',
  PASSWORD_EXPIRED = 'PASSWORD_EXPIRED',
}

export interface LoginFormat {
  login: string;
  password: string;
}
