export const ROUTES = {
  root: '/',
  dashboardHome: '/dashboard/home',
  mockDashboard: '/mockDashboard',
  changePassword: '/changePassword',
  protectedPathTest: '/protectedPathTest',
  withDataStorageTest: '/withDataStorageTest',
  error: 'error',
  vehicleSelect: '/vehicleSelect',
  productFamilySelectionPage: '/productFamilySelectionPage',
  newQuote: '/newQuote',
  saveAndContinueLanding: '/continue',
  saveAndContinueTanValidate: '/continue/validation',
  saveAndContinueExpired: '/continue/expired',
} as const;

export type ROUTES_VALUES = (typeof ROUTES)[keyof typeof ROUTES];
