import i18n from 'i18next';
import { VehicleType } from '../../screens/VehicleSelectPage/vehicleTypeSelectPage.model';
import Yup from '../../services/common/validation';

const conditionallyRequired = (isRequired: boolean, schema: Yup.Schema<any>) => {
  if (isRequired) {
    return schema.required(i18n.t('validation:required'));
  }

  return schema;
};

export const additionalServicePlanValidationSchema = (vehicleType: VehicleType) => {
  return Yup.object({
    firstRegistrationDate: conditionallyRequired(vehicleType === 'used', Yup.string()),
    recordedKilometers: conditionallyRequired(
      vehicleType === 'used',
      Yup.number()
        .integer(i18n.t('validation:numberInteger'))
        .nullable()
        .maxFraction(0, i18n.t('validation:maximumFraction', { value: 0 }))
        .transform((_, val) => (val === Number(val) ? val : null))
    ),
    servicePlanName: Yup.string().required(i18n.t('validation:required')),
    paymentType: Yup.string().required(i18n.t('validation:required')),
    paymentTerm: Yup.string().when('paymentType', {
      is: (paymentType: string) => paymentType !== 'upfront',
      then: (schema) => {
        return schema.required(i18n.t('validation:required')).when(['contractDuration'], {
          is: (contractDuration) => !Number.isNaN(parseInt(contractDuration, 10)),
          then: (schema) =>
            schema.test(
              'isLessThanOrEqualToContractDuration',
              i18n.t(
                'additionalServicePlan:additionalServicePlanForm.contractDuration.errors.isLessThanOrEqualToContractDuration'
              ),
              (paymentTerm, context) => {
                const paymentTermNum = paymentTerm ? parseInt(paymentTerm, 10) : NaN;
                const contractDurationNum = parseInt(context.parent.contractDuration, 10);

                return !Number.isNaN(paymentTermNum) && paymentTermNum <= contractDurationNum;
              }
            ),
        });
      },
      otherwise: (schema) => schema.notRequired(),
    }),
    contractDuration: Yup.string().required(i18n.t('validation:required')),
  });
};
