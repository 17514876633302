import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { omitExcludedForms } from '../../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../../services/redux/storeUtils';

export interface CustomerDataSIBusinessPartnershipState {
  allFormsValid: boolean;
  isFirstPersonAdded: boolean;
  isSecondPersonAdded: boolean;
}

const initialState: CustomerDataSIBusinessPartnershipState = {
  allFormsValid: false,
  isFirstPersonAdded: false,
  isSecondPersonAdded: false,
};

const name = 'customerDataSIBusinessPartnership';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAllFormsValid: (state, action: PayloadAction<{ allFormsValid: boolean }>) => {
      state.allFormsValid = action.payload.allFormsValid;
    },
    setIsFirstPersonAdded: (state, action: PayloadAction<boolean>) => {
      state.isFirstPersonAdded = action.payload;
    },
    setIsSecondPersonAdded: (state, action: PayloadAction<boolean>) => {
      state.isSecondPersonAdded = action.payload;
    },
    resetAddedCoApplicants: (state) => {
      state.isFirstPersonAdded = false;
      state.isSecondPersonAdded = false;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    isFirstPersonAdded({ customerDataSIBusinessPartnership }) {
      return customerDataSIBusinessPartnership.isFirstPersonAdded;
    },
    isSecondPersonAdded({ customerDataSIBusinessPartnership }) {
      return customerDataSIBusinessPartnership.isSecondPersonAdded;
    },
    areAllFormsValid({ customerDataSIBusinessPartnership }) {
      return customerDataSIBusinessPartnership.allFormsValid;
    },
    getDataForSubmit(state) {
      return omitExcludedForms({
        ...state.tradingDetailsSIBusinessForm.forms,
        ...state.contactDetailsForm.forms,
        ...state.bankDetailsForm.forms,
        ...state.personalDetailsForm.forms,
      });
    },
  },
});

export const customerDataSIBusinessPartnershipSlice = slice;
export const customerDataSIBusinessPartnershipGettersDef = gettersDef;
