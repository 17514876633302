import i18n from 'i18next';
import * as Yup from 'yup';
import { PaymentType } from '../../types/forms';

export const standaloneServicePlanFormValidationSchema = (isNewVehicle) => {
  const validationSchema: any = {
    productType: Yup.string().required(i18n.t('validation:required')),
    servicePlan: Yup.string().required(i18n.t('validation:required')),
    lastProposalDate: Yup.string(),
    lastActivationDate: Yup.string(),
    paymentType: Yup.string().required(i18n.t('validation:required')),
    contractDuration: Yup.string().required(i18n.t('validation:required')),
    paymentTerm: Yup.string().when('paymentType', {
      is: (paymentType: string) => paymentType === PaymentType.Monthly,
      then: (schema) => schema.required(i18n.t('validation:required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    calculation: Yup.number().required(i18n.t('validation:required')),
  };

  if (!isNewVehicle) {
    validationSchema.vatApplicable = Yup.string().required(i18n.t('validation:required'));
    validationSchema.firstRegistrationDate = Yup.string().required(i18n.t('validation:required'));
    validationSchema.recordedKilometres = Yup.number()
      .required(i18n.t('validation:required'))
      .max(99999999)
      .integer(i18n.t('validation:numberInteger'))
      .maxFraction(0, i18n.t('validation:maximumFraction', { value: 0 }))
      .transform((_, val) => (val === Number(val) ? val : null));
  }

  return Yup.object(validationSchema);
};
