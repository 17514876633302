import { Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { FunctionComponent, ReactElement, memo, useMemo, useState } from 'react';
import { formAutocomplete } from '../../config';
import { actions, getters, useGetters } from '../../services/redux';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import { BankDetailsFormModel, BankDetailsFormModelFields } from './bankDetailsForm.model';
import { BankDetailsFormState } from './bankDetailsForm.slice';
import { createBankDetailsFormValidator } from './bankDetailsForm.validator';

const typeSafeFormFieldName = (formFieldName: keyof BankDetailsFormModel) => formFieldName;

const createFormInputs = ((disableAllFormControls: boolean) => {
  return {
    iban: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('iban')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="bankDetailsForm:fields:iban:label"
        placeholderTranslationKey="bankDetailsForm:fields:iban:placeholder"
      />
    ),
    bic: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('bic')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="bankDetailsForm:fields:bic:label"
        placeholderTranslationKey="bankDetailsForm:fields:bic:placeholder"
      />
    ),
    bankAccountHolder: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('bankAccountHolder')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="bankDetailsForm:fields:bankAccountHolder:label"
        placeholderTranslationKey="bankDetailsForm:fields:bankAccountHolder:placeholder"
      />
    ),
  };
}) satisfies (disableAllFormControls: boolean) => Record<BankDetailsFormModelFields, (...args: any[]) => ReactElement>;

const formName = 'bankDetails';

// eslint-disable-next-line react/display-name
export const BankDetailsForm: FunctionComponent = memo(() => {
  const [initialValues /* , setInitialValues */] = useState(() => getters.bankDetailsForm.bankDetailsValues);
  const shouldValidate = useGetters().bankDetailsForm.bankDetailsTriggers.validation;
  const shouldReset = useGetters().bankDetailsForm.bankDetailsTriggers.reset;

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const inputs = useMemo(() => createFormInputs(disableAllFormControls), [disableAllFormControls]);

  return (
    <Formik
      validationSchema={createBankDetailsFormValidator}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      {/* Disable prettier to keep template compact and readable. */}
      {/* prettier-ignore */}
      <Form  autoComplete={formAutocomplete}>
        <PersistFormInState<BankDetailsFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          updateForm={actions.bankDetailsForm.updateForm}
          shouldReset={shouldReset}
          valuesToResetTo={getters.bankDetailsForm.forms.bankDetails.initialValues}
        />

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="2/3" s="1/1">{inputs.iban()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.bic()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="2/3" s="1/1">{inputs.bankAccountHolder()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
      </Form>
    </Formik>
  );
});
