import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect } from 'react';
import { actions, useGetters } from '../../services/redux';
import { useGetLookupQuery } from '../../services/redux/features/api/lookup.api-slice';
import { useSearchUsedCarMutation } from '../../services/redux/features/api/usedCar.api-slice';
import { RegistrationNumberFormField } from '../Fieldset/RegistrationNumberFormField';
import { typeSafeFormFieldName } from '../UsedCarSelectionForm/UsedCarSelectionFormFieldset';
import { BrandFormField, ModelFormField, VariantFormField } from './UsedCarSearchFormFieldset';
import { UsedCarSearchFormModel } from './UsedCarSearchFormModels';

const UsedCarSearchForm: FunctionComponent = () => {
  const [searchUsedCar, { isLoading }] = useSearchUsedCarMutation();

  const { data: brandLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'brand' });
  const { data: modelLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'model' });
  const { data: variantLookups } = useGetLookupQuery({ group: 'usedCar', resource: 'variant' });

  const mapValuesToLabels = async (fields: UsedCarSearchFormModel) => {
    const remappedFields: UsedCarSearchFormModel = {
      ...fields,
    };

    remappedFields.brand = brandLookups?.find((lookup) => lookup.value === fields.brand)?.label ?? '';
    remappedFields.model = modelLookups?.find((lookup) => lookup.value === fields.model)?.label ?? '';
    remappedFields.variant = variantLookups?.find((lookup) => lookup.value === fields.variant)?.label ?? '';

    return remappedFields;
  };

  const handleSearchCar = async (registrationNumber: string): Promise<UsedCarSearchFormModel> => {
    const usedCarData = await searchUsedCar({ registrationNumber }).unwrap();

    actions.usedCarFormSelectorPage.updateSearchResults({ data: usedCarData });

    return mapValuesToLabels(usedCarData);
  };

  const initialFormValueFromState = useGetters().usedCarFormSelectorPage.usedCarSearchForm.values;

  return (
    <>
      <Formik
        initialValues={initialFormValueFromState}
        onSubmit={async (values, { setSubmitting, setValues, setFieldError }) => {
          try {
            actions.usedCarFormSelectorPage.updateSearchResults({ data: null });

            setValues({ ...values, brand: '', model: '', variant: '' });

            const usedCarData = await handleSearchCar(values.registrationNumber);
            setValues(usedCarData);
          } catch (error) {
            if ((error as FetchBaseQueryError).status === 404) {
              setFieldError(
                typeSafeFormFieldName('registrationNumber'),
                t('usedCarSearchForm:form.formFields.registrationNumber.notFoundError')
              );
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, handleChange, values, isValid, dirty }) => {
          const isRegistrationNumberExisting = !!values.registrationNumber;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            actions.usedCarFormSelectorPage.updateForm({
              formName: 'usedCarSearch',
              values,
              isValid,
              dirty,
            });
          }, [values, isValid, dirty]);

          return (
            <Form onSubmit={handleSubmit} onChange={handleChange}>
              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/1">
                    <Layout.Item default="3/4" s="1/1">
                      <RegistrationNumberFormField
                        labelTranslationKey="usedCarSearchForm:form.formFields.registrationNumber.label"
                        placeholderTranslationKey="usedCarSearchForm:form.formFields.registrationNumber.placeholder"
                        fieldName={typeSafeFormFieldName('registrationNumber')}
                      />
                    </Layout.Item>

                    <Layout.Item default="1/4" s="1/1">
                      <Fieldset.Row>
                        <Button
                          full
                          testId="usedCarSearchForm_button_search"
                          type="submit"
                          className="u-mt"
                          disabled={!isRegistrationNumberExisting || isLoading}
                        >
                          {t('usedCarSearchForm:form.searchButton')}
                        </Button>
                      </Fieldset.Row>
                    </Layout.Item>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>

              <Fieldset.Row>
                <Layout>
                  <Layout.Item default="1/1">
                    <Layout.Item default="1/3" s="1/1">
                      <BrandFormField />
                    </Layout.Item>

                    <Layout.Item default="1/3" s="1/1">
                      <ModelFormField />
                    </Layout.Item>

                    <Layout.Item default="1/3" s="1/1">
                      <VariantFormField />
                    </Layout.Item>
                  </Layout.Item>
                </Layout>
              </Fieldset.Row>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default UsedCarSearchForm;
