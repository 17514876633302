import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import Yup, { maxTextAreaLength } from '../../services/common/validation';
import { getEnumValues } from '../../services/common/miscUtils';
import { AdditionalIncome, FinancialDetailsFormModel, FrequencyOfNetIncomeEnum } from './financialDetailsForm.model';

export const createFinancialDetailsFormValidator = (): ObjectSchema<FinancialDetailsFormModel> => {
  return Yup.object({
    frequencyOfNetIncome: Yup.string().requiredWithMsg().oneOf(getEnumValues(FrequencyOfNetIncomeEnum)),
    netMonthlyIncome: Yup.number().requiredWithMsg().validateNumber(16, 2),
    additionalIncome: Yup.array()
      .ensure()
      .defined()
      .min(0)
      .of(
        Yup.object({
          otherIncomeSource: Yup.string()
            .requiredWithMsg()
            .max(64, t('validation:maxLength', { value: 64 })),
          otherFrequencyOfNetIncome: Yup.string().requiredWithMsg().oneOf(getEnumValues(FrequencyOfNetIncomeEnum)),
          otherNetAnnualIncome: Yup.number().validateNumber(16, 2).requiredWithMsg(),
        }) satisfies ObjectSchema<AdditionalIncome>
      ),
    financialObligations: Yup.string().requiredWithMsg().lengthBetween(1, maxTextAreaLength),
  });
};
