import { Modal } from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toggleAdditionalServicePlan } from './additionalServicePlan.slice';

interface AdditionalServicePlanRemoveModalProps {
  isShown: boolean;
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdditionalServicePlanRemoveModal: FunctionComponent<AdditionalServicePlanRemoveModalProps> = ({
  isShown,
  setIsShow,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Modal
      buttonCloseLabel={t('additionalServicePlan:additionalServicePlanModal.closeLabel')}
      buttonConfirmLabel={t('additionalServicePlan:additionalServicePlanModal.closeLabel')}
      buttonConfirmText={t('additionalServicePlan:additionalServicePlanModal.confirmButton')}
      buttonConfirmType="button"
      id="additionalServicePlanRemoveModal"
      onConfirm={() => {
        dispatch(toggleAdditionalServicePlan());
        setIsShow(false);
      }}
      onClose={() => {
        setIsShow(false);
      }}
      shown={isShown}
      title={t('additionalServicePlan:additionalServicePlanModal.title')}
    >
      {t('additionalServicePlan:additionalServicePlanModal.modalBody')}
    </Modal>
  );
};

export default AdditionalServicePlanRemoveModal;
