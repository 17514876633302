import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { omitExcludedForms } from '../../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../../services/redux/storeUtils';

export interface CustomerDataPrivateState {
  allFormsValid: boolean;
  isAnotherHirerAdded: boolean;
}

const initialState: CustomerDataPrivateState = {
  allFormsValid: false,
  isAnotherHirerAdded: false,
};

const name = 'customerDataPrivate';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAllFormsValid: (state, action: PayloadAction<{ allFormsValid: boolean }>) => {
      state.allFormsValid = action.payload.allFormsValid;
    },
    setIsAnotherHirerAdded: (state, action: PayloadAction<{ isAnotherHirerAdded }>) => {
      state.isAnotherHirerAdded = action.payload.isAnotherHirerAdded;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    areAllFormsValid({ customerDataPrivate }) {
      return customerDataPrivate.allFormsValid;
    },
    isAnotherHirerAdded({ customerDataPrivate }) {
      return customerDataPrivate.isAnotherHirerAdded;
    },
    getDataForSubmit(state) {
      return omitExcludedForms({
        ...state.customerDetailsForm.forms,
        ...state.addressWithPreviousForm.forms,
        ...state.addressWithPreviousForm.forms,
        ...state.employmentDetailsFormWithPreviousForm.forms,
        ...state.employmentDetailsFormWithPreviousForm.forms,
        ...state.financialDetailsForm.forms,
        ...state.linkedRelationshipsForm.forms,
        ...state.bankDetailsForm.forms,
      });
    },
  },
});

export const customerDataPrivateSlice = slice;
export const customerDataPrivateGettersDef = gettersDef;
