import { CorrespondenceMethodEnum, CountryCodeEnum, NoYesEnum, TelephoneTypeEnum } from '../../types/forms';

export enum MaritalStatusEnum {
  married = 'married',
  single = 'single',
  coHabiting = 'coHabiting',
  divorced = 'divorced',
  separated = 'separated',
  widowed = 'widowed',
  widower = 'widower',
}

export enum GenderEnum {
  male = 'male',
  female = 'female',
}

export enum TitleEnum {
  mr = 'mr',
  mrs = 'mrs',
  miss = 'miss',
  ms = 'ms',
  dr = 'dr',
  notApplicable = 'notApplicable',
}

export enum CcrTypeEnum {
  /**
   * Personal Public Service (PPS) Number
   */
  ppsn = 'PPSN',
  itnNonRoi = 'Individual Tax Number (non ROI)',
}

export interface CustomerDetailsFormModel {
  gender?: GenderEnum;
  title?: TitleEnum;
  maritalStatus?: MaritalStatusEnum | null;
  makeIndemnifier?: NoYesEnum | null;
  firstName?: string;
  lastName?: string;
  middleName?: string | null;
  maidenName?: string;
  dateOfBirth?: string;
  email?: string;
  telephoneType?: TelephoneTypeEnum;
  phoneNumber?: string;
  countryCode?: CountryCodeEnum;
  correspondenceMethod?: CorrespondenceMethodEnum;
  ccrType?: CcrTypeEnum | null;
  ccrId?: string | null;
}

export type CustomerDetailsFormModelFields = keyof CustomerDetailsFormModel;
