import { FormikErrors, FormikValues, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { FormUpdateActionType } from '../../services/common/miscUtils';
import { ReduxTrackedForm } from '../../types/reduxTrackedForm';

export type SetFieldValueType<Values extends FormikValues = FormikValues> = (
  field: keyof Values,
  value: any,
  shouldValidate?: boolean
) => Promise<FormikErrors<Values>> | Promise<void>;

export const setFieldValueInitial = <Values extends FormikValues = FormikValues>() => {
  return (() => 'placeholder') as unknown as SetFieldValueType<Values>;
};

/**
 * - Helper for updating form data in state.
 * - Use it in render function passed to Formik (or within child element).
 */
export function PersistFormInState<
  FormState extends { forms: Record<any, ReduxTrackedForm<Record<string, any>>> },
  FormName extends string | number | symbol = keyof FormState['forms'],
>(props: {
  children?: JSX.Element;
  formName: FormName;
  shouldValidate?: FormState['forms'][FormName]['triggers']['validation'];
  /**
   * - Provide for forms that might be reset.
   */
  shouldReset?: FormState['forms'][FormName]['triggers']['reset'];
  updateForm: (payload: FormUpdateActionType<FormState>['payload']) => void;
  setFormFieldSetter?: (setFieldValue: SetFieldValueType<any>) => void;
  valuesToResetTo?: FormState['forms'][FormName]['values'];
}) {
  const {
    values,
    isValid,
    dirty,
    submitForm,
    isValidating,
    isSubmitting,
    errors,
    submitCount,
    setFieldValue,
    setValues,
  } = useFormikContext();
  const { formName, shouldValidate, shouldReset, updateForm, valuesToResetTo } = props;

  const isSubmittingRef = useRef(false);
  const lastIsSubmitting = isSubmittingRef.current;

  const isFirstRenderRef = useRef(true);
  const isFirstRender = isFirstRenderRef.current;

  // Ensure form component is not mounted with reset trigger ON.
  useEffect(() => {
    isFirstRenderRef.current = false;
    updateForm({ formName, triggerReset: false });
  });

  useEffect(() => {
    if (props.setFormFieldSetter) {
      // Passing `() => setFieldValue` instead of `setFieldValue` is intentional.
      props.setFormFieldSetter((() => setFieldValue) as unknown as SetFieldValueType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values) {
      updateForm({ formName, values, isValid, dirty });
    }
  }, [values, isValid, dirty, formName, updateForm]);

  useEffect(() => {
    if (shouldReset && !isFirstRender) {
      if (!valuesToResetTo) {
        throw new Error(`Form ${String(formName)} has no initialValues to reset to.`);
      }

      setValues(props.valuesToResetTo);
      updateForm({ formName, triggerReset: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldReset]);

  useEffect(() => {
    if (shouldValidate) {
      submitForm();
      updateForm({ formName, triggerValidation: false });
    }
  }, [shouldValidate, submitForm, updateForm, formName]);

  useEffect(() => {
    updateForm({ formName, isSubmitting, isValidating, submitCount });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isValidating, updateForm, formName]);

  useEffect(() => {
    // Print errors after submit for debugging.
    if (lastIsSubmitting && !isSubmitting) {
      /* eslint-disable no-console */
      if (Object.keys(errors).length > 0) {
        console.groupCollapsed(`❌ Submit results for ${String(formName)}`);
        console.table(errors);
      } else {
        console.groupCollapsed(`✔ Submit results for ${String(formName)}`);
        console.table(values);
      }
      console.groupEnd();
      /* eslint-enable no-console */
    }

    isSubmittingRef.current = isSubmitting;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  if (props.children) {
    throw new Error('This component should not serve as a wrapper');
  }

  return null;
}

export default PersistFormInState;
