import { RadioButton, RadioButtonGroup } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import { FormField } from '..';
import { FormFieldPropsBase, RadioOption } from './fieldset.model';

interface RadioGroupFormControlProps extends FormFieldPropsBase {
  optionList: Array<RadioOption>;
}

export const RadioGroupFormControl: FunctionComponent<RadioGroupFormControlProps> = ({
  fieldName,
  testId,
  labelTranslationKey = '',
  optionList,
  optional,
  isDisabled,
}): React.JSX.Element => {
  const [field, , helpers] = useField(fieldName);

  return (
    <FormField
      type="radio-group"
      name={fieldName}
      testId={testId ?? fieldName}
      labelText={`${t(labelTranslationKey)}${optional ? t('formControls:optionalLabelAffix') : ''}`}
      isDisabled={isDisabled}
      render={() => (
        <RadioButtonGroup>
          {optionList.map((o) => (
            <RadioButton
              value={o.value}
              checked={field.value === o.value}
              onChange={() => helpers.setValue(o.value)}
              key={o.value}
              disabled={isDisabled}
              name={fieldName}
            >
              {o.label}
            </RadioButton>
          ))}
        </RadioButtonGroup>
      )}
    />
  );
};
