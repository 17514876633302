import { Button, ButtonContainer, Fieldset, FormSection, FormSectionGroup, Layout } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddressWithPreviousForm from '../../../../components/AddressWithPreviousForm/AddressWithPreviousForm';
import { BankDetailsForm } from '../../../../components/BankDetailsForm/BankDetailsForm';
import { CustomerDetailsForm } from '../../../../components/CustomerDetailsForm/CustomerDetailsForm';
import { FinancialDetailsForm } from '../../../../components/FinancialDetailsForm/FinancialDetailsForm';
import { LinkedRelationshipsForm } from '../../../../components/LinkedRelationshipsForm/LinkedRelationshipsForm';
import { TradingDetailsForm } from '../../../../components/TradingDetailsForm/TradingDetailsForm';
import {
  FormUpdateActionTypeGeneric,
  checkIfSectionIsReadyAndValid,
  getFormSectionId,
  getFormSectionStatusProps,
  getFormsState,
  scrollIntoSection,
  setAllFoldToFalse,
} from '../../../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../../../services/redux';
import { FlowTypeEnum } from '../../../../types/forms';
import useSaveSession from '../../../../utils/useSaveSession/useSaveSession';
import { getSectionTitleForCustomerCurried } from '../../../utils';

enum AvailableSections {
  tradingDetails,
  customerDetails,
  customerAddress,
  financialDetails,
  linkedRelationships,
  bankDetails,
}

const NavigationControls = ({ onNextNavigate }) => {
  return (
    <>
      <Layout right>
        <Layout.Item default="1/6">
          <ButtonContainer right>
            <Button onClick={onNextNavigate}>{t('customerDataPage:nextFormButton')}</Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </>
  );
};

const getSectionTitleForCustomerLocal = getSectionTitleForCustomerCurried(FlowTypeEnum.soleTrader);

export const customerDataBusinessSoleTraderUpdateForm = {
  mainApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.tradingDetailsForm.updateForm({ formName: 'tradingDetails', ...payload });
    actions.customerDetailsForm.updateForm({ formName: 'customerDetails1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'currentAddress1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'previousAddress1', ...payload });
    actions.financialDetailsForm.updateForm({ formName: 'financialDetails1', ...payload });
    actions.linkedRelationshipsForm.updateForm({ formName: 'linkedRelationshipsForm1', ...payload });
    actions.bankDetailsForm.updateForm({ formName: 'bankDetails', ...payload });
  },
};

const CustomerDataBusinessSoleTrader = () => {
  const formSectionDesignator = 'customerDataSoleTraderFormSection';
  const { focusOnError } = useGetters().customerDataPage;

  const [currentSection, setCurrentSection] = useState(AvailableSections.tradingDetails);
  const [pendingSection, setPendingSection] = useState<AvailableSections | null>(
    () => AvailableSections.tradingDetails
  );

  const { handleSaveSession } = useSaveSession();

  const [areAllFormsSettled, setAreAllFormsSettled] = useState(false);

  const setActiveSection = (section: AvailableSections) => {
    setCurrentSection(section);
  };

  const formsState = getFormsState({
    ...useGetters().customerDetailsForm.forms,
    ...useGetters().tradingDetailsForm.forms,
    ...useGetters().bankDetailsForm.forms,
    ...useGetters().financialDetailsForm.forms,
    ...useGetters().addressWithPreviousForm.forms,
    ...useGetters().linkedRelationshipsForm.forms,
  });

  // When in Standalone Service Plan user selected Upfront payment for service plan,
  // then we do not need Bank Details
  const isUpfrontPayment =
    getters.standaloneServicePlanForm.standaloneServicePlanValues.paymentType?.toLowerCase() === 'upfront';

  useEffect(() => {
    if (isUpfrontPayment) {
      actions.bankDetailsForm.updateForm({ formName: 'bankDetails', excluded: true });
    }
  }, [isUpfrontPayment]);

  const areAllFormsValid = Object.entries(formsState).every(([, { isValid, excluded }]) => isValid || excluded);

  useEffect(() => {
    setAreAllFormsSettled(
      Object.entries(formsState).every(([, { isValidating, excluded }]) => !isValidating || excluded)
    );
  }, [formsState]);

  const [foldStatus, setFoldStatus] = useState<Record<number, boolean>>({
    [AvailableSections.tradingDetails]: false,
    [AvailableSections.customerDetails]: false,
    [AvailableSections.customerAddress]: false,
    [AvailableSections.financialDetails]: false,
    [AvailableSections.linkedRelationships]: false,
    [AvailableSections.bankDetails]: false,
  });

  const formSectionMap = useMemo(() => {
    return {
      [AvailableSections.tradingDetails]: formsState.tradingDetails,
      [AvailableSections.customerDetails]: formsState.customerDetails1,
      [AvailableSections.customerAddress]: [formsState.currentAddress1, formsState.previousAddress1],
      [AvailableSections.financialDetails]: formsState.financialDetails1,
      [AvailableSections.linkedRelationships]: formsState.linkedRelationshipsForm1,
      [AvailableSections.bankDetails]: formsState.bankDetails,
    };
  }, [formsState]);

  useEffect(() => {
    if (areAllFormsValid) {
      actions.customerDataBusinessSoleTrader.setAllFormsValid({ allFormsValid: true });
    } else {
      actions.customerDataBusinessSoleTrader.setAllFormsValid({ allFormsValid: false });
    }
  }, [areAllFormsValid]);

  const { currentSectionIsReady, currentSectionIsValid } = checkIfSectionIsReadyAndValid(
    AvailableSections,
    currentSection,
    formSectionMap
  );

  const findFirstSectionToOpen = useCallback((): AvailableSections => {
    return Object.entries(formSectionMap).find(([section]) => {
      const form = formSectionMap[section];
      const props = getFormSectionStatusProps(form);
      return props.error;
    })?.[0] as unknown as AvailableSections;
  }, [formSectionMap]);

  useEffect(() => {
    if (pendingSection === null || !currentSectionIsReady) return;
    if (currentSectionIsValid) setCurrentSection(pendingSection);
    setPendingSection(null);
  }, [currentSection, pendingSection, currentSectionIsReady, currentSectionIsValid]);

  useEffect(
    function focusOnFirstErrorSection() {
      if (focusOnError && areAllFormsSettled) {
        const firstSectionToOpen = findFirstSectionToOpen();
        if (firstSectionToOpen) {
          setCurrentSection(firstSectionToOpen);
          actions.customerDataPage.updateFocusOnError({ state: false });
        }
      }
    },
    [focusOnError, findFirstSectionToOpen, areAllFormsSettled]
  );

  const closeAllSections = () => {
    const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

    setFoldStatus(resetFoldStatus);
  };

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  useEffect(
    function openNextActiveSection() {
      const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

      // When available Save And Continue Lead form must not be scrolled out of viewport
      if (!disableAllFormControls) {
        scrollIntoSection(getFormSectionId<AvailableSections>(formSectionDesignator, currentSection));
      }

      const next = { ...resetFoldStatus, [currentSection]: true };

      setFoldStatus(next);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSection]
  );

  const changeFoldStatus = (section: AvailableSections, isOpened: boolean) => {
    const next = { ...foldStatus, [section]: isOpened };

    setFoldStatus(next);
  };

  return (
    <FormSectionGroup>
      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.tradingDetails)}
        defaultOpen={foldStatus[AvailableSections.tradingDetails]}
        title={getSectionTitleForCustomerLocal('tradingDetailsForm')}
        onClickCapture={() => setActiveSection(AvailableSections.tradingDetails)}
        {...getFormSectionStatusProps(formsState.tradingDetails)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.tradingDetails, isOpened);
        }}
      >
        <Fieldset.Row>
          <TradingDetailsForm />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.tradingDetailsForm.updateForm({ triggerValidation: true, formName: 'tradingDetails' });
              setPendingSection(AvailableSections.customerDetails);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerDetails)}
        defaultOpen={foldStatus[AvailableSections.customerDetails]}
        title={getSectionTitleForCustomerLocal('customerDetailsForm')}
        onClickCapture={() => setActiveSection(AvailableSections.customerDetails)}
        {...getFormSectionStatusProps(formsState.customerDetails1)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.customerDetails, isOpened);
        }}
      >
        <Fieldset.Row>
          <CustomerDetailsForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.customerDetailsForm.updateForm({ triggerValidation: true, formName: 'customerDetails1' });
              setPendingSection(AvailableSections.customerAddress);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerAddress)}
        defaultOpen={foldStatus[AvailableSections.customerAddress]}
        title={getSectionTitleForCustomerLocal('addressWithPreviousForm')}
        onClickCapture={() => setActiveSection(AvailableSections.customerAddress)}
        {...getFormSectionStatusProps([formsState.currentAddress1, formsState.previousAddress1])}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.customerAddress, isOpened);
        }}
      >
        <Fieldset.Row>
          <AddressWithPreviousForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'currentAddress1' });
              actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'previousAddress1' });
              setPendingSection(AvailableSections.financialDetails);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.financialDetails)}
        defaultOpen={foldStatus[AvailableSections.financialDetails]}
        title={getSectionTitleForCustomerLocal('financialDetailsForm')}
        onClickCapture={() => setActiveSection(AvailableSections.financialDetails)}
        {...getFormSectionStatusProps(formsState.financialDetails1)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.financialDetails, isOpened);
        }}
      >
        <Fieldset.Row>
          <FinancialDetailsForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.financialDetailsForm.updateForm({ triggerValidation: true, formName: 'financialDetails1' });
              setPendingSection(AvailableSections.linkedRelationships);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.linkedRelationships)}
        defaultOpen={foldStatus[AvailableSections.linkedRelationships]}
        title={getSectionTitleForCustomerLocal('linkedRelationshipsForm')}
        onClickCapture={() => setActiveSection(AvailableSections.linkedRelationships)}
        {...getFormSectionStatusProps(formsState.linkedRelationshipsForm1)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.linkedRelationships, isOpened);
        }}
      >
        <Fieldset.Row>
          <LinkedRelationshipsForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.linkedRelationshipsForm.updateForm({
                triggerValidation: true,
                formName: 'linkedRelationshipsForm1',
              });

              if (isUpfrontPayment) {
                closeAllSections();
                setPendingSection(null);
              } else {
                setPendingSection(AvailableSections.bankDetails);
              }
            }}
          />
        </Fieldset.Row>
      </FormSection>

      {!isUpfrontPayment && (
        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.bankDetails)}
          defaultOpen={foldStatus[AvailableSections.bankDetails]}
          title={getSectionTitleForCustomerLocal('bankDetailsForm')}
          onClickCapture={() => setActiveSection(AvailableSections.bankDetails)}
          {...getFormSectionStatusProps(formsState.bankDetails)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.bankDetails, isOpened);
          }}
        >
          <Fieldset.Row>
            <BankDetailsForm />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.bankDetailsForm.updateForm({
                  triggerValidation: true,
                  formName: 'bankDetails',
                });

                closeAllSections();
                setPendingSection(null);
              }}
            />
          </Fieldset.Row>
        </FormSection>
      )}
    </FormSectionGroup>
  );
};

export default CustomerDataBusinessSoleTrader;
