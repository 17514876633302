import { apiSlice } from './api.api-slice';

export const standaloneServicePlanSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendStandaloneServicePlan: builder.mutation<any, any>({
      query: ({ standaloneServicePlanValues, isNewVehicle }) => ({
        url: '/standaloneServicePlanData',
        method: 'POST',
        body: { ...standaloneServicePlanValues, isNewVehicle },
      }),
      extraOptions: {
        secondaryPath: '',
        applyProductPath: false,
      },
    }),
  }),
});

export const { useSendStandaloneServicePlanMutation } = standaloneServicePlanSlice;
