import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../../types/reduxTrackedForm';
import { SnCInitializationFormModel } from './SnCInitialization.model';

export interface SnCInitializationState
  extends ReduxStateWithForms<{
    snCInitializationForm: ReduxTrackedForm<SnCInitializationFormModel>;
  }> {
  snCSubmitted: boolean;
  snCClosed: boolean;
}

export const snCInitializationFormInitialValues: SnCInitializationFormModel = {
  countryCode: '+353',
  email: '',
  mobileNumber: '',
};

const initialState: SnCInitializationState = {
  snCClosed: false,
  snCSubmitted: false,
  forms: {
    snCInitializationForm: {
      ...createReduxTrackedFormDefaults<SnCInitializationFormModel>({
        initialValues: snCInitializationFormInitialValues,
      }),
      values: { ...snCInitializationFormInitialValues },
    },
  },
};

const name = 'snCInitialization';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    snCSubmitted: (state) => {
      state.snCSubmitted = true;
    },
    snCClosed: (state) => {
      state.snCClosed = true;
    },
    updateForm: getFormUpdater<SnCInitializationState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    snCSubmitted({ snCInitialization }) {
      return snCInitialization.snCSubmitted;
    },
    snCClosed({ snCInitialization }) {
      return snCInitialization.snCClosed;
    },
    snCEmail({ snCInitialization }) {
      return snCInitialization.forms.snCInitializationForm.values.email;
    },
    snCFormValues({ snCInitialization }) {
      return snCInitialization.forms.snCInitializationForm.values;
    },
  },
});

export const snCInitializationSlice = slice;
export const snCInitializationGettersDef = gettersDef;
