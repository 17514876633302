import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import Yup from '../../services/common/validation';
import { AddressFormGenericModel, AddressFormGenericModelFields } from './addressFormGeneric.model';

export const getAddressFormValidationSchema = (
  fieldsToRender: Array<AddressFormGenericModelFields>
): ObjectSchema<Partial<AddressFormGenericModel>> => {
  const shouldBeEmptySchema = Yup.string().shouldBeEmpty();

  return Yup.object({
    addressLine1: fieldsToRender.includes('addressLine1')
      ? Yup.string()
          .matches(/^[a-zA-Z0-9 ]*$/, t('validation:onlyLettersAndNumbers'))
          .required(t('validation:required'))
      : shouldBeEmptySchema,
    addressLine2: fieldsToRender.includes('addressLine2')
      ? Yup.string()
          .matches(/^[a-zA-Z0-9 ]*$/, t('validation:onlyLettersAndNumbers'))
          .notRequired()
      : shouldBeEmptySchema,
    addressLine3: fieldsToRender.includes('addressLine3')
      ? Yup.string()
          .matches(/^[a-zA-Z0-9 ]*$/, t('validation:onlyLettersAndNumbers'))
          .notRequired()
      : shouldBeEmptySchema,
    eirCode: fieldsToRender.includes('eirCode')
      ? Yup.string().eirCode(t('validation:invalidEircode')).notRequired()
      : shouldBeEmptySchema,
    town: fieldsToRender.includes('town')
      ? Yup.string()
          .matches(/^[a-zA-Z0-9 ]*$/, t('validation:onlyLettersAndNumbers'))
          .required(t('validation:required'))
      : shouldBeEmptySchema,
    county: fieldsToRender.includes('county') ? Yup.string().required(t('validation:required')) : shouldBeEmptySchema,
    residentialStatus: fieldsToRender.includes('residentialStatus')
      ? Yup.string().required(t('validation:required'))
      : shouldBeEmptySchema,
    timeAtAddressYears: fieldsToRender.includes('timeAtAddressYears')
      ? Yup.string().required(t('validation:required'))
      : shouldBeEmptySchema,
    timeAtAddressMonths: fieldsToRender.includes('timeAtAddressMonths')
      ? Yup.string().required(t('validation:required'))
      : shouldBeEmptySchema,
  });
};
