/* eslint-disable react/require-default-props */
import React, { FunctionComponent, ReactNode, memo } from 'react';

import { FormField as BronsonReactFormField, ErrorMessage } from '@vwfs-bronson/bronson-react';
import { Field, FieldInputProps, FormikProps } from 'formik';
import get from 'lodash/get';

interface FormFieldProps {
  render: (props: any) => ReactNode;
  name: string;
  labelElement?: string;
  labelText: string;
  hint?: string;
  infoIcon?: any;
  notion?: boolean;
  type: any;
  className?: string;
  testId?: string;
  validateOnChange?: boolean;
  /**
   * - Prop indicating if SelectFormField changed contents
   * of it's list due to eagerListRender-ing mechanics.
   */
  showEntireList?: boolean;
  inputVisualValue?: string;
  isDisabled?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  optionList?: Array<any>;
}

interface FieldAndFormProps {
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  value: FieldInputProps<any>['value'];
  fieldErrors: string;
  fieldTouched: boolean;
}

interface RenderBronsonFormFieldProps extends FormFieldProps, FieldAndFormProps {}

const getErrorMessage = (fieldTouched: boolean, errors: string, name: string) => {
  return fieldTouched && !!errors ? (
    <ErrorMessage testId={`error-message-${name}`} dangerouslySetInnerHTML={{ __html: errors as string }} />
  ) : null;
};

// eslint-disable-next-line react/display-name
const RenderBronsonFormField = memo(
  (props: RenderBronsonFormFieldProps) => {
    const { className, labelElement, labelText, field, testId, fieldErrors, fieldTouched, name, form } = props;
    const { hint, notion, infoIcon, type, render } = props;

    return (
      <BronsonReactFormField
        className={className}
        labelElement={labelElement || 'label'}
        labelText={labelText}
        type={type || 'input'}
        errorMessage={getErrorMessage(fieldTouched, fieldErrors, name)}
        hint={hint}
        notion={notion}
        infoIcon={infoIcon}
        testId={testId}
      >
        {render({
          ...field,
          onChange: (e) => {
            form.setFieldValue(field.name, e.target.value).then(() => {
              if (props.validateOnChange) {
                form.validateField(field.name);
              }
            });
          },
          type,
        } as FieldInputProps<any>)}
      </BronsonReactFormField>
    );
  },
  (oldProps, newProps) => {
    return (
      oldProps.value === newProps.value &&
      oldProps.fieldErrors === newProps.fieldErrors &&
      oldProps.fieldTouched === newProps.fieldTouched &&
      oldProps.showEntireList === newProps.showEntireList &&
      oldProps.inputVisualValue === newProps.inputVisualValue &&
      oldProps.isDisabled === newProps.isDisabled &&
      oldProps.isError === newProps.isError &&
      oldProps.isSuccess === newProps.isSuccess &&
      oldProps.optionList === newProps.optionList
    );
  }
);

const FormField: FunctionComponent<FormFieldProps> = (props) => (
  <Field name={props.name}>
    {(fieldAndFormProps: FieldAndFormProps) => (
      <RenderBronsonFormField
        {...props}
        {...fieldAndFormProps}
        isDisabled={props.isDisabled}
        value={fieldAndFormProps.field.value}
        fieldErrors={get(fieldAndFormProps.form.errors, fieldAndFormProps.field.name) as string}
        fieldTouched={get(fieldAndFormProps.form.touched, fieldAndFormProps.field.name) as boolean}
      />
    )}
  </Field>
);
export default FormField;
