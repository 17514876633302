import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { ContactAddressFormModel } from './contactAddressForm.model';

export interface ContactAddressFormState
  extends ReduxStateWithForms<{
    contactAddressForm: ReduxTrackedForm<ContactAddressFormModel>;
  }> {}

const contactAddressFormInitialValues: ContactAddressFormModel = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  eirCode: '',
  town: '',
  county: '',
};

const initialState: ContactAddressFormState = {
  forms: {
    contactAddressForm: {
      ...createReduxTrackedFormDefaults<ContactAddressFormModel>({
        initialValues: contactAddressFormInitialValues,
      }),
      values: {
        ...contactAddressFormInitialValues,
      },
    },
  },
};

const name = 'contactAddressForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<ContactAddressFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    contactAddressTriggers({ contactAddressForm }) {
      return contactAddressForm.forms.contactAddressForm.triggers;
    },
    contactAddressValues({ contactAddressForm }) {
      return contactAddressForm.forms.contactAddressForm.values;
    },
    forms({ contactAddressForm }) {
      return contactAddressForm.forms;
    },
  },
});

export const contactAddressFormSlice = slice;
export const contactAddressFormGettersDef = gettersDef;
