import { Heading, Paragraph } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { actions, getters, useGetters } from '../../services/redux';
import { EmploymentDetailsForm } from '../EmploymentDetailsForm/EmploymentDetailsForm';
import { EmploymentDetailsFormModel } from '../EmploymentDetailsForm/employmentDetailsForm.model';
import { PreviousEmploymentDetailsForm } from '../PreviousEmploymentDetailsForm/PreviousEmploymentDetailsForm';
import { EmploymentDetailsFormWithPreviousFormState } from './employmentDetailsFormWithPreviousForm.slice';

function _EmploymentDetailsFormWithPreviousForm<
  FormName extends keyof EmploymentDetailsFormWithPreviousFormState['forms'],
>(props: {
  formNameCurrentEmployment: FormName;
  formNamePreviousEmployment: FormName;
  initialValuesCurrentEmployment: EmploymentDetailsFormModel;
  initialValuesPreviousEmployment: EmploymentDetailsFormModel;
  valuesToResetToCurrentEmployment: EmploymentDetailsFormModel | undefined;
  valuesToResetToPreviousEmployment: EmploymentDetailsFormModel | undefined;
  currentValuesCurrentEmployment: EmploymentDetailsFormModel;
  shouldValidateCurrent: boolean;
  shouldValidatePrevious: boolean;
  shouldResetCurrent: boolean;
  shouldResetPrevious: boolean;
  isPreviousExcluded: boolean;
}) {
  const [initialValuesCurrentEmployment, setInitialValuesCurrentEmployment] = useState(
    () => props.initialValuesCurrentEmployment
  );
  const [initialValuesPreviousEmployment, setInitialValuesPreviousEmployment] = useState(
    () => props.initialValuesPreviousEmployment
  );

  useEffect(
    function ShouldShowPreviousEmploymentForm() {
      const years = props.currentValuesCurrentEmployment?.timeAtEmployerYears;
      const yearsNumber = Number(years);

      let shouldPreviousBeExcluded: boolean;

      if (![undefined, null, ''].includes(years) && yearsNumber < 3) {
        shouldPreviousBeExcluded = false;
      } else {
        shouldPreviousBeExcluded = true;
      }

      // Adjust form exclusion to local component exclusion
      if (props.isPreviousExcluded !== shouldPreviousBeExcluded) {
        actions.employmentDetailsFormWithPreviousForm.updateForm({
          formName: props.formNamePreviousEmployment,
          excluded: shouldPreviousBeExcluded,
        });
      }
    },
    [props.currentValuesCurrentEmployment, props.formNamePreviousEmployment, props.isPreviousExcluded]
  );

  return (
    <>
      <EmploymentDetailsForm
        formName={props.formNameCurrentEmployment}
        initialValues={initialValuesCurrentEmployment}
        shouldValidate={props.shouldValidateCurrent}
        shouldReset={props.shouldResetCurrent}
        onReset={() => {
          if (!props.valuesToResetToCurrentEmployment) {
            throw new Error(`Form ${props.formNameCurrentEmployment} has no initialValues to reset to.`);
          }
          setInitialValuesCurrentEmployment(props.valuesToResetToCurrentEmployment);
        }}
      />

      {!props.isPreviousExcluded && (
        <>
          <Heading level={5} className="u-mt u-mb-small u-text-left">
            {t('employmentDetailsFormWithPreviousForm:previousEmploymentDetailsForm.heading')}
          </Heading>
          <Paragraph className="u-mb u-text-left">
            {t('employmentDetailsFormWithPreviousForm:previousEmploymentDetailsForm.paragraph')}
          </Paragraph>
          <PreviousEmploymentDetailsForm
            formName={props.formNamePreviousEmployment}
            initialValues={initialValuesPreviousEmployment}
            shouldValidate={props.shouldValidatePrevious}
            shouldReset={props.shouldResetPrevious}
            onReset={() => {
              if (!props.valuesToResetToPreviousEmployment) {
                throw new Error(`Form ${props.formNamePreviousEmployment} has no initialValues to reset to.`);
              }
              setInitialValuesPreviousEmployment(props.valuesToResetToPreviousEmployment);
            }}
          />
        </>
      )}
    </>
  );
}

export const EmploymentDetailsFormWithPreviousForm: FunctionComponent<{ isMainApplicant: boolean }> = ({
  isMainApplicant,
}) => {
  let formNameCurrentEmployment: keyof EmploymentDetailsFormWithPreviousFormState['forms'];
  let formNamePreviousEmployment: keyof EmploymentDetailsFormWithPreviousFormState['forms'];
  let initialValuesCurrentEmployment: EmploymentDetailsFormModel;
  let initialValuesPreviousEmployment: EmploymentDetailsFormModel;
  let valuesToResetToCurrentEmployment: EmploymentDetailsFormModel | undefined;
  let valuesToResetToPreviousEmployment: EmploymentDetailsFormModel | undefined;
  let currentValuesCurrentEmployment: EmploymentDetailsFormModel;
  let shouldValidateCurrent: boolean;
  let shouldValidatePrevious: boolean;
  let shouldResetCurrent: boolean;
  let shouldResetPrevious: boolean;
  let isPreviousExcluded: boolean;

  /* eslint-disable react-hooks/rules-of-hooks */
  if (isMainApplicant) {
    formNameCurrentEmployment = 'currentEmploymentDetails1';
    formNamePreviousEmployment = 'previousEmploymentDetails1';

    initialValuesCurrentEmployment = getters.employmentDetailsFormWithPreviousForm.currentEmploymentDetails1Values;
    initialValuesPreviousEmployment = getters.employmentDetailsFormWithPreviousForm.previousEmploymentDetails1Values;
    valuesToResetToCurrentEmployment =
      getters.employmentDetailsFormWithPreviousForm.forms.currentEmploymentDetails1.initialValues;
    valuesToResetToPreviousEmployment =
      getters.employmentDetailsFormWithPreviousForm.forms.previousEmploymentDetails1.initialValues;

    isPreviousExcluded = useGetters().employmentDetailsFormWithPreviousForm.previousEmploymentDetails1Excluded;
    currentValuesCurrentEmployment = useGetters().employmentDetailsFormWithPreviousForm.currentEmploymentDetails1Values;
    shouldValidateCurrent =
      useGetters().employmentDetailsFormWithPreviousForm.currentEmploymentDetails1Triggers.validation;
    shouldValidatePrevious =
      useGetters().employmentDetailsFormWithPreviousForm.previousEmploymentDetails1Triggers.validation;
    shouldResetCurrent = getters.employmentDetailsFormWithPreviousForm.currentEmploymentDetails1Triggers.reset;
    shouldResetPrevious = getters.employmentDetailsFormWithPreviousForm.previousEmploymentDetails1Triggers.reset;
  } else {
    formNameCurrentEmployment = 'currentEmploymentDetails2';
    formNamePreviousEmployment = 'previousEmploymentDetails2';

    initialValuesCurrentEmployment = getters.employmentDetailsFormWithPreviousForm.currentEmploymentDetails2Values;
    initialValuesPreviousEmployment = getters.employmentDetailsFormWithPreviousForm.previousEmploymentDetails2Values;
    valuesToResetToCurrentEmployment =
      getters.employmentDetailsFormWithPreviousForm.forms.currentEmploymentDetails2.initialValues;
    valuesToResetToPreviousEmployment =
      getters.employmentDetailsFormWithPreviousForm.forms.previousEmploymentDetails2.initialValues;

    isPreviousExcluded = useGetters().employmentDetailsFormWithPreviousForm.previousEmploymentDetails2Excluded;
    currentValuesCurrentEmployment = useGetters().employmentDetailsFormWithPreviousForm.currentEmploymentDetails2Values;
    shouldValidateCurrent =
      useGetters().employmentDetailsFormWithPreviousForm.currentEmploymentDetails2Triggers.validation;
    shouldValidatePrevious =
      useGetters().employmentDetailsFormWithPreviousForm.previousEmploymentDetails2Triggers.validation;
    shouldResetCurrent = getters.employmentDetailsFormWithPreviousForm.currentEmploymentDetails2Triggers.reset;
    shouldResetPrevious = getters.employmentDetailsFormWithPreviousForm.previousEmploymentDetails2Triggers.reset;
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  return _EmploymentDetailsFormWithPreviousForm({
    formNameCurrentEmployment,
    formNamePreviousEmployment,
    initialValuesCurrentEmployment,
    initialValuesPreviousEmployment,
    valuesToResetToCurrentEmployment,
    valuesToResetToPreviousEmployment,
    currentValuesCurrentEmployment,
    shouldValidateCurrent,
    shouldValidatePrevious,
    isPreviousExcluded,
    shouldResetCurrent,
    shouldResetPrevious,
  });
};
