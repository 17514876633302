import { FormField, Modal, Select } from '@vwfs-bronson/bronson-react';
import { Field, Form, Formik, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { formAutocomplete } from '../../config';
import { Outlet, useOutletListQuery } from '../../services/redux/features/api/outlet.api-slice';
import { saveSelectedOutlet } from '../../services/redux/features/outlet.slice';

type Props = {
  isShown: boolean;
};

export const OutletSelectionPopup: React.FC<Props> = ({ isShown = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formikRef = React.useRef<FormikProps<Outlet>>(null);

  const { isSuccess, data: outletsList } = useOutletListQuery();

  const handleOutletSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const companyName = outletsList?.find((outlet) => outlet.companyRegistrationNumber === e.target.value)?.companyName;

    formikRef.current?.setFieldValue('companyName', companyName);
    formikRef.current?.setFieldValue('companyRegistrationNumber', e.target.value);
  };

  const handleSubmit = () => {
    dispatch(saveSelectedOutlet(formikRef.current!.values));
  };

  return isSuccess ? (
    <Modal
      buttonConfirmText={t('outletSelectionPopup:button')}
      buttonConfirmType="submit"
      hideCloseButton
      id="outletSelectionModal"
      onConfirm={handleSubmit}
      shown={isShown}
      title={t('outletSelectionPopup:title')}
    >
      <Formik
        validateOnMount
        validateOnChange={false}
        validateOnBlur
        initialValues={outletsList[0]}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        <Form autoComplete={formAutocomplete}>
          <FormField labelForId="outletSelectionDropdown" type="select">
            <Field name="companyRegistrationDate">
              {() => (
                <Select id="outletSelectionDropdown" onChange={handleOutletSelection}>
                  {outletsList?.map(({ companyRegistrationNumber, companyName }) => (
                    <Select.Item key={`${companyRegistrationNumber}-${companyName}`} value={companyRegistrationNumber}>
                      {`${companyRegistrationNumber} ${companyName}`}
                    </Select.Item>
                  ))}
                </Select>
              )}
            </Field>
          </FormField>
        </Form>
      </Formik>
    </Modal>
  ) : null;
};
