import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { BankDetailsFormModel } from './bankDetailsForm.model';

export interface BankDetailsFormState
  extends ReduxStateWithForms<{
    bankDetails: ReduxTrackedForm<BankDetailsFormModel>;
  }> {}

const bankDetailsInitialValues: BankDetailsFormModel = {
  iban: '',
  bic: '',
  bankAccountHolder: '',
};

const initialState: BankDetailsFormState = {
  forms: {
    bankDetails: {
      ...createReduxTrackedFormDefaults<BankDetailsFormModel>({ initialValues: bankDetailsInitialValues }),
      values: {
        ...bankDetailsInitialValues,
      },
    },
  },
};

const name = 'bankDetailsForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<BankDetailsFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    bankDetailsTriggers({ bankDetailsForm }) {
      return bankDetailsForm.forms.bankDetails.triggers;
    },
    bankDetailsValues({ bankDetailsForm }) {
      return bankDetailsForm.forms.bankDetails.values;
    },
    forms({ bankDetailsForm }) {
      return bankDetailsForm.forms;
    },
  },
});

export const bankDetailsFormSlice = slice;
export const bankDetailsFormGettersDef = gettersDef;
