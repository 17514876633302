import { ButtonGroup, Form, Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect } from 'react';
import { getEnumValues } from '../../../services/common/miscUtils';
import { actions, useGetters } from '../../../services/redux';
import { CustomerTypeEnum } from './CustomerTypeSelectionPageModels';
import { excludeAndResetAllCustomerDataForms } from '../CustomerDataPage/CustomerDataPage';

export const CustomerTypeSelectionForm: FunctionComponent = () => {
  const {
    customerTypeSelectionForm: { values },
  } = useGetters().customerTypeSelectionPage;

  // Check initial value
  useEffect(() => {
    if (values.customerType && getEnumValues(CustomerTypeEnum).includes(values.customerType)) {
      actions.customerTypeSelectionPage.updateForm({ formName: 'customerTypeSelection', isValid: true, dirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form>
      <Fieldset.Row>
        <Layout className="u-m-xlarge">
          <Layout.Item default="1/1">
            <ButtonGroup buttonGroupName="formSelector" buttonGroupType="radio">
              <ButtonGroup.Item
                index="1"
                readOnly
                buttonGroupItemIcon="semantic-user"
                checked={values.customerType === CustomerTypeEnum.consumer}
                testId="button_customer_consumer"
                onClick={() => {
                  actions.customerTypeSelectionPage.updateForm({
                    formName: 'customerTypeSelection',
                    values: { customerType: CustomerTypeEnum.consumer },
                    isValid: true,
                    dirty: true,
                  });
                  actions.customerDataPage.deselectBusinessCustomerType();
                  excludeAndResetAllCustomerDataForms();
                }}
              >
                {t('customerTypeSelectionPage:customerType.consumer')}
              </ButtonGroup.Item>
              <ButtonGroup.Item
                index="2"
                readOnly
                buttonGroupItemIcon="briefcase"
                checked={values.customerType === CustomerTypeEnum.business}
                testId="button_customer_business"
                onClick={() => {
                  actions.customerTypeSelectionPage.updateForm({
                    formName: 'customerTypeSelection',
                    values: { customerType: CustomerTypeEnum.business },
                    isValid: true,
                    dirty: true,
                  });
                  actions.customerDataPage.selectDefaultBusinessCustomerType();
                  excludeAndResetAllCustomerDataForms();
                }}
              >
                {t('customerTypeSelectionPage:customerType.business')}
              </ButtonGroup.Item>
            </ButtonGroup>
          </Layout.Item>
        </Layout>
      </Fieldset.Row>
    </Form>
  );
};
