import { ObjectSchema } from 'yup';
import { getEnumValues } from '../../services/common/miscUtils';
import Yup from '../../services/common/validation';
import { NoYesEnum } from '../../types/forms';
import {
  TradingDetailsSIBusinessFormAddressModelFields,
  TradingDetailsSIBusinessFormModel,
} from './tradingDetailsSIBusinessForm.model';

export const createTradingDetailsSIBusinessFormValidator = (): ObjectSchema<
  Omit<TradingDetailsSIBusinessFormModel, TradingDetailsSIBusinessFormAddressModelFields>
> => {
  return Yup.object({
    tradingAs: Yup.string().requiredWithMsg(),
    legalName: Yup.string().requiredWithMsg(),
    vatLiable: Yup.string().requiredWithMsg().oneOf(getEnumValues(NoYesEnum)),
    vatNumber: Yup.string().when(['vatLiable'], {
      is: (vatLiable: NoYesEnum) => {
        return vatLiable === NoYesEnum.yes;
      },
      then: (schema) => schema.requiredWithMsg(),
      otherwise: (schema) => schema.notRequired(),
    }),
    companyRegistrationNumber: Yup.string(),
  });
};
