import { ObjectSchema } from 'yup';
import Yup from '../../services/common/validation';
import { BankDetailsFormModel } from './bankDetailsForm.model';

export const createBankDetailsFormValidator = (): ObjectSchema<BankDetailsFormModel> => {
  return Yup.object({
    iban: Yup.string().requiredWithMsg(),
    bic: Yup.string().requiredWithMsg(),
    bankAccountHolder: Yup.string().requiredWithMsg(),
  });
};
