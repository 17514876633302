import { localeIdentifier } from '../../config';

export const formatMoney = (value: number | undefined, currency: string | undefined): string => {
  if (typeof value === 'number' && currency) {
    const formatter = new Intl.NumberFormat(localeIdentifier, {
      style: 'currency',
      currency,
    });

    return formatter.format(value);
  }

  return '';
};
