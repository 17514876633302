import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import Yup, { maxMobileNumberLength, minMobileNumberLength } from '../../../services/common/validation';
import { SnCInitializationFormModel } from './SnCInitialization.model';

export const snCInitializationFormSchema = (): ObjectSchema<SnCInitializationFormModel> => {
  return Yup.object({
    email: Yup.string().email(t('validation:invalidEmail')).required(t('validation:required')),
    countryCode: Yup.string().required(t('validation:required')).oneOf(['+48', '+353']),
    mobileNumber: Yup.string()
      .required(t('validation:required'))
      .matches(/^[0-9\s]*$/, t('validation:invalidPhoneNumber'))
      .lengthBetween(minMobileNumberLength, maxMobileNumberLength),
  });
};
