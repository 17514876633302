import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { DriverDetailsFormModel } from './driverDetailsForm.model';

export interface DriverDetailsFormState
  extends ReduxStateWithForms<{
    driverDetailsForm: ReduxTrackedForm<DriverDetailsFormModel>;
  }> {}

const driverDetailsFormInitialValues: DriverDetailsFormModel = {
  firstName: '',
  lastName: '',
};

const initialState: DriverDetailsFormState = {
  forms: {
    driverDetailsForm: {
      ...createReduxTrackedFormDefaults<DriverDetailsFormModel>({ initialValues: driverDetailsFormInitialValues }),
      values: {
        ...driverDetailsFormInitialValues,
      },
    },
  },
};

const name = 'driverDetailsForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<DriverDetailsFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    driverDetailsFormTriggers({ driverDetailsForm }) {
      return driverDetailsForm.forms.driverDetailsForm.triggers;
    },
    driverDetailsFormValues({ driverDetailsForm }) {
      return driverDetailsForm.forms.driverDetailsForm.values;
    },
    forms({ driverDetailsForm }) {
      return driverDetailsForm.forms;
    },
  },
});

export const driverDetailsFormSlice = slice;
export const driverDetailsFormGettersDef = gettersDef;
