import { Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { FunctionComponent, ReactElement, useMemo, useState } from 'react';
import { formAutocomplete } from '../../config';
import { createComboboxList, createOptionsList } from '../../services/common/miscUtils';
import { actions, useGetters } from '../../services/redux';
import { useGetAddressQuery } from '../../services/redux/features/api/searchAddress.api-slice';
import { CountyEnum } from '../AddressFormGeneric/addressFormGeneric.model';
import AutoFillAddress from '../AutofillAdress/AutfillAddress';
import { EmploymentDetailsFormWithPreviousFormState } from '../EmploymentDetailsFormWithPreviousForm/employmentDetailsFormWithPreviousForm.slice';
import { AddressSearchFormField } from '../Fieldset/AddressSearchFormField';
import { EircodeFormField } from '../Fieldset/EircodeFormField';
import { SearchableSelectFormField } from '../Fieldset/SearchableSelectFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import {
  EmploymentDetailsFormModel,
  EmploymentDetailsFormModelFields,
  EmploymentStatusEnum,
  OccupationEnum,
  TimeAtEmployerMonthsEnum,
  TimeAtEmployerYearsEnum,
} from './employmentDetailsForm.model';
import { employmentDetailsFormSchema } from './employmentDetailsForm.validator';

const createFormInputs = ((disableAllFormControls: boolean) => {
  return {
    employmentStatus: () => (
      <SelectFormField
        fieldName="employmentStatus"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.employmentStatus.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.employmentStatus.placeholder"
        optionList={createOptionsList(
          EmploymentStatusEnum,
          'employmentDetailsForm',
          'employmentStatus',
          'currentEmploymentDetailsForm'
        )}
      />
    ),
    occupation: () => (
      <SearchableSelectFormField
        fieldName="occupation"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.occupation.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.occupation.placeholder"
        optionList={createComboboxList(OccupationEnum, 'employmentDetailsForm', 'occupation')}
      />
    ),
    employer: () => (
      <TextInputFormField
        fieldName="employer"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.employer.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.employer.placeholder"
      />
    ),
    occupationNotes: () => (
      <TextInputFormField
        fieldName="occupationNotes"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.occupationNotes.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.occupationNotes.placeholder"
      />
    ),
    searchAddress: (isSearchAddressFetching: boolean, setSearchQuery: React.Dispatch<React.SetStateAction<string>>) => (
      <AddressSearchFormField
        isLoading={isSearchAddressFetching}
        searchCallback={(query) => setSearchQuery(query)}
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.searchAddress.placeholder"
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.searchAddress.label"
        fieldName="search"
        isDisabled={disableAllFormControls}
      />
    ),
    addressLine1: () => (
      <TextInputFormField
        fieldName="addressLine1"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.addressLine1.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.addressLine1.placeholder"
      />
    ),
    addressLine2: () => (
      <TextInputFormField
        fieldName="addressLine2"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.addressLine2.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.addressLine2.placeholder"
      />
    ),
    addressLine3: () => (
      <TextInputFormField
        fieldName="addressLine3"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.addressLine3.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.addressLine3.label"
      />
    ),
    eirCode: () => (
      <EircodeFormField
        fieldName="eirCode"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.eirCode.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.eirCode.label"
      />
    ),
    town: () => (
      <TextInputFormField
        fieldName="town"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.town.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.town.label"
      />
    ),
    county: () => (
      <SelectFormField
        fieldName="county"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.county.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.county.pla"
        optionList={createOptionsList(CountyEnum, 'employmentDetailsForm', 'county', 'currentEmploymentDetailsForm')}
      />
    ),
    timeAtEmployerYears: () => (
      <SelectFormField
        fieldName="timeAtEmployerYears"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.timeAtEmployerYears.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.timeAtEmployerYears.label"
        optionList={createOptionsList(
          TimeAtEmployerYearsEnum,
          'employmentDetailsForm',
          'timeAtEmployerYears',
          'currentEmploymentDetailsForm'
        )}
      />
    ),
    timeAtEmployerMonths: () => (
      <SelectFormField
        fieldName="timeAtEmployerMonths"
        isDisabled={disableAllFormControls}
        labelTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.timeAtEmployerMonths.label"
        placeholderTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.timeAtEmployerMonths.placeholder"
        optionList={createOptionsList(
          TimeAtEmployerMonthsEnum,
          'employmentDetailsForm',
          'timeAtEmployerMonths',
          'currentEmploymentDetailsForm'
        )}
      />
    ),
  };
}) satisfies (
  disableAllFormControls: boolean
) => Record<EmploymentDetailsFormModelFields, (...args: any[]) => ReactElement>;

interface EmploymentDetailsFormProps {
  initialValues: EmploymentDetailsFormModel;
  formName: keyof EmploymentDetailsFormWithPreviousFormState['forms'];
  shouldValidate: boolean;
  shouldReset: boolean;
  onReset: () => void;
}

export const EmploymentDetailsForm: FunctionComponent<EmploymentDetailsFormProps> = ({
  initialValues,
  formName,
  shouldValidate,
  shouldReset,
  onReset,
}) => {
  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const inputs = useMemo(() => createFormInputs(disableAllFormControls), [disableAllFormControls]);

  const [searchQuery, setSearchQuery] = useState('');
  const {
    data: addressData,
    isFetching: isSearchAddressFetching,
    isError: isSearchAddressError,
  } = useGetAddressQuery({ query: searchQuery }, { skip: searchQuery === '' });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      onReset={onReset}
      validationSchema={employmentDetailsFormSchema()}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      <>
        <PersistFormInState<EmploymentDetailsFormWithPreviousFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          updateForm={actions.employmentDetailsFormWithPreviousForm.updateForm}
          shouldReset={shouldReset}
        />

        <Form autoComplete={formAutocomplete}>
          <AutoFillAddress
            address={addressData}
            isSearchAddressError={isSearchAddressError}
            errorTranslationKey="employmentDetailsForm:currentEmploymentDetailsForm.searchAddress.error"
          />

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.employmentStatus()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.occupation()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.employer()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.occupationNotes()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.searchAddress(isSearchAddressFetching, setSearchQuery)}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.addressLine1()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.addressLine2()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.addressLine3()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.town()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.county()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.eirCode()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.timeAtEmployerYears()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.timeAtEmployerMonths()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
        </Form>
      </>
    </Formik>
  );
};
