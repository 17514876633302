import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { MockLoginStateEnum } from '../../../../resources/mocks/mockLogins';
import { apiSlice } from './api.api-slice';

const extractMessage = (input: string): MockLoginStateEnum => {
  const regex = /\[\d+\]\s*(\w+)/;
  const match = input.match(regex);
  return match ? (match[1] as unknown as MockLoginStateEnum) : MockLoginStateEnum.FAIL;
};

export const authenticationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<MockLoginStateEnum, { login: string; password: string }>({
      query: ({ login, password }) => ({
        url: '/login',
        method: 'POST',
        body: { login, password },
      }),
      extraOptions: {
        applyProductPath: false,
        secondaryPath: '',
      },
      transformResponse: (rawResponse: { message: string; status: MockLoginStateEnum }) => {
        return rawResponse.status;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        if (
          typeof error.data === 'object' &&
          error.data != null &&
          'message' in error.data &&
          typeof error.data.message === 'string'
        ) {
          return extractMessage(error.data.message);
        }

        return MockLoginStateEnum.SERVER_ERROR;
      },
    }),
  }),
});

export const { useLoginMutation } = authenticationSlice;
