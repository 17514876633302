import {
  Button,
  ButtonContainer,
  Card,
  Checkbox,
  ContentSection,
  Heading,
  Icon,
  Spinner,
  Table,
} from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { WizardPagesEnum } from '../../screens/NewQuotation/newQuotation.slice';
import { actions, useGetters } from '../../services/redux';
import { useSearchVehicleQuery } from '../../services/redux/features/api/search-vehicle.slice';
import { selectIsAdditionalServicePlanAdded } from '../AdditionalServicePlan/additionalServicePlan.slice';
import { quotesMapper } from './compareQuotes.mapper';
import { CompareQuoteTableDataModel } from './compareQuotes.model';

const CompareQuotes: FunctionComponent = () => {
  const { t } = useTranslation();
  const headers: { [key: string]: string } = {};
  const headingsKeys = ['financeDetailsHeading', 'vehicleDetailsHeading', 'servicePlanHeading'];
  const headersKeys = [
    'financeDetailsHeading',
    'invoicePrice',
    'financeProduct',
    'campaignName',
    'totalDepositPercentage',
    'totalDepositEuro',
    'amountFinanced',
    'apr',
    'term',
    'monthlyPayment',
    'vehicleDetailsHeading',
    'brand',
    'model',
    'variant',
    'newUsed',
    'servicePlanHeading',
    'servicePlanPrice',
    'servicePlan',
    'contractDuration',
    'paymentType',
    'paymentTerm',
  ];

  const [selectedQuoteId, setSelectedQuoteId] = useState<string>();
  const [mappedQuotes, setMappedQuotes] = useState<Array<CompareQuoteTableDataModel>>([]);

  const isAdditionalServicePlanAdded = useSelector(selectIsAdditionalServicePlanAdded);
  const vehicleType = useGetters().vehicleTypeSelectPage.vehicleTypeSelectForm.values.selectedType;
  const isDisplayedQuotesCountLimitReached = mappedQuotes.length <= 2;

  const { isLoading, data: usedCarData } = useSearchVehicleQuery();

  headersKeys.forEach((headerKey) => {
    headers[headerKey] = t(`compareQuotes:header.${headerKey}`);
  });

  const { quotes } = useGetters().financialProductPage;

  useEffect(() => {
    if (usedCarData) {
      setMappedQuotes(quotesMapper(quotes, isAdditionalServicePlanAdded, usedCarData, vehicleType));
    }
  }, [quotes, isAdditionalServicePlanAdded, vehicleType, usedCarData]);

  const editSelectedQuote = () => {
    if (selectedQuoteId) {
      actions.financialProductPage.loadQuoteForEditing({ id: selectedQuoteId });
      actions.newQuotation.wizardNavigateToPage(WizardPagesEnum.financialProduct);
    }
  };

  const copySelectedQuote = () => {
    if (selectedQuoteId) {
      actions.financialProductPage.copyQuote({ id: selectedQuoteId });
    }
  };

  const deleteSelectedQuote = () => {
    if (selectedQuoteId) {
      actions.financialProductPage.deleteQuote({ id: selectedQuoteId });
    }
  };

  const isAnyQuoteSelected = () => {
    return !!selectedQuoteId;
  };

  const valueWithIcon = (quoteIndex, headerKey) => {
    return mappedQuotes[quoteIndex][`${headerKey}Valid`] ? (
      <span className="u-text-success">
        {mappedQuotes[quoteIndex][headerKey]} <Icon name="semantic-success" />
      </span>
    ) : (
      <span className="u-text-alert">
        {mappedQuotes[quoteIndex][headerKey]} <Icon name="semantic-error" />
      </span>
    );
  };

  return (
    <Spinner busy={isLoading} section>
      <ContentSection pageWrapSize="small">
        <Heading level={2}>{t('compareQuotes:multiquote')}</Heading>
        <Card className="u-text-left" element="div">
          <Table>
            <React.Fragment key=".0">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th />
                  {mappedQuotes.map((quote) => (
                    <Table.Th key={`quote-${quote.quoteNumber}`}>
                      <Checkbox
                        name={`quote-${quote.quoteNumber}`}
                        checked={selectedQuoteId === quote.quoteNumber}
                        onChange={() => {
                          setSelectedQuoteId(quote.quoteNumber);
                        }}
                      >
                        {`${quote.quoteNumber}/${quote.quoteVersion}`}
                      </Checkbox>
                    </Table.Th>
                  ))}
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {Object.entries(headers).map(([headerKey, headerName]) => {
                  if (headingsKeys.includes(headerKey)) {
                    if (headerKey === 'servicePlanHeading') {
                      return (
                        <Table.Tr key={headerKey} selected>
                          <Table.Th>{headerName}</Table.Th>
                          {Array.from(mappedQuotes.keys()).map((quoteIndex) => (
                            <Table.Th key={`${headerKey}-${quoteIndex + 1}`}>
                              {`${mappedQuotes[quoteIndex].quoteNumber}/${mappedQuotes[quoteIndex].quoteVersion}`}
                            </Table.Th>
                          ))}
                        </Table.Tr>
                      );
                    }
                    return (
                      <Table.Tr key={headerKey} selected>
                        <Table.Th colSpan={mappedQuotes.length + 1}>{headerName}</Table.Th>
                      </Table.Tr>
                    );
                  }

                  return (
                    <Table.Tr key={headerKey}>
                      <Table.Th>{headerName}</Table.Th>
                      {Array.from(mappedQuotes.keys()).map((quoteIndex) => (
                        <Table.Td key={`${headerKey}-${quoteIndex + 1}`}>
                          {['monthlyPayment', 'servicePlanPrice'].includes(headerKey)
                            ? valueWithIcon(quoteIndex, headerKey)
                            : mappedQuotes[quoteIndex][headerKey]}
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  );
                })}
              </Table.Tbody>
            </React.Fragment>
          </Table>
          <ButtonContainer center>
            <Button secondary disabled={!isAnyQuoteSelected()} onClick={editSelectedQuote}>
              {t('compareQuotes:edit')}
            </Button>
            <Button
              secondary
              disabled={!isAnyQuoteSelected() || !isDisplayedQuotesCountLimitReached}
              onClick={copySelectedQuote}
            >
              {t('compareQuotes:copy')}
            </Button>
            <Button
              secondary
              disabled={!isAnyQuoteSelected() || mappedQuotes.length <= 1}
              onClick={deleteSelectedQuote}
            >
              {t('compareQuotes:delete')}
            </Button>
          </ButtonContainer>
        </Card>
        <br />
        <ButtonContainer center>
          <Button secondary disabled={!isAnyQuoteSelected()} onClick={editSelectedQuote}>
            {t('compareQuotes:back')}
          </Button>
          <Button disabled={!isAnyQuoteSelected()} onClick={() => actions.newQuotation.wizardNextPage()}>
            {t('compareQuotes:continue')}
          </Button>
        </ButtonContainer>
      </ContentSection>
    </Spinner>
  );
};

export default CompareQuotes;
