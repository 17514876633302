import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { NoYesEnum } from '../../types/forms';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { TradingDetailsSIBusinessFormModel } from './tradingDetailsSIBusinessForm.model';

export interface TradingDetailsSIBusinessFormState
  extends ReduxStateWithForms<{
    tradingDetailsSIBusiness: ReduxTrackedForm<TradingDetailsSIBusinessFormModel>;
  }> {}

const tradingDetailsInitialValues: TradingDetailsSIBusinessFormModel = {
  tradingAs: '',
  legalName: '',
  vatLiable: NoYesEnum.yes,
  vatNumber: '',
  companyRegistrationNumber: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  eirCode: '',
  town: '',
  county: '',
};

const initialState: TradingDetailsSIBusinessFormState = {
  forms: {
    tradingDetailsSIBusiness: {
      ...createReduxTrackedFormDefaults<TradingDetailsSIBusinessFormModel>({
        initialValues: tradingDetailsInitialValues,
      }),
      values: {
        ...tradingDetailsInitialValues,
      },
    },
  },
};

const name = 'tradingDetailsSIBusinessForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<TradingDetailsSIBusinessFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    tradingDetailsTriggers({ tradingDetailsSIBusinessForm }) {
      return tradingDetailsSIBusinessForm.forms.tradingDetailsSIBusiness.triggers;
    },
    tradingDetailsValues({ tradingDetailsSIBusinessForm }) {
      return tradingDetailsSIBusinessForm.forms.tradingDetailsSIBusiness.values;
    },
    forms({ tradingDetailsSIBusinessForm }) {
      return tradingDetailsSIBusinessForm.forms;
    },
  },
});

export const tradingDetailsSIBusinessFormSlice = slice;
export const tradingDetailsSIBusinessFormGettersDef = gettersDef;
