import { Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { FunctionComponent, ReactElement, memo, useState } from 'react';
import { ObjectSchema } from 'yup';
import { formAutocomplete } from '../../config';
import { createOptionsList } from '../../services/common/miscUtils';
import { getMaxBirthDate, getMinBirthDate } from '../../services/common/validation';
import { actions, getters, useGetters } from '../../services/redux';
import { AddressFormGeneric } from '../AddressFormGeneric/AddressFormGeneric';
import { getAddressFormValidationSchema } from '../AddressFormGeneric/addressFormGeneric.validator';
import { TitleEnum } from '../CustomerDetailsForm/customerDetailsForm.model';
import { DatePickerFormField } from '../Fieldset/DatePickerFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState, setFieldValueInitial } from '../PersistFormInState/PersistFormInState';
import {
  PersonalDetailsFormAddressModelFields,
  PersonalDetailsFormModel,
  PersonalDetailsFormModelFields,
} from './personalDetailsForm.model';
import { PersonalDetailsFormState } from './personalDetailsForm.slice';
import { createPersonalDetailsFormValidator } from './personalDetailsForm.validator';

const typeSafeFormFieldName = (formFieldName: keyof PersonalDetailsFormModel) => formFieldName;

const createFormInputs = (() => {
  return {
    title: () => (
      <SelectFormField
        fieldName={typeSafeFormFieldName('title')}
        labelTranslationKey="contactDetailsForm:fields:title:label"
        optionList={createOptionsList(TitleEnum, 'personalDetailsForm', 'title')}
      />
    ),
    firstName: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('firstName')}
        labelTranslationKey="contactDetailsForm:fields:firstName:label"
        placeholderTranslationKey="contactDetailsForm:fields:firstName:placeholder"
      />
    ),
    lastName: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('lastName')}
        labelTranslationKey="contactDetailsForm:fields:lastName:label"
        placeholderTranslationKey="contactDetailsForm:fields:lastName:placeholder"
      />
    ),
    dateOfBirth: () => (
      <DatePickerFormField
        fieldName={typeSafeFormFieldName('dateOfBirth')}
        labelTranslationKey="contactDetailsForm:fields:dateOfBirth:label"
        placeholderTranslationKey="contactDetailsForm:fields:dateOfBirth:placeholder"
        maxDate={getMaxBirthDate()}
        minDate={getMinBirthDate()}
      />
    ),
  };
}) satisfies () => Record<
  Exclude<PersonalDetailsFormModelFields, PersonalDetailsFormAddressModelFields>,
  (...args: any[]) => ReactElement
>;

const addressFormFieldsToUse: PersonalDetailsFormAddressModelFields[] = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'town',
  'county',
  'eirCode',
];

// eslint-disable-next-line react/display-name
function _PersonalDetailsForm<FormName extends keyof PersonalDetailsFormState['forms']>(props: {
  formName: FormName;
  initialValues: PersonalDetailsFormModel;
  currentValues: PersonalDetailsFormModel;
  shouldValidate: boolean;
  valuesToResetTo: PersonalDetailsFormModel;
  shouldReset: boolean;
}) {
  const [initialValues /* , setInitialValues */] = useState(() => props.initialValues);
  const [_setFieldValue, _setSetFieldValue] = useState(setFieldValueInitial<PersonalDetailsFormModel>);
  const { currentValues, valuesToResetTo, shouldValidate, shouldReset, formName } = props;

  const [inputs /* , updateInputs */] = useState<ReturnType<typeof createFormInputs>>(() => createFormInputs());
  const [validationSchema] = useState<ObjectSchema<PersonalDetailsFormModel>>(() =>
    createPersonalDetailsFormValidator().concat(getAddressFormValidationSchema(addressFormFieldsToUse))
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      <Form autoComplete={formAutocomplete}>
        <PersistFormInState<PersonalDetailsFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          shouldReset={shouldReset}
          updateForm={actions.personalDetailsForm.updateForm}
          setFormFieldSetter={_setSetFieldValue}
          valuesToResetTo={valuesToResetTo}
        />

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">
              {inputs.title()}
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
              {inputs.firstName()}
            </Layout.Item>
            <Layout.Item default="1/3" s="1/1">
              {inputs.lastName()}
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">
              {inputs.dateOfBirth()}
            </Layout.Item>
          </Layout>
        </Fieldset.Row>

        <AddressFormGeneric
          initialFormValue={currentValues}
          formTranslationKey="personalDetailsForm"
          fieldsToRender={addressFormFieldsToUse}
        />
      </Form>
    </Formik>
  );
}

export type FormCount = 'first' | 'second';

// eslint-disable-next-line react/display-name
export const PersonalDetailsForm: FunctionComponent<{ formVariant: FormCount }> = memo(({ formVariant }) => {
  let formName;
  let initialValues;
  let currentValues;
  let shouldValidate;
  let shouldReset;
  let valuesToResetTo;

  switch (formVariant) {
    case 'first':
      formName = 'personalDetails1';
      initialValues = getters.personalDetailsForm.personalDetails1Values;
      valuesToResetTo = getters.personalDetailsForm.forms.personalDetails1.initialValues;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      currentValues = useGetters().personalDetailsForm.personalDetails1Values;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      shouldValidate = useGetters().personalDetailsForm.personalDetails1Triggers.validation;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      shouldReset = useGetters().personalDetailsForm.personalDetails1Triggers.reset;
      break;

    case 'second':
      formName = 'personalDetails2';
      initialValues = getters.personalDetailsForm.personalDetails2Values;
      valuesToResetTo = getters.personalDetailsForm.forms.personalDetails2.initialValues;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      currentValues = useGetters().personalDetailsForm.personalDetails2Values;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      shouldValidate = useGetters().personalDetailsForm.personalDetails2Triggers.validation;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      shouldReset = useGetters().personalDetailsForm.personalDetails2Triggers.reset;
      break;

    default:
      // eslint-disable-next-line no-console
      console.warn('Wrong PersonalDetailsForm variant.');
  }

  return _PersonalDetailsForm({ formName, initialValues, currentValues, shouldValidate, shouldReset, valuesToResetTo });
});
