import QueryString from 'qs';
import { useCallback } from 'react';
import { useNavigate as _useNavigate } from 'react-router-dom';
import { ROUTES, ROUTES_VALUES } from './routes';

const getPath = (newPath: string) => {
  let path = newPath;
  if (newPath.includes('?')) {
    const search = QueryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const searchNew = QueryString.parse(newPath.split('?')[1]);
    path = `${newPath.split('?')[0]}?${QueryString.stringify({
      ...search,
      ...searchNew,
    })}`;
  } else {
    path = `${newPath}${window.location.search}`;
    if (path.includes('situation')) {
      path = path.slice(0, path.lastIndexOf('&'));
    }
  }
  return path;
};

export function useNavigate() {
  const navigate = _useNavigate();

  const customNavigate = useCallback(
    (path: ROUTES_VALUES, params = '') => {
      navigate(getPath(path + params));
    },
    [navigate]
  );

  /* Navigate to any of predefined Paths */

  const navigateObject = Object.entries(ROUTES).reduce(
    (acc, [pathKey, pathValue]) => {
      return {
        ...acc,
        [pathKey]: (params = '') => customNavigate(pathValue, params),
      };
    },
    {} as Record<keyof typeof ROUTES, (params?: string) => void>
  );

  /* Navigate to arbitrary url */

  const navigateToUrl = useCallback(
    /**
     *
     * @param url Complete URL or it's fragment.
     */
    (url: string) => {
      return navigate(getPath(url));
    },
    [navigate]
  );

  Object.assign(navigateObject, { url: navigateToUrl });

  return navigateObject as typeof navigateObject & {
    /**
     * - Navigate by passing complete URL or it's fragment.
     * - It can be used to go to any path within the app, not only external address.
     * - By passing only query parameter, e.g. "?newParam=123", it will be appended to existing ones.
     */
    url: typeof navigateToUrl;
  };
}

export type NavigateType = ReturnType<typeof useNavigate>;
