import { Button, ButtonContainer, Fieldset, FormSection, FormSectionGroup, Layout } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BankDetailsForm } from '../../../../components/BankDetailsForm/BankDetailsForm';
import { ContactDetailsForm } from '../../../../components/ContactDetailsForm/ContactDetailsForm';
import { PersonalDetailsForm } from '../../../../components/PersonalDetailsForm/PersonalDetailsForm';
import { TradingDetailsSIBusinessForm } from '../../../../components/TradingDetailsSIBusinessForm/TradingDetailsSIBusinessForm';
import {
  FormUpdateActionTypeGeneric,
  checkIfSectionIsReadyAndValid,
  getFormSectionId,
  getFormSectionStatusProps,
  getFormsState,
  scrollIntoSection,
  setAllFoldToFalse,
} from '../../../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../../../services/redux';
import { FlowTypeEnum } from '../../../../types/forms';
import useSaveSession from '../../../../utils/useSaveSession/useSaveSession';
import { getSectionTitleForCustomerCurried } from '../../../utils';

enum AvailableSections {
  partnershipDetails,
  contactDetails,
  partnershipBankAccountDetails,
  partner1Details,
  partner2Details,
}

const NavigationControls = ({ onNextNavigate }) => {
  return (
    <>
      <Layout right>
        <Layout.Item default="1/6">
          <ButtonContainer right>
            <Button onClick={onNextNavigate}>{t('customerDataPage:nextFormButton')}</Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </>
  );
};

const getSectionTitleForCustomerLocal = getSectionTitleForCustomerCurried(FlowTypeEnum.partnership);

export const customerDataSIBusinessPartnershipUpdateForm = {
  mainApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.tradingDetailsSIBusinessForm.updateForm({ formName: 'tradingDetailsSIBusiness', ...payload });
    actions.contactDetailsForm.updateForm({ formName: 'contactDetailsForm', ...payload });
    actions.bankDetailsForm.updateForm({ formName: 'bankDetails', ...payload });
  },
  firstPerson: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.personalDetailsForm.updateForm({ formName: 'personalDetails1', ...payload });
  },
  secondPerson: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.personalDetailsForm.updateForm({ formName: 'personalDetails2', ...payload });
  },
};

export const CustomerDataSIBusinessPartnership = () => {
  const formSectionDesignator = 'customerDataSIBusinessPartnershipFormSection';
  const { focusOnError } = useGetters().customerDataPage;

  const [currentSection, setCurrentSection] = useState(AvailableSections.partnershipDetails);
  const [pendingSection, setPendingSection] = useState<AvailableSections | null>(
    () => AvailableSections.partnershipDetails
  );

  const { isFirstPersonAdded, isSecondPersonAdded } = useGetters().customerDataSIBusinessPartnership;

  const { handleSaveSession } = useSaveSession();

  const [areAllFormsSettled, setAreAllFormsSettled] = useState(false);

  const setActiveSection = (section: AvailableSections) => {
    setCurrentSection(section);
  };

  const formsState = getFormsState({
    ...useGetters().tradingDetailsSIBusinessForm.forms,
    ...useGetters().contactDetailsForm.forms,
    ...useGetters().bankDetailsForm.forms,
    ...useGetters().personalDetailsForm.forms,
  });

  useEffect(function doCleanupOnLeave() {
    return function resetCoApplicantsAdditionStatus() {
      actions.customerDataSIBusinessPartnership.resetAddedCoApplicants();
    };
  }, []);

  useEffect(
    function toggleFirstPersonFormExclusion() {
      customerDataSIBusinessPartnershipUpdateForm.firstPerson({ excluded: !isFirstPersonAdded });
    },
    [isFirstPersonAdded]
  );

  useEffect(
    function toggleSecondPersonFormExclusion() {
      customerDataSIBusinessPartnershipUpdateForm.secondPerson({ excluded: !isSecondPersonAdded });
    },
    [isSecondPersonAdded]
  );

  useEffect(
    function autoOpenNextSectionOnFirstPersonAddition() {
      if (isFirstPersonAdded) {
        setActiveSection(AvailableSections.partner1Details);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFirstPersonAdded]
  );

  useEffect(
    function autoOpenNextSectionOnSecondPersonAddition() {
      if (isSecondPersonAdded) {
        setActiveSection(AvailableSections.partner2Details);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSecondPersonAdded]
  );

  // When in Standalone Service Plan user selected Upfront payment for service plan,
  // then we do not need Bank Details
  const isUpfrontPayment =
    getters.standaloneServicePlanForm.standaloneServicePlanValues.paymentType?.toLowerCase() === 'upfront';

  useEffect(() => {
    if (isUpfrontPayment) {
      actions.bankDetailsForm.updateForm({ formName: 'bankDetails', excluded: true });
    }
  }, [isUpfrontPayment]);

  const areAllFormsValid = Object.entries(formsState).every(([, { isValid, excluded }]) => isValid || excluded);

  useEffect(() => {
    setAreAllFormsSettled(
      Object.entries(formsState).every(([, { isValidating, excluded }]) => !isValidating || excluded)
    );
  }, [formsState]);

  const [foldStatus, setFoldStatus] = useState<Record<number, boolean>>({
    [AvailableSections.partnershipDetails]: false,
    [AvailableSections.contactDetails]: false,
    [AvailableSections.partnershipBankAccountDetails]: false,
    [AvailableSections.partner1Details]: false,
    [AvailableSections.partner2Details]: false,
  });

  const formSectionMap = useMemo(() => {
    return {
      [AvailableSections.partnershipDetails]: formsState.tradingDetailsSIBusiness,
      [AvailableSections.contactDetails]: formsState.contactDetailsForm,
      [AvailableSections.partnershipBankAccountDetails]: formsState.bankDetails,
      [AvailableSections.partner1Details]: formsState.personalDetails1,
      [AvailableSections.partner2Details]: formsState.personalDetails2,
    };
  }, [formsState]);

  useEffect(() => {
    actions.customerDataSIBusinessPartnership.setAllFormsValid({ allFormsValid: areAllFormsValid });
  }, [areAllFormsValid, formsState]);

  const { currentSectionIsReady, currentSectionIsValid } = checkIfSectionIsReadyAndValid(
    AvailableSections,
    currentSection,
    formSectionMap
  );

  const findFirstSectionToOpen = useCallback((): AvailableSections => {
    return Object.entries(formSectionMap).find(([section]) => {
      const form = formSectionMap[section];
      const props = getFormSectionStatusProps(form);
      return props.error;
    })?.[0] as unknown as AvailableSections;
  }, [formSectionMap]);

  useEffect(() => {
    if (pendingSection === null || !currentSectionIsReady) return;
    if (currentSectionIsValid) setCurrentSection(pendingSection);
    setPendingSection(null);
  }, [currentSection, pendingSection, currentSectionIsReady, currentSectionIsValid]);

  useEffect(
    function focusOnFirstErrorSection() {
      if (focusOnError && areAllFormsSettled) {
        const firstSectionToOpen = findFirstSectionToOpen();
        if (firstSectionToOpen) {
          setCurrentSection(firstSectionToOpen);
          actions.customerDataPage.updateFocusOnError({ state: false });
        }
      }
    },
    [focusOnError, findFirstSectionToOpen, areAllFormsSettled]
  );

  const closeAllSections = () => {
    const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

    setFoldStatus(resetFoldStatus);
  };

  useEffect(
    function openNextActiveSection() {
      const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

      scrollIntoSection(getFormSectionId<AvailableSections>(formSectionDesignator, currentSection));

      const next = { ...resetFoldStatus, [currentSection]: true };

      setFoldStatus(next);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSection]
  );

  const changeFoldStatus = (section: AvailableSections, isOpened: boolean) => {
    const next = { ...foldStatus, [section]: isOpened };

    setFoldStatus(next);
  };

  return (
    <>
      <FormSectionGroup>
        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partnershipDetails)}
          defaultOpen={foldStatus[AvailableSections.partnershipDetails]}
          title={getSectionTitleForCustomerLocal('tradingDetailsSIBusinessForm')}
          onClickCapture={() => setActiveSection(AvailableSections.partnershipDetails)}
          {...getFormSectionStatusProps(formsState.tradingDetailsSIBusiness)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.partnershipDetails, isOpened);
          }}
        >
          <Fieldset.Row>
            <TradingDetailsSIBusinessForm />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.tradingDetailsSIBusinessForm.updateForm({
                  triggerValidation: true,
                  formName: 'tradingDetailsSIBusiness',
                });
                setPendingSection(AvailableSections.contactDetails);
              }}
            />
          </Fieldset.Row>
        </FormSection>

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.contactDetails)}
          defaultOpen={foldStatus[AvailableSections.contactDetails]}
          title={getSectionTitleForCustomerLocal('contactDetailsForm')}
          onClickCapture={() => setActiveSection(AvailableSections.contactDetails)}
          {...getFormSectionStatusProps(formsState.contactDetailsForm)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.contactDetails, isOpened);
          }}
        >
          <Fieldset.Row>
            <ContactDetailsForm />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.contactDetailsForm.updateForm({
                  triggerValidation: true,
                  formName: 'contactDetailsForm',
                });

                if (!isUpfrontPayment) {
                  setPendingSection(AvailableSections.partnershipBankAccountDetails);
                } else if (isFirstPersonAdded) {
                  setPendingSection(AvailableSections.partner1Details);
                } else {
                  closeAllSections();
                }
              }}
            />
          </Fieldset.Row>
        </FormSection>

        {!isUpfrontPayment && (
          <FormSection
            id={getFormSectionId<AvailableSections>(
              formSectionDesignator,
              AvailableSections.partnershipBankAccountDetails
            )}
            defaultOpen={foldStatus[AvailableSections.partnershipBankAccountDetails]}
            title={getSectionTitleForCustomerLocal('bankDetailsForm')}
            onClickCapture={() => setActiveSection(AvailableSections.partnershipBankAccountDetails)}
            {...getFormSectionStatusProps(formsState.bankDetails)}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.partnershipBankAccountDetails, isOpened);
            }}
          >
            <Fieldset.Row>
              <BankDetailsForm />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.bankDetailsForm.updateForm({ triggerValidation: true, formName: 'bankDetails' });

                  if (isFirstPersonAdded) {
                    setPendingSection(AvailableSections.partner1Details);
                  } else {
                    closeAllSections();
                  }
                }}
              />
            </Fieldset.Row>
          </FormSection>
        )}

        <Layout className="u-mb">
          <Layout.Item>
            <Button
              icon={isFirstPersonAdded ? 'semantic-minus' : 'semantic-plus'}
              link
              secondary
              onClick={() => actions.customerDataSIBusinessPartnership.setIsFirstPersonAdded(!isFirstPersonAdded)}
            >
              {isFirstPersonAdded
                ? t('customerDataSIBusinessPartnership:removePartner1')
                : t('customerDataSIBusinessPartnership:addPartner1')}
            </Button>
          </Layout.Item>
        </Layout>

        {isFirstPersonAdded && (
          <>
            <FormSection
              id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partner1Details)}
              defaultOpen={foldStatus[AvailableSections.partner1Details]}
              title={getSectionTitleForCustomerLocal('personalDetailsForm', true)}
              onClickCapture={() => setActiveSection(AvailableSections.partner1Details)}
              {...getFormSectionStatusProps(formsState.personalDetails1)}
              onChange={(isOpened: boolean) => {
                changeFoldStatus(AvailableSections.partner1Details, isOpened);
              }}
            >
              <Fieldset.Row>
                <PersonalDetailsForm formVariant="first" />
              </Fieldset.Row>
              <Fieldset.Row>
                <NavigationControls
                  onNextNavigate={() => {
                    handleSaveSession();
                    actions.personalDetailsForm.updateForm({ triggerValidation: true, formName: 'personalDetails1' });

                    if (isSecondPersonAdded) {
                      setPendingSection(AvailableSections.partner2Details);
                    } else {
                      closeAllSections();
                    }
                  }}
                />
              </Fieldset.Row>
            </FormSection>
          </>
        )}

        <Layout className="u-mb">
          <Layout.Item>
            <Button
              icon={isSecondPersonAdded ? 'semantic-minus' : 'semantic-plus'}
              link
              secondary
              onClick={() => actions.customerDataSIBusinessPartnership.setIsSecondPersonAdded(!isSecondPersonAdded)}
            >
              {isSecondPersonAdded
                ? t('customerDataSIBusinessPartnership:removePartner2')
                : t('customerDataSIBusinessPartnership:addPartner2')}
            </Button>
          </Layout.Item>
        </Layout>

        {isSecondPersonAdded && (
          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partner2Details)}
            defaultOpen={foldStatus[AvailableSections.partner2Details]}
            title={getSectionTitleForCustomerLocal('personalDetailsForm', false)}
            onClickCapture={() => setActiveSection(AvailableSections.partner2Details)}
            {...getFormSectionStatusProps(formsState.personalDetails2)}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.partner2Details, isOpened);
            }}
          >
            <Fieldset.Row>
              <PersonalDetailsForm formVariant="second" />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.personalDetailsForm.updateForm({ triggerValidation: true, formName: 'personalDetails2' });

                  closeAllSections();
                  setPendingSection(null);
                }}
              />
            </Fieldset.Row>
          </FormSection>
        )}
      </FormSectionGroup>
    </>
  );
};
