import { Button, ButtonContainer, Card, ContentSection, Heading, Layout } from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { actions, useGetters } from '../../../services/redux';
import { useNavigate } from '../../../services/routing';
import { ProductFamilyEnum } from '../../ProductFamilySelectionPage/productFamilySelectionPageModel';
import { WizardPagesEnum } from '../newQuotation.slice';
import { CustomerTypeSelectionForm } from './CustomerTypeSelectionForm';

export const CustomerTypeSelectionPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isValid } = useGetters().customerTypeSelectionPage.customerTypeSelectionForm;
  const { productFamily } = useGetters().productFamilySelectionPage;

  return (
    <>
      <ContentSection pageWrapSize="small">
        <Heading level={1}>{t('customerTypeSelectionPage:formHeading')}</Heading>
      </ContentSection>

      <ContentSection pageWrapSize="small">
        <Layout center>
          <Card element="div">
            <CustomerTypeSelectionForm />
          </Card>
        </Layout>
      </ContentSection>

      <ContentSection pageWrapSize="small">
        <Layout center className="u-mt-xsmall">
          <Layout.Item>
            <ButtonContainer center>
              <Button
                testId="button_goBack"
                secondary
                onClick={() => {
                  if (productFamily === ProductFamilyEnum.servicePlan) {
                    navigate.newQuote(`/${WizardPagesEnum.standaloneServicePlan}`);
                  } else {
                    navigate.newQuote(`/${WizardPagesEnum.compareQuotes}`);
                  }
                }}
              >
                {t('customerTypeSelectionPage:navigation.back')}
              </Button>

              <Button
                testId="button_continue"
                disabled={!isValid}
                onClick={() => {
                  actions.newQuotation.wizardNavigateToPage(WizardPagesEnum.customerData);
                }}
              >
                {t('customerTypeSelectionPage:navigation.continue')}
              </Button>
            </ButtonContainer>
          </Layout.Item>
        </Layout>
      </ContentSection>
    </>
  );
};
