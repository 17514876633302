import { Fieldset, Heading, Layout } from '@vwfs-bronson/bronson-react';
import { Form, Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, memo, useMemo, useState } from 'react';
import { formAutocomplete } from '../../config';
import { createOptionsList } from '../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../services/redux';
import { NoYesEnum } from '../../types/forms';
import { RadioGroupFormControl } from '../Fieldset/RadioGroupFormControl';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import { LinkedRelationshipFormModel, LinkedRelationshipFormModelFields } from './linkedRelationshipsForm.model';
import { LinkedRelationshipsFormState } from './linkedRelationshipsForm.slice';
import { linkedRelationshipsFormSchema } from './linkedRelationshipsForm.validator';

const typeSafeFormFieldName = (formFieldName: keyof LinkedRelationshipFormModel) => formFieldName;

const createFormInputs = ((disableAllFormControls: boolean) => {
  return {
    majorityInCompanies: () => (
      <>
        <Heading level={6} className="u-mb-small">
          {t('linkedRelationshipsForm:linkedRelationshipsForm.majorityInCompanies.label')}
        </Heading>

        <RadioGroupFormControl
          fieldName={typeSafeFormFieldName('majorityInCompanies')}
          isDisabled={disableAllFormControls}
          optionList={createOptionsList(NoYesEnum, 'linkedRelationshipsForm', 'noYes', null)}
        />
      </>
    ),
    majorityInCompaniesCompanyName: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('majorityInCompaniesCompanyName')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="linkedRelationshipsForm:linkedRelationshipsForm.majorityInCompaniesCompanyName.label"
        placeholderTranslationKey="linkedRelationshipsForm:linkedRelationshipsForm.majorityInCompaniesCompanyName.placeholder"
      />
    ),
    partnerOfCompanies: () => (
      <>
        <Heading level={6} className="u-mb-small">
          {t('linkedRelationshipsForm:linkedRelationshipsForm.partnerOfCompanies.label')}
        </Heading>

        <RadioGroupFormControl
          fieldName={typeSafeFormFieldName('partnerOfCompanies')}
          isDisabled={disableAllFormControls}
          optionList={createOptionsList(NoYesEnum, 'linkedRelationshipsForm', 'noYes', null)}
        />
      </>
    ),
    partnerOfCompaniesCompanyName: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('partnerOfCompaniesCompanyName')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="linkedRelationshipsForm:linkedRelationshipsForm.partnerOfCompaniesCompanyName.label"
        placeholderTranslationKey="linkedRelationshipsForm:linkedRelationshipsForm.partnerOfCompaniesCompanyName.placeholder"
      />
    ),
    customerOfVwfs: () => (
      <>
        <Heading level={6} className="u-mb-small">
          {t('linkedRelationshipsForm:linkedRelationshipsForm.customerOfVwfs.label')}
        </Heading>

        <RadioGroupFormControl
          fieldName={typeSafeFormFieldName('customerOfVwfs')}
          isDisabled={disableAllFormControls}
          optionList={createOptionsList(NoYesEnum, 'linkedRelationshipsForm', 'noYes', null)}
        />
      </>
    ),
    contactNumberOrVehicleRegistrationNumber: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('contactNumberOrVehicleRegistrationNumber')}
        isDisabled={disableAllFormControls}
        labelTranslationKey="linkedRelationshipsForm:linkedRelationshipsForm.contactNumberOrVehicleRegistrationNumber.label"
        placeholderTranslationKey="linkedRelationshipsForm:linkedRelationshipsForm.contactNumberOrVehicleRegistrationNumber.placeholder"
      />
    ),
  };
}) satisfies (
  disableAllFormControls: boolean
) => Record<LinkedRelationshipFormModelFields, (...args: any[]) => ReactElement>;

function _LinkedRelationshipsForm<FormName extends keyof LinkedRelationshipsFormState['forms']>(props: {
  formName: FormName;
  initialValues: LinkedRelationshipFormModel;
  currentValues: LinkedRelationshipFormModel;
  shouldValidate: boolean;
  valuesToResetTo: LinkedRelationshipFormModel;
  shouldReset: boolean;
}) {
  const [initialValues /* , setInitialValues */] = useState(() => props.initialValues);
  const { currentValues, shouldValidate, formName, shouldReset, valuesToResetTo } = props;

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const inputs = useMemo(() => createFormInputs(disableAllFormControls), [disableAllFormControls]);

  return (
    <Formik
      validationSchema={linkedRelationshipsFormSchema()}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      <>
        <PersistFormInState<LinkedRelationshipsFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          shouldReset={shouldReset}
          updateForm={actions.linkedRelationshipsForm.updateForm}
          valuesToResetTo={valuesToResetTo}
        />

        <Form autoComplete={formAutocomplete}>
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/1">{inputs.majorityInCompanies()}</Layout.Item>
            </Layout>
          </Fieldset.Row>

          {currentValues.majorityInCompanies === 'yes' && (
            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/1">{inputs.majorityInCompaniesCompanyName()}</Layout.Item>
              </Layout>
            </Fieldset.Row>
          )}

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/1">{inputs.partnerOfCompanies()}</Layout.Item>
            </Layout>
          </Fieldset.Row>

          {currentValues.partnerOfCompanies === 'yes' && (
            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/1">{inputs.partnerOfCompaniesCompanyName()}</Layout.Item>
              </Layout>
            </Fieldset.Row>
          )}

          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/1">{inputs.customerOfVwfs()}</Layout.Item>
            </Layout>
          </Fieldset.Row>

          {currentValues.customerOfVwfs === 'yes' && (
            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/1">{inputs.contactNumberOrVehicleRegistrationNumber()}</Layout.Item>
              </Layout>
            </Fieldset.Row>
          )}
        </Form>
      </>
    </Formik>
  );
}

// eslint-disable-next-line react/display-name
export const LinkedRelationshipsForm: FunctionComponent<{ isMainApplicant: boolean }> = memo(({ isMainApplicant }) => {
  let formName;
  let initialValues;
  let currentValues;
  let shouldValidate;
  let shouldReset;
  let valuesToResetTo;

  if (isMainApplicant) {
    formName = 'linkedRelationshipsForm1';
    // eslint-disable-next-line react-hooks/rules-of-hooks
    initialValues = getters.linkedRelationshipsForm.linkedRelationshipsForm1Values;
    valuesToResetTo = getters.customerDetailsForm.forms.customerDetails1.initialValues;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    currentValues = useGetters().linkedRelationshipsForm.linkedRelationshipsForm1Values;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldValidate = useGetters().linkedRelationshipsForm.linkedRelationshipsForm1Triggers.validation;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldReset = useGetters().linkedRelationshipsForm.linkedRelationshipsForm1Triggers.reset;
  } else {
    formName = 'linkedRelationshipsForm2';
    initialValues = getters.linkedRelationshipsForm.linkedRelationshipsForm2Values;
    valuesToResetTo = getters.customerDetailsForm.forms.customerDetails2.initialValues;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    currentValues = useGetters().linkedRelationshipsForm.linkedRelationshipsForm2Values;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldValidate = useGetters().linkedRelationshipsForm.linkedRelationshipsForm2Triggers.validation;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldReset = useGetters().linkedRelationshipsForm.linkedRelationshipsForm2Triggers.reset;
  }

  return _LinkedRelationshipsForm({
    formName,
    initialValues,
    currentValues,
    shouldValidate,
    shouldReset,
    valuesToResetTo,
  });
});
