export enum FrequencyOfNetIncomeEnum {
  weekly = 'Weekly',
  monthly = 'Monthly',
  biMonthly = 'Bi-Monthly',
  annually = 'Annually',
}

export interface AdditionalIncome {
  otherIncomeSource: string;
  otherFrequencyOfNetIncome: FrequencyOfNetIncomeEnum;
  otherNetAnnualIncome: number;
}

export interface FinancialDetailsFormModel {
  frequencyOfNetIncome?: FrequencyOfNetIncomeEnum;
  netMonthlyIncome?: number;
  additionalIncome: Array<AdditionalIncome>;
  financialObligations?: string;
}

export type FinancialDetailsFormModelFields =
  | keyof Omit<FinancialDetailsFormModel, 'additionalIncome'>
  | keyof AdditionalIncome;
