export const mockKeyValuesLookup = (key: string | undefined): string => {
  if (!key) {
    return '';
  }

  const map = {
    MONTHS: 'months',
    KILOMETERS: 'km',
    MANUAL: 'Manual',
  };

  return map[key] || '';
};
