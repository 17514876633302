import React, { FunctionComponent, useState } from 'react';
import { t } from 'i18next';
import MaskedInput from 'react-text-mask';
import { FieldInputProps } from 'formik';
import { TextInputFormFieldProps } from './TextInputFormField';

interface Props extends TextInputFormFieldProps {
  mask: NonNullable<TextInputFormFieldProps['mask']>;
  fieldProps: FieldInputProps<any>;
}

/**
 * - Helper wrapper over MaskedInput that makes it easier to
 * preserve in form state unmasked values instead of masked ones.
 */
export const StatefulMaskedInput: FunctionComponent<Props> = ({
  placeholderTranslationKey = '',
  isDisabled,
  isReadonly,
  mask,
  maskedValueToFormValue,
  fieldProps,
}): React.JSX.Element => {
  const [initialValue, setInitialValue] = useState(() => fieldProps.value);
  const [maskedValue, setMaskedValue] = useState(() => fieldProps.value);

  return (
    <MaskedInput
      className="c-input__input"
      guide={false}
      keepCharPositions
      mask={mask}
      disabled={isDisabled}
      aria-disabled={isDisabled}
      placeholder={t(placeholderTranslationKey)}
      readOnly={isReadonly}
      name={fieldProps.name}
      onBlur={fieldProps.onBlur}
      onChange={(e: React.ChangeEvent<any>) => {
        setInitialValue(undefined);

        if (e.target.value === maskedValue) return;

        setMaskedValue(e.target.value);

        const newValue = maskedValueToFormValue ? maskedValueToFormValue(e.target.value) : e.target.value;

        if (e.target?.['value']) {
          e.target['value'] = newValue;
        }

        fieldProps.onChange(e);
      }}
      value={initialValue}
    />
  );
};
