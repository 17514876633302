import { Button, ButtonContainer, Fieldset, FormSection, FormSectionGroup, Layout } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import AddressWithPreviousForm from '../../../../components/AddressWithPreviousForm/AddressWithPreviousForm';
import { BankDetailsForm } from '../../../../components/BankDetailsForm/BankDetailsForm';
import { CustomerDetailsForm } from '../../../../components/CustomerDetailsForm/CustomerDetailsForm';
import {
  FormUpdateActionTypeGeneric,
  checkIfSectionIsReadyAndValid,
  getFormSectionStatusProps,
  getFormsState,
} from '../../../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../../../services/redux';
import { FlowTypeEnum } from '../../../../types/forms';
import useSaveSession from '../../../../utils/useSaveSession/useSaveSession';
import { getSectionTitleForCustomerCurried } from '../../../utils';

enum AvailableSections {
  customerDetails,
  customerAddress,
  bankDetails,
}

const NavigationControls = ({ onNextNavigate }) => {
  return (
    <>
      <Layout right>
        <Layout.Item default="1/6">
          <ButtonContainer right>
            <Button onClick={onNextNavigate}>{t('customerDataPage:nextFormButton')}</Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </>
  );
};

export const customerDataServicePlanUpdateForm = {
  mainApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.customerDetailsForm.updateForm({ formName: 'customerDetails1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'currentAddress1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'previousAddress1', ...payload });
    actions.bankDetailsForm.updateForm({ formName: 'bankDetails', ...payload });
  },
};

const getSectionTitleForCustomerLocal = getSectionTitleForCustomerCurried(FlowTypeEnum.servicePlan);

const CustomerDataServicePlan = () => {
  const [currentSection, setCurrentSection] = useState(AvailableSections.customerDetails);
  const [pendingSection, setPendingSection] = useState<AvailableSections | null>(
    () => AvailableSections.customerDetails
  );

  const { handleSaveSession } = useSaveSession();

  const isSectionOpen = useCallback((section: AvailableSections) => section === currentSection, [currentSection]);

  const formsState = getFormsState({
    ...useGetters().customerDetailsForm.forms,
    ...useGetters().addressWithPreviousForm.forms,
    ...useGetters().bankDetailsForm.forms,
  });

  const areAllFormsValid = Object.entries(formsState).every(([, { isValid, excluded }]) => isValid || excluded);

  // When in Standalone Service Plan user selected Upfront payment for service plan,
  // then we do not need Bank Details PC-2305
  const isUpfrontPayment =
    getters.standaloneServicePlanForm.standaloneServicePlanValues.paymentType?.toLowerCase() === 'upfront';

  useEffect(() => {
    if (isUpfrontPayment) {
      actions.bankDetailsForm.updateForm({ formName: 'bankDetails', excluded: true });
    }
  }, [isUpfrontPayment]);

  useEffect(() => {
    actions.customerDataServicePlan.setAllFormsValid({ allFormsValid: areAllFormsValid });
  }, [areAllFormsValid, formsState]);

  const { currentSectionIsReady, currentSectionIsValid } = checkIfSectionIsReadyAndValid(
    AvailableSections,
    currentSection,
    {
      [AvailableSections.customerDetails]: formsState.customerDetails1,
      [AvailableSections.customerAddress]: [formsState.currentAddress1, formsState.previousAddress1],
      [AvailableSections.bankDetails]: formsState.bankDetails,
    }
  );

  useEffect(() => {
    if (pendingSection === null || !currentSectionIsReady) return;
    if (currentSectionIsValid) setCurrentSection(pendingSection);
    setPendingSection(null);
  }, [currentSection, pendingSection, currentSectionIsReady, currentSectionIsValid]);

  return (
    <FormSectionGroup>
      <FormSection
        defaultOpen={isSectionOpen(AvailableSections.customerDetails)}
        title={getSectionTitleForCustomerLocal('customerDetailsForm')}
        onClickCapture={() => setCurrentSection(AvailableSections.customerDetails)}
        {...getFormSectionStatusProps(formsState.customerDetails1)}
      >
        <Fieldset.Row>
          <CustomerDetailsForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.customerDetailsForm.updateForm({ triggerValidation: true, formName: 'customerDetails1' });
              setPendingSection(AvailableSections.customerAddress);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        defaultOpen={isSectionOpen(AvailableSections.customerAddress)}
        title={getSectionTitleForCustomerLocal('addressForm')}
        onClickCapture={() => setCurrentSection(AvailableSections.customerAddress)}
        {...getFormSectionStatusProps([formsState.currentAddress1, formsState.previousAddress1])}
      >
        <Fieldset.Row>
          <AddressWithPreviousForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'currentAddress1' });
              actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'previousAddress1' });

              if (!isUpfrontPayment) {
                setPendingSection(AvailableSections.bankDetails);
              }
            }}
          />
        </Fieldset.Row>
      </FormSection>

      {!isUpfrontPayment && (
        <FormSection
          defaultOpen={isSectionOpen(AvailableSections.bankDetails)}
          title={getSectionTitleForCustomerLocal('bankDetailsForm')}
          onClickCapture={() => setCurrentSection(AvailableSections.bankDetails)}
          {...getFormSectionStatusProps(formsState.bankDetails)}
        >
          <Fieldset.Row>
            <BankDetailsForm />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.bankDetailsForm.updateForm({
                  triggerValidation: true,
                  formName: 'bankDetails',
                });
              }}
            />
          </Fieldset.Row>
        </FormSection>
      )}
    </FormSectionGroup>
  );
};

export default CustomerDataServicePlan;
