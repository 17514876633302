import {
  Button,
  ButtonContainer,
  Fieldset,
  FormHeading,
  FormSection,
  FormSectionGroup,
  Layout,
} from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddressWithPreviousForm from '../../../../components/AddressWithPreviousForm/AddressWithPreviousForm';
import { BankDetailsForm } from '../../../../components/BankDetailsForm/BankDetailsForm';
import { CustomerDetailsForm } from '../../../../components/CustomerDetailsForm/CustomerDetailsForm';
import { EmploymentDetailsFormWithPreviousForm } from '../../../../components/EmploymentDetailsFormWithPreviousForm/EmploymentDetailsFormWithPreviousForm';
import { FinancialDetailsForm } from '../../../../components/FinancialDetailsForm/FinancialDetailsForm';
import { LinkedRelationshipsForm } from '../../../../components/LinkedRelationshipsForm/LinkedRelationshipsForm';
import {
  FormUpdateActionTypeGeneric,
  checkIfSectionIsReadyAndValid,
  getFormSectionId,
  getFormSectionStatusProps,
  getFormsState,
  scrollIntoSection,
  setAllFoldToFalse,
} from '../../../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../../../services/redux';
import { FlowTypeEnum } from '../../../../types/forms';
import useSaveSession from '../../../../utils/useSaveSession/useSaveSession';
import { getSectionTitleForCustomerCurried } from '../../../utils';

enum AvailableSections {
  customerDetails1,
  customerDetails2,
  customerAddress1,
  customerAddress2,
  employmentDetails1,
  employmentDetails2,
  financialDetails1,
  financialDetails2,
  linkedRelationship1,
  linkedRelationship2,
  bankDetails,
}

const NavigationControls = ({ onNextNavigate, isDisabled }: { onNextNavigate: any; isDisabled: boolean }) => {
  return (
    <>
      <Layout right>
        <Layout.Item default="1/6">
          <ButtonContainer right>
            <Button disabled={isDisabled} onClick={onNextNavigate}>
              {t('customerDataPage:nextFormButton')}
            </Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </>
  );
};

const getSectionTitleForCustomerLocal = getSectionTitleForCustomerCurried(FlowTypeEnum.consumer);

export const customerDataPrivateUpdateForm = {
  mainApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.customerDetailsForm.updateForm({ formName: 'customerDetails1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'currentAddress1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'previousAddress1', ...payload });
    actions.employmentDetailsFormWithPreviousForm.updateForm({
      formName: 'currentEmploymentDetails1',
      ...payload,
    });
    actions.employmentDetailsFormWithPreviousForm.updateForm({
      formName: 'previousEmploymentDetails1',
      ...payload,
    });
    actions.financialDetailsForm.updateForm({ formName: 'financialDetails1', ...payload });
    actions.linkedRelationshipsForm.updateForm({ formName: 'linkedRelationshipsForm1', ...payload });
    actions.bankDetailsForm.updateForm({ formName: 'bankDetails', ...payload });
  },
  coApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.customerDetailsForm.updateForm({ formName: 'customerDetails2', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'currentAddress2', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'previousAddress2', ...payload });
    actions.employmentDetailsFormWithPreviousForm.updateForm({
      formName: 'currentEmploymentDetails2',
      ...payload,
    });
    actions.employmentDetailsFormWithPreviousForm.updateForm({
      formName: 'previousEmploymentDetails2',
      ...payload,
    });
    actions.financialDetailsForm.updateForm({ formName: 'financialDetails2', ...payload });
    actions.linkedRelationshipsForm.updateForm({ formName: 'linkedRelationshipsForm2', ...payload });
  },
};

const CustomerDataPrivate = () => {
  const formSectionDesignator = 'customerDataPrivateFormSection';
  const { focusOnError } = useGetters().customerDataPage;

  const [currentSection, setCurrentSection] = useState(AvailableSections.customerDetails1);
  const [pendingSection, setPendingSection] = useState<AvailableSections | null>(
    () => AvailableSections.customerDetails1
  );

  const { handleSaveSession } = useSaveSession();

  const [areAllFormsSettled, setAreAllFormsSettled] = useState(false);

  const setActiveSection = (section: AvailableSections) => {
    setCurrentSection(section);
  };

  const { isAnotherHirerAdded } = useGetters().customerDataPrivate;

  const formsState = getFormsState({
    ...useGetters().customerDetailsForm.forms,
    ...useGetters().addressWithPreviousForm.forms,
    ...useGetters().financialDetailsForm.forms,
    ...useGetters().employmentDetailsFormWithPreviousForm.forms,
    ...useGetters().linkedRelationshipsForm.forms,
    ...useGetters().bankDetailsForm.forms,
  });

  // When in Standalone Service Plan user selected Upfront payment for service plan,
  // then we do not need Bank Details
  const isUpfrontPayment =
    getters.standaloneServicePlanForm.standaloneServicePlanValues.paymentType?.toLowerCase() === 'upfront';

  useEffect(() => {
    if (isUpfrontPayment) {
      actions.bankDetailsForm.updateForm({ formName: 'bankDetails', excluded: true });
    }
  }, [isUpfrontPayment]);

  const areAllFormsValid = Object.entries(formsState).every(([, { isValid, excluded }]) => isValid || excluded);

  useEffect(() => {
    actions.customerDataPrivate.setAllFormsValid({ allFormsValid: areAllFormsValid });
  }, [areAllFormsValid, formsState]);

  useEffect(() => {
    setAreAllFormsSettled(
      Object.entries(formsState).every(([, { isValidating, excluded }]) => !isValidating || excluded)
    );
  }, [formsState]);

  const [foldStatus, setFoldStatus] = useState<Record<number, boolean>>({
    [AvailableSections.customerDetails1]: false,
    [AvailableSections.customerDetails2]: false,
    [AvailableSections.customerAddress1]: false,
    [AvailableSections.customerAddress2]: false,
    [AvailableSections.employmentDetails1]: false,
    [AvailableSections.employmentDetails2]: false,
    [AvailableSections.financialDetails1]: false,
    [AvailableSections.financialDetails2]: false,
    [AvailableSections.linkedRelationship1]: false,
    [AvailableSections.linkedRelationship2]: false,
    [AvailableSections.bankDetails]: false,
  });

  const formSectionMap = useMemo(() => {
    return {
      [AvailableSections.customerDetails1]: formsState.customerDetails1,
      [AvailableSections.customerDetails2]: formsState.customerDetails2,
      [AvailableSections.customerAddress1]: [formsState.currentAddress1, formsState.previousAddress1],
      [AvailableSections.customerAddress2]: [formsState.currentAddress2, formsState.previousAddress2],
      [AvailableSections.employmentDetails1]: [
        formsState.currentEmploymentDetails1,
        formsState.previousEmploymentDetails1,
      ],
      [AvailableSections.employmentDetails2]: [
        formsState.currentEmploymentDetails2,
        formsState.previousEmploymentDetails2,
      ],
      [AvailableSections.financialDetails1]: formsState.financialDetails1,
      [AvailableSections.financialDetails2]: formsState.financialDetails2,
      [AvailableSections.linkedRelationship1]: formsState.linkedRelationshipsForm1,
      [AvailableSections.linkedRelationship2]: formsState.linkedRelationshipsForm2,
      [AvailableSections.bankDetails]: formsState.bankDetails,
    };
  }, [formsState]);

  const { currentSectionIsReady, currentSectionIsValid } = checkIfSectionIsReadyAndValid(
    AvailableSections,
    currentSection,
    formSectionMap
  );

  const findFirstSectionToOpen = useCallback((): AvailableSections => {
    return Object.entries(formSectionMap).find(([section]) => {
      const form = formSectionMap[section];
      const props = getFormSectionStatusProps(form);
      return props.error;
    })?.[0] as unknown as AvailableSections;
  }, [formSectionMap]);

  const toggleAnotherHirer = () => {
    actions.customerDataPrivate.setIsAnotherHirerAdded({ isAnotherHirerAdded: !isAnotherHirerAdded });
  };

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  useEffect(() => {
    if (pendingSection === null || !currentSectionIsReady) return;
    if (currentSectionIsValid) setCurrentSection(pendingSection);
    setPendingSection(null);
  }, [currentSection, pendingSection, currentSectionIsReady, currentSectionIsValid]);

  useEffect(
    function focusOnFirstErrorSection() {
      if (focusOnError && areAllFormsSettled) {
        const firstSectionToOpen = findFirstSectionToOpen();
        if (firstSectionToOpen) {
          setCurrentSection(firstSectionToOpen);
          actions.customerDataPage.updateFocusOnError({ state: false });
        }
      }
    },
    [focusOnError, findFirstSectionToOpen, areAllFormsSettled]
  );

  const closeAllSections = () => {
    const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

    setFoldStatus(resetFoldStatus);
  };

  useEffect(
    function openNextActiveSection() {
      const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

      // When available Save And Continue Lead form must not be scrolled out of viewport
      if (!disableAllFormControls) {
        scrollIntoSection(getFormSectionId<AvailableSections>(formSectionDesignator, currentSection));
      }

      const next = { ...resetFoldStatus, [currentSection]: true };

      setFoldStatus(next);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSection]
  );

  const changeFoldStatus = (section: AvailableSections, isOpened: boolean) => {
    const next = { ...foldStatus, [section]: isOpened };

    setFoldStatus(next);
  };

  useEffect(() => {
    customerDataPrivateUpdateForm.coApplicant({ excluded: !isAnotherHirerAdded });
  }, [isAnotherHirerAdded]);

  useEffect(
    function autoOpenNextSectionOnCoApplicantAddition() {
      if (isAnotherHirerAdded) {
        setActiveSection(AvailableSections.customerDetails2);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAnotherHirerAdded]
  );

  return (
    <FormSectionGroup>
      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerDetails1)}
        defaultOpen={foldStatus[AvailableSections.customerDetails1]}
        title={getSectionTitleForCustomerLocal('customerDetailsForm', true)}
        onClickCapture={() => setActiveSection(AvailableSections.customerDetails1)}
        {...getFormSectionStatusProps(formsState.customerDetails1)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.customerDetails1, isOpened);
        }}
      >
        <Fieldset.Row>
          <CustomerDetailsForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            isDisabled={disableAllFormControls}
            onNextNavigate={() => {
              handleSaveSession();
              actions.customerDetailsForm.updateForm({ triggerValidation: true, formName: 'customerDetails1' });
              setPendingSection(AvailableSections.customerAddress1);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerAddress1)}
        defaultOpen={foldStatus[AvailableSections.customerAddress1]}
        title={getSectionTitleForCustomerLocal('addressForm', true)}
        onClickCapture={() => setActiveSection(AvailableSections.customerAddress1)}
        {...getFormSectionStatusProps([formsState.currentAddress1, formsState.previousAddress1])}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.customerAddress1, isOpened);
        }}
      >
        <Fieldset.Row>
          <AddressWithPreviousForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            isDisabled={disableAllFormControls}
            onNextNavigate={() => {
              handleSaveSession();
              actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'currentAddress1' });
              actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'previousAddress1' });
              setPendingSection(AvailableSections.employmentDetails1);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.employmentDetails1)}
        defaultOpen={foldStatus[AvailableSections.employmentDetails1]}
        title={getSectionTitleForCustomerLocal('employmentDetailsForm', true)}
        onClickCapture={() => setActiveSection(AvailableSections.employmentDetails1)}
        {...getFormSectionStatusProps([formsState.currentEmploymentDetails1, formsState.previousEmploymentDetails1])}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.employmentDetails1, isOpened);
        }}
      >
        <Fieldset.Row>
          <EmploymentDetailsFormWithPreviousForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            isDisabled={disableAllFormControls}
            onNextNavigate={() => {
              handleSaveSession();
              actions.employmentDetailsFormWithPreviousForm.updateForm({
                triggerValidation: true,
                formName: 'currentEmploymentDetails1',
              });
              actions.employmentDetailsFormWithPreviousForm.updateForm({
                triggerValidation: true,
                formName: 'previousEmploymentDetails1',
              });
              setPendingSection(AvailableSections.financialDetails1);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.financialDetails1)}
        defaultOpen={foldStatus[AvailableSections.financialDetails1]}
        title={getSectionTitleForCustomerLocal('financialDetailsForm', true)}
        onClickCapture={() => setActiveSection(AvailableSections.financialDetails1)}
        {...getFormSectionStatusProps(formsState.financialDetails1)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.financialDetails1, isOpened);
        }}
      >
        <Fieldset.Row>
          <FinancialDetailsForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            isDisabled={disableAllFormControls}
            onNextNavigate={() => {
              handleSaveSession();
              actions.financialDetailsForm.updateForm({ triggerValidation: true, formName: 'financialDetails1' });
              setPendingSection(AvailableSections.linkedRelationship1);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.linkedRelationship1)}
        defaultOpen={foldStatus[AvailableSections.linkedRelationship1]}
        title={getSectionTitleForCustomerLocal('linkedRelationshipsForm', true)}
        onClickCapture={() => setActiveSection(AvailableSections.linkedRelationship1)}
        {...getFormSectionStatusProps(formsState.linkedRelationshipsForm1)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.linkedRelationship1, isOpened);
        }}
      >
        <Fieldset.Row>
          <LinkedRelationshipsForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            isDisabled={disableAllFormControls}
            onNextNavigate={() => {
              handleSaveSession();
              actions.linkedRelationshipsForm.updateForm({
                triggerValidation: true,
                formName: 'linkedRelationshipsForm1',
              });

              if (!isUpfrontPayment) {
                setPendingSection(AvailableSections.bankDetails);
              } else if (isAnotherHirerAdded) {
                setPendingSection(AvailableSections.customerDetails2);
              } else {
                closeAllSections();
              }
            }}
          />
        </Fieldset.Row>
      </FormSection>

      {!isUpfrontPayment && (
        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.bankDetails)}
          defaultOpen={foldStatus[AvailableSections.bankDetails]}
          title={getSectionTitleForCustomerLocal('bankDetailsForm', true)}
          onClickCapture={() => setActiveSection(AvailableSections.bankDetails)}
          {...getFormSectionStatusProps(formsState.bankDetails)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.bankDetails, isOpened);
          }}
        >
          <Fieldset.Row>
            <BankDetailsForm />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              isDisabled={disableAllFormControls}
              onNextNavigate={() => {
                handleSaveSession();
                actions.bankDetailsForm.updateForm({
                  triggerValidation: true,
                  formName: 'bankDetails',
                });

                if (isAnotherHirerAdded) {
                  setPendingSection(AvailableSections.customerDetails2);
                } else {
                  closeAllSections();
                  setPendingSection(null);
                }
              }}
            />
          </Fieldset.Row>
        </FormSection>
      )}

      {isAnotherHirerAdded && (
        <>
          <Layout>
            <Layout.Item default="1/2">
              <FormHeading>{t('customerDataPrivate:header:private2')}</FormHeading>
            </Layout.Item>
            <Layout.Item default="1/2" className="u-text-right">
              <Button icon="semantic-forward" link simple small>
                {t('customerDataPrivate:viewDpn')}
              </Button>
            </Layout.Item>
          </Layout>

          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerDetails2)}
            defaultOpen={foldStatus[AvailableSections.customerDetails2]}
            title={getSectionTitleForCustomerLocal('customerDetailsForm', false)}
            onClickCapture={() => setActiveSection(AvailableSections.customerDetails2)}
            {...getFormSectionStatusProps(formsState.customerDetails2)}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.customerDetails2, isOpened);
            }}
          >
            <Fieldset.Row>
              <CustomerDetailsForm isMainApplicant={false} />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                isDisabled={disableAllFormControls}
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.customerDetailsForm.updateForm({ triggerValidation: true, formName: 'customerDetails2' });
                  setPendingSection(AvailableSections.customerAddress2);
                }}
              />
            </Fieldset.Row>
          </FormSection>

          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerAddress2)}
            defaultOpen={foldStatus[AvailableSections.customerAddress2]}
            title={getSectionTitleForCustomerLocal('addressForm', false)}
            onClickCapture={() => setActiveSection(AvailableSections.customerAddress2)}
            {...getFormSectionStatusProps([formsState.currentAddress2, formsState.previousAddress2])}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.customerAddress2, isOpened);
            }}
          >
            <Fieldset.Row>
              <AddressWithPreviousForm isMainApplicant={false} />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                isDisabled={disableAllFormControls}
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.addressWithPreviousForm.updateForm({
                    triggerValidation: true,
                    formName: 'currentAddress2',
                  });
                  actions.addressWithPreviousForm.updateForm({
                    triggerValidation: true,
                    formName: 'previousAddress2',
                  });
                  setPendingSection(AvailableSections.employmentDetails2);
                }}
              />
            </Fieldset.Row>
          </FormSection>

          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.employmentDetails2)}
            defaultOpen={foldStatus[AvailableSections.employmentDetails2]}
            title={getSectionTitleForCustomerLocal('employmentDetailsForm', false)}
            onClickCapture={() => setActiveSection(AvailableSections.employmentDetails2)}
            {...getFormSectionStatusProps([
              formsState.currentEmploymentDetails2,
              formsState.previousEmploymentDetails2,
            ])}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.employmentDetails2, isOpened);
            }}
          >
            <Fieldset.Row>
              <EmploymentDetailsFormWithPreviousForm isMainApplicant={false} />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                isDisabled={disableAllFormControls}
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.employmentDetailsFormWithPreviousForm.updateForm({
                    triggerValidation: true,
                    formName: 'currentEmploymentDetails2',
                  });
                  actions.employmentDetailsFormWithPreviousForm.updateForm({
                    triggerValidation: true,
                    formName: 'previousEmploymentDetails2',
                  });
                  setPendingSection(AvailableSections.financialDetails2);
                }}
              />
            </Fieldset.Row>
          </FormSection>

          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.financialDetails2)}
            defaultOpen={foldStatus[AvailableSections.financialDetails2]}
            title={getSectionTitleForCustomerLocal('financialDetailsForm', false)}
            onClickCapture={() => setActiveSection(AvailableSections.financialDetails2)}
            {...getFormSectionStatusProps(formsState.financialDetails2)}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.financialDetails2, isOpened);
            }}
          >
            <Fieldset.Row>
              <FinancialDetailsForm isMainApplicant={false} />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                isDisabled={disableAllFormControls}
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.financialDetailsForm.updateForm({ triggerValidation: true, formName: 'financialDetails2' });
                  setPendingSection(AvailableSections.linkedRelationship2);
                }}
              />
            </Fieldset.Row>
          </FormSection>

          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.linkedRelationship2)}
            defaultOpen={foldStatus[AvailableSections.linkedRelationship2]}
            title={getSectionTitleForCustomerLocal('linkedRelationshipsForm', false)}
            onClickCapture={() => setActiveSection(AvailableSections.linkedRelationship2)}
            {...getFormSectionStatusProps(formsState.linkedRelationshipsForm2)}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.linkedRelationship2, isOpened);
            }}
          >
            <Fieldset.Row>
              <LinkedRelationshipsForm isMainApplicant={false} />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                isDisabled={disableAllFormControls}
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.linkedRelationshipsForm.updateForm({
                    triggerValidation: true,
                    formName: 'linkedRelationshipsForm2',
                  });

                  closeAllSections();
                  setPendingSection(null);
                }}
              />
            </Fieldset.Row>
          </FormSection>
        </>
      )}

      <Layout className="u-mb">
        <Layout.Item>
          <Button
            icon={isAnotherHirerAdded ? 'semantic-minus' : 'semantic-plus'}
            link
            secondary
            onClick={toggleAnotherHirer}
          >
            {isAnotherHirerAdded
              ? t('customerDataPrivate:removeAnotherButton')
              : t('customerDataPrivate:addAnotherButton')}
          </Button>
        </Layout.Item>
      </Layout>
    </FormSectionGroup>
  );
};

export default CustomerDataPrivate;
