import { apiSlice } from './api.api-slice';

export interface QuoteCalculateResponse {
  totalDeposit: number;
  totalDepositPercentage: number;
  totalFinalKm: number;
  excessKmCharge: number;
  amountFinanced: number;
  gmfvAmount: number;
  monthlyPayment: number;
  isQuoteValid: boolean;
  cString: string;
}

export interface QuoteCalculationServicePlanResponseModel {
  payment: number;
  isQuoteValid: boolean;
}

interface RequestModel {
  productType: string;
  paymentType?: string;
}

export const quoteCalculate = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    quoteCalculate: builder.mutation<QuoteCalculateResponse | QuoteCalculationServicePlanResponseModel, RequestModel>({
      query: ({ productType, paymentType }) => ({
        url: '/quoteCalculate',
        method: 'POST',
        body: { productType, paymentType },
      }),
      extraOptions: {
        secondaryPath: '',
        applyProductPath: false,
      },
    }),
  }),
});

export const { useQuoteCalculateMutation } = quoteCalculate;
