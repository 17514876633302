import { SearchAddressResponse } from '../../services/redux/features/api/searchAddress.api-slice';
import { AddressFormModel } from '../AddressForm/addressForm.model';
import { AddressFormGenericModel } from './addressFormGeneric.model';

export const addressMapper = (
  dto: SearchAddressResponse,
  previousValues: AddressFormGenericModel
): AddressFormModel => {
  return {
    addressLine1: `${dto.street} ${dto.houseNumber}`,
    addressLine2: dto.district,
    addressLine3: '',
    eirCode: dto.postalCode,
    town: dto.city,
    county: dto.county,
    residentialStatus: previousValues.residentialStatus,
    timeAtAddressYears: previousValues.timeAtAddressYears,
    timeAtAddressMonths: previousValues.timeAtAddressMonths,
  };
};
