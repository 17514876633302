import { Accordion, Button, Fieldset, Form, Icon, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formAutocomplete } from '../../config';
import { VehicleType } from '../../screens/VehicleSelectPage/vehicleTypeSelectPage.model';
import { getMaxLastPayoutDate, getMaxLastProposalDate } from '../../services/common/validation';
import { actions, getters, useGetters } from '../../services/redux';
import { DatePickerFormField } from '../Fieldset/DatePickerFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import { FinancialProductFormModelFields } from './financialProductForm.model';
import { financialProductFormValidationSchema } from './financialProductForm.validator';

const createFormInputs = (() => {
  return {
    productType: () => (
      <SelectFormField
        fieldName="productType"
        labelTranslationKey="financialProduct:financialProductForm.productType.label"
        placeholderTranslationKey="financialProduct:financialProductForm.productType.placeholder"
        optionList={[
          {
            label: 'Hire Purchase',
            value: 'hirePurchase',
          },
          {
            label: 'Hire Purchase Balloon',
            value: 'hirePurchaseBalloon',
          },
          {
            label: 'PCP',
            value: 'pcp',
          },
        ]}
        testId="productType"
      />
    ),
    campaign: () => (
      <SelectFormField
        fieldName="campaign"
        labelTranslationKey="financialProduct:financialProductForm.campaign.label"
        placeholderTranslationKey="financialProduct:financialProductForm.campaign.placeholder"
        optionList={[
          { label: t('formControls:select.noSelection'), value: '' },
          { label: 'New VWPC 241 5.9% PCP Specific Range', value: 'vwpc241_59' },
          { label: 'New VWPC 241 6.9% PCP ID.4 Special Offer', value: 'vwpc241_69' },
          { label: 'New VWPC 241 7.9% PCP Full Range', value: 'vwpc241_79' },
        ]}
        testId="campaign"
      />
    ),
    term: () => (
      <SelectFormField
        fieldName="term"
        labelTranslationKey="financialProduct:financialProductForm.term.label"
        placeholderTranslationKey="financialProduct:financialProductForm.term.placeholder"
        optionList={[
          { label: t('formControls:select.noSelection'), value: '' },
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' },
          { label: '6', value: '6' },
          { label: '7', value: '7' },
          { label: '8', value: '8' },
          { label: '9', value: '9' },
          { label: '10', value: '10' },
          { label: '11', value: '11' },
          { label: '12', value: '12' },
          { label: '13', value: '13' },
          { label: '14', value: '14' },
          { label: '15', value: '15' },
          { label: '16', value: '16' },
          { label: '17', value: '17' },
          { label: '18', value: '18' },
          { label: '19', value: '19' },
          { label: '20', value: '20' },
          { label: '21', value: '21' },
          { label: '22', value: '22' },
          { label: '23', value: '23' },
          { label: '24', value: '24' },
          { label: '25', value: '25' },
          { label: '26', value: '26' },
          { label: '27', value: '27' },
          { label: '28', value: '28' },
          { label: '29', value: '29' },
          { label: '30', value: '30' },
          { label: '31', value: '31' },
          { label: '32', value: '32' },
          { label: '33', value: '33' },
          { label: '34', value: '34' },
          { label: '35', value: '35' },
          { label: '36', value: '36' },
          { label: '37', value: '37' },
          { label: '38', value: '38' },
          { label: '39', value: '39' },
          { label: '40', value: '40' },
          { label: '41', value: '41' },
          { label: '42', value: '42' },
          { label: '43', value: '43' },
          { label: '44', value: '44' },
          { label: '45', value: '45' },
          { label: '46', value: '46' },
          { label: '47', value: '47' },
          { label: '48', value: '48' },
          { label: '49', value: '49' },
          { label: '50', value: '50' },
          { label: '51', value: '51' },
          { label: '52', value: '52' },
          { label: '53', value: '53' },
          { label: '54', value: '54' },
          { label: '55', value: '55' },
          { label: '56', value: '56' },
          { label: '57', value: '57' },
          { label: '58', value: '58' },
          { label: '59', value: '59' },
          { label: '60', value: '60' },
        ]}
        testId="term"
      />
    ),
    vatApplicable: (vehicleType: VehicleType) => (
      <SelectFormField
        fieldName="vatApplicable"
        labelTranslationKey="financialProduct:financialProductForm.vatApplicable.label"
        placeholderTranslationKey="financialProduct:financialProductForm.vatApplicable.placeholder"
        optionList={[
          { label: t('formControls:select.noSelection'), value: '' },
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
        testId="vatApplicable"
        isDisabled={vehicleType === 'newBto'}
      />
    ),
    lastProposalDate: () => (
      <DatePickerFormField
        fieldName="lastProposalDate"
        labelTranslationKey="financialProduct:financialProductForm.lastProposalDate.label"
        placeholderTranslationKey="financialProduct:financialProductForm.lastProposalDate.placeholder"
        testId="lastProposalDate"
        isDisabled
        maxDate={getMaxLastProposalDate()}
      />
    ),
    lastPayoutDate: () => (
      <DatePickerFormField
        fieldName="lastPayoutDate"
        labelTranslationKey="financialProduct:financialProductForm.lastPayoutDate.label"
        placeholderTranslationKey="financialProduct:financialProductForm.lastPayoutDate.placeholder"
        testId="lastPayoutDate"
        isDisabled
        maxDate={getMaxLastPayoutDate()}
      />
    ),
    cString: () => (
      <TextInputFormField
        fieldName="cString"
        labelTranslationKey="financialProduct:financialProductForm.cString.label"
        testId="cString"
        isDisabled
      />
    ),
  };
}) satisfies () => Record<FinancialProductFormModelFields, (...args: any[]) => ReactElement>;

interface FinancialProductFormProps {
  vehicleType: VehicleType;
}

const FinancialProductForm: FunctionComponent<FinancialProductFormProps> = ({ vehicleType }) => {
  const { t } = useTranslation();
  const formName = 'financialProduct';

  const [initialValues /* , setInitialValues */] = useState(() => getters.financialProductForm.financialProductValues);
  const shouldValidate = useGetters().financialProductForm.financialProductValidationTrigger;

  const [isBrandApproval, _] = useState<boolean>(false);

  const { cString } = useGetters().financialProductPage;

  const [inputs /* , updateInputs */] = useState<ReturnType<typeof createFormInputs>>(() => createFormInputs());

  return (
    <>
      <Formik
        validationSchema={financialProductFormValidationSchema}
        initialValues={initialValues}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => {}}
        validateOnMount
        validateOnChange={false}
        validateOnBlur
      >
        {({ setFieldValue }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (cString) {
              setFieldValue('cString', cString, false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [cString, setFieldValue]);

          return (
            <Accordion className="u-m">
              <Accordion.Item defaultOpen title={t('financialProduct:financialProductForm.title')}>
                <Form autoComplete={formAutocomplete}>
                  <PersistFormInState
                    formName={formName}
                    updateForm={actions.financialProductForm.updateForm}
                    shouldValidate={shouldValidate}
                  />
                  <Fieldset.Row>
                    <Layout>
                      <Layout.Item default="2/3" s="1/1">
                        {inputs.productType()}
                      </Layout.Item>
                    </Layout>
                  </Fieldset.Row>

                  <Fieldset.Row>
                    <Layout>
                      <Layout.Item default="2/3" s="1/1">
                        {inputs.campaign()}
                      </Layout.Item>
                      <Layout.Item default="1/3" s="1/1">
                        <Layout>
                          <Layout.Item default="3/5">
                            <Button type="button" secondary full className="u-mt">
                              {t('financialProduct:financialProductForm.searchCampaignButton')}
                            </Button>
                          </Layout.Item>
                          <Layout.Item default="1/4">
                            {isBrandApproval && <Icon className="u-mt" name="semantic-lock" />}
                          </Layout.Item>
                        </Layout>
                      </Layout.Item>
                    </Layout>
                  </Fieldset.Row>

                  <Fieldset.Row>
                    <Layout>
                      <Layout.Item default="1/3" s="1/1">
                        {inputs.term()}
                      </Layout.Item>
                      <Layout.Item default="1/3" s="1/1">
                        {inputs.vatApplicable(vehicleType)}
                      </Layout.Item>
                    </Layout>
                  </Fieldset.Row>
                  <Fieldset.Row>
                    <Layout>
                      <Layout.Item default="1/3" s="1/1">
                        {inputs.lastProposalDate()}
                      </Layout.Item>
                      <Layout.Item default="1/3" s="1/1">
                        {inputs.lastPayoutDate()}
                      </Layout.Item>
                      <Layout.Item default="1/3" s="1/1">
                        {inputs.cString()}
                      </Layout.Item>
                    </Layout>
                  </Fieldset.Row>
                </Form>
              </Accordion.Item>
            </Accordion>
          );
        }}
      </Formik>
    </>
  );
};

export default FinancialProductForm;
