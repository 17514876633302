import { Button, ButtonContainer, Fieldset, FormSection, FormSectionGroup, Layout } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddressWithPreviousForm from '../../../../components/AddressWithPreviousForm/AddressWithPreviousForm';
import { BankDetailsForm } from '../../../../components/BankDetailsForm/BankDetailsForm';
import { CompanyDetailsForm } from '../../../../components/CompanyDetailsForm/CompanyDetailsForm';
import { CustomerDetailsForm } from '../../../../components/CustomerDetailsForm/CustomerDetailsForm';
import { DriverDetailsForm } from '../../../../components/DriverDetailsForm/DriverDetailsForm';
import { FormSectionDpnButton, sectionWithTitleClass } from '../../../../components/FormSectionDpnButton';
import { LinkedRelationshipsForm } from '../../../../components/LinkedRelationshipsForm/LinkedRelationshipsForm';
import {
  FormUpdateActionTypeGeneric,
  checkIfSectionIsReadyAndValid,
  getFormSectionId,
  getFormSectionStatusProps,
  getFormsState,
  scrollIntoSection,
  setAllFoldToFalse,
} from '../../../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../../../services/redux';
import { FlowTypeEnum } from '../../../../types/forms';
import useSaveSession from '../../../../utils/useSaveSession/useSaveSession';
import { getSectionTitleForCustomerCurried } from '../../../utils';

enum AvailableSections {
  companyDetails,
  driverDetails,
  bankDetails,
  customerDetails1,
  customerAddress1,
  linkedRelationships1,
  customerDetails2,
  customerAddress2,
  linkedRelationships2,
}

const NavigationControls = ({ onNextNavigate }) => {
  return (
    <>
      <Layout right>
        <Layout.Item default="1/6">
          <ButtonContainer right>
            <Button onClick={onNextNavigate}>{t('customerDataPage:nextFormButton')}</Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </>
  );
};

const getSectionTitleForCustomerLocal = getSectionTitleForCustomerCurried(FlowTypeEnum.ltdPlc);

export const customerDataBusinessLtdPlcUpdateForm = {
  mainApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.companyDetailsForm.updateForm({ formName: 'companyDetails', ...payload });
    actions.driverDetailsForm.updateForm({ formName: 'driverDetailsForm', ...payload });
    actions.bankDetailsForm.updateForm({ formName: 'bankDetails', ...payload });
    actions.customerDetailsForm.updateForm({ formName: 'customerDetails1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'currentAddress1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'previousAddress1', ...payload });
    actions.linkedRelationshipsForm.updateForm({ formName: 'linkedRelationshipsForm1', ...payload });
  },
  coApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.customerDetailsForm.updateForm({ formName: 'customerDetails2', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'currentAddress2', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'previousAddress2', ...payload });
    actions.linkedRelationshipsForm.updateForm({ formName: 'linkedRelationshipsForm2', ...payload });
  },
};

export const CustomerDataBusinessLtdPlc = () => {
  const formSectionDesignator = 'customerDataLtdPlcFormSection';
  const { focusOnError } = useGetters().customerDataPage;

  const [currentSection, setCurrentSection] = useState(AvailableSections.companyDetails);
  const [pendingSection, setPendingSection] = useState<AvailableSections | null>(
    () => AvailableSections.companyDetails
  );

  const { handleSaveSession } = useSaveSession();

  const [areAllFormsSettled, setAreAllFormsSettled] = useState(false);

  const setActiveSection = (section: AvailableSections) => {
    setCurrentSection(section);
  };

  const { isCoApplicantAdded } = useGetters().customerDataBusinessLtdPlc;
  const toggleCoApplicant = () => actions.customerDataBusinessLtdPlc.setIsCoApplicantAdded(!isCoApplicantAdded);

  const formsState = getFormsState({
    ...useGetters().companyDetailsForm.forms,
    ...useGetters().driverDetailsForm.forms,
    ...useGetters().bankDetailsForm.forms,
    ...useGetters().customerDetailsForm.forms,
    ...useGetters().addressWithPreviousForm.forms,
    ...useGetters().linkedRelationshipsForm.forms,
  });

  useEffect(function doCleanupOnLeave() {
    return function resetCoApplicantsAdditionStatus() {
      actions.customerDataBusinessLtdPlc.resetAddedCoApplicants();
    };
  }, []);

  useEffect(() => {
    customerDataBusinessLtdPlcUpdateForm.coApplicant({ excluded: !isCoApplicantAdded });
  }, [isCoApplicantAdded]);

  useEffect(
    function autoOpenNextSectionOnCoApplicantAddition() {
      if (isCoApplicantAdded) {
        setActiveSection(AvailableSections.customerDetails2);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCoApplicantAdded]
  );

  const areAllFormsValid = Object.entries(formsState).every(([, { isValid, excluded }]) => isValid || excluded);

  useEffect(() => {
    setAreAllFormsSettled(
      Object.entries(formsState).every(([, { isValidating, excluded }]) => !isValidating || excluded)
    );
  }, [formsState]);

  // When in Standalone Service Plan user selected Upfront payment for service plan,
  // then we do not need Bank Details
  const isUpfrontPayment =
    getters.standaloneServicePlanForm.standaloneServicePlanValues.paymentType?.toLowerCase() === 'upfront';

  useEffect(() => {
    if (isUpfrontPayment) {
      actions.bankDetailsForm.updateForm({ formName: 'bankDetails', excluded: true });
    }
  }, [isUpfrontPayment]);

  const [foldStatus, setFoldStatus] = useState<Record<number, boolean>>({
    [AvailableSections.companyDetails]: false,
    [AvailableSections.driverDetails]: false,
    [AvailableSections.bankDetails]: false,
    [AvailableSections.customerDetails1]: false,
    [AvailableSections.customerAddress1]: false,
    [AvailableSections.linkedRelationships1]: false,
    [AvailableSections.customerDetails2]: false,
    [AvailableSections.customerAddress2]: false,
    [AvailableSections.linkedRelationships2]: false,
  });

  const formSectionMap = useMemo(() => {
    return {
      [AvailableSections.companyDetails]: formsState.companyDetails,
      [AvailableSections.driverDetails]: formsState.driverDetailsForm,
      [AvailableSections.bankDetails]: formsState.bankDetails,
      [AvailableSections.customerDetails1]: formsState.customerDetails1,
      [AvailableSections.customerAddress1]: [formsState.currentAddress1, formsState.previousAddress1],
      [AvailableSections.linkedRelationships1]: formsState.linkedRelationshipsForm1,
      [AvailableSections.customerDetails2]: formsState.customerDetails2,
      [AvailableSections.customerAddress2]: [formsState.currentAddress2, formsState.previousAddress2],
      [AvailableSections.linkedRelationships2]: formsState.linkedRelationshipsForm2,
    };
  }, [formsState]);

  useEffect(() => {
    actions.customerDataBusinessLtdPlc.setAllFormsValid({ allFormsValid: areAllFormsValid });
  }, [areAllFormsValid]);

  const { currentSectionIsReady, currentSectionIsValid } = checkIfSectionIsReadyAndValid(
    AvailableSections,
    currentSection,
    formSectionMap
  );

  const findFirstSectionToOpen = useCallback((): AvailableSections => {
    return Object.entries(formSectionMap).find(([section]) => {
      const form = formSectionMap[section];
      const props = getFormSectionStatusProps(form);
      return props.error;
    })?.[0] as unknown as AvailableSections;
  }, [formSectionMap]);

  useEffect(() => {
    if (pendingSection === null || !currentSectionIsReady) return;
    if (currentSectionIsValid) setCurrentSection(pendingSection);
    setPendingSection(null);
  }, [currentSection, pendingSection, currentSectionIsReady, currentSectionIsValid]);

  useEffect(
    function focusOnFirstErrorSection() {
      if (focusOnError && areAllFormsSettled) {
        const firstSectionToOpen = findFirstSectionToOpen();
        if (firstSectionToOpen) {
          setCurrentSection(firstSectionToOpen);
          actions.customerDataPage.updateFocusOnError({ state: false });
        }
      }
    },
    [focusOnError, findFirstSectionToOpen, areAllFormsSettled]
  );

  const closeAllSections = () => {
    const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

    setFoldStatus(resetFoldStatus);
  };

  useEffect(
    function openNextActiveSection() {
      const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

      scrollIntoSection(getFormSectionId<AvailableSections>(formSectionDesignator, currentSection));

      const next = { ...resetFoldStatus, [currentSection]: true };

      setFoldStatus(next);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSection]
  );

  const changeFoldStatus = (section: AvailableSections, isOpened: boolean) => {
    const next = { ...foldStatus, [section]: isOpened };

    setFoldStatus(next);
  };

  return (
    <FormSectionGroup>
      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.companyDetails)}
        defaultOpen={foldStatus[AvailableSections.companyDetails]}
        title={getSectionTitleForCustomerLocal('companyDetailsForm')}
        onClickCapture={() => setActiveSection(AvailableSections.companyDetails)}
        {...getFormSectionStatusProps(formsState.companyDetails)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.companyDetails, isOpened);
        }}
      >
        <Fieldset.Row>
          <CompanyDetailsForm />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.companyDetailsForm.updateForm({ triggerValidation: true, formName: 'companyDetails' });
              setPendingSection(AvailableSections.driverDetails);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.driverDetails)}
        defaultOpen={foldStatus[AvailableSections.driverDetails]}
        title={getSectionTitleForCustomerLocal('driverDetailsForm')}
        onClickCapture={() => setActiveSection(AvailableSections.driverDetails)}
        {...getFormSectionStatusProps(formsState.driverDetailsForm)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.driverDetails, isOpened);
        }}
      >
        <Fieldset.Row>
          <DriverDetailsForm />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.driverDetailsForm.updateForm({ triggerValidation: true, formName: 'driverDetailsForm' });

              if (isUpfrontPayment) {
                setPendingSection(AvailableSections.customerDetails1);
              } else {
                setPendingSection(AvailableSections.bankDetails);
              }
            }}
          />
        </Fieldset.Row>
      </FormSection>

      {!isUpfrontPayment && (
        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.bankDetails)}
          defaultOpen={foldStatus[AvailableSections.bankDetails]}
          title={getSectionTitleForCustomerLocal('bankDetailsForm')}
          onClickCapture={() => setActiveSection(AvailableSections.bankDetails)}
          {...getFormSectionStatusProps(formsState.bankDetails)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.bankDetails, isOpened);
          }}
        >
          <Fieldset.Row>
            <BankDetailsForm />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.bankDetailsForm.updateForm({ triggerValidation: true, formName: 'bankDetails' });
                setPendingSection(AvailableSections.customerDetails1);
              }}
            />
          </Fieldset.Row>
        </FormSection>
      )}

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerDetails1)}
        defaultOpen={foldStatus[AvailableSections.customerDetails1]}
        className={sectionWithTitleClass}
        title={
          <>
            {getSectionTitleForCustomerLocal('customerDetailsForm', true)}
            <FormSectionDpnButton />
          </>
        }
        onClick={() => setActiveSection(AvailableSections.customerDetails1)}
        {...getFormSectionStatusProps(formsState.customerDetails1)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.customerDetails1, isOpened);
        }}
      >
        <Fieldset.Row>
          <CustomerDetailsForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.customerDetailsForm.updateForm({ triggerValidation: true, formName: 'customerDetails1' });
              setPendingSection(AvailableSections.customerAddress1);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerAddress1)}
        defaultOpen={foldStatus[AvailableSections.customerAddress1]}
        title={getSectionTitleForCustomerLocal('addressWithPreviousForm', true)}
        onClickCapture={() => setActiveSection(AvailableSections.customerAddress1)}
        {...getFormSectionStatusProps([formsState.currentAddress1, formsState.previousAddress1])}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.customerAddress1, isOpened);
        }}
      >
        <Fieldset.Row>
          <AddressWithPreviousForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'currentAddress1' });
              actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'previousAddress1' });
              setPendingSection(AvailableSections.linkedRelationships1);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.linkedRelationships1)}
        defaultOpen={foldStatus[AvailableSections.linkedRelationships1]}
        title={getSectionTitleForCustomerLocal('linkedRelationshipsForm', true)}
        onClickCapture={() => setActiveSection(AvailableSections.linkedRelationships1)}
        {...getFormSectionStatusProps(formsState.linkedRelationshipsForm1)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.linkedRelationships1, isOpened);
        }}
      >
        <Fieldset.Row>
          <LinkedRelationshipsForm isMainApplicant />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.linkedRelationshipsForm.updateForm({
                triggerValidation: true,
                formName: 'linkedRelationshipsForm1',
              });

              if (isCoApplicantAdded) {
                setPendingSection(AvailableSections.customerDetails2);
              } else {
                closeAllSections();
                setPendingSection(null);
              }
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <Layout className="u-mb">
        <Layout.Item>
          <Button
            icon={isCoApplicantAdded ? 'semantic-minus' : 'semantic-plus'}
            link
            secondary
            onClick={toggleCoApplicant}
          >
            {isCoApplicantAdded
              ? t('customerDataBusinessLtdPlc:removeCoApplicant')
              : t('customerDataBusinessLtdPlc:addCoApplicant')}
          </Button>
        </Layout.Item>
      </Layout>

      {isCoApplicantAdded && (
        <>
          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerDetails2)}
            defaultOpen={foldStatus[AvailableSections.customerDetails2]}
            className={sectionWithTitleClass}
            title={
              <>
                {getSectionTitleForCustomerLocal('customerDetailsForm', false)}
                <FormSectionDpnButton />
              </>
            }
            onClick={() => setActiveSection(AvailableSections.customerDetails2)}
            {...getFormSectionStatusProps(formsState.customerDetails2)}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.customerDetails2, isOpened);
            }}
          >
            <Fieldset.Row>
              <CustomerDetailsForm isMainApplicant={false} />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.customerDetailsForm.updateForm({ triggerValidation: true, formName: 'customerDetails2' });
                  setPendingSection(AvailableSections.customerAddress2);
                }}
              />
            </Fieldset.Row>
          </FormSection>

          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.customerAddress2)}
            defaultOpen={foldStatus[AvailableSections.customerAddress2]}
            title={getSectionTitleForCustomerLocal('addressWithPreviousForm', false)}
            onClickCapture={() => setActiveSection(AvailableSections.customerAddress2)}
            {...getFormSectionStatusProps([formsState.currentAddress2, formsState.previousAddress2])}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.customerAddress2, isOpened);
            }}
          >
            <Fieldset.Row>
              <AddressWithPreviousForm isMainApplicant={false} />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'currentAddress2' });
                  actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'previousAddress2' });
                  setPendingSection(AvailableSections.linkedRelationships2);
                }}
              />
            </Fieldset.Row>
          </FormSection>

          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.linkedRelationships2)}
            defaultOpen={foldStatus[AvailableSections.linkedRelationships2]}
            title={getSectionTitleForCustomerLocal('linkedRelationshipsForm', false)}
            onClickCapture={() => setActiveSection(AvailableSections.linkedRelationships2)}
            {...getFormSectionStatusProps(formsState.linkedRelationshipsForm2)}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.linkedRelationships2, isOpened);
            }}
          >
            <Fieldset.Row>
              <LinkedRelationshipsForm isMainApplicant={false} />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.linkedRelationshipsForm.updateForm({
                    triggerValidation: true,
                    formName: 'linkedRelationshipsForm2',
                  });

                  closeAllSections();
                  setPendingSection(null);
                }}
              />
            </Fieldset.Row>
          </FormSection>
        </>
      )}
    </FormSectionGroup>
  );
};
