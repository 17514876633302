import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { EmploymentDetailsFormModel } from '../EmploymentDetailsForm/employmentDetailsForm.model';
import { PreviousEmploymentDetailsFormModel } from '../PreviousEmploymentDetailsForm/previousEmploymentDetailsForm.model';

export interface EmploymentDetailsFormWithPreviousFormState
  extends ReduxStateWithForms<{
    currentEmploymentDetails1: ReduxTrackedForm<EmploymentDetailsFormModel>;
    previousEmploymentDetails1: ReduxTrackedForm<PreviousEmploymentDetailsFormModel>;
    currentEmploymentDetails2: ReduxTrackedForm<EmploymentDetailsFormModel>;
    previousEmploymentDetails2: ReduxTrackedForm<PreviousEmploymentDetailsFormModel>;
  }> {}

const employmentDetailsInitialValues: EmploymentDetailsFormModel = {
  employmentStatus: undefined,
  occupation: undefined,
  employer: '',
  occupationNotes: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  eirCode: '',
  town: '',
  county: '',
  timeAtEmployerYears: undefined,
  timeAtEmployerMonths: undefined,
};

const initialState: EmploymentDetailsFormWithPreviousFormState = {
  forms: {
    currentEmploymentDetails1: {
      ...createReduxTrackedFormDefaults<EmploymentDetailsFormModel>({ initialValues: employmentDetailsInitialValues }),
      values: {
        ...employmentDetailsInitialValues,
      },
    },
    previousEmploymentDetails1: {
      ...createReduxTrackedFormDefaults<PreviousEmploymentDetailsFormModel>({
        excluded: true,
        initialValues: employmentDetailsInitialValues,
      }),
      values: {
        ...employmentDetailsInitialValues,
      },
    },
    currentEmploymentDetails2: {
      ...createReduxTrackedFormDefaults<EmploymentDetailsFormModel>({
        excluded: true,
        initialValues: employmentDetailsInitialValues,
      }),
      values: {
        ...employmentDetailsInitialValues,
      },
    },
    previousEmploymentDetails2: {
      ...createReduxTrackedFormDefaults<PreviousEmploymentDetailsFormModel>({
        excluded: true,
        initialValues: employmentDetailsInitialValues,
      }),
      values: {
        ...employmentDetailsInitialValues,
      },
    },
  },
};

const name = 'employmentDetailsFormWithPreviousForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<EmploymentDetailsFormWithPreviousFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    currentEmploymentDetails1Triggers({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.currentEmploymentDetails1.triggers;
    },
    previousEmploymentDetails1Triggers({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.previousEmploymentDetails1.triggers;
    },
    previousEmploymentDetails1Excluded({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.previousEmploymentDetails1.excluded;
    },
    currentEmploymentDetails2Triggers({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.currentEmploymentDetails2.triggers;
    },
    previousEmploymentDetails2Triggers({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.previousEmploymentDetails2.triggers;
    },
    previousEmploymentDetails2Excluded({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.previousEmploymentDetails2.excluded;
    },
    currentEmploymentDetails1Values({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.currentEmploymentDetails1.values;
    },
    previousEmploymentDetails1Values({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.previousEmploymentDetails1.values;
    },
    currentEmploymentDetails2Values({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.currentEmploymentDetails2.values;
    },
    previousEmploymentDetails2Values({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms.previousEmploymentDetails2.values;
    },
    forms({ employmentDetailsFormWithPreviousForm }) {
      return employmentDetailsFormWithPreviousForm.forms;
    },
  },
});

export const employmentDetailsFormWithPreviousFormSlice = slice;
export const employmentDetailsFormWithPreviousFormGettersDef = gettersDef;
