import { brandType } from '../../../config';
import { axiosInstance } from './axios';

const { REACT_APP_COUNTRY_TYPECODE, REACT_APP_PRODUCT_TYPE, REACT_APP_ENTRY_POINT_TYPE } = process.env;

const DEFAULT_RESOLVE_STATUSES = [200, 201];

type FesRequestProps<T = any> = {
  httpMethod: string;
  path: string;
  data?: T;
  dataStorageId?: string;
  /**
   * - Add brand, product etc to path (default: true)
   */
  applyProductPath?: boolean;
  /**
   * - Path fragment to append after variable path fragments (productPath and dataStorageId)
   */
  secondaryPath?: string;
  /**
   * - http statuses for promise resolve (default: [200, 201])
   */
  resolveStatuses?: number[];
  /**
   * - Error handler to use.
   */
  onError?: (...args: any[]) => any;
};

const fesRequest = <T = any, R = any>(props: FesRequestProps<T>): Promise<R> => {
  const {
    dataStorageId,
    httpMethod,
    data,
    path: primaryPath,
    secondaryPath = '',
    applyProductPath = true,
    resolveStatuses = DEFAULT_RESOLVE_STATUSES,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError = () => {},
  } = props;
  const productPath = applyProductPath
    ? `/${REACT_APP_PRODUCT_TYPE}/${REACT_APP_COUNTRY_TYPECODE}/${brandType}/${REACT_APP_ENTRY_POINT_TYPE}`
    : '';
  const transactionPath = dataStorageId ? `/${dataStorageId}` : '';

  const url = `${primaryPath}${productPath}${transactionPath}${secondaryPath}`;

  return axiosInstance<R>({
    method: httpMethod,
    url,
    data,
    validateStatus: (statusCode) => resolveStatuses.includes(statusCode),
  })
    .then((response) => response.data)
    .catch(onError);
};

export default fesRequest;
