import { Input } from '@vwfs-bronson/bronson-react';
import { useFormikContext } from 'formik';
import i18n from 'i18next';
import React, { FunctionComponent, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { FormField } from '..';
import { UsedCarSelectionFormFields } from './UsedCarSelectionFormModels';

export const typeSafeFormFieldName = (name: keyof UsedCarSelectionFormFields): keyof UsedCarSelectionFormFields => name;

export interface ChassisNumberFormFieldProps {
  testId: string;
}

export const ChassisNumberFormField: FunctionComponent<ChassisNumberFormFieldProps> = ({ testId }) => {
  const { setFieldValue } = useFormikContext();

  const formFieldName = typeSafeFormFieldName('chassisNumber');
  const maxLength = 17;

  const chassisNumberMask = (rawValue) => {
    const filteredChars = rawValue.split('').filter((char) => /[A-Za-z0-9]/.test(char));

    const mask = filteredChars.map(() => /[A-Za-z0-9]/);

    return mask.slice(0, maxLength);
  };

  const handleChangeInternal = (event) => {
    const newValue = event.target.value.toUpperCase();

    setFieldValue(formFieldName, newValue, true);
  };

  return (
    <FormField
      type="input"
      name={formFieldName}
      labelText={i18n.t(`usedCarSelectionForm:form.formFields.${formFieldName}.label`)}
      testId={testId}
      render={(fieldProps) => {
        return (
          <MaskedInput
            {...fieldProps}
            className="c-input__input"
            guide={false}
            keepCharPositions
            mask={(value) => chassisNumberMask(value)}
            name={formFieldName}
            onChange={handleChangeInternal}
            placeholder={i18n.t(`usedCarSelectionForm:form.formFields.${formFieldName}.placeholder`)}
            placeholderChar={'\u2000'}
            value={fieldProps.value}
            onBlur={fieldProps.onBlur}
          />
        );
      }}
    />
  );
};

export interface RecordedMileageInputProps {
  testId: string;
}

export const RecordedMileageInput: FunctionComponent<RecordedMileageInputProps> = ({ testId }) => {
  const { setFieldValue } = useFormikContext();
  const [inputValue, setInputValue] = useState('');

  const formFieldName = typeSafeFormFieldName('recordedMileage');

  const formatNumber = (numString) => {
    const number = parseInt(numString.replace(/\D/g, ''), 10);
    if (!Number.isNaN(number)) {
      return new Intl.NumberFormat('en-IE').format(number);
    }
    return '';
  };

  const handleChange = (event) => {
    const rawValue = event.target.value.replace(/\D/g, '').slice(0, 8);
    const formattedValue = formatNumber(rawValue);

    setInputValue(formattedValue);
    setFieldValue(formFieldName, rawValue);
  };

  return (
    <FormField
      type="text"
      name={formFieldName}
      testId={testId}
      labelText={i18n.t(`usedCarSelectionForm:form.formFields.${formFieldName}.label`)}
      render={(fieldProps) => (
        <Input
          {...fieldProps}
          inputMode="numeric"
          pattern="\d*"
          name={formFieldName}
          testId={testId}
          maxLength={8}
          placeholder={i18n.t(`usedCarSelectionForm:form.formFields.${formFieldName}.placeholder`)}
          onChange={handleChange}
          value={inputValue}
        />
      )}
    />
  );
};
