import { Button, ButtonContainer, Card, ContentSection, Hr, Layout, Spinner } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import AdditionalServicePlan from '../../../components/AdditionalServicePlan/AdditionalServicePlan';
import ApplicationSummary from '../../../components/ApplicationSummary/ApplicationSummary';
import FinancialProductForm from '../../../components/FinancialProductForm/FinancialProductForm';
import FinancialProductSpecificationForm from '../../../components/FinancialProductSpecificationForm/FinancialProductSpecificationForm';
import { getFormsState } from '../../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../../services/redux';
import { useSearchVehicleQuery } from '../../../services/redux/features/api/search-vehicle.slice';
import { WizardPagesEnum } from '../newQuotation.slice';

const useGetFinancialProductPageFormsValidity = () => {
  const formsState = getFormsState({
    ...useGetters().financialProductForm.forms,
    ...useGetters().financialProductSpecificationForm.forms,
    ...useGetters().additionalServicePlan.forms,
  });

  const areAllFormsValid = Object.entries(formsState).every(([, { isValid, excluded }]) => isValid || excluded);

  useEffect(
    function updateFormsValidityInState() {
      actions.financialProductPage.setAllFormsValid({ allFormsValid: areAllFormsValid });
    },
    [areAllFormsValid]
  );
};

const FinancialProductPage: FunctionComponent = () => {
  const { isLoading } = useSearchVehicleQuery();

  const vehicleType = useGetters().vehicleTypeSelectPage.vehicleTypeSelectForm.values.selectedType;
  const financialProduct = useGetters().financialProductForm.financialProductValues.productType;

  const usedCarData = useGetters().usedCarFormSelectorPage.usedCarSearchResult;
  const usedCarSelectionMethod = useGetters().usedCarFormSelectorPage.usedCarSearchSelectedMethod;

  const { areAllFormsValid, currentQuote } = useGetters().financialProductPage;

  const [pendingNavigateNextPage, setPendingNavigateNextPage] = useState(false);

  useGetFinancialProductPageFormsValidity();

  const submitFinancialProductForms = () => {
    actions.financialProductForm.updateForm({ triggerValidation: true, formName: 'financialProduct' });
    actions.financialProductSpecificationForm.updateForm({
      triggerValidation: true,
      formName: 'financialProductSpecification',
    });
    actions.additionalServicePlan.updateForm({ triggerValidation: true, formName: 'additionalServicePlan' });

    setPendingNavigateNextPage(true);
  };

  const editExistingQuoteOrPushNewToStore = () => {
    // Update memorized quote,
    // case when we are going back from compare quote view with some
    // selected quote id and after changes updating it.
    const currentForms = {
      form: {
        additionalServicePlan: getters.additionalServicePlan.forms.additionalServicePlan,
        financialProduct: getters.financialProductForm.forms.financialProduct,
        financialProductSpecification: getters.financialProductSpecificationForm.forms.financialProductSpecification,
      },
    };

    if (currentQuote.isEditMode) {
      actions.financialProductPage.updateQuote(currentForms);
    } else {
      actions.financialProductPage.addCurrentQuote(currentForms);
    }
  };

  useEffect(
    function navigateNextPageIfPossible() {
      if (pendingNavigateNextPage && areAllFormsValid) {
        editExistingQuoteOrPushNewToStore();
        actions.newQuotation.wizardNavigateToPage(WizardPagesEnum.compareQuotes);
      } else {
        setPendingNavigateNextPage(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [areAllFormsValid, pendingNavigateNextPage]
  );

  useEffect(
    function loadQuoteFromStateInEditMode() {
      const loadedQuote = getters.financialProductPage.getCurrentQuote;

      if (currentQuote.isEditMode && loadedQuote) {
        actions.financialProductForm.updateForm({
          formName: 'financialProduct',
          values: loadedQuote.forms.financialProduct.values,
        });
        actions.financialProductSpecificationForm.updateForm({
          formName: 'financialProductSpecification',
          values: loadedQuote.forms.financialProductSpecification.values,
        });
        actions.additionalServicePlan.updateForm({
          formName: 'additionalServicePlan',
          values: loadedQuote.forms.additionalServicePlan.values,
        });
      }
    },
    [currentQuote]
  );

  return (
    <Spinner busy={isLoading} section>
      {!isLoading && (
        <>
          <ContentSection pageWrapSize="small">
            <Card className="u-text-left" element="div">
              <ApplicationSummary isDefaultOpen={false} />
              <Hr className="u-m-none u-mt" />
              <FinancialProductForm vehicleType={vehicleType} />
              <Hr className="u-m-none" />
              <FinancialProductSpecificationForm financialProductType={financialProduct} vehicleType={vehicleType} />
              <Hr className="u-m-none" />
              <AdditionalServicePlan
                vehicleType={vehicleType}
                usedCarData={usedCarData}
                usedCarSelectionMethod={usedCarSelectionMethod}
              />
            </Card>
          </ContentSection>

          <ContentSection pageWrapSize="small">
            <Layout center>
              <ButtonContainer center>
                <Layout.Item default="1/3">
                  <Button
                    type="button"
                    full
                    testId="button_back"
                    secondary
                    onClick={() => {
                      actions.newQuotation.wizardNavigateToPage(WizardPagesEnum.vehicle);
                    }}
                  >
                    {t('financialProductPage:navigation:cancel')}
                  </Button>
                </Layout.Item>
                <Layout.Item default="1/3">
                  <Button type="submit" full testId="button_continue" onClick={submitFinancialProductForms}>
                    {t('financialProductPage:navigation:continue')}
                  </Button>
                </Layout.Item>
              </ButtonContainer>
            </Layout>
          </ContentSection>
        </>
      )}
    </Spinner>
  );
};

export default FinancialProductPage;
