import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { omitExcludedForms } from '../../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../../services/redux/storeUtils';

export interface CustomerDataPBusinessPartnershipState {
  allFormsValid: boolean;
}

const initialState: CustomerDataPBusinessPartnershipState = {
  allFormsValid: false,
};

const name = 'customerDataBusinessPartnership';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAllFormsValid: (state, action: PayloadAction<{ allFormsValid: boolean }>) => {
      state.allFormsValid = action.payload.allFormsValid;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    areAllFormsValid({ customerDataBusinessPartnership }) {
      return customerDataBusinessPartnership.allFormsValid;
    },
    getDataForSubmit(state) {
      return omitExcludedForms({
        ...state.companyDetailsForm.forms,
        ...state.driverDetailsForm.forms,
        ...state.bankDetailsForm.forms,
        ...state.customerDetailsForm.forms,
        ...state.addressWithPreviousForm.forms,
        ...state.addressWithPreviousForm.forms,
        ...state.financialDetailsForm.forms,
        ...state.linkedRelationshipsForm.forms,
      });
    },
  },
});

export const customerDataBusinessPartnershipSlice = slice;
export const customerDataBusinessPartnershipGettersDef = gettersDef;
