import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FunctionComponent } from 'react';
import { ErrorPage } from '..';
import CompareQuotes from '../../components/CompareQuotes/CompareQuotes';
import StandaloneServicePlan from '../../components/StandaloneServicePlan/StandaloneServicePlan';
import { getEnumValues, isTestStage } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { CreditCheckStatusEnum } from '../../types/forms';
import { CreditCheckPage } from './CreditCheckPage/CreditCheckPage';
import CustomerDataPage from './CustomerDataPage/CustomerDataPage';
import { CustomerTypeSelectionPage } from './CustomerTypeSelectionPage/CustomerTypeSelectionPage';
import DocumentUploadPage from './DocumentUploadPage/DocumentUploadPage';
import FinancialProductPage from './FinancialProductPage/FinancialProductPage';
import UsedCarFormSelectorPage from './UsedCarFormSelectorPage/UsedCarFormSelectorPage';

export enum WizardPagesEnum {
  'empty' = 'empty',
  'vehicle' = 'vehicle',
  'customerTypeSelection' = 'customerTypeSelection',
  'financialProduct' = 'financialProduct',
  'compareQuotes' = 'compareQuotes',
  'prospect' = 'prospect',
  'standaloneServicePlan' = 'standaloneServicePlan',
  'customerData' = 'customerData',
  'review' = 'review',
  'amlDocuments' = 'amlDocuments',
  'creditCheck' = 'creditCheck',
}

interface PageItem {
  /**
   * Translation key for breadcrumb text in the wizard.
   */
  breadcrumb: string;
  pageName: WizardPagesEnum;
  component: FunctionComponent;
  headingTranslationKey?: string;
}

export const newQuotationWizardPages: Array<PageItem> = [
  {
    breadcrumb: 'vehicle',
    pageName: WizardPagesEnum.vehicle,
    component: UsedCarFormSelectorPage,
  },

  {
    breadcrumb: 'quotation',
    pageName: WizardPagesEnum.financialProduct,
    component: FinancialProductPage,
  },

  {
    breadcrumb: 'quotation',
    pageName: WizardPagesEnum.compareQuotes,
    component: CompareQuotes,
  },

  {
    breadcrumb: 'quotation',
    pageName: WizardPagesEnum.customerTypeSelection,
    component: CustomerTypeSelectionPage,
  },

  {
    breadcrumb: 'prospect',
    pageName: WizardPagesEnum.empty,
    component: ErrorPage,
  },

  {
    breadcrumb: 'customer',
    pageName: WizardPagesEnum.customerData,
    component: CustomerDataPage,
  },

  {
    breadcrumb: 'quotation',
    pageName: WizardPagesEnum.standaloneServicePlan,
    component: StandaloneServicePlan,
  },

  {
    breadcrumb: 'customer',
    pageName: WizardPagesEnum.amlDocuments,
    component: DocumentUploadPage,
  },

  {
    breadcrumb: 'review',
    pageName: WizardPagesEnum.creditCheck,
    component: CreditCheckPage,
    headingTranslationKey: 'creditCheckPage:pageHeader',
  },
];

if (isTestStage) {
  /* eslint-disable no-console */
  console.groupCollapsed('Wizard sub-pages:');
  console.table(newQuotationWizardPages.map(({ pageName }) => ({ pageName })));
  console.groupEnd();
  console.groupCollapsed('Credit check statuses:');
  console.table(getEnumValues(CreditCheckStatusEnum));
  console.groupEnd();
  /* eslint-enable no-console */
}

export const newQuotationWizardBreadcrumbs: string[] = (() => {
  return newQuotationWizardPages.reduce((breadcrumbs, { breadcrumb }) => {
    if (!breadcrumbs.includes(breadcrumb)) {
      breadcrumbs.push(breadcrumb);
    }
    return [...breadcrumbs];
  }, [] as string[]);
})();

interface NewQuotationState {
  wizard: {
    currentPageName?: WizardPagesEnum;
    navigateToCurrent: boolean;
    navigating: boolean;
  };
}

const initialState: NewQuotationState = {
  wizard: {
    currentPageName: undefined,
    navigateToCurrent: false,
    navigating: false,
  },
};

export const getWizardPageOfName = (pageName?: WizardPagesEnum) => {
  return newQuotationWizardPages.find(({ pageName: pageId }) => pageId === pageName) ?? newQuotationWizardPages[0];
};

const name = 'newQuotation';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    /**
     * - ⚠ Only for use in wizard component.
     * @inner
     */
    setCurrentPage: (state, action: PayloadAction<WizardPagesEnum>) => {
      state.wizard.currentPageName = action.payload;
    },
    wizardNavigateToPage: (state, action: PayloadAction<WizardPagesEnum>) => {
      state.wizard.currentPageName = action.payload;
      state.wizard.navigateToCurrent = true;
    },
    startNavigation: (state) => {
      state.wizard.navigating = true;
    },
    endNavigation: (state) => {
      state.wizard.navigating = false;
      state.wizard.navigateToCurrent = false;
    },
    wizardNextPage: (state) => {
      if (newQuotationWizardPages.at(-1)?.pageName === state.wizard.currentPageName) return;

      const currentIndex = newQuotationWizardPages.findIndex(
        ({ pageName }) => pageName === state.wizard.currentPageName
      );
      state.wizard.currentPageName = newQuotationWizardPages.at(currentIndex + 1)?.pageName;
      state.wizard.navigateToCurrent = true;
    },
    wizardPrevPage: (state) => {
      if (newQuotationWizardPages[0]?.pageName === state.wizard.currentPageName) return;

      const currentIndex = newQuotationWizardPages.findIndex(
        ({ pageName }) => pageName === state.wizard.currentPageName
      );
      state.wizard.currentPageName = newQuotationWizardPages.at(currentIndex - 1)?.pageName;
      state.wizard.navigateToCurrent = true;
    },
    wizardFinishNavigation: (state) => {
      state.wizard.navigateToCurrent = false;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDefinition = createGettersDefinition({
  reactive: {
    currentPageName({ newQuotation }) {
      return newQuotation.wizard.currentPageName;
    },
    navigateToCurrent({ newQuotation }) {
      return newQuotation.wizard.navigateToCurrent;
    },
    isNavigating({ newQuotation }) {
      return newQuotation.wizard.navigating;
    },
  },
});

export const newQuotationGettersDefinition = gettersDefinition;
export const newQuotationSlice = slice;
