import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { ProductFamilySelectionFormStateModel } from './productFamilySelectionPageModel';

interface ProductFamilySelectionFormState
  extends ReduxStateWithForms<{
    productFamilySelection: ReduxTrackedForm<ProductFamilySelectionFormStateModel>;
  }> {}

const productFamilySelectionFormStateInitialValues: ProductFamilySelectionFormStateModel = {
  productFamily: null,
};

const initialState: ProductFamilySelectionFormState = {
  forms: {
    productFamilySelection: {
      ...createReduxTrackedFormDefaults<ProductFamilySelectionFormStateModel>({
        initialValues: productFamilySelectionFormStateInitialValues,
      }),
      values: {
        ...productFamilySelectionFormStateInitialValues,
      },
    },
  },
};

const name = 'productFamilySelectionPage';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<ProductFamilySelectionFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    productFamilySelectionForm({ productFamilySelectionPage }) {
      return productFamilySelectionPage.forms.productFamilySelection;
    },
    productFamily({ productFamilySelectionPage }) {
      return productFamilySelectionPage.forms.productFamilySelection.values.productFamily;
    },
  },
});

export const productFamilySelectionPageSlice = slice;
export const productFamilySelectionPageGettersDef = gettersDef;
