import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import { FlowTypeCheckerFn, getEnumValues, getEnumValuesNullable } from '../../services/common/miscUtils';
import Yup, {
  maxLandlineNumberLength,
  maxMobileNumberLength,
  minLandlineNumberLength,
  minMobileNumberLength,
  regExEmail,
} from '../../services/common/validation';
import { CorrespondenceMethodEnum, CountryCodeEnum, NoYesEnum, TelephoneTypeEnum } from '../../types/forms';
import {
  CcrTypeEnum,
  CustomerDetailsFormModel,
  GenderEnum,
  MaritalStatusEnum,
  TitleEnum,
} from './customerDetailsForm.model';

const checkPPSN = (number) => {
  const validationRegex = /^(\d{7})([A-Z]{1,2})$/i;

  if (!validationRegex.test(number)) return false;

  const numericPart = RegExp.$1;
  const checksumCharacter = RegExp.$2;
  let multiplyingFactor = 8;
  let sum = 0;

  for (let i = 0; i < numericPart.length; i += 1) {
    sum += Number(numericPart[i]) * multiplyingFactor;
    multiplyingFactor -= 1;
  }

  if (RegExp.$2[1]) {
    sum += (RegExp.$2[1].toUpperCase().charCodeAt(0) - 64) * 9;
  }

  const checksum = sum % 23;

  if (
    checksum === 0
      ? checksum + 87 !== checksumCharacter.toUpperCase().charCodeAt(0)
      : checksum + 64 !== checksumCharacter.toUpperCase().charCodeAt(0)
  ) {
    return false;
  }
  return true;
};

export const createCustomerDetailsFormValidator = (
  customerCheckerFn: FlowTypeCheckerFn
): ObjectSchema<CustomerDetailsFormModel> => {
  const testCcrType = customerCheckerFn('consumer', 'soleTrader');
  const testCcrId = customerCheckerFn('consumer', 'soleTrader');
  const testMaritalStatus = customerCheckerFn('consumer', 'soleTrader', 'partnership');

  return Yup.object({
    gender: Yup.string().required(t('validation:required')).oneOf(getEnumValues(GenderEnum)),
    title: Yup.string().required(t('validation:required')).oneOf(getEnumValues(TitleEnum)),
    maritalStatus: testMaritalStatus
      ? Yup.string().required(t('validation:required')).oneOf(getEnumValues(MaritalStatusEnum))
      : Yup.string().notRequired().oneOf(getEnumValuesNullable(MaritalStatusEnum)),
    makeIndemnifier: Yup.string().notRequired().oneOf(getEnumValuesNullable(NoYesEnum)),
    firstName: Yup.string().required(t('validation:required')).unaccentedLetters().lengthBetween(0, 64),
    lastName: Yup.string().required(t('validation:required')).unaccentedLetters().lengthBetween(0, 64),
    middleName: Yup.string().notRequired().unaccentedLetters().lengthBetween(0, 64),
    maidenName: Yup.string()
      .unaccentedLetters()
      .when(['gender', 'maritalStatus'], {
        is: (gender: GenderEnum, maritalStatus: MaritalStatusEnum) => {
          const isFemale = gender === GenderEnum.female;
          const isOrWasMarried = [
            MaritalStatusEnum.married,
            MaritalStatusEnum.divorced,
            MaritalStatusEnum.separated,
          ].includes(maritalStatus);
          return isFemale && isOrWasMarried;
        },
        then: (schema) => schema.required(t('validation:required')),
        otherwise: (schema) => schema.notRequired(),
      })
      .lengthBetween(0, 64),
    dateOfBirth: Yup.string().required(t('validation:required')),
    email: Yup.string().required(t('validation:required')).matches(regExEmail, t('validation:invalidEmail')),
    telephoneType: Yup.string().required(t('validation:required')).oneOf(getEnumValues(TelephoneTypeEnum)),
    phoneNumber: Yup.string()
      .requiredWithMsg()
      .when(['telephoneType'], {
        is: (telephoneType: TelephoneTypeEnum) => {
          return telephoneType === TelephoneTypeEnum.mobile;
        },
        then: (schema) => schema.lengthBetween(minMobileNumberLength, maxMobileNumberLength),
        otherwise: (schema) => schema.lengthBetween(minLandlineNumberLength, maxLandlineNumberLength),
      }),
    countryCode: Yup.string().requiredWithMsg().oneOf(getEnumValues(CountryCodeEnum)),
    correspondenceMethod: Yup.string()
      .required(t('validation:required'))
      .oneOf(getEnumValues(CorrespondenceMethodEnum)),
    ccrType: testCcrType
      ? Yup.string().required(t('validation:required')).oneOf(getEnumValues(CcrTypeEnum))
      : Yup.string().notRequired().oneOf(getEnumValuesNullable(CcrTypeEnum)),
    ccrId: testCcrId
      ? Yup.string().required(t('validation:required')).test('Check PPSN', t('validation:invalidPPSN'), checkPPSN)
      : Yup.string().notRequired(),
  });
};
