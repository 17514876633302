import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { AddressFormModel } from '../AddressForm/addressForm.model';

export interface AddressFormWithPreviousFormState
  extends ReduxStateWithForms<{
    currentAddress1: ReduxTrackedForm<AddressFormModel>;
    previousAddress1: ReduxTrackedForm<AddressFormModel>;
    currentAddress2: ReduxTrackedForm<AddressFormModel>;
    previousAddress2: ReduxTrackedForm<AddressFormModel>;
  }> {}

const addressFormInitialValues: AddressFormModel = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  eirCode: '',
  town: '',
  county: '',
  residentialStatus: '',
  timeAtAddressYears: '',
  timeAtAddressMonths: '',
};

const initialState: AddressFormWithPreviousFormState = {
  forms: {
    currentAddress1: {
      ...createReduxTrackedFormDefaults<AddressFormModel>({ initialValues: addressFormInitialValues }),
      values: {
        ...addressFormInitialValues,
      },
    },
    previousAddress1: {
      ...createReduxTrackedFormDefaults<AddressFormModel>({ excluded: true, initialValues: addressFormInitialValues }),
      values: {
        ...addressFormInitialValues,
      },
    },
    currentAddress2: {
      ...createReduxTrackedFormDefaults<AddressFormModel>({ excluded: true, initialValues: addressFormInitialValues }),
      values: {
        ...addressFormInitialValues,
      },
    },
    previousAddress2: {
      ...createReduxTrackedFormDefaults<AddressFormModel>({ excluded: true, initialValues: addressFormInitialValues }),
      values: {
        ...addressFormInitialValues,
      },
    },
  },
};

const name = 'addressWithPreviousForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<AddressFormWithPreviousFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    currentAddress1Triggers({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.currentAddress1.triggers;
    },
    previousAddress1Triggers({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.previousAddress1.triggers;
    },
    previousAddress1Excluded({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.previousAddress1.excluded;
    },
    currentAddress2Triggers({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.currentAddress2.triggers;
    },
    previousAddress2Triggers({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.previousAddress2.triggers;
    },
    previousAddress2Excluded({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.previousAddress2.excluded;
    },
    currentAddress1Values({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.currentAddress1.values;
    },
    previousAddress1Values({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.previousAddress1.values;
    },
    currentAddress2Values({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.currentAddress2.values;
    },
    previousAddress2Values({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms.previousAddress2.values;
    },
    forms({ addressWithPreviousForm }) {
      return addressWithPreviousForm.forms;
    },
  },
});

export const addressWithPreviousFormSlice = slice;
export const addressWithPreviousFormGettersDef = gettersDef;
