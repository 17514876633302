import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, memo, useEffect, useMemo, useState } from 'react';
import { ObjectSchema } from 'yup';
import { formAutocomplete } from '../../config';
import { createOptionsList, getPlaceholderOption } from '../../services/common/miscUtils';
import {
  getNaceCodesOptionsListBySection,
  naceCodesOptionsList,
  naceSectionsOptionsList,
} from '../../services/common/naceCodes';
import {
  checkIfEnterpriseSizeIsOptional,
  getMaxDateOfIncorporationDate,
  landlineNumberMask,
  maxLandlineNumberLength,
  maxMobileNumberLength,
  mobileNumberMask,
} from '../../services/common/validation';
import { actions, getters, useGetters } from '../../services/redux';
import {
  CorrespondenceMethodEnum,
  CountryCodeEnum,
  EnterpriseSizeEnum,
  EntityFormEnum,
  InstitutionalTypeEnum,
  TelephoneTypeEnum,
} from '../../types/forms';
import { AddressFormGeneric } from '../AddressFormGeneric/AddressFormGeneric';
import { getAddressFormValidationSchema } from '../AddressFormGeneric/addressFormGeneric.validator';
import { DatePickerFormField } from '../Fieldset/DatePickerFormField';
import { NumericInputFormField } from '../Fieldset/NumericInputFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { TextareaInputFormField } from '../Fieldset/TextareaInputFormField';
import { PersistFormInState, setFieldValueInitial } from '../PersistFormInState/PersistFormInState';
import { DropdownOption } from '../UsedCarSelectionForm/UsedCarSelectionFormModels';
import {
  TradingDetailsFormAddressModelFields,
  TradingDetailsFormModel,
  TradingDetailsFormModelFields,
} from './tradingDetailsForm.model';
import { TradingDetailsFormState } from './tradingDetailsForm.slice';
import { createTradingDetailsFormValidator } from './tradingDetailsForm.validator';

const createFormInputs = ((disableAllFormControls: boolean) => {
  return {
    entityForm: () => (
      <SelectFormField
        fieldName="entityForm"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:entityForm:label"
        optionList={createOptionsList(EntityFormEnum, 'tradingDetailsForm', 'entityForm')}
      />
    ),
    businessType: (options: Array<DropdownOption>) => (
      <SelectFormField
        fieldName="businessType"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:businessType:label"
        optionList={options}
      />
    ),
    businessSubType: (options: Array<DropdownOption>) => (
      <SelectFormField
        fieldName="businessSubType"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:businessSubType:label"
        optionList={options}
      />
    ),
    institutionalType: () => (
      <SelectFormField
        fieldName="institutionalType"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:institutionalType:label"
        optionList={createOptionsList(InstitutionalTypeEnum, 'tradingDetailsForm', 'institutionalType')}
      />
    ),
    enterpriseSize: (optional: boolean) => (
      <SelectFormField
        fieldName="enterpriseSize"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:enterpriseSize:label"
        optionList={createOptionsList(EnterpriseSizeEnum, 'tradingDetailsForm', 'enterpriseSize')}
        optional={optional}
      />
    ),
    correspondenceMethod: () => (
      <SelectFormField
        fieldName="correspondenceMethod"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:correspondenceMethod:label"
        optionList={createOptionsList(CorrespondenceMethodEnum, 'tradingDetailsForm', 'correspondenceMethod')}
      />
    ),
    telephoneType: () => (
      <SelectFormField
        fieldName="telephoneType"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:telephoneType:label"
        optionList={createOptionsList(TelephoneTypeEnum, 'tradingDetailsForm', 'telephoneType')}
      />
    ),
    countryCode: () => (
      <SelectFormField
        fieldName="countryCode"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:countryCode:label"
        optionList={createOptionsList(CountryCodeEnum, 'tradingDetailsForm', 'countryCode')}
      />
    ),
    tradingAs: () => (
      <TextInputFormField
        fieldName="tradingAs"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:tradingAs:label"
        placeholderTranslationKey="tradingDetailsForm:fields:tradingAs:placeholder"
      />
    ),
    dateOfIncorporation: () => (
      <DatePickerFormField
        fieldName="dateOfIncorporation"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:dateOfIncorporation:label"
        placeholderTranslationKey="tradingDetailsForm:fields:dateOfIncorporation:placeholder"
        maxDate={getMaxDateOfIncorporationDate()}
      />
    ),
    vatNumber: () => (
      <TextInputFormField
        fieldName="vatNumber"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:vatNumber:label"
        placeholderTranslationKey="tradingDetailsForm:fields:vatNumber:placeholder"
        optional
      />
    ),
    annualProfitCurrentYear: () => (
      <NumericInputFormField
        fieldName="annualProfitCurrentYear"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:annualProfitCurrentYear:label"
        placeholderTranslationKey="tradingDetailsForm:fields:annualProfitCurrentYear:placeholder"
        type="money"
        maximumFractionDigits={2}
      />
    ),
    annualProfitPreviousYear: () => (
      <NumericInputFormField
        fieldName="annualProfitPreviousYear"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:annualProfitPreviousYear:label"
        placeholderTranslationKey="tradingDetailsForm:fields:annualProfitCurrentYear:placeholder"
        type="money"
        maximumFractionDigits={2}
      />
    ),
    currentBusinessContracts: () => (
      <TextareaInputFormField
        fieldName="currentBusinessContracts"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:currentBusinessContracts:label"
        placeholderTranslationKey="tradingDetailsForm:fields:currentBusinessContracts:placeholder"
        maxCounter={255}
        charCounter
      />
    ),
    currentFinancialObligations: () => (
      <TextareaInputFormField
        fieldName="currentFinancialObligations"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:currentFinancialObligations:label"
        placeholderTranslationKey="tradingDetailsForm:fields:currentFinancialObligations:placeholder"
        maxCounter={255}
        charCounter
      />
    ),
    businessHistory: () => (
      <TextareaInputFormField
        fieldName="businessHistory"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:businessHistory:label"
        placeholderTranslationKey="tradingDetailsForm:fields:businessHistory:placeholder"
        maxCounter={255}
        charCounter
      />
    ),
    phoneNumber: (selectedMobileTelephoneType: boolean) => {
      return selectedMobileTelephoneType ? (
        <TextInputFormField
          fieldName="phoneNumber"
          isDisabled={disableAllFormControls}
          labelTranslationKey="tradingDetailsForm:fields:mobileNumber:label"
          placeholderTranslationKey="tradingDetailsForm:fields:mobileNumber:placeholder"
          mask={mobileNumberMask}
          maskedValueToFormValue={(maskedValue) => maskedValue.replace(/[^\d]/g, '')}
        />
      ) : (
        <TextInputFormField
          fieldName="phoneNumber"
          isDisabled={disableAllFormControls}
          labelTranslationKey="tradingDetailsForm:fields:telephoneNumber:label"
          placeholderTranslationKey="tradingDetailsForm:fields:telephoneNumber:placeholder"
          mask={landlineNumberMask}
          maskedValueToFormValue={(maskedValue) => maskedValue.replace(/[^\d]/g, '')}
          optional
        />
      );
    },
    companyEmail: () => (
      <TextInputFormField
        fieldName="companyEmail"
        isDisabled={disableAllFormControls}
        labelTranslationKey="tradingDetailsForm:fields:companyEmail:label"
        placeholderTranslationKey="tradingDetailsForm:fields:companyEmail:placeholder"
      />
    ),
  };
}) satisfies (
  disableAllFormControls: boolean
) => Record<
  Exclude<TradingDetailsFormModelFields, TradingDetailsFormAddressModelFields>,
  (...args: any[]) => ReactElement
>;

const formName = 'tradingDetails';

const addressFormFieldsToUse: TradingDetailsFormAddressModelFields[] = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'town',
  'county',
  'eirCode',
];

// eslint-disable-next-line react/display-name
export const TradingDetailsForm: FunctionComponent = memo(() => {
  const [initialValues /* , setInitialValues */] = useState(() => getters.tradingDetailsForm.tradingDetailsValues);
  const [_setFieldValue, _setSetFieldValue] = useState(setFieldValueInitial<TradingDetailsFormModel>);
  const currentValues = useGetters().tradingDetailsForm.tradingDetailsValues;
  const shouldValidate = useGetters().tradingDetailsForm.tradingDetailsTriggers.validation;
  const shouldReset = useGetters().tradingDetailsForm.tradingDetailsTriggers.reset;
  const valuesToResetTo = getters.tradingDetailsForm.forms.tradingDetails.initialValues;

  const [businessTypeOptions] = useState(() => [
    getPlaceholderOption('tradingDetailsForm', 'businessType'),
    ...naceSectionsOptionsList,
  ]);
  const [businessSubTypeOptions, setBusinessSubTypeOptions] = useState(() =>
    currentValues.businessType
      ? [
          getPlaceholderOption('tradingDetailsForm', 'businessSubType'),
          ...getNaceCodesOptionsListBySection(currentValues.businessType),
        ]
      : [getPlaceholderOption('tradingDetailsForm', 'businessSubType'), ...naceCodesOptionsList]
  );
  const [validationSchema] = useState<ObjectSchema<TradingDetailsFormModel>>(() =>
    createTradingDetailsFormValidator().concat(getAddressFormValidationSchema(addressFormFieldsToUse))
  );

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const inputs = useMemo(() => createFormInputs(disableAllFormControls), [disableAllFormControls]);

  const selectedMobileTelephoneType = currentValues.telephoneType === TelephoneTypeEnum.mobile;
  const selectedLandlineTelephoneType = currentValues.telephoneType === TelephoneTypeEnum.landline;

  // Reset phone number then phone type changed.
  useEffect(() => {
    if (selectedMobileTelephoneType) {
      _setFieldValue('phoneNumber', currentValues.phoneNumber?.slice(0, maxMobileNumberLength));
    } else if (selectedLandlineTelephoneType) {
      _setFieldValue('phoneNumber', currentValues.phoneNumber?.slice(0, maxLandlineNumberLength));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues.telephoneType]);

  // Reset business sub type if business type changed, and sub type is no longer valid.
  useEffect(() => {
    const newBusinessSubTypeOptions = currentValues.businessType
      ? [
          getPlaceholderOption('tradingDetailsForm', 'businessSubType'),
          ...getNaceCodesOptionsListBySection(currentValues.businessType),
        ]
      : [getPlaceholderOption('tradingDetailsForm', 'businessSubType'), ...naceCodesOptionsList];

    setBusinessSubTypeOptions(newBusinessSubTypeOptions);

    if (newBusinessSubTypeOptions.some(({ value }) => value === currentValues.businessSubType)) return;
    _setFieldValue('businessSubType', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues.businessType]);

  const enterpriseSizeOptional = checkIfEnterpriseSizeIsOptional(currentValues.institutionalType);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      {/* Disable prettier to keep template compact and readable. */}
      {/* prettier-ignore */}
      <Form autoComplete={formAutocomplete}>
        <PersistFormInState<TradingDetailsFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          shouldReset={shouldReset}
          updateForm={actions.tradingDetailsForm.updateForm}
          setFormFieldSetter={_setSetFieldValue}
          valuesToResetTo={valuesToResetTo}
        />
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3">
            <Button disabled={disableAllFormControls} type="button" secondary icon="address-book" ariaLabel="Search existing customer" onClick={() => ''}>
              {t('tradingDetailsForm:searchExistingCustomer')}
            </Button>
            </Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.tradingAs()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.businessType(businessTypeOptions)}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.businessSubType(businessSubTypeOptions)}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.institutionalType()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.entityForm()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.dateOfIncorporation()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.enterpriseSize(enterpriseSizeOptional)}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.vatNumber()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.annualProfitCurrentYear()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.annualProfitPreviousYear()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/1" s="1/1">{inputs.currentBusinessContracts()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.currentFinancialObligations()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.businessHistory()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.telephoneType()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.countryCode()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.phoneNumber(selectedMobileTelephoneType)}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/3" s="1/1">{inputs.correspondenceMethod()}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{}</Layout.Item>
            <Layout.Item default="1/3" s="1/1">{inputs.companyEmail()}</Layout.Item>
          </Layout>
        </Fieldset.Row>
        <AddressFormGeneric
          initialFormValue={currentValues}
          formTranslationKey='tradingDetailsForm'
          fieldsToRender={addressFormFieldsToUse}
        />
      </Form>
    </Formik>
  );
});
