import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { CorrespondenceMethodEnum } from '../../types/forms';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { CompanyDetailsFormModel } from './companyDetailsForm.model';

export interface CompanyDetailsFormState
  extends ReduxStateWithForms<{
    companyDetails: ReduxTrackedForm<CompanyDetailsFormModel>;
  }> {}

const companyDetailsInitialValues: CompanyDetailsFormModel = {
  identityType: undefined,
  identityNumber: '',
  companyRegistrationNumber: undefined,
  registeredPartnershipName: '',
  entityForm: undefined,
  businessType: undefined,
  businessSubType: undefined,
  institutionalType: undefined,
  enterpriseSize: undefined,
  telephoneType: undefined,
  countryCode: undefined,
  correspondenceMethod: CorrespondenceMethodEnum.email,
  dateOfIncorporation: undefined,
  vatNumber: '',
  annualProfitCurrentYear: undefined,
  annualProfitPreviousYear: undefined,
  currentBusinessContracts: undefined,
  businessHistory: undefined,
  phoneNumber: undefined,
  companyEmail: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  eirCode: '',
  town: '',
  county: '',
  contactFirstName: '',
  contactLastName: '',
  contactPosition: '',
  contactCountryCode: undefined,
  contactPhoneNumber: '',
};

const initialState: CompanyDetailsFormState = {
  forms: {
    companyDetails: {
      ...createReduxTrackedFormDefaults<CompanyDetailsFormModel>({ initialValues: companyDetailsInitialValues }),
      values: {
        ...companyDetailsInitialValues,
      },
    },
  },
};

const name = 'companyDetailsForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<CompanyDetailsFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    companyDetailsValidationTrigger({ companyDetailsForm }) {
      return companyDetailsForm.forms.companyDetails.triggers.validation;
    },
    companyDetailsValues({ companyDetailsForm }) {
      return companyDetailsForm.forms.companyDetails.values;
    },
    forms({ companyDetailsForm }) {
      return companyDetailsForm.forms;
    },
  },
});

export const companyDetailsFormSlice = slice;
export const companyDetailsFormGettersDef = gettersDef;
