import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { brandType } from '../../../config';
import { RootState } from '../../redux';

const { REACT_APP_COUNTRY_TYPECODE, REACT_APP_PRODUCT_TYPE, REACT_APP_ENTRY_POINT_TYPE } = process.env;

// All properties must me optional due to bug https://github.com/reduxjs/redux-toolkit/issues/1087
interface ExtraOptions {
  applyProductPath: boolean;
  dataStorageId: string;
  secondaryPath: string;
}

type FesBaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  Partial<ExtraOptions>,
  FetchBaseQueryMeta
>;

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_GATEWAY,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth?.token;

    if (token) {
      headers.set('x-auth-token', token);
    }

    return headers;
  },
});

const baseQueryFesRequest: FesBaseQuery = (args, api, extraOptions = {}) => {
  const { applyProductPath = true, dataStorageId = '', secondaryPath = '' } = extraOptions;

  const productPath = applyProductPath
    ? `/${REACT_APP_PRODUCT_TYPE}/${REACT_APP_COUNTRY_TYPECODE}/${brandType}/${REACT_APP_ENTRY_POINT_TYPE}`
    : '';
  const transactionPath = dataStorageId ? `/${dataStorageId}` : '';

  let fullUrl = '';

  if (typeof args === 'string') {
    fullUrl = `${args}${productPath}${transactionPath}${secondaryPath}`;

    return baseQuery(args, api, extraOptions);
  }

  fullUrl = `${args.url}${productPath}${transactionPath}${secondaryPath}`;
  const cloneArgs = args;
  cloneArgs.url = fullUrl;

  return baseQuery(cloneArgs, api, extraOptions);
};

export default baseQueryFesRequest;
