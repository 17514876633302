import {
  Button,
  ButtonContainer,
  ButtonGroup,
  Card,
  Fieldset,
  FormHeading,
  Hr,
  Layout,
} from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { UsedCarSelectionForm } from '../../../components';
import UsedCarSearchForm from '../../../components/UsedCarSearchForm/UsedCarSearchForm';
import { useFlowTypeChecker } from '../../../services/common/miscUtils';
import { actions, useGetters } from '../../../services/redux';
import { useNavigate } from '../../../services/routing';
import { WizardPagesEnum } from '../newQuotation.slice';
import { UsedCarFormTypesEnum } from './UsedCarFormSelectorPage.model';

const UsedCarFormSelectorPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const flowTypeIs = useFlowTypeChecker();

  const usedCarSearchFormState = useGetters().usedCarFormSelectorPage.usedCarSearchForm;
  const usedCarSelectFormState = useGetters().usedCarFormSelectorPage.usedCarSelectionForm;
  const usedCarSelectionSelectedMethod = useGetters().usedCarFormSelectorPage.usedCarSearchSelectedMethod;

  return (
    <>
      <Card element="div">
        <Fieldset>
          <Fieldset.Row>
            <FormHeading>{t('usedCarFormSelectorPage:formHeading')}</FormHeading>
          </Fieldset.Row>
          <Fieldset.Row>
            <ButtonGroup buttonGroupName="formSelector" buttonGroupType="radio">
              <React.Fragment key=".0">
                <ButtonGroup.Item
                  index="1"
                  onChange={() =>
                    actions.usedCarFormSelectorPage.changeCarSearchMethod({
                      selectedMethod: UsedCarFormTypesEnum.Search,
                    })
                  }
                  checked={usedCarSelectionSelectedMethod === UsedCarFormTypesEnum.Search}
                  testId="button_usedCarSearch"
                >
                  {t('usedCarFormSelector:formSelector.usedCarSearch')}
                </ButtonGroup.Item>
                <ButtonGroup.Item
                  index="2"
                  onChange={() =>
                    actions.usedCarFormSelectorPage.changeCarSearchMethod({
                      selectedMethod: UsedCarFormTypesEnum.Selection,
                    })
                  }
                  checked={usedCarSelectionSelectedMethod === UsedCarFormTypesEnum.Selection}
                  testId="button_usedCarSelection"
                >
                  {t('usedCarFormSelector:formSelector.usedCarSelection')}
                </ButtonGroup.Item>
              </React.Fragment>
            </ButtonGroup>

            <Hr className="u-mt" />

            <div className={usedCarSelectionSelectedMethod === UsedCarFormTypesEnum.Search ? '' : 'u-hide'}>
              <UsedCarSearchForm />
            </div>

            <div className={usedCarSelectionSelectedMethod === UsedCarFormTypesEnum.Selection ? '' : 'u-hide'}>
              <UsedCarSelectionForm />
            </div>
          </Fieldset.Row>
        </Fieldset>
      </Card>
      <Layout center className="u-mt">
        <ButtonContainer center>
          {usedCarSelectionSelectedMethod === UsedCarFormTypesEnum.Search && (
            <>
              <Layout.Item default="1/4">
                <Button
                  testId="button_goBack"
                  secondary
                  full
                  onClick={() => {
                    navigate.vehicleSelect();
                  }}
                >
                  {t('usedCarFormSelectorPage:navigation.back')}
                </Button>
              </Layout.Item>

              <Layout.Item default="1/4">
                <Button
                  testId="button_continue"
                  full
                  disabled={!usedCarSearchFormState.values.brand}
                  onClick={() => {
                    if (flowTypeIs('servicePlan')) {
                      navigate.newQuote(`/${WizardPagesEnum.standaloneServicePlan}`);
                    } else {
                      actions.newQuotation.wizardNextPage();
                    }
                  }}
                >
                  {t('usedCarFormSelectorPage:navigation.continue')}
                </Button>
              </Layout.Item>
            </>
          )}

          {usedCarSelectionSelectedMethod === UsedCarFormTypesEnum.Selection && (
            <>
              <Layout.Item default="1/4">
                <Button
                  testId="button_goBack"
                  full
                  secondary
                  onClick={() => {
                    actions.usedCarFormSelectorPage.changeCarSearchMethod({
                      selectedMethod: UsedCarFormTypesEnum.Search,
                    });
                  }}
                >
                  {t('usedCarFormSelectorPage:navigation.back')}
                </Button>
              </Layout.Item>

              <Layout.Item default="1/4">
                <Button
                  disabled={!usedCarSelectFormState.isValid}
                  full
                  onClick={() => {
                    if (flowTypeIs('servicePlan')) {
                      navigate.newQuote(`/${WizardPagesEnum.standaloneServicePlan}`);
                    } else {
                      actions.newQuotation.wizardNextPage();
                    }
                  }}
                >
                  {t('usedCarFormSelectorPage:navigation.continue')}
                </Button>
              </Layout.Item>
            </>
          )}
        </ButtonContainer>
      </Layout>
    </>
  );
};

export default UsedCarFormSelectorPage;
