import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import { getEnumValues } from '../../services/common/miscUtils';
import Yup from '../../services/common/validation';
import {
  OccupationEnum,
  TimeAtEmployerMonthsEnum,
  TimeAtEmployerYearsEnum,
} from '../EmploymentDetailsForm/employmentDetailsForm.model';
import { PreviousEmploymentDetailsFormModel } from './previousEmploymentDetailsForm.model';

export const previousEmploymentDetailsFormSchema = (): ObjectSchema<PreviousEmploymentDetailsFormModel> => {
  return Yup.object({
    employer: Yup.string().required(t('validation:required')),
    occupation: Yup.string().required(t('validation:required')).oneOf(getEnumValues(OccupationEnum)),
    occupationNotes: Yup.string().optional().notRequired(),
    addressLine1: Yup.string()
      .matches(/^[a-zA-Z0-9 ]*$/, t('validation:onlyLettersAndNumbers'))
      .required(t('validation:required')),
    addressLine2: Yup.string()
      .matches(/^[a-zA-Z0-9 ]*$/, t('validation:onlyLettersAndNumbers'))
      .notRequired(),
    addressLine3: Yup.string()
      .matches(/^[a-zA-Z0-9 ]*$/, t('validation:onlyLettersAndNumbers'))
      .notRequired(),
    eirCode: Yup.string().eirCode(t('validation:invalidEircode')).required(t('validation:required')),
    town: Yup.string()
      .matches(/^[a-zA-Z0-9 ]*$/, t('validation:onlyLettersAndNumbers'))
      .required(t('validation:required')),
    county: Yup.string().required(t('validation:required')),
    timeAtEmployerYears: Yup.string().required(t('validation:required')).oneOf(getEnumValues(TimeAtEmployerYearsEnum)),
    timeAtEmployerMonths: Yup.string()
      .required(t('validation:required'))
      .oneOf(getEnumValues(TimeAtEmployerMonthsEnum)),
  });
};
