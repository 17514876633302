import { Select } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { FunctionComponent, useState } from 'react';
import { FormField } from '..';
import { DropdownOption, FormFieldPropsBase } from './fieldset.model';

interface SelectFormFieldProps extends FormFieldPropsBase {
  optionList: Array<DropdownOption>;
  defaultValue?: string;
  /**
   * - By default only first item of the list (placeholder) is rendered or current selection.
   * - Set to true to render all items even if list was not interacted with.
   */
  eagerListRender?: boolean;
}

export const SelectFormField: FunctionComponent<SelectFormFieldProps> = ({
  optionList,
  labelTranslationKey = '',
  isDisabled,
  fieldName,
  testId,
  optional,
  defaultValue,
  eagerListRender,
}): React.JSX.Element => {
  const [showEntireList, setShowEntireList] = useState(() => eagerListRender);

  const getFirstItemFromOptionList = (): DropdownOption => {
    if (optionList && optionList.length > 0) {
      return optionList[0];
    }

    return { value: '', label: t('formControls:select.loading') };
  };

  return (
    <FormField
      type="select"
      name={fieldName}
      testId={testId ?? fieldName}
      labelText={`${t(labelTranslationKey)}${optional ? t('formControls:optionalLabelAffix') : ''}`}
      showEntireList={showEntireList}
      isDisabled={isDisabled}
      validateOnChange
      optionList={optionList}
      render={(fieldProps) => (
        <Select
          {...fieldProps}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          testId={testId}
          defaultValue={defaultValue}
          onMouseDownCapture={() => {
            if (eagerListRender) return;
            // eslint-disable-next-line no-console
            console.warn('>> onMouseDownCapture triggered. State will update and full list of items rendered.');
            setShowEntireList(true);
          }}
          onBlurCapture={() => {
            if (eagerListRender) return;
            setShowEntireList(false);
          }}
        >
          {(showEntireList
            ? optionList
            : // Show either current selection or first element that should be placeholder
              [optionList.find(({ value: _value }) => _value === fieldProps.value) ?? getFirstItemFromOptionList()]
          ).map(({ label, value }) => {
            return (
              <Select.Item key={`${label}`} value={`${value}`}>
                {label}
              </Select.Item>
            );
          })}
        </Select>
      )}
    />
  );
};
