import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../../types/reduxTrackedForm';
import { BusinessCustomerTypeFormModel, BusinessCustomerTypesEnum } from './customerDataPage.model';

export interface CustomerDataPageState
  extends ReduxStateWithForms<{
    businessCustomerTypeForm: ReduxTrackedForm<BusinessCustomerTypeFormModel>;
  }> {
  focusOnError: boolean;
}

const businessCustomerTypeFormInitialValues = {
  selectedBusinessCustomerType: undefined,
};

const initialState: CustomerDataPageState = {
  focusOnError: false,
  forms: {
    businessCustomerTypeForm: {
      ...createReduxTrackedFormDefaults<BusinessCustomerTypeFormModel>({
        initialValues: businessCustomerTypeFormInitialValues,
      }),
      values: {
        ...businessCustomerTypeFormInitialValues,
      },
    },
  },
};

const defaultBusinessCustomerType = BusinessCustomerTypesEnum.soleTrader;

const name = 'customerDataPage';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<CustomerDataPageState>(),
    deselectBusinessCustomerType: (state) => {
      state.forms.businessCustomerTypeForm.values.selectedBusinessCustomerType = undefined;
    },
    selectDefaultBusinessCustomerType: (state) => {
      state.forms.businessCustomerTypeForm.values.selectedBusinessCustomerType = defaultBusinessCustomerType;
    },
    updateFocusOnError: (state, action: PayloadAction<{ state: boolean }>) => {
      state.focusOnError = action.payload.state;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    businessCustomerTypeForm({ customerDataPage }) {
      return customerDataPage.forms.businessCustomerTypeForm;
    },
    focusOnError({ customerDataPage }) {
      return customerDataPage.focusOnError;
    },
  },
});

export const customerDataPageSlice = slice;
export const customerDataPageGettersDef = gettersDef;
