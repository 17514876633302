/**
 * - NACE codes based on https://www.cro.ie/Portals/0/Notices/Nace%20code%202%20%20Sept%202019.pdf
 * - Raw text content of pdf available in src/resources/naceCodesFullText.txt
 * - We use NACE codes as `businessSubType` values.
 * - We use NACE sections names as `businessType` values.
 */
export const naceCodes = [
  {
    section: { letter: 'A', description: 'A — AGRICULTURE, FORESTRY AND FISHING' },
    codes: [
      { code: 'A01', description: '01 Crop and animal production, hunting and related service activities' },
      { code: 'A01.1', description: '01.1 Growing of non-perennial crops' },
      { code: 'A01.11', description: '01.11 Growing of cereals (except rice), leguminous crops and oil seeds 0111' },
      { code: 'A01.12', description: '01.12 Growing of rice 0112' },
      { code: 'A01.13', description: '01.13 Growing of vegetables and melons, roots and tubers 0113' },
      { code: 'A01.14', description: '01.14 Growing of sugar cane 0114' },
      { code: 'A01.15', description: '01.15 Growing of tobacco 0115' },
      { code: 'A01.16', description: '01.16 Growing of fibre crops 0116' },
      { code: 'A01.19', description: '01.19 Growing of other non-perennial crops 0119' },
      { code: 'A01.2', description: '01.2 Growing of perennial crops' },
      { code: 'A01.21', description: '01.21 Growing of grapes 0121' },
      { code: 'A01.22', description: '01.22 Growing of tropical and subtropical fruits 0122' },
      { code: 'A01.23', description: '01.23 Growing of citrus fruits 0123' },
      { code: 'A01.24', description: '01.24 Growing of pome fruits and stone fruits 0124' },
      { code: 'A01.25', description: '01.25 Growing of other tree and bush fruits and nuts 0125' },
      { code: 'A01.26', description: '01.26 Growing of oleaginous fruits 0126' },
      { code: 'A01.27', description: '01.27 Growing of beverage crops 0127' },
      { code: 'A01.28', description: '01.28 Growing of spices, aromatic, drug and pharmaceutical crops 0128' },
      { code: 'A01.29', description: '01.29 Growing of other perennial crops 0129' },
      { code: 'A01.3', description: '01.3 Plant propagation' },
      { code: 'A01.30', description: '01.30 Plant propagation 0130' },
      { code: 'A01.4', description: '01.4 Animal production' },
      { code: 'A01.41', description: '01.41 Raising of dairy cattle 0141*' },
      { code: 'A01.42', description: '01.42 Raising of other cattle and buffaloes 0141*' },
      { code: 'A01.43', description: '01.43 Raising of horses and other equines 0142' },
      { code: 'A01.44', description: '01.44 Raising of camels and camelids 0143' },
      { code: 'A01.45', description: '01.45 Raising of sheep and goats 0144' },
      { code: 'A01.46', description: '01.46 Raising of swine/pigs 0145' },
      { code: 'A01.47', description: '01.47 Raising of poultry 0146' },
      { code: 'A01.49', description: '01.49 Raising of other animals 0149' },
      { code: 'A01.5', description: '01.5 Mixed farming' },
      { code: 'A01.50', description: '01.50 Mixed farming 0150' },
      { code: 'A01.6', description: '01.6 Support activities to agriculture and post-harvest crop activities' },
      { code: 'A01.61', description: '01.61 Support activities for crop production 0161' },
      { code: 'A01.62', description: '01.62 Support activities for animal production 0162' },
      { code: 'A01.63', description: '01.63 Post-harvest crop activities 0163' },
      { code: 'A01.64', description: '01.64 Seed processing for propagation 0164' },
      { code: 'A01.7', description: '01.7 Hunting, trapping and related service activities' },
      { code: 'A01.70', description: '01.70 Hunting, trapping and related service activities 0170' },
      { code: 'A02', description: '02 Forestry and logging' },
      { code: 'A02.1', description: '02.1 Silviculture and other forestry activities' },
      { code: 'A02.10', description: '02.10 Silviculture and other forestry activities 0210' },
      { code: 'A02.2', description: '02.2 Logging' },
      { code: 'A02.20', description: '02.20 Logging 0220' },
      { code: 'A02.3', description: '02.3 Gathering of wild growing non-wood products' },
      { code: 'A02.30', description: '02.30 Gathering of wild growing non-wood products 0230' },
      { code: 'A02.4', description: '02.4 Support services to forestry' },
      { code: 'A02.40', description: '02.40 Support services to forestry 0240' },
      { code: 'A03', description: '03 Fishing and aquaculture' },
      { code: 'A03.1', description: '03.1 Fishing' },
      { code: 'A03.11', description: '03.11 Marine fishing 0311' },
      { code: 'A03.12', description: '03.12 Freshwater fishing 0312' },
      { code: 'A03.2', description: '03.2 Aquaculture' },
      { code: 'A03.21', description: '03.21 Marine aquaculture 0321' },
      { code: 'A03.22', description: '03.22 Freshwater aquaculture 0322' },
    ],
  },
  {
    section: { letter: 'B', description: 'B — MINING AND QUARRYING' },
    codes: [
      { code: 'B05', description: '05 Mining of coal and lignite' },
      { code: 'B05.1', description: '05.1 Mining of hard coal' },
      { code: 'B05.10', description: '05.10 Mining of hard coal 0510' },
      { code: 'B05.2', description: '05.2 Mining of lignite' },
      { code: 'B05.20', description: '05.20 Mining of lignite 0520' },
      { code: 'B06', description: '06 Extraction of crude petroleum and natural gas' },
      { code: 'B06.1', description: '06.1 Extraction of crude petroleum' },
      { code: 'B06.10', description: '06.10 Extraction of crude petroleum 0610' },
      { code: 'B06.2', description: '06.2 Extraction of natural gas' },
      { code: 'B06.20', description: '06.20 Extraction of natural gas 0620' },
      { code: 'B07', description: '07 Mining of metal ores' },
      { code: 'B07.1', description: '07.1 Mining of iron ores' },
      { code: 'B07.10', description: '07.10 Mining of iron ores 0710' },
      { code: 'B07.2', description: '07.2 Mining of non-ferrous metal ores' },
      { code: 'B07.21', description: '07.21 Mining of uranium and thorium ores 0721' },
      { code: 'B07.29', description: '07.29 Mining of other non-ferrous metal ores 0729' },
      { code: 'B08', description: '08 Other mining and quarrying' },
      { code: 'B08.1', description: '08.1 Quarrying of stone, sand and clay' },
      {
        code: 'B08.11',
        description: '08.11 Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate 0810*',
      },
      { code: 'B08.12', description: '08.12 Operation of gravel and sand pits; mining of clays and kaolin 0810*' },
      { code: 'B08.9', description: '08.9 Mining and quarrying n.e.c.' },
      { code: 'B08.91', description: '08.91 Mining of chemical and fertiliser minerals 0891' },
      { code: 'B08.92', description: '08.92 Extraction of peat 0892' },
      { code: 'B08.93', description: '08.93 Extraction of salt 0893' },
      { code: 'B08.99', description: '08.99 Other mining and quarrying n.e.c. 0899' },
      { code: 'B09', description: '09 Mining support service activities' },
      { code: 'B09.1', description: '09.1 Support activities for petroleum and natural gas extraction' },
      { code: 'B09.10', description: '09.10 Support activities for petroleum and natural gas extraction 0910' },
      { code: 'B09.9', description: '09.9 Support activities for other mining and quarrying' },
      { code: 'B09.90', description: '09.90 Support activities for other mining and quarrying 0990' },
    ],
  },
  {
    section: { letter: 'C', description: 'C — MANUFACTURING' },
    codes: [
      { code: 'C10', description: '10 Manufacture of food products' },
      { code: 'C10.1', description: '10.1 Processing and preserving of meat and production of meat products' },
      { code: 'C10.11', description: '10.11 Processing and preserving of meat 1010*' },
      { code: 'C10.12', description: '10.12 Processing and preserving of poultry meat 1010*' },
      { code: 'C10.13', description: '10.13 Production of meat and poultry meat products 1010*' },
      { code: 'C10.2', description: '10.2 Processing and preserving of fish, crustaceans and molluscs' },
      { code: 'C10.20', description: '10.20 Processing and preserving of fish, crustaceans and molluscs 1020' },
      { code: 'C10.3', description: '10.3 Processing and preserving of fruit and vegetables' },
      { code: 'C10.31', description: '10.31 Processing and preserving of potatoes 1030*' },
      { code: 'C10.32', description: '10.32 Manufacture of fruit and vegetable juice 1030*' },
      { code: 'C10.39', description: '10.39 Other processing and preserving of fruit and vegetables 1030*' },
      { code: 'C10.4', description: '10.4 Manufacture of vegetable and animal oils and fats' },
      { code: 'C10.41', description: '10.41 Manufacture of oils and fats 1040*' },
      { code: 'C10.42', description: '10.42 Manufacture of margarine and similar edible fats 1040*' },
      { code: 'C10.5', description: '10.5 Manufacture of dairy products' },
      { code: 'C10.51', description: '10.51 Operation of dairies and cheese making 1050*' },
      { code: 'C10.52', description: '10.52 Manufacture of ice cream 1050*' },
      { code: 'C10.6', description: '10.6 Manufacture of grain mill products, starches and starch products' },
      { code: 'C10.61', description: '10.61 Manufacture of grain mill products 1061' },
      { code: 'C10.62', description: '10.62 Manufacture of starches and starch products 1062' },
      { code: 'C10.7', description: '10.7 Manufacture of bakery and farinaceous products' },
      { code: 'C10.71', description: '10.71 Manufacture of bread; manufacture of fresh pastry goods and cakes 1071*' },
      {
        code: 'C10.72',
        description: '10.72 Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes 1071*',
      },
      {
        code: 'C10.73',
        description: '10.73 Manufacture of macaroni, noodles, couscous and similar farinaceous products 1074',
      },
      { code: 'C10.8', description: '10.8 Manufacture of other food products' },
      { code: 'C10.81', description: '10.81 Manufacture of sugar 1072' },
      { code: 'C10.82', description: '10.82 Manufacture of cocoa, chocolate and sugar confectionery 1073' },
      { code: 'C10.83', description: '10.83 Processing of tea and coffee 1079*' },
      { code: 'C10.84', description: '10.84 Manufacture of condiments and seasonings 1079*' },
      { code: 'C10.85', description: '10.85 Manufacture of prepared meals and dishes 1075' },
      { code: 'C10.86', description: '10.86 Manufacture of homogenised food preparations and dietetic food 1079*' },
      { code: 'C10.89', description: '10.89 Manufacture of other food products n.e.c. 1079*' },
      { code: 'C10.9', description: '10.9 Manufacture of prepared animal feeds' },
      { code: 'C10.91', description: '10.91 Manufacture of prepared feeds for farm animals 1080*' },
      { code: 'C10.92', description: '10.92 Manufacture of prepared pet foods 1080*' },
      { code: 'C11', description: '11 Manufacture of beverages' },
      { code: 'C11.0', description: '11.0 Manufacture of beverages' },
      { code: 'C11.01', description: '11.01 Distilling, rectifying and blending of spirits 1101' },
      { code: 'C11.02', description: '11.02 Manufacture of wine from grape 1102*' },
      { code: 'C11.03', description: '11.03 Manufacture of cider and other fruit wines 1102*' },
      { code: 'C11.04', description: '11.04 Manufacture of other non-distilled fermented beverages 1102*' },
      { code: 'C11.05', description: '11.05 Manufacture of beer 1103*' },
      { code: 'C11.06', description: '11.06 Manufacture of malt 1103*' },
      {
        code: 'C11.07',
        description: '11.07 Manufacture of soft drinks; production of mineral waters and other bottled waters 1104',
      },
      { code: 'C12', description: '12 Manufacture of tobacco products' },
      { code: 'C12.0', description: '12.0 Manufacture of tobacco products' },
      { code: 'C12.00', description: '12.00 Manufacture of tobacco products 1200' },
      { code: 'C13', description: '13 Manufacture of textiles' },
      { code: 'C13.1', description: '13.1 Preparation and spinning of textile fibres' },
      { code: 'C13.10', description: '13.10 Preparation and spinning of textile fibres 1311' },
      { code: 'C13.2', description: '13.2 Weaving of textiles' },
      { code: 'C13.20', description: '13.20 Weaving of textiles 1312' },
      { code: 'C13.3', description: '13.3 Finishing of textiles' },
      { code: 'C13.30', description: '13.30 Finishing of textiles 1313' },
      { code: 'C13.9', description: '13.9 Manufacture of other textiles' },
      { code: 'C13.91', description: '13.91 Manufacture of knitted and crocheted fabrics 1391' },
      { code: 'C13.92', description: '13.92 Manufacture of made-up textile articles, except apparel 1392' },
      { code: 'C13.93', description: '13.93 Manufacture of carpets and rugs 1393' },
      { code: 'C13.94', description: '13.94 Manufacture of cordage, rope, twine and netting 1394' },
      {
        code: 'C13.95',
        description: '13.95 Manufacture of non-wovens and articles made from non-wovens, except apparel 1399*',
      },
      { code: 'C13.96', description: '13.96 Manufacture of other technical and industrial textiles 1399*' },
      { code: 'C13.99', description: '13.99 Manufacture of other textiles n.e.c. 1399*' },
      { code: 'C14', description: '14 Manufacture of wearing apparel' },
      { code: 'C14.1', description: '14.1 Manufacture of wearing apparel, except fur apparel' },
      { code: 'C14.11', description: '14.11 Manufacture of leather clothes 1410*' },
      { code: 'C14.12', description: '14.12 Manufacture of workwear 1410*' },
      { code: 'C14.13', description: '14.13 Manufacture of other outerwear 1410*' },
      { code: 'C14.14', description: '14.14 Manufacture of underwear 1410*' },
      { code: 'C14.19', description: '14.19 Manufacture of other wearing apparel and accessories 1410*' },
      { code: 'C14.2', description: '14.2 Manufacture of articles of fur' },
      { code: 'C14.20', description: '14.20 Manufacture of articles of fur 1420' },
      { code: 'C14.3', description: '14.3 Manufacture of knitted and crocheted apparel' },
      { code: 'C14.31', description: '14.31 Manufacture of knitted and crocheted hosiery 1430*' },
      { code: 'C14.39', description: '14.39 Manufacture of other knitted and crocheted apparel 1430*' },
      { code: 'C15', description: '15 Manufacture of leather and related products' },
      {
        code: 'C15.1',
        description:
          '15.1 Tanning and dressing of leather; manufacture of luggage, handbags, saddlery and harness; dressing and dyeing of fur',
      },
      { code: 'C15.11', description: '15.11 Tanning and dressing of leather; dressing and dyeing of fur 1511' },
      {
        code: 'C15.12',
        description: '15.12 Manufacture of luggage, handbags and the like, saddlery and harness 1512',
      },
      { code: 'C15.2', description: '15.2 Manufacture of footwear' },
      { code: 'C15.20', description: '15.20 Manufacture of footwear 1520' },
      {
        code: 'C16',
        description:
          '16 Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials',
      },
      { code: 'C16.1', description: '16.1 Sawmilling and planing of wood' },
      { code: 'C16.10', description: '16.10 Sawmilling and planing of wood 1610' },
      { code: 'C16.2', description: '16.2 Manufacture of products of wood, cork, straw and plaiting materials' },
      { code: 'C16.21', description: '16.21 Manufacture of veneer sheets and wood-based panels 1621' },
      { code: 'C16.22', description: '16.22 Manufacture of assembled parquet floors 1622*' },
      { code: 'C16.23', description: '16.23 Manufacture of other builders carpentry and joinery 1622*' },
      { code: 'C16.24', description: '16.24 Manufacture of wooden containers 1623' },
      {
        code: 'C16.29',
        description:
          '16.29 Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials 1629',
      },
      { code: 'C17', description: '17 Manufacture of paper and paper products' },
      { code: 'C17.1', description: '17.1 Manufacture of pulp, paper and paperboard' },
      { code: 'C17.11', description: '17.11 Manufacture of pulp 1701*' },
      { code: 'C17.12', description: '17.12 Manufacture of paper and paperboard 1701*' },
      { code: 'C17.2', description: '17.2 Manufacture of articles of paper and paperboard' },
      {
        code: 'C17.21',
        description:
          '17.21 Manufacture of corrugated paper and paperboard and of containers of paper and paperboard 1702',
      },
      {
        code: 'C17.22',
        description: '17.22 Manufacture of household and sanitary goods and of toilet requisites 1709*',
      },
      { code: 'C17.23', description: '17.23 Manufacture of paper stationery 1709*' },
      { code: 'C17.24', description: '17.24 Manufacture of wallpaper 1709*' },
      { code: 'C17.29', description: '17.29 Manufacture of other articles of paper and paperboard 1709*' },
      { code: 'C18', description: '18 Printing and reproduction of recorded media' },
      { code: 'C18.1', description: '18.1 Printing and service activities related to printing' },
      { code: 'C18.11', description: '18.11 Printing of newspapers 1811*' },
      { code: 'C18.12', description: '18.12 Other printing 1811*' },
      { code: 'C18.13', description: '18.13 Pre-press and pre-media services 1812*' },
      { code: 'C18.14', description: '18.14 Binding and related services 1812*' },
      { code: 'C18.2', description: '18.2 Reproduction of recorded media' },
      { code: 'C18.20', description: '18.20 Reproduction of recorded media 1820' },
      { code: 'C19', description: '19 Manufacture of coke and refined petroleum products' },
      { code: 'C19.1', description: '19.1 Manufacture of coke oven products' },
      { code: 'C19.10', description: '19.10 Manufacture of coke oven products 1910' },
      { code: 'C19.2', description: '19.2 Manufacture of refined petroleum products' },
      { code: 'C19.20', description: '19.20 Manufacture of refined petroleum products 1920' },
      { code: 'C20', description: '20 Manufacture of chemicals and chemical products' },
      {
        code: 'C20.1',
        description:
          '20.1 Manufacture of basic chemicals, fertilisers and nitrogen compounds, plastics and synthetic rubber in primary forms',
      },
      { code: 'C20.11', description: '20.11 Manufacture of industrial gases 2011*' },
      { code: 'C20.12', description: '20.12 Manufacture of dyes and pigments 2011*' },
      { code: 'C20.13', description: '20.13 Manufacture of other inorganic basic chemicals 2011*' },
      { code: 'C20.14', description: '20.14 Manufacture of other organic basic chemicals 2011*' },
      { code: 'C20.15', description: '20.15 Manufacture of fertilisers and nitrogen compounds 2012' },
      { code: 'C20.16', description: '20.16 Manufacture of plastics in primary forms 2013*' },
      { code: 'C20.17', description: '20.17 Manufacture of synthetic rubber in primary forms 2013*' },
      { code: 'C20.2', description: '20.2 Manufacture of pesticides and other agrochemical products' },
      { code: 'C20.20', description: '20.20 Manufacture of pesticides and other agrochemical products 2021' },
      {
        code: 'C20.3',
        description: '20.3 Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
      },
      {
        code: 'C20.30',
        description: '20.30 Manufacture of paints, varnishes and similar coatings, printing ink and Mastics 2022',
      },
      {
        code: 'C20.4',
        description:
          '20.4 Manufacture of soap and detergents, cleaning and polishing preparations, perfumes and toilet preparations',
      },
      {
        code: 'C20.41',
        description: '20.41 Manufacture of soap and detergents, cleaning and polishing preparations 2023*',
      },
      { code: 'C20.42', description: '20.42 Manufacture of perfumes and toilet preparations 2023*' },
      { code: 'C20.5', description: '20.5 Manufacture of other chemical products' },
      { code: 'C20.51', description: '20.51 Manufacture of explosives 2029*' },
      { code: 'C20.52', description: '20.52 Manufacture of glues 2029*' },
      { code: 'C20.53', description: '20.53 Manufacture of essential oils 2029*' },
      { code: 'C20.59', description: '20.59 Manufacture of other chemical products n.e.c. 2029*' },
      { code: 'C20.6', description: '20.6 Manufacture of man-made fibres' },
      { code: 'C20.60', description: '20.60 Manufacture of man-made fibres 2030' },
      { code: 'C21', description: '21 Manufacture of basic pharmaceutical products and pharmaceutical preparations' },
      { code: 'C21.1', description: '21.1 Manufacture of basic pharmaceutical products' },
      { code: 'C21.10', description: '21.10 Manufacture of basic pharmaceutical products 2100*' },
      { code: 'C21.2', description: '21.2 Manufacture of pharmaceutical preparations' },
      { code: 'C21.20', description: '21.20 Manufacture of pharmaceutical preparations 2100*' },
      { code: 'C22', description: '22 Manufacture of rubber and plastic products' },
      { code: 'C22.1', description: '22.1 Manufacture of rubber products' },
      {
        code: 'C22.11',
        description: '22.11 Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres 2211',
      },
      { code: 'C22.19', description: '22.19 Manufacture of other rubber products 2219' },
      { code: 'C22.2', description: '22.2 Manufacture of plastics products' },
      { code: 'C22.21', description: '22.21 Manufacture of plastic plates, sheets, tubes and profiles 2220*' },
      { code: 'C22.22', description: '22.22 Manufacture of plastic packing goods 2220*' },
      { code: 'C22.23', description: '22.23 Manufacture of builders ware of plastic 2220*' },
      { code: 'C22.29', description: '22.29 Manufacture of other plastic products 2220*' },
      { code: 'C23', description: '23 Manufacture of other non-metallic mineral products' },
      { code: 'C23.1', description: '23.1 Manufacture of glass and glass products' },
      { code: 'C23.11', description: '23.11 Manufacture of flat glass 2310*' },
      { code: 'C23.12', description: '23.12 Shaping and processing of flat glass 2310*' },
      { code: 'C23.13', description: '23.13 Manufacture of hollow glass 2310*' },
      { code: 'C23.14', description: '23.14 Manufacture of glass fibres 2310*' },
      {
        code: 'C23.19',
        description: '23.19 Manufacture and processing of other glass, including technical glassware 2310*',
      },
      { code: 'C23.2', description: '23.2 Manufacture of refractory products' },
      { code: 'C23.20', description: '23.20 Manufacture of refractory products 2391' },
      { code: 'C23.3', description: '23.3 Manufacture of clay building materials' },
      { code: 'C23.31', description: '23.31 Manufacture of ceramic tiles and flags 2392*' },
      {
        code: 'C23.32',
        description: '23.32 Manufacture of bricks, tiles and construction products, in baked clay 2392*',
      },
      { code: 'C23.4', description: '23.4 Manufacture of other porcelain and ceramic products' },
      { code: 'C23.41', description: '23.41 Manufacture of ceramic household and ornamental articles 2393*' },
      { code: 'C23.42', description: '23.42 Manufacture of ceramic sanitary fixtures 2393*' },
      { code: 'C23.43', description: '23.43 Manufacture of ceramic insulators and insulating fittings 2393*' },
      { code: 'C23.44', description: '23.44 Manufacture of other technical ceramic products 2393*' },
      { code: 'C23.49', description: '23.49 Manufacture of other ceramic products 2393*' },
      { code: 'C23.5', description: '23.5 Manufacture of cement, lime and plaster' },
      { code: 'C23.51', description: '23.51 Manufacture of cement 2394*' },
      { code: 'C23.52', description: '23.52 Manufacture of lime and plaster 2394*' },
      { code: 'C23.6', description: '23.6 Manufacture of articles of concrete, cement and plaster' },
      { code: 'C23.61', description: '23.61 Manufacture of concrete products for construction purposes 2395*' },
      { code: 'C23.62', description: '23.62 Manufacture of plaster products for construction purposes 2395*' },
      { code: 'C23.63', description: '23.63 Manufacture of ready-mixed concrete 2395*' },
      { code: 'C23.64', description: '23.64 Manufacture of mortars 2395*' },
      { code: 'C23.65', description: '23.65 Manufacture of fibre cement 2395*' },
      { code: 'C23.69', description: '23.69 Manufacture of other articles of concrete, plaster and cement 2395*' },
      { code: 'C23.7', description: '23.7 Cutting, shaping and finishing of stone' },
      { code: 'C23.70', description: '23.70 Cutting, shaping and finishing of stone 2396' },
      { code: 'C23.9', description: '23.9 Manufacture of abrasive products and non-metallic mineral products n.e.c.' },
      { code: 'C23.91', description: '23.91 Production of abrasive products 2399*' },
      { code: 'C23.99', description: '23.99 Manufacture of other non-metallic mineral products n.e.c. 2399*' },
      { code: 'C24', description: '24 Manufacture of basic metals' },
      { code: 'C24.1', description: '24.1 Manufacture of basic iron and steel and of ferro-alloys' },
      { code: 'C24.10', description: '24.10 Manufacture of basic iron and steel and of ferro-alloys 2410*' },
      {
        code: 'C24.2',
        description: '24.2 Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
      },
      {
        code: 'C24.20',
        description: '24.20 Manufacture of tubes, pipes, hollow profiles and related fittings, of steel 2410*',
      },
      { code: 'C24.3', description: '24.3 Manufacture of other products of first processing of steel' },
      { code: 'C24.31', description: '24.31 Cold drawing of bars 2410*' },
      { code: 'C24.32', description: '24.32 Cold rolling of narrow strip 2410*' },
      { code: 'C24.33', description: '24.33 Cold forming or folding 2410*' },
      { code: 'C24.34', description: '24.34 Cold drawing of wire 2410*' },
      { code: 'C24.4', description: '24.4 Manufacture of basic precious and other non-ferrous metals' },
      { code: 'C24.41', description: '24.41 Precious metals production 2420*' },
      { code: 'C24.42', description: '24.42 Aluminium production 2420*' },
      { code: 'C24.43', description: '24.43 Lead, zinc and tin production 2420*' },
      { code: 'C24.44', description: '24.44 Copper production 2420*' },
      { code: 'C24.45', description: '24.45 Other non-ferrous metal production 2420*' },
      { code: 'C24.46', description: '24.46 Processing of nuclear fuel 2420*' },
      { code: 'C24.5', description: '24.5 Casting of metals' },
      { code: 'C24.51', description: '24.51 Casting of iron 2431*' },
      { code: 'C24.52', description: '24.52 Casting of steel 2431*' },
      { code: 'C24.53', description: '24.53 Casting of light metals 2432*' },
      { code: 'C24.54', description: '24.54 Casting of other non-ferrous metals 2432*' },
      { code: 'C25', description: '25 Manufacture of fabricated metal products, except machinery and equipment' },
      { code: 'C25.1', description: '25.1 Manufacture of structural metal products' },
      { code: 'C25.11', description: '25.11 Manufacture of metal structures and parts of structures 2511*' },
      { code: 'C25.12', description: '25.12 Manufacture of doors and windows of metal 2511*' },
      { code: 'C25.2', description: '25.2 Manufacture of tanks, reservoirs and containers of metal' },
      { code: 'C25.21', description: '25.21 Manufacture of central heating radiators and boilers 2512*' },
      { code: 'C25.29', description: '25.29 Manufacture of other tanks, reservoirs and containers of metal 2512*' },
      { code: 'C25.3', description: '25.3 Manufacture of steam generators, except central heating hot water boilers' },
      {
        code: 'C25.30',
        description: '25.30 Manufacture of steam generators, except central heating hot water boilers 2513',
      },
      { code: 'C25.4', description: '25.4 Manufacture of weapons and ammunition' },
      { code: 'C25.40', description: '25.40 Manufacture of weapons and ammunition 2520' },
      { code: 'C25.5', description: '25.5 Forging, pressing, stamping and roll-forming of metal; powder metallurgy' },
      {
        code: 'C25.50',
        description: '25.50 Forging, pressing, stamping and roll-forming of metal; powder metallurgy 2591',
      },
      { code: 'C25.6', description: '25.6 Treatment and coating of metals; machining' },
      { code: 'C25.61', description: '25.61 Treatment and coating of metals 2592*' },
      { code: 'C25.62', description: '25.62 Machining 2592*' },
      { code: 'C25.7', description: '25.7 Manufacture of cutlery, tools and general hardware' },
      { code: 'C25.71', description: '25.71 Manufacture of cutlery 2593*' },
      { code: 'C25.72', description: '25.72 Manufacture of locks and hinges 2593*' },
      { code: 'C25.73', description: '25.73 Manufacture of tools 2593*' },
      { code: 'C25.9', description: '25.9 Manufacture of other fabricated metal products' },
      { code: 'C25.91', description: '25.91 Manufacture of steel drums and similar containers 2599*' },
      { code: 'C25.92', description: '25.92 Manufacture of light metal packaging 2599*' },
      { code: 'C25.93', description: '25.93 Manufacture of wire products, chain and springs 2599*' },
      { code: 'C25.94', description: '25.94 Manufacture of fasteners and screw machine products 2599*' },
      { code: 'C25.99', description: '25.99 Manufacture of other fabricated metal products n.e.c. 2599*' },
      { code: 'C26', description: '26 Manufacture of computer, electronic and optical products' },
      { code: 'C26.1', description: '26.1 Manufacture of electronic components and boards' },
      { code: 'C26.11', description: '26.11 Manufacture of electronic components 2610*' },
      { code: 'C26.12', description: '26.12 Manufacture of loaded electronic boards 2610*' },
      { code: 'C26.2', description: '26.2 Manufacture of computers and peripheral equipment' },
      { code: 'C26.20', description: '26.20 Manufacture of computers and peripheral equipment 2620' },
      { code: 'C26.3', description: '26.3 Manufacture of communication equipment' },
      { code: 'C26.30', description: '26.30 Manufacture of communication equipment 2630' },
      { code: 'C26.4', description: '26.4 Manufacture of consumer electronics' },
      { code: 'C26.40', description: '26.40 Manufacture of consumer electronics 2640' },
      {
        code: 'C26.5',
        description:
          '26.5 Manufacture of instruments and appliances for measuring, testing and navigation; watches and clocks',
      },
      {
        code: 'C26.51',
        description: '26.51 Manufacture of instruments and appliances for measuring, testing and navigation 2651',
      },
      { code: 'C26.52', description: '26.52 Manufacture of watches and clocks 2652' },
      {
        code: 'C26.6',
        description: '26.6 Manufacture of irradiation, electromedical and electrotherapeutic equipment',
      },
      {
        code: 'C26.60',
        description: '26.60 Manufacture of irradiation, electromedical and electrotherapeutic equipment 2660',
      },
      { code: 'C26.7', description: '26.7 Manufacture of optical instruments and photographic equipment' },
      { code: 'C26.70', description: '26.70 Manufacture of optical instruments and photographic equipment 2670' },
      { code: 'C26.8', description: '26.8 Manufacture of magnetic and optical media' },
      { code: 'C26.80', description: '26.80 Manufacture of magnetic and optical media 2680' },
      { code: 'C27', description: '27 Manufacture of electrical equipment' },
      {
        code: 'C27.1',
        description:
          '27.1 Manufacture of electric motors, generators, transformers and electricity distribution and control apparatus',
      },
      { code: 'C27.11', description: '27.11 Manufacture of electric motors, generators and transformers 2710*' },
      { code: 'C27.12', description: '27.12 Manufacture of electricity distribution and control apparatus 2710*' },
      { code: 'C27.2', description: '27.2 Manufacture of batteries and accumulators' },
      { code: 'C27.20', description: '27.20 Manufacture of batteries and accumulators 2720' },
      { code: 'C27.3', description: '27.3 Manufacture of wiring and wiring devices' },
      { code: 'C27.31', description: '27.31 Manufacture of fibre optic cables 2731' },
      { code: 'C27.32', description: '27.32 Manufacture of other electronic and electric wires and cables 2732' },
      { code: 'C27.33', description: '27.33 Manufacture of wiring devices 2733' },
      { code: 'C27.4', description: '27.4 Manufacture of electric lighting equipment' },
      { code: 'C27.40', description: '27.40 Manufacture of electric lighting equipment 2740' },
      { code: 'C27.5', description: '27.5 Manufacture of domestic appliances' },
      { code: 'C27.51', description: '27.51 Manufacture of electric domestic appliances 2750*' },
      { code: 'C27.52', description: '27.52 Manufacture of non-electric domestic appliances 2750*' },
      { code: 'C27.9', description: '27.9 Manufacture of other electrical equipment' },
      { code: 'C27.90', description: '27.90 Manufacture of other electrical equipment 2790' },
      { code: 'C28', description: '28 Manufacture of machinery and equipment n.e.c.' },
      { code: 'C28.1', description: '28.1 Manufacture of general — purpose machinery' },
      {
        code: 'C28.11',
        description: '28.11 Manufacture of engines and turbines, except aircraft, vehicle and cycle engines 2811',
      },
      { code: 'C28.12', description: '28.12 Manufacture of fluid power equipment 2812' },
      { code: 'C28.13', description: '28.13 Manufacture of other pumps and compressors 2813*' },
      { code: 'C28.14', description: '28.14 Manufacture of other taps and valves 2813*' },
      { code: 'C28.15', description: '28.15 Manufacture of bearings, gears, gearing and driving elements 2814' },
      { code: 'C28.2', description: '28.2 Manufacture of other general-purpose machinery' },
      { code: 'C28.21', description: '28.21 Manufacture of ovens, furnaces and furnace burners 2815' },
      { code: 'C28.22', description: '28.22 Manufacture of lifting and handling equipment 2816' },
      {
        code: 'C28.23',
        description:
          '28.23 Manufacture of office machinery and equipment (except computers and peripheral equipment) 2817',
      },
      { code: 'C28.24', description: '28.24 Manufacture of power-driven hand tools 2818' },
      { code: 'C28.25', description: '28.25 Manufacture of non-domestic cooling and ventilation equipment 2819*' },
      { code: 'C28.29', description: '28.29 Manufacture of other general-purpose machinery n.e.c. 2819*' },
      { code: 'C28.3', description: '28.3 Manufacture of agricultural and forestry machinery' },
      { code: 'C28.30', description: '28.30 Manufacture of agricultural and forestry machinery 2821' },
      { code: 'C28.4', description: '28.4 Manufacture of metal forming machinery and machine tools' },
      { code: 'C28.41', description: '28.41 Manufacture of metal forming machinery 2822*' },
      { code: 'C28.49', description: '28.49 Manufacture of other machine tools 2822*' },
      { code: 'C28.9', description: '28.9 Manufacture of other special-purpose machinery' },
      { code: 'C28.91', description: '28.91 Manufacture of machinery for metallurgy 2823' },
      { code: 'C28.92', description: '28.92 Manufacture of machinery for mining, quarrying and construction 2824' },
      { code: 'C28.93', description: '28.93 Manufacture of machinery for food, beverage and tobacco processing 2825' },
      {
        code: 'C28.94',
        description: '28.94 Manufacture of machinery for textile, apparel and leather production 2826',
      },
      { code: 'C28.95', description: '28.95 Manufacture of machinery for paper and paperboard production 2829*' },
      { code: 'C28.96', description: '28.96 Manufacture of plastic and rubber machinery 2829*' },
      { code: 'C28.99', description: '28.99 Manufacture of other special-purpose machinery n.e.c. 2829*' },
      { code: 'C29', description: '29 Manufacture of motor vehicles, trailers and semi-trailers' },
      { code: 'C29.1', description: '29.1 Manufacture of motor vehicles' },
      { code: 'C29.10', description: '29.10 Manufacture of motor vehicles 2910' },
      {
        code: 'C29.2',
        description:
          '29.2 Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
      },
      {
        code: 'C29.20',
        description:
          '29.20 Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers 2920',
      },
      { code: 'C29.3', description: '29.3 Manufacture of parts and accessories for motor vehicles' },
      {
        code: 'C29.31',
        description: '29.31 Manufacture of electrical and electronic equipment for motor vehicles 2930*',
      },
      { code: 'C29.32', description: '29.32 Manufacture of other parts and accessories for motor vehicles 2930*' },
      { code: 'C30', description: '30 Manufacture of other transport equipment' },
      { code: 'C30.1', description: '30.1 Building of ships and boats' },
      { code: 'C30.11', description: '30.11 Building of ships and floating structures 3011' },
      { code: 'C30.12', description: '30.12 Building of pleasure and sporting boats 3012' },
      { code: 'C30.2', description: '30.2 Manufacture of railway locomotives and rolling stock' },
      { code: 'C30.20', description: '30.20 Manufacture of railway locomotives and rolling stock 3020' },
      { code: 'C30.3', description: '30.3 Manufacture of air and spacecraft and related machinery' },
      { code: 'C30.30', description: '30.30 Manufacture of air and spacecraft and related machinery 3030' },
      { code: 'C30.4', description: '30.4 Manufacture of military fighting vehicles' },
      { code: 'C30.40', description: '30.40 Manufacture of military fighting vehicles 3040' },
      { code: 'C30.9', description: '30.9 Manufacture of transport equipment n.e.c.' },
      { code: 'C30.91', description: '30.91 Manufacture of motorcycles 3091' },
      { code: 'C30.92', description: '30.92 Manufacture of bicycles and invalid carriages 3092' },
      { code: 'C30.99', description: '30.99 Manufacture of other transport equipment n.e.c. 3099' },
      { code: 'C31', description: '31 Manufacture of furniture' },
      { code: 'C31.0', description: '31.0 Manufacture of furniture' },
      { code: 'C31.01', description: '31.01 Manufacture of office and shop furniture 3100*' },
      { code: 'C31.02', description: '31.02 Manufacture of kitchen furniture 3100*' },
      { code: 'C31.03', description: '31.03 Manufacture of mattresses 3100*' },
      { code: 'C31.09', description: '31.09 Manufacture of other furniture 3100*' },
      { code: 'C32', description: '32 Other manufacturing' },
      { code: 'C32.1', description: '32.1 Manufacture of jewellery, bijouterie and related articles' },
      { code: 'C32.11', description: '32.11 Striking of coins 3211*' },
      { code: 'C32.12', description: '32.12 Manufacture of jewellery and related articles 3211*' },
      { code: 'C32.13', description: '32.13 Manufacture of imitation jewellery and related articles 3212' },
      { code: 'C32.2', description: '32.2 Manufacture of musical instruments' },
      { code: 'C32.20', description: '32.20 Manufacture of musical instruments 3220' },
      { code: 'C32.3', description: '32.3 Manufacture of sports goods' },
      { code: 'C32.30', description: '32.30 Manufacture of sports goods 3230' },
      { code: 'C32.4', description: '32.4 Manufacture of games and toys' },
      { code: 'C32.40', description: '32.40 Manufacture of games and toys 3240' },
      { code: 'C32.5', description: '32.5 Manufacture of medical and dental instruments and supplies' },
      { code: 'C32.50', description: '32.50 Manufacture of medical and dental instruments and supplies 3250' },
      { code: 'C32.9', description: '32.9 Manufacturing n.e.c.' },
      { code: 'C32.91', description: '32.91 Manufacture of brooms and brushes 3290*' },
      { code: 'C32.99', description: '32.99 Other manufacturing n.e.c. 3290*' },
      { code: 'C33', description: '33 Repair and installation of machinery and equipment' },
      { code: 'C33.1', description: '33.1 Repair of fabricated metal products, machinery and equipment' },
      { code: 'C33.11', description: '33.11 Repair of fabricated metal products 3311' },
      { code: 'C33.12', description: '33.12 Repair of machinery 3312' },
      { code: 'C33.13', description: '33.13 Repair of electronic and optical equipment 3313' },
      { code: 'C33.14', description: '33.14 Repair of electrical equipment 3314' },
      { code: 'C33.15', description: '33.15 Repair and maintenance of ships and boats 3315*' },
      { code: 'C33.16', description: '33.16 Repair and maintenance of aircraft and spacecraft 3315*' },
      { code: 'C33.17', description: '33.17 Repair and maintenance of other transport equipment 3315*' },
      { code: 'C33.19', description: '33.19 Repair of other equipment 3319' },
      { code: 'C33.2', description: '33.2 Installation of industrial machinery and equipment' },
      { code: 'C33.20', description: '33.20 Installation of industrial machinery and equipment 3320' },
    ],
  },
  {
    section: { letter: 'D', description: 'D — ELECTRICITY, GAS, STEAM AND AIR CONDITIONING SUPPLY' },
    codes: [
      { code: 'D35', description: '35 Electricity, gas, steam and air conditioning supply' },
      { code: 'D35.1', description: '35.1 Electric power generation, transmission and distribution' },
      { code: 'D35.11', description: '35.11 Production of electricity 3510*' },
      { code: 'D35.12', description: '35.12 Transmission of electricity 3510*' },
      { code: 'D35.13', description: '35.13 Distribution of electricity 3510*' },
      { code: 'D35.14', description: '35.14 Trade of electricity 3510*' },
      { code: 'D35.2', description: '35.2 Manufacture of gas; distribution of gaseous fuels through mains' },
      { code: 'D35.21', description: '35.21 Manufacture of gas 3520*' },
      { code: 'D35.22', description: '35.22 Distribution of gaseous fuels through mains 3520*' },
      { code: 'D35.23', description: '35.23 Trade of gas through mains 3520*' },
      { code: 'D35.3', description: '35.3 Steam and air conditioning supply' },
      { code: 'D35.30', description: '35.30 Steam and air conditioning supply 3530' },
    ],
  },
  {
    section: {
      letter: 'E',
      description: 'E — WATER SUPPLY; SEWERAGE, WASTE MANAGEMENT AND REMEDIATION ACTIVITIES',
    },
    codes: [
      { code: 'E36', description: '36 Water collection, treatment and supply' },
      { code: 'E36.0', description: '36.0 Water collection, treatment and supply' },
      { code: 'E36.00', description: '36.00 Water collection, treatment and supply 3600' },
      { code: 'E37', description: '37 Sewerage' },
      { code: 'E37.0', description: '37.0 Sewerage' },
      { code: 'E37.00', description: '37.00 Sewerage 3700' },
      { code: 'E38', description: '38 Waste collection, treatment and disposal activities; materials recovery' },
      { code: 'E38.1', description: '38.1 Waste collection' },
      { code: 'E38.11', description: '38.11 Collection of non-hazardous waste 3811' },
      { code: 'E38.12', description: '38.12 Collection of hazardous waste 3812' },
      { code: 'E38.2', description: '38.2 Waste treatment and disposal' },
      { code: 'E38.21', description: '38.21 Treatment and disposal of non-hazardous waste 3821' },
      { code: 'E38.22', description: '38.22 Treatment and disposal of hazardous waste 3822' },
      { code: 'E38.3', description: '38.3 Materials recovery' },
      { code: 'E38.31', description: '38.31 Dismantling of wrecks 3830*' },
      { code: 'E38.32', description: '38.32 Recovery of sorted materials 3830*' },
      { code: 'E39', description: '39 Remediation activities and other waste management services' },
      { code: 'E39.0', description: '39.0 Remediation activities and other waste management services' },
      { code: 'E39.00', description: '39.00 Remediation activities and other waste management services 3900' },
    ],
  },
  {
    section: { letter: 'F', description: 'F — CONSTRUCTION' },
    codes: [
      { code: 'F41', description: '41 Construction of buildings' },
      { code: 'F41.1', description: '41.1 Development of building projects' },
      { code: 'F41.10', description: '41.10 Development of building projects 4100*' },
      { code: 'F41.2', description: '41.2 Construction of residential and non-residential buildings' },
      { code: 'F41.20', description: '41.20 Construction of residential and non-residential buildings 4100*' },
      { code: 'F42', description: '42 Civil engineering' },
      { code: 'F42.1', description: '42.1 Construction of roads and railways' },
      { code: 'F42.11', description: '42.11 Construction of roads and motorways 4210*' },
      { code: 'F42.12', description: '42.12 Construction of railways and underground railways 4210*' },
      { code: 'F42.13', description: '42.13 Construction of bridges and tunnels 4210*' },
      { code: 'F42.2', description: '42.2 Construction of utility projects' },
      { code: 'F42.21', description: '42.21 Construction of utility projects for fluids 4220*' },
      {
        code: 'F42.22',
        description: '42.22 Construction of utility projects for electricity and telecommunications 4220*',
      },
      { code: 'F42.9', description: '42.9 Construction of other civil engineering projects' },
      { code: 'F42.91', description: '42.91 Construction of water projects 4290*' },
      { code: 'F42.99', description: '42.99 Construction of other civil engineering projects n.e.c. 4290*' },
      { code: 'F43', description: '43 Specialised construction activities' },
      { code: 'F43.1', description: '43.1 Demolition and site preparation' },
      { code: 'F43.11', description: '43.11 Demolition 4311' },
      { code: 'F43.12', description: '43.12 Site preparation 4312*' },
      { code: 'F43.13', description: '43.13 Test drilling and boring 4312*' },
      { code: 'F43.2', description: '43.2 Electrical, plumbing and other construction installation activities' },
      { code: 'F43.21', description: '43.21 Electrical installation 4321' },
      { code: 'F43.22', description: '43.22 Plumbing, heat and air conditioning installation 4322' },
      { code: 'F43.29', description: '43.29 Other construction installation 4329' },
      { code: 'F43.3', description: '43.3 Building completion and finishing' },
      { code: 'F43.31', description: '43.31 Plastering 4330*' },
      { code: 'F43.32', description: '43.32 Joinery installation 4330*' },
      { code: 'F43.33', description: '43.33 Floor and wall covering 4330*' },
      { code: 'F43.34', description: '43.34 Painting and glazing 4330*' },
      { code: 'F43.39', description: '43.39 Other building completion and finishing 4330*' },
      { code: 'F43.9', description: '43.9 Other specialised construction activities' },
      { code: 'F43.91', description: '43.91 Roofing activities 4390*' },
      { code: 'F43.99', description: '43.99 Other specialised construction activities n.e.c. 4390*' },
    ],
  },
  {
    section: {
      letter: 'G',
      description: 'G — WHOLESALE AND RETAIL TRADE; REPAIR OF MOTOR VEHICLES AND MOTORCYCLES',
    },
    codes: [
      { code: 'G45', description: '45 Wholesale and retail trade and repair of motor vehicles and motorcycles' },
      { code: 'G45.1', description: '45.1 Sale of motor vehicles' },
      { code: 'G45.11', description: '45.11 Sale of cars and light motor vehicles 4510*' },
      { code: 'G45.19', description: '45.19 Sale of other motor vehicles 4510*' },
      { code: 'G45.2', description: '45.2 Maintenance and repair of motor vehicles' },
      { code: 'G45.20', description: '45.20 Maintenance and repair of motor vehicles 4520' },
      { code: 'G45.3', description: '45.3 Sale of motor vehicle parts and accessories' },
      { code: 'G45.31', description: '45.31 Wholesale trade of motor vehicle parts and accessories 4530*' },
      { code: 'G45.32', description: '45.32 Retail trade of motor vehicle parts and accessories 4530*' },
      {
        code: 'G45.4',
        description: '45.4 Sale, maintenance and repair of motorcycles and related parts and accessories',
      },
      {
        code: 'G45.40',
        description: '45.40 Sale, maintenance and repair of motorcycles and related parts and accessories 4540',
      },
      { code: 'G46', description: '46 Wholesale trade, except of motor vehicles and motorcycles' },
      { code: 'G46.1', description: '46.1 Wholesale on a fee or contract basis' },
      {
        code: 'G46.11',
        description:
          '46.11 Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods 4610*',
      },
      {
        code: 'G46.12',
        description: '46.12 Agents involved in the sale of fuels, ores, metals and industrial chemicals 4610*',
      },
      { code: 'G46.13', description: '46.13 Agents involved in the sale of timber and building materials 4610*' },
      {
        code: 'G46.14',
        description: '46.14 Agents involved in the sale of machinery, industrial equipment, ships and aircraft 4610*',
      },
      {
        code: 'G46.15',
        description: '46.15 Agents involved in the sale of furniture, household goods, hardware and ironmongery 4610*',
      },
      {
        code: 'G46.16',
        description: '46.16 Agents involved in the sale of textiles, clothing, fur, footwear and leather goods 4610*',
      },
      { code: 'G46.17', description: '46.17 Agents involved in the sale of food, beverages and tobacco 4610*' },
      { code: 'G46.18', description: '46.18 Agents specialised in the sale of other particular products 4610*' },
      { code: 'G46.19', description: '46.19 Agents involved in the sale of a variety of goods 4610*' },
      { code: 'G46.2', description: '46.2 Wholesale of agricultural raw materials and live animals' },
      { code: 'G46.21', description: '46.21 Wholesale of grain, unmanufactured tobacco, seeds and animal feeds 4620*' },
      { code: 'G46.22', description: '46.22 Wholesale of flowers and plants 4620*' },
      { code: 'G46.23', description: '46.23 Wholesale of live animals 4620*' },
      { code: 'G46.24', description: '46.24 Wholesale of hides, skins and leather 4620*' },
      { code: 'G46.3', description: '46.3 Wholesale of food, beverages and tobacco' },
      { code: 'G46.31', description: '46.31 Wholesale of fruit and vegetables 4630*' },
      { code: 'G46.32', description: '46.32 Wholesale of meat and meat products 4630*' },
      { code: 'G46.33', description: '46.33 Wholesale of dairy products, eggs and edible oils and fats 4630*' },
      { code: 'G46.34', description: '46.34 Wholesale of beverages 4630*' },
      { code: 'G46.35', description: '46.35 Wholesale of tobacco products 4630*' },
      { code: 'G46.36', description: '46.36 Wholesale of sugar and chocolate and sugar confectionery 4630*' },
      { code: 'G46.37', description: '46.37 Wholesale of coffee, tea, cocoa and spices 4630*' },
      { code: 'G46.38', description: '46.38 Wholesale of other food, including fish, crustaceans and molluscs 4630*' },
      { code: 'G46.39', description: '46.39 Non-specialised wholesale of food, beverages and tobacco 4630*' },
      { code: 'G46.4', description: '46.4 Wholesale of household goods' },
      { code: 'G46.41', description: '46.41 Wholesale of textiles 4641*' },
      { code: 'G46.42', description: '46.42 Wholesale of clothing and footwear 4641*' },
      { code: 'G46.43', description: '46.43 Wholesale of electrical household appliances 4649*' },
      { code: 'G46.44', description: '46.44 Wholesale of china and glassware and cleaning materials 4649*' },
      { code: 'G46.45', description: '46.45 Wholesale of perfume and cosmetics 4649*' },
      { code: 'G46.46', description: '46.46 Wholesale of pharmaceutical goods 4649*' },
      { code: 'G46.47', description: '46.47 Wholesale of furniture, carpets and lighting equipment 4649*' },
      { code: 'G46.48', description: '46.48 Wholesale of watches and jewellery 4649*' },
      { code: 'G46.49', description: '46.49 Wholesale of other household goods 4649*' },
      { code: 'G46.5', description: '46.5 Wholesale of information and communication equipment' },
      { code: 'G46.51', description: '46.51 Wholesale of computers, computer peripheral equipment and software 4651' },
      { code: 'G46.52', description: '46.52 Wholesale of electronic and telecommunications equipment and parts 4652' },
      { code: 'G46.6', description: '46.6 Wholesale of other machinery, equipment and supplies' },
      { code: 'G46.61', description: '46.61 Wholesale of agricultural machinery, equipment and supplies 4653' },
      { code: 'G46.62', description: '46.62 Wholesale of machine tools 4659*' },
      { code: 'G46.63', description: '46.63 Wholesale of mining, construction and civil engineering machinery 4659*' },
      {
        code: 'G46.64',
        description: '46.64 Wholesale of machinery for the textile industry and of sewing and knitting machines 4659*',
      },
      { code: 'G46.65', description: '46.65 Wholesale of office furniture 4659*' },
      { code: 'G46.66', description: '46.66 Wholesale of other office machinery and equipment 4659*' },
      { code: 'G46.69', description: '46.69 Wholesale of other machinery and equipment 4659*' },
      { code: 'G46.7', description: '46.7 Other specialised wholesale' },
      { code: 'G46.71', description: '46.71 Wholesale of solid, liquid and gaseous fuels and related products 4661' },
      { code: 'G46.72', description: '46.72 Wholesale of metals and metal ores 4662' },
      { code: 'G46.73', description: '46.73 Wholesale of wood, construction materials and sanitary equipment 4663*' },
      { code: 'G46.74', description: '46.74 Wholesale of hardware, plumbing and heating equipment and supplies 4663*' },
      { code: 'G46.75', description: '46.75 Wholesale of chemical products 4669*' },
      { code: 'G46.76', description: '46.76 Wholesale of other intermediate products 4669*' },
      { code: 'G46.77', description: '46.77 Wholesale of waste and scrap 4669*' },
      { code: 'G46.9', description: '46.9 Non-specialised wholesale trade' },
      { code: 'G46.90', description: '46.90 Non-specialised wholesale trade 4690' },
      { code: 'G47', description: '47 Retail trade, except of motor vehicles and motorcycles' },
      { code: 'G47.1', description: '47.1 Retail sale in non-specialised stores' },
      {
        code: 'G47.11',
        description: '47.11 Retail sale in non-specialised stores with food, beverages or tobacco predominating 4711',
      },
      { code: 'G47.19', description: '47.19 Other retail sale in non-specialised stores 4719' },
      { code: 'G47.2', description: '47.2 Retail sale of food, beverages and tobacco in specialised stores' },
      { code: 'G47.21', description: '47.21 Retail sale of fruit and vegetables in specialised stores 4721*' },
      { code: 'G47.22', description: '47.22 Retail sale of meat and meat products in specialised stores 4721*' },
      {
        code: 'G47.23',
        description: '47.23 Retail sale of fish, crustaceans and molluscs in specialised stores 4721*',
      },
      {
        code: 'G47.24',
        description:
          '47.24 Retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores 4721*',
      },
      { code: 'G47.25', description: '47.25 Retail sale of beverages in specialised stores 4722' },
      { code: 'G47.26', description: '47.26 Retail sale of tobacco products in specialised stores 4723' },
      { code: 'G47.29', description: '47.29 Other retail sale of food in specialised stores 4721*' },
      { code: 'G47.3', description: '47.3 Retail sale of automotive fuel in specialised stores' },
      { code: 'G47.30', description: '47.30 Retail sale of automotive fuel in specialised stores 4730' },
      {
        code: 'G47.4',
        description: '47.4 Retail sale of information and communication equipment in specialised stores',
      },
      {
        code: 'G47.41',
        description: '47.41 Retail sale of computers, peripheral units and software in specialised stores 4741*',
      },
      { code: 'G47.42', description: '47.42 Retail sale of telecommunications equipment in specialised stores 4741*' },
      { code: 'G47.43', description: '47.43 Retail sale of audio and video equipment in specialised stores 4742' },
      { code: 'G47.5', description: '47.5 Retail sale of other household equipment in specialised stores' },
      { code: 'G47.51', description: '47.51 Retail sale of textiles in specialised stores 4751' },
      { code: 'G47.52', description: '47.52 Retail sale of hardware, paints and glass in specialised stores 4752' },
      {
        code: 'G47.53',
        description: '47.53 Retail sale of carpets, rugs, wall and floor coverings in specialised stores 4753',
      },
      {
        code: 'G47.54',
        description: '47.54 Retail sale of electrical household appliances in specialised stores 4759*',
      },
      {
        code: 'G47.59',
        description:
          '47.59 Retail sale of furniture, lighting equipment and other household articles in specialised stores 4759*',
      },
      { code: 'G47.6', description: '47.6 Retail sale of cultural and recreation goods in specialised stores' },
      { code: 'G47.61', description: '47.61 Retail sale of books in specialised stores 4761*' },
      { code: 'G47.62', description: '47.62 Retail sale of newspapers and stationery in specialised stores 4761*' },
      { code: 'G47.63', description: '47.63 Retail sale of music and video recordings in specialised stores 4762' },
      { code: 'G47.64', description: '47.64 Retail sale of sporting equipment in specialised stores 4763' },
      { code: 'G47.65', description: '47.65 Retail sale of games and toys in specialised stores 4764' },
      { code: 'G47.7', description: '47.7 Retail sale of other goods in specialised stores' },
      { code: 'G47.71', description: '47.71 Retail sale of clothing in specialised stores 4771*' },
      { code: 'G47.72', description: '47.72 Retail sale of footwear and leather goods in specialised stores 4771*' },
      { code: 'G47.73', description: '47.73 Dispensing chemist in specialised stores 4772*' },
      { code: 'G47.74', description: '47.74 Retail sale of medical and orthopaedic goods in specialised stores 4772*' },
      { code: 'G47.75', description: '47.75 Retail sale of cosmetic and toilet articles in specialised stores 4772*' },
      {
        code: 'G47.76',
        description:
          '47.76 Retail sale of flowers, plants, seeds, fertilisers, pet animals and pet food in specialised stores 4773*',
      },
      { code: 'G47.77', description: '47.77 Retail sale of watches and jewellery in specialised stores 4773*' },
      { code: 'G47.78', description: '47.78 Other retail sale of new goods in specialised stores 4773*' },
      { code: 'G47.79', description: '47.79 Retail sale of second-hand goods in stores 4774' },
      { code: 'G47.8', description: '47.8 Retail sale via stalls and markets' },
      {
        code: 'G47.81',
        description: '47.81 Retail sale via stalls and markets of food, beverages and tobacco products 4781',
      },
      {
        code: 'G47.82',
        description: '47.82 Retail sale via stalls and markets of textiles, clothing and footwear 4782',
      },
      { code: 'G47.89', description: '47.89 Retail sale via stalls and markets of other goods 4789' },
      { code: 'G47.9', description: '47.9 Retail trade not in stores, stalls or markets' },
      { code: 'G47.91', description: '47.91 Retail sale via mail order houses or via Internet 4791' },
      { code: 'G47.99', description: '47.99 Other retail sale not in stores, stalls or markets 4799' },
    ],
  },
  {
    section: { letter: 'H', description: 'H — TRANSPORTATION AND STORAGE' },
    codes: [
      { code: 'H49', description: '49 Land transport and transport via pipelines' },
      { code: 'H49.1', description: '49.1 Passenger rail transport, interurban' },
      { code: 'H49.10', description: '49.10 Passenger rail transport, interurban 4911' },
      { code: 'H49.2', description: '49.2 Freight rail transport' },
      { code: 'H49.20', description: '49.20 Freight rail transport 4912' },
      { code: 'H49.3', description: '49.3 Other passenger land transport' },
      { code: 'H49.31', description: '49.31 Urban and suburban passenger land transport 4921' },
      { code: 'H49.32', description: '49.32 Taxi operation 4922*' },
      { code: 'H49.39', description: '49.39 Other passenger land transport n.e.c. 4922*' },
      { code: 'H49.4', description: '49.4 Freight transport by road and removal services' },
      { code: 'H49.41', description: '49.41 Freight transport by road 4923*' },
      { code: 'H49.42', description: '49.42 Removal services 4923*' },
      { code: 'H49.5', description: '49.5 Transport via pipeline' },
      { code: 'H49.50', description: '49.50 Transport via pipeline 4930' },
      { code: 'H50', description: '50 Water transport' },
      { code: 'H50.1', description: '50.1 Sea and coastal passenger water transport' },
      { code: 'H50.10', description: '50.10 Sea and coastal passenger water transport 5011' },
      { code: 'H50.2', description: '50.2 Sea and coastal freight water transport' },
      { code: 'H50.20', description: '50.20 Sea and coastal freight water transport 5012' },
      { code: 'H50.3', description: '50.3 Inland passenger water transport' },
      { code: 'H50.30', description: '50.30 Inland passenger water transport 5021' },
      { code: 'H50.4', description: '50.4 Inland freight water transport' },
      { code: 'H50.40', description: '50.40 Inland freight water transport 5022' },
      { code: 'H51', description: '51 Air transport' },
      { code: 'H51.1', description: '51.1 Passenger air transport' },
      { code: 'H51.10', description: '51.10 Passenger air transport 5110' },
      { code: 'H51.2', description: '51.2 Freight air transport and space transport' },
      { code: 'H51.21', description: '51.21 Freight air transport 5120*' },
      { code: 'H51.22', description: '51.22 Space transport 5120*' },
      { code: 'H52', description: '52 Warehousing and support activities for transportation' },
      { code: 'H52.1', description: '52.1 Warehousing and storage' },
      { code: 'H52.10', description: '52.10 Warehousing and storage 5210' },
      { code: 'H52.2', description: '52.2 Support activities for transportation' },
      { code: 'H52.21', description: '52.21 Service activities incidental to land transportation 5221' },
      { code: 'H52.22', description: '52.22 Service activities incidental to water transportation 5222' },
      { code: 'H52.23', description: '52.23 Service activities incidental to air transportation 5223' },
      { code: 'H52.24', description: '52.24 Cargo handling 5224' },
      { code: 'H52.29', description: '52.29 Other transportation support activities 5229' },
      { code: 'H53', description: '53 Postal and courier activities' },
      { code: 'H53.1', description: '53.1 Postal activities under universal service obligation' },
      { code: 'H53.10', description: '53.10 Postal activities under universal service obligation 5310' },
      { code: 'H53.2', description: '53.2 Other postal and courier activities' },
      { code: 'H53.20', description: '53.20 Other postal and courier activities 5320' },
    ],
  },
  {
    section: { letter: 'I', description: 'I — ACCOMMODATION AND FOOD SERVICE ACTIVITIES' },
    codes: [
      { code: 'I55', description: '55 Accommodation' },
      { code: 'I55.1', description: '55.1 Hotels and similar accommodation' },
      { code: 'I55.10', description: '55.10 Hotels and similar accommodation 5510*' },
      { code: 'I55.2', description: '55.2 Holiday and other short-stay accommodation' },
      { code: 'I55.20', description: '55.20 Holiday and other short-stay accommodation 5510*' },
      { code: 'I55.3', description: '55.3 Camping grounds, recreational vehicle parks and trailer parks' },
      { code: 'I55.30', description: '55.30 Camping grounds, recreational vehicle parks and trailer parks 5520' },
      { code: 'I55.9', description: '55.9 Other accommodation' },
      { code: 'I55.90', description: '55.90 Other accommodation 5590' },
      { code: 'I56', description: '56 Food and beverage service activities' },
      { code: 'I56.1', description: '56.1 Restaurants and mobile food service activities' },
      { code: 'I56.10', description: '56.10 Restaurants and mobile food service activities 5610' },
      { code: 'I56.2', description: '56.2 Event catering and other food service activities' },
      { code: 'I56.21', description: '56.21 Event catering activities 5621' },
      { code: 'I56.29', description: '56.29 Other food service activities 5629' },
      { code: 'I56.3', description: '56.3 Beverage serving activities' },
      { code: 'I56.30', description: '56.30 Beverage serving activities 5630' },
    ],
  },
  {
    section: { letter: 'J', description: 'J — INFORMATION AND COMMUNICATION' },
    codes: [
      { code: 'J58', description: '58 Publishing activities' },
      { code: 'J58.1', description: '58.1 Publishing of books, periodicals and other publishing activities' },
      { code: 'J58.11', description: '58.11 Book publishing 5811' },
      { code: 'J58.12', description: '58.12 Publishing of directories and mailing lists 5812' },
      { code: 'J58.13', description: '58.13 Publishing of newspapers 5813*' },
      { code: 'J58.14', description: '58.14 Publishing of journals and periodicals 5813*' },
      { code: 'J58.19', description: '58.19 Other publishing activities 5819' },
      { code: 'J58.2', description: '58.2 Software publishing' },
      { code: 'J58.21', description: '58.21 Publishing of computer games 5820*' },
      { code: 'J58.29', description: '58.29 Other software publishing 5820*' },
      {
        code: 'J59',
        description:
          '59 Motion picture, video and television programme production, sound recording and music publishing activities',
      },
      { code: 'J59.1', description: '59.1 Motion picture, video and television programme activities' },
      {
        code: 'J59.11',
        description: '59.11 Motion picture, video and television programme production activities 5911',
      },
      {
        code: 'J59.12',
        description: '59.12 Motion picture, video and television programme post-production activities 5912',
      },
      {
        code: 'J59.13',
        description: '59.13 Motion picture, video and television programme distribution activities 5913',
      },
      { code: 'J59.14', description: '59.14 Motion picture projection activities 5914' },
      { code: 'J59.2', description: '59.2 Sound recording and music publishing activities' },
      { code: 'J59.20', description: '59.20 Sound recording and music publishing activities 5920' },
      { code: 'J60', description: '60 Programming and broadcasting activities' },
      { code: 'J60.1', description: '60.1 Radio broadcasting' },
      { code: 'J60.10', description: '60.10 Radio broadcasting 6010' },
      { code: 'J60.2', description: '60.2 Television programming and broadcasting activities' },
      { code: 'J60.20', description: '60.20 Television programming and broadcasting activities 6020' },
      { code: 'J61', description: '61 Telecommunications' },
      { code: 'J61.1', description: '61.1 Wired telecommunications activities' },
      { code: 'J61.10', description: '61.10 Wired telecommunications activities 6110' },
      { code: 'J61.2', description: '61.2 Wireless telecommunications activities' },
      { code: 'J61.20', description: '61.20 Wireless telecommunications activities 6120' },
      { code: 'J61.3', description: '61.3 Satellite telecommunications activities' },
      { code: 'J61.30', description: '61.30 Satellite telecommunications activities 6130' },
      { code: 'J61.9', description: '61.9 Other telecommunications activities' },
      { code: 'J61.90', description: '61.90 Other telecommunications activities 6190' },
      { code: 'J62', description: '62 Computer programming, consultancy and related activities' },
      { code: 'J62.0', description: '62.0 Computer programming, consultancy and related activities' },
      { code: 'J62.01', description: '62.01 Computer programming activities 6201' },
      { code: 'J62.02', description: '62.02 Computer consultancy activities 6202*' },
      { code: 'J62.03', description: '62.03 Computer facilities management activities 6202*' },
      { code: 'J62.09', description: '62.09 Other information technology and computer service activities 6209' },
      { code: 'J63', description: '63 Information service activities' },
      { code: 'J63.1', description: '63.1 Data processing, hosting and related activities; web portals' },
      { code: 'J63.11', description: '63.11 Data processing, hosting and related activities 6311' },
      { code: 'J63.12', description: '63.12 Web portals 6312' },
      { code: 'J63.9', description: '63.9 Other information service activities' },
      { code: 'J63.91', description: '63.91 News agency activities 6391' },
      { code: 'J63.99', description: '63.99 Other information service activities n.e.c. 6399' },
    ],
  },
  {
    section: { letter: 'K', description: 'K — FINANCIAL AND INSURANCE ACTIVITIES' },
    codes: [
      { code: 'K64', description: '64 Financial service activities, except insurance and pension funding' },
      { code: 'K64.1', description: '64.1 Monetary intermediation' },
      { code: 'K64.11', description: '64.11 Central banking 6411' },
      { code: 'K64.19', description: '64.19 Other monetary intermediation 6419' },
      { code: 'K64.2', description: '64.2 Activities of holding companies' },
      { code: 'K64.20', description: '64.20 Activities of holding companies 6420' },
      { code: 'K64.3', description: '64.3 Trusts, funds and similar financial entities' },
      { code: 'K64.30', description: '64.30 Trusts, funds and similar financial entities 6430' },
      { code: 'K64.9', description: '64.9 Other financial service activities, except insurance and pension funding' },
      { code: 'K64.91', description: '64.91 Financial leasing 6491' },
      { code: 'K64.92', description: '64.92 Other credit granting 6492' },
      {
        code: 'K64.99',
        description: '64.99 Other financial service activities, except insurance and pension funding n.e.c. 6499',
      },
      { code: 'K65', description: '65 Insurance, reinsurance and pension funding, except compulsory social security' },
      { code: 'K65.1', description: '65.1 Insurance' },
      { code: 'K65.11', description: '65.11 Life insurance 6511' },
      { code: 'K65.12', description: '65.12 Non-life insurance 6512' },
      { code: 'K65.2', description: '65.2 Reinsurance' },
      { code: 'K65.20', description: '65.20 Reinsurance 6520' },
      { code: 'K65.3', description: '65.3 Pension funding' },
      { code: 'K65.30', description: '65.30 Pension funding 6530' },
      { code: 'K66', description: '66 Activities auxiliary to financial services and insurance activities' },
      {
        code: 'K66.1',
        description: '66.1 Activities auxiliary to financial services, except insurance and pension funding',
      },
      { code: 'K66.11', description: '66.11 Administration of financial markets 6611' },
      { code: 'K66.12', description: '66.12 Security and commodity contracts brokerage 6612' },
      {
        code: 'K66.19',
        description:
          '66.19 Other activities auxiliary to financial services, except insurance and pension funding 6619',
      },
      { code: 'K66.2', description: '66.2 Activities auxiliary to insurance and pension funding' },
      { code: 'K66.21', description: '66.21 Risk and damage evaluation 6621' },
      { code: 'K66.22', description: '66.22 Activities of insurance agents and brokers 6622' },
      { code: 'K66.29', description: '66.29 Other activities auxiliary to insurance and pension funding 6629' },
      { code: 'K66.3', description: '66.3 Fund management activities' },
      { code: 'K66.30', description: '66.30 Fund management activities 6630' },
    ],
  },
  {
    section: { letter: 'L', description: 'L — REAL ESTATE ACTIVITIES' },
    codes: [
      { code: 'L68', description: '68 Real estate activities' },
      { code: 'L68.1', description: '68.1 Buying and selling of own real estate' },
      { code: 'L68.10', description: '68.10 Buying and selling of own real estate 6810*' },
      { code: 'L68.2', description: '68.2 Renting and operating of own or leased real estate' },
      { code: 'L68.20', description: '68.20 Renting and operating of own or leased real estate 6810*' },
      { code: 'L68.3', description: '68.3 Real estate activities on a fee or contract basis' },
      { code: 'L68.31', description: '68.31 Real estate agencies 6820*' },
      { code: 'L68.32', description: '68.32 Management of real estate on a fee or contract basis 6820*' },
    ],
  },
  {
    section: { letter: 'M', description: 'M — PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES' },
    codes: [
      { code: 'M69', description: '69 Legal and accounting activities' },
      { code: 'M69.1', description: '69.1 Legal activities' },
      { code: 'M69.10', description: '69.10 Legal activities 6910' },
      { code: 'M69.2', description: '69.2 Accounting, bookkeeping and auditing activities; tax consultancy' },
      { code: 'M69.20', description: '69.20 Accounting, bookkeeping and auditing activities; tax consultancy 6920' },
      { code: 'M70', description: '70 Activities of head offices; management consultancy activities' },
      { code: 'M70.1', description: '70.1 Activities of head offices' },
      { code: 'M70.10', description: '70.10 Activities of head offices 7010' },
      { code: 'M70.2', description: '70.2 Management consultancy activities' },
      { code: 'M70.21', description: '70.21 Public relations and communication activities 7020*' },
      { code: 'M70.22', description: '70.22 Business and other management consultancy activities 7020*' },
      { code: 'M71', description: '71 Architectural and engineering activities; technical testing and analysis' },
      { code: 'M71.1', description: '71.1 Architectural and engineering activities and related technical consultancy' },
      { code: 'M71.11', description: '71.11 Architectural activities 7110*' },
      { code: 'M71.12', description: '71.12 Engineering activities and related technical consultancy 7110*' },
      { code: 'M71.2', description: '71.2 Technical testing and analysis' },
      { code: 'M71.20', description: '71.20 Technical testing and analysis 7120' },
      { code: 'M72', description: '72 Scientific research and development' },
      { code: 'M72.1', description: '72.1 Research and experimental development on natural sciences and engineering' },
      { code: 'M72.11', description: '72.11 Research and experimental development on biotechnology 7210*' },
      {
        code: 'M72.19',
        description: '72.19 Other research and experimental development on natural sciences and engineering 7210*',
      },
      { code: 'M72.2', description: '72.2 Research and experimental development on social sciences and humanities' },
      {
        code: 'M72.20',
        description: '72.20 Research and experimental development on social sciences and humanities 7220',
      },
      { code: 'M73', description: '73 Advertising and market research' },
      { code: 'M73.1', description: '73.1 Advertising' },
      { code: 'M73.11', description: '73.11 Advertising agencies 7310*' },
      { code: 'M73.12', description: '73.12 Media representation 7310*' },
      { code: 'M73.2', description: '73.2 Market research and public opinion polling' },
      { code: 'M73.20', description: '73.20 Market research and public opinion polling 7320' },
      { code: 'M74', description: '74 Other professional, scientific and technical activities' },
      { code: 'M74.1', description: '74.1 Specialised design activities' },
      { code: 'M74.10', description: '74.10 Specialised design activities 7410' },
      { code: 'M74.2', description: '74.2 Photographic activities' },
      { code: 'M74.20', description: '74.20 Photographic activities 7420' },
      { code: 'M74.3', description: '74.3 Translation and interpretation activities' },
      { code: 'M74.30', description: '74.30 Translation and interpretation activities 7490*' },
      { code: 'M74.9', description: '74.9 Other professional, scientific and technical activities n.e.c.' },
      { code: 'M74.90', description: '74.90 Other professional, scientific and technical activities n.e.c. 7490*' },
      { code: 'M75', description: '75 Veterinary activities' },
      { code: 'M75.0', description: '75.0 Veterinary activities' },
      { code: 'M75.00', description: '75.00 Veterinary activities 7500' },
    ],
  },
  {
    section: { letter: 'N', description: 'N — ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES' },
    codes: [
      { code: 'N77', description: '77 Rental and leasing activities' },
      { code: 'N77.1', description: '77.1 Renting and leasing of motor vehicles' },
      { code: 'N77.11', description: '77.11 Renting and leasing of cars and light motor vehicles 7710*' },
      { code: 'N77.12', description: '77.12 Renting and leasing of trucks 7710*' },
      { code: 'N77.2', description: '77.2 Renting and leasing of personal and household goods' },
      { code: 'N77.21', description: '77.21 Renting and leasing of recreational and sports goods 7721' },
      { code: 'N77.22', description: '77.22 Renting of video tapes and disks 7722' },
      { code: 'N77.29', description: '77.29 Renting and leasing of other personal and household goods 7729' },
      { code: 'N77.3', description: '77.3 Renting and leasing of other machinery, equipment and tangible goods' },
      { code: 'N77.31', description: '77.31 Renting and leasing of agricultural machinery and equipment 7730*' },
      {
        code: 'N77.32',
        description: '77.32 Renting and leasing of construction and civil engineering machinery and equipment 7730*',
      },
      {
        code: 'N77.33',
        description: '77.33 Renting and leasing of office machinery and equipment (including computers) 7730*',
      },
      { code: 'N77.34', description: '77.34 Renting and leasing of water transport equipment 7730*' },
      { code: 'N77.35', description: '77.35 Renting and leasing of air transport equipment 7730*' },
      {
        code: 'N77.39',
        description: '77.39 Renting and leasing of other machinery, equipment and tangible goods n.e.c. 7730*',
      },
      {
        code: 'N77.4',
        description: '77.4 Leasing of intellectual property and similar products, except copyrighted works',
      },
      {
        code: 'N77.40',
        description: '77.40 Leasing of intellectual property and similar products, except copyrighted works 7740',
      },
      { code: 'N78', description: '78 Employment activities' },
      { code: 'N78.1', description: '78.1 Activities of employment placement agencies' },
      { code: 'N78.10', description: '78.10 Activities of employment placement agencies 7810' },
      { code: 'N78.2', description: '78.2 Temporary employment agency activities' },
      { code: 'N78.20', description: '78.20 Temporary employment agency activities 7820' },
      { code: 'N78.3', description: '78.3 Other human resources provision' },
      { code: 'N78.30', description: '78.30 Other human resources provision 7830' },
      { code: 'N79', description: '79 Travel agency, tour operator reservation service and related activities' },
      { code: 'N79.1', description: '79.1 Travel agency and tour operator activities' },
      { code: 'N79.11', description: '79.11 Travel agency activities 7911' },
      { code: 'N79.12', description: '79.12 Tour operator activities 7912' },
      { code: 'N79.9', description: '79.9 Other reservation service and related activities' },
      { code: 'N79.90', description: '79.90 Other reservation service and related activities 7990' },
      { code: 'N80', description: '80 Security and investigation activities' },
      { code: 'N80.1', description: '80.1 Private security activities' },
      { code: 'N80.10', description: '80.10 Private security activities 8010' },
      { code: 'N80.2', description: '80.2 Security systems service activities' },
      { code: 'N80.20', description: '80.20 Security systems service activities 8020' },
      { code: 'N80.3', description: '80.3 Investigation activities' },
      { code: 'N80.30', description: '80.30 Investigation activities 8030' },
      { code: 'N81', description: '81 Services to buildings and landscape activities' },
      { code: 'N81.1', description: '81.1 Combined facilities support activities' },
      { code: 'N81.10', description: '81.10 Combined facilities support activities 8110' },
      { code: 'N81.2', description: '81.2 Cleaning activities' },
      { code: 'N81.21', description: '81.21 General cleaning of buildings 8121' },
      { code: 'N81.22', description: '81.22 Other building and industrial cleaning activities 8129*' },
      { code: 'N81.29', description: '81.29 Other cleaning activities 8129*' },
      { code: 'N81.3', description: '81.3 Landscape service activities' },
      { code: 'N81.30', description: '81.30 Landscape service activities 8130' },
      { code: 'N82', description: '82 Office administrative, office support and other business support activities' },
      { code: 'N82.1', description: '82.1 Office administrative and support activities' },
      { code: 'N82.11', description: '82.11 Combined office administrative service activities 8211' },
      {
        code: 'N82.19',
        description: '82.19 Photocopying, document preparation and other specialised office support activities 8219',
      },
      { code: 'N82.2', description: '82.2 Activities of call centres' },
      { code: 'N82.20', description: '82.20 Activities of call centres 8220' },
      { code: 'N82.3', description: '82.3 Organisation of conventions and trade shows' },
      { code: 'N82.30', description: '82.30 Organisation of conventions and trade shows 8230' },
      { code: 'N82.9', description: '82.9 Business support service activities n.e.c.' },
      { code: 'N82.91', description: '82.91 Activities of collection agencies and credit bureaus 8291' },
      { code: 'N82.92', description: '82.92 Packaging activities 8292' },
      { code: 'N82.99', description: '82.99 Other business support service activities n.e.c. 8299' },
    ],
  },
  {
    section: { letter: 'O', description: 'O — PUBLIC ADMINISTRATION AND DEFENCE; COMPULSORY SOCIAL SECURITY' },
    codes: [
      { code: 'O84', description: '84 Public administration and defence; compulsory social security' },
      {
        code: 'O84.1',
        description: '84.1 Administration of the State and the economic and social policy of the community',
      },
      { code: 'O84.11', description: '84.11 General public administration activities 8411' },
      {
        code: 'O84.12',
        description:
          '84.12 Regulation of the activities of providing health care, education, cultural services and other social services, excluding social security 8412',
      },
      {
        code: 'O84.13',
        description: '84.13 Regulation of and contribution to more efficient operation of businesses 8413',
      },
      { code: 'O84.2', description: '84.2 Provision of services to the community as a whole' },
      { code: 'O84.21', description: '84.21 Foreign affairs 8421' },
      { code: 'O84.22', description: '84.22 Defence activities 8422' },
      { code: 'O84.23', description: '84.23 Justice and judicial activities 8423*' },
      { code: 'O84.24', description: '84.24 Public order and safety activities 8423*' },
      { code: 'O84.25', description: '84.25 Fire service activities 8423*' },
      { code: 'O84.3', description: '84.3 Compulsory social security activities' },
      { code: 'O84.30', description: '84.30 Compulsory social security activities 8430' },
    ],
  },
  {
    section: { letter: 'P', description: 'P — EDUCATION' },
    codes: [
      { code: 'P85', description: '85 Education' },
      { code: 'P85.1', description: '85.1 Pre-primary education' },
      { code: 'P85.10', description: '85.10 Pre-primary education 8510*' },
      { code: 'P85.2', description: '85.2 Primary education' },
      { code: 'P85.20', description: '85.20 Primary education 8510*' },
      { code: 'P85.3', description: '85.3 Secondary education' },
      { code: 'P85.31', description: '85.31 General secondary education 8521' },
      { code: 'P85.32', description: '85.32 Technical and vocational secondary education 8522' },
      { code: 'P85.4', description: '85.4 Higher education' },
      { code: 'P85.41', description: '85.41 Post-secondary non-tertiary education 8530*' },
      { code: 'P85.42', description: '85.42 Tertiary education 8530*' },
      { code: 'P85.5', description: '85.5 Other education' },
      { code: 'P85.51', description: '85.51 Sports and recreation education 8541' },
      { code: 'P85.52', description: '85.52 Cultural education 8542' },
      { code: 'P85.53', description: '85.53 Driving school activities 8549*' },
      { code: 'P85.59', description: '85.59 Other education n.e.c. 8549*' },
      { code: 'P85.6', description: '85.6 Educational support activities' },
      { code: 'P85.60', description: '85.60 Educational support activities 8550' },
    ],
  },
  {
    section: { letter: 'Q', description: 'Q — HUMAN HEALTH AND SOCIAL WORK ACTIVITIES' },
    codes: [
      { code: 'Q86', description: '86 Human health activities' },
      { code: 'Q86.1', description: '86.1 Hospital activities' },
      { code: 'Q86.10', description: '86.10 Hospital activities 8610' },
      { code: 'Q86.2', description: '86.2 Medical and dental practice activities' },
      { code: 'Q86.21', description: '86.21 General medical practice activities 8620*' },
      { code: 'Q86.22', description: '86.22 Specialist medical practice activities 8620*' },
      { code: 'Q86.23', description: '86.23 Dental practice activities 8620*' },
      { code: 'Q86.9', description: '86.9 Other human health activities' },
      { code: 'Q86.90', description: '86.90 Other human health activities 8690' },
      { code: 'Q87', description: '87 Residential care activities' },
      { code: 'Q87.1', description: '87.1 Residential nursing care activities' },
      { code: 'Q87.10', description: '87.10 Residential nursing care activities 8710' },
      {
        code: 'Q87.2',
        description: '87.2 Residential care activities for mental retardation, mental health and substance abuse',
      },
      {
        code: 'Q87.20',
        description: '87.20 Residential care activities for mental retardation, mental health and substance abuse 8720',
      },
      { code: 'Q87.3', description: '87.3 Residential care activities for the elderly and disabled' },
      { code: 'Q87.30', description: '87.30 Residential care activities for the elderly and disabled 8730' },
      { code: 'Q87.9', description: '87.9 Other residential care activities' },
      { code: 'Q87.90', description: '87.90 Other residential care activities 8790' },
      { code: 'Q88', description: '88 Social work activities without accommodation' },
      { code: 'Q88.1', description: '88.1 Social work activities without accommodation for the elderly and disabled' },
      {
        code: 'Q88.10',
        description: '88.10 Social work activities without accommodation for the elderly and disabled 8810',
      },
      { code: 'Q88.9', description: '88.9 Other social work activities without accommodation' },
      { code: 'Q88.91', description: '88.91 Child day-care activities 8890*' },
      { code: 'Q88.99', description: '88.99 Other social work activities without accommodation n.e.c. 8890*' },
    ],
  },
  {
    section: { letter: 'R', description: 'R — ARTS, ENTERTAINMENT AND RECREATION' },
    codes: [
      { code: 'R90', description: '90 Creative, arts and entertainment activities' },
      { code: 'R90.0', description: '90.0 Creative, arts and entertainment activities' },
      { code: 'R90.01', description: '90.01 Performing arts 9000*' },
      { code: 'R90.02', description: '90.02 Support activities to performing arts 9000*' },
      { code: 'R90.03', description: '90.03 Artistic creation 9000*' },
      { code: 'R90.04', description: '90.04 Operation of arts facilities 9000*' },
      { code: 'R91', description: '91 Libraries, archives, museums and other cultural activities' },
      { code: 'R91.0', description: '91.0 Libraries, archives, museums and other cultural activities' },
      { code: 'R91.01', description: '91.01 Library and archives activities 9101' },
      { code: 'R91.02', description: '91.02 Museums activities 9102*' },
      {
        code: 'R91.03',
        description: '91.03 Operation of historical sites and buildings and similar visitor attractions 9102*',
      },
      { code: 'R91.04', description: '91.04 Botanical and zoological gardens and nature reserves activities 9103' },
      { code: 'R92', description: '92 Gambling and betting activities' },
      { code: 'R92.0', description: '92.0 Gambling and betting activities' },
      { code: 'R92.00', description: '92.00 Gambling and betting activities 9200' },
      { code: 'R93', description: '93 Sports activities and amusement and recreation activities' },
      { code: 'R93.1', description: '93.1 Sports activities' },
      { code: 'R93.11', description: '93.11 Operation of sports facilities 9311*' },
      { code: 'R93.12', description: '93.12 Activities of sport clubs 9312' },
      { code: 'R93.13', description: '93.13 Fitness facilities 9311*' },
      { code: 'R93.19', description: '93.19 Other sports activities 9319' },
      { code: 'R93.2', description: '93.2 Amusement and recreation activities' },
      { code: 'R93.21', description: '93.21 Activities of amusement parks and theme parks 9321' },
      { code: 'R93.29', description: '93.29 Other amusement and recreation activities 9329' },
    ],
  },
  {
    section: { letter: 'S', description: 'S — OTHER SERVICE ACTIVITIES' },
    codes: [
      { code: 'S94', description: '94 Activities of membership organisations' },
      {
        code: 'S94.1',
        description: '94.1 Activities of business, employers and professional membership organisations',
      },
      { code: 'S94.11', description: '94.11 Activities of business and employers membership organisations 9411' },
      { code: 'S94.12', description: '94.12 Activities of professional membership organisations 9412' },
      { code: 'S94.2', description: '94.2 Activities of trade unions' },
      { code: 'S94.20', description: '94.20 Activities of trade unions 9420' },
      { code: 'S94.9', description: '94.9 Activities of other membership organisations' },
      { code: 'S94.91', description: '94.91 Activities of religious organisations 9491' },
      { code: 'S94.92', description: '94.92 Activities of political organisations 9492' },
      { code: 'S94.99', description: '94.99 Activities of other membership organisations n.e.c. 9499' },
      { code: 'S95', description: '95 Repair of computers and personal and household goods' },
      { code: 'S95.1', description: '95.1 Repair of computers and communication equipment' },
      { code: 'S95.11', description: '95.11 Repair of computers and peripheral equipment 9511' },
      { code: 'S95.12', description: '95.12 Repair of communication equipment 9512' },
      { code: 'S95.2', description: '95.2 Repair of personal and household goods' },
      { code: 'S95.21', description: '95.21 Repair of consumer electronics 9521' },
      { code: 'S95.22', description: '95.22 Repair of household appliances and home and garden equipment 9522' },
      { code: 'S95.23', description: '95.23 Repair of footwear and leather goods 9523' },
      { code: 'S95.24', description: '95.24 Repair of furniture and home furnishings 9524' },
      { code: 'S95.25', description: '95.25 Repair of watches, clocks and jewellery 9529*' },
      { code: 'S95.29', description: '95.29 Repair of other personal and household goods 9529*' },
      { code: 'S96', description: '96 Other personal service activities' },
      { code: 'S96.0', description: '96.0 Other personal service activities' },
      { code: 'S96.01', description: '96.01 Washing and (dry-)cleaning of textile and fur products 9601' },
      { code: 'S96.02', description: '96.02 Hairdressing and other beauty treatment 9602' },
      { code: 'S96.03', description: '96.03 Funeral and related activities 9603' },
      { code: 'S96.04', description: '96.04 Physical well-being activities 9609*' },
      { code: 'S96.09', description: '96.09 Other personal service activities n.e.c. 9609*' },
    ],
  },
  {
    section: {
      letter: 'T',
      description:
        'T — ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS; UNDIFFERENTIATED GOODSAND SERVICES-PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE',
    },
    codes: [
      { code: 'T97', description: '97 Activities of households as employers of domestic personnel' },
      { code: 'T97.0', description: '97.0 Activities of households as employers of domestic personnel' },
      { code: 'T97.00', description: '97.00 Activities of households as employers of domestic personnel 9700' },
      {
        code: 'T98',
        description: '98 Undifferentiated goods- and services-producing activities of private households for own use',
      },
      {
        code: 'T98.1',
        description: '98.1 Undifferentiated goods-producing activities of private households for own use',
      },
      {
        code: 'T98.10',
        description: '98.10 Undifferentiated goods-producing activities of private households for own use 9810',
      },
      {
        code: 'T98.2',
        description: '98.2 Undifferentiated service-producing activities of private households for own use',
      },
      {
        code: 'T98.20',
        description: '98.20 Undifferentiated service-producing activities of private households for own use 9820',
      },
    ],
  },
] as const;

export const allNaceSections = (() => naceCodes.map(({ section }) => section.letter))();

export type NaceCodes = (typeof naceCodes)[number]['codes'][number]['code'];
type NaceDescriptions = (typeof naceCodes)[number]['codes'][number]['description'];
export type NaceSections = (typeof allNaceSections)[number];

export const allNaceCodes: NaceCodes[] = (() => {
  return naceCodes.map(({ codes }) => codes.map(({ code }) => code).flat()).flat();
})();

/**
 * - Create options list for SelectFormField component.
 * - NACE sections are business types.
 */
export const naceSectionsOptionsList = (() => {
  return naceCodes
    .map(({ section }) => section)
    .map(({ letter, description }) => ({ value: letter, label: description }));
})();

const getNaceSection = (searchedSection: NaceSections) => {
  return naceCodes.filter(({ section }) => section.letter === searchedSection)[0];
};

export const getNaceCodesOptionsListBySection = (
  searchedSection: NaceSections
): Array<{ value: NaceCodes; label: NaceDescriptions }> => {
  return getNaceSection(searchedSection).codes.map(({ code, description }) => ({ value: code, label: description }));
};

/**
 * - Create options list for SelectFormField component.
 * - NACE codes are business sub types.
 */
export const naceCodesOptionsList: Array<{ value: NaceCodes; label: NaceDescriptions }> = (() => {
  return naceCodes
    .map(({ codes }) => {
      return codes.map(({ code, description }) => ({ value: code, label: description })).flat();
    })
    .flat();
})();
