import { DescriptionList, FoldOut, ProductInfo, ProductInfoSummary, ProductTable } from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import car_sample from '../../resources/mocks/car_sample.jpg';
import { useSearchVehicleQuery } from '../../services/redux/features/api/search-vehicle.slice';
import applicationSummaryMapper from './applicationSummary.mapper';
import { ApplicationSummaryData } from './applicationSummary.model';

interface ApplicationSummaryProps {
  isDefaultOpen?: boolean;
}

const ApplicationSummary: FunctionComponent<ApplicationSummaryProps> = ({ isDefaultOpen = true }) => {
  const { t } = useTranslation();

  const { isLoading, data } = useSearchVehicleQuery();
  const [mappedData, setMappedData] = useState<ApplicationSummaryData>();
  const [caption, setCaption] = useState('');

  const [defaultOpen, _setDefaultOpen] = useState(isDefaultOpen);

  useEffect(() => {
    if (data) {
      const summaryData = applicationSummaryMapper(data);
      const captionValues = [summaryData.fuelType, summaryData.horsePower, summaryData.gear, summaryData.colorExterior]
        .filter((option) => !!option)
        .join(' | ');

      setCaption(captionValues);
      setMappedData(summaryData);
    }
  }, [data]);

  return (
    !isLoading &&
    mappedData && (
      <FoldOut
        defaultOpen={defaultOpen}
        productInfoSummary={
          <ProductInfoSummary
            imgSrc={car_sample}
            infoLabel={t('applicationSummary:monthlyPayment')}
            infoValue={mappedData.monthlyPayment}
            subtitle={caption}
            title={mappedData.vehicleModel}
          />
        }
      >
        <ProductInfo
          caption={caption}
          media={<img alt={mappedData.vehicleModel} src={car_sample} />}
          title={mappedData.vehicleModel}
          titleTagline={t('applicationSummary:applicationSummary')}
          className="u-mb"
        >
          <ProductTable
            title={mappedData.vehicleModel}
            summary={
              <DescriptionList split>
                <DescriptionList.Group
                  termClassName="c-product-table__summary-term"
                  termText={t('applicationSummary:total')}
                >
                  <DescriptionList.Detail className="c-product-table__summary-detail">
                    {mappedData.total}
                  </DescriptionList.Detail>
                </DescriptionList.Group>
              </DescriptionList>
            }
          >
            <ProductTable.Section>
              <DescriptionList split>
                <DescriptionList.Group termText={t('applicationSummary:pff')}>
                  <DescriptionList.Detail>{mappedData.pff}</DescriptionList.Detail>
                </DescriptionList.Group>
                <DescriptionList.Group
                  termText={`${t('applicationSummary:colorExterior')}: ${mappedData.colorExterior}`}
                >
                  <DescriptionList.Detail>{mappedData.colorExteriorPrice}</DescriptionList.Detail>
                </DescriptionList.Group>
                <DescriptionList.Group
                  termText={`${t('applicationSummary:colorInterior')}: ${mappedData.colorInterior}`}
                >
                  <DescriptionList.Detail>{mappedData.colorInteriorPrice}</DescriptionList.Detail>
                </DescriptionList.Group>
              </DescriptionList>
            </ProductTable.Section>
          </ProductTable>
        </ProductInfo>

        <ProductTable
          summary={
            <DescriptionList split>
              <DescriptionList.Group
                termClassName="c-product-table__summary-term"
                termText={t('applicationSummary:monthlyPayment')}
              >
                <DescriptionList.Detail className="c-product-table__summary-detail">
                  {mappedData.monthlyPayment}
                </DescriptionList.Detail>
                <DescriptionList.Detail className="c-product-table__information u-text-right">
                  {t('applicationSummary:vatIncluded')}
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          }
        >
          <ProductTable.Section>
            <DescriptionList split>
              <DescriptionList.Group termText={t('applicationSummary:client')}>
                <DescriptionList.Detail>{mappedData.client}</DescriptionList.Detail>
              </DescriptionList.Group>
              <DescriptionList.Group termText={t('applicationSummary:financialProduct')}>
                <DescriptionList.Detail>PCP</DescriptionList.Detail>
              </DescriptionList.Group>
              <DescriptionList.Group termText={t('applicationSummary:terms')}>
                <DescriptionList.Detail>{mappedData.terms}</DescriptionList.Detail>
              </DescriptionList.Group>
              <DescriptionList.Group termText={t('applicationSummary:mileage')}>
                <DescriptionList.Detail>{mappedData.mileage}</DescriptionList.Detail>
              </DescriptionList.Group>
              <DescriptionList.Group termText={t('applicationSummary:deposit')}>
                <DescriptionList.Detail>{mappedData.deposit}</DescriptionList.Detail>
              </DescriptionList.Group>
              <DescriptionList.Group termText={t('applicationSummary:totalVat')}>
                <DescriptionList.Detail>{mappedData.totalVat}</DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </ProductTable.Section>
        </ProductTable>
        {/* <Layout className="u-mt">
          <Layout.Item default="1/1" className="u-text-right">
            <Button
              icon="semantic-up"
              link
              round
              secondary
              small
              onClick={() => {
                setDefaultOpen(false);
              }}
            >
              {t('applicationSummary:closeSummary')}
            </Button>
          </Layout.Item>
        </Layout> */}
      </FoldOut>
    )
  );
};

export default ApplicationSummary;
