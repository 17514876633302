import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { CorrespondenceMethodEnum, TelephoneTypeEnum } from '../../types/forms';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { CcrTypeEnum, CustomerDetailsFormModel } from './customerDetailsForm.model';

export interface CustomerDetailsFormState
  extends ReduxStateWithForms<{
    customerDetails1: ReduxTrackedForm<CustomerDetailsFormModel>;
    customerDetails2: ReduxTrackedForm<CustomerDetailsFormModel>;
  }> {}

const customerDetailsInitialValues: CustomerDetailsFormModel = {
  gender: undefined,
  title: undefined,
  maritalStatus: undefined,
  makeIndemnifier: undefined,
  firstName: '',
  lastName: '',
  middleName: '',
  maidenName: '',
  dateOfBirth: '',
  email: '',
  telephoneType: TelephoneTypeEnum.mobile,
  phoneNumber: '',
  countryCode: undefined,
  correspondenceMethod: CorrespondenceMethodEnum.email,
  ccrType: CcrTypeEnum.ppsn,
  ccrId: '',
};

const initialState: CustomerDetailsFormState = {
  forms: {
    customerDetails1: {
      ...createReduxTrackedFormDefaults<CustomerDetailsFormModel>({ initialValues: customerDetailsInitialValues }),
      values: {
        ...customerDetailsInitialValues,
      },
    },
    customerDetails2: {
      ...createReduxTrackedFormDefaults<CustomerDetailsFormModel>({
        excluded: true,
        initialValues: customerDetailsInitialValues,
      }),
      values: {
        ...customerDetailsInitialValues,
      },
    },
  },
};

const name = 'customerDetailsForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<CustomerDetailsFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    customerDetails1Triggers({ customerDetailsForm }) {
      return customerDetailsForm.forms.customerDetails1.triggers;
    },
    customerDetails2Triggers({ customerDetailsForm }) {
      return customerDetailsForm.forms.customerDetails2.triggers;
    },
    customerDetails1Values({ customerDetailsForm }) {
      return customerDetailsForm.forms.customerDetails1.values;
    },
    customerDetails2Values({ customerDetailsForm }) {
      return customerDetailsForm.forms.customerDetails2.values;
    },
    forms({ customerDetailsForm }) {
      return customerDetailsForm.forms;
    },
  },
});

export const customerDetailsFormSlice = slice;
export const customerDetailsFormGettersDef = gettersDef;
