import { RootState } from '../..';
import { apiSlice } from './api.api-slice';

export interface SnCInitializationRequestModel {
  frontendModel: RootState;
  receiverPhoneNumber: string;
  receiverEmail: string;
}

export interface SnCInitializationResponseModel {
  transactionId: string;
  token: string;
}

export interface SnCSaveSessionRequestModel {
  frontendModel: RootState;
  transactionId: string;
}

const removeRedundantStateKeys = (state: RootState): Partial<RootState> => {
  const keysToRemove: Array<keyof RootState> = ['api', 'auth'];
  const workingCopy = { ...state };

  keysToRemove.forEach((key) => {
    delete workingCopy[key];
  });

  return workingCopy;
};

export const saveAndContinueApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    saveSessionWithContinueLink: builder.mutation<SnCInitializationResponseModel, SnCInitializationRequestModel>({
      query: ({ frontendModel, receiverPhoneNumber, receiverEmail }) => ({
        url: `/saveSessionWithContinueLink/${process.env.REACT_APP_BRAND_TYPE}`,
        method: 'POST',
        body: {
          frontendModel: removeRedundantStateKeys(frontendModel),
          receiverPhoneNumber,
          receiverEmail,
        },
      }),
      extraOptions: {
        secondaryPath: '',
        applyProductPath: false,
      },
    }),
    saveSession: builder.mutation<void, SnCSaveSessionRequestModel>({
      query: ({ frontendModel, transactionId }) => ({
        url: `/saveSession/${transactionId}/${process.env.REACT_APP_BRAND_TYPE}`,
        method: 'POST',
        body: {
          frontendModel: removeRedundantStateKeys(frontendModel),
        },
      }),
      extraOptions: {
        secondaryPath: '',
        applyProductPath: false,
      },
    }),
  }),
});

export const { useSaveSessionWithContinueLinkMutation, useSaveSessionMutation } = saveAndContinueApiSlice;
