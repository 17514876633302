import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AdditionalServicePlanFormModel } from '../../../components/AdditionalServicePlan/additionalServicePlan.model';
import { FinancialProductFormModel } from '../../../components/FinancialProductForm/financialProductForm.model';
import { FinancialProductSpecificationFormModel } from '../../../components/FinancialProductSpecificationForm/financialProductSpecificationForm.model';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../services/redux/storeUtils';
import { ReduxTrackedForm } from '../../../types/reduxTrackedForm';

export interface QuoteModel {
  id: string;
  version: string;
  forms: {
    financialProduct: ReduxTrackedForm<FinancialProductFormModel>;
    financialProductSpecification: ReduxTrackedForm<FinancialProductSpecificationFormModel>;
    additionalServicePlan: ReduxTrackedForm<AdditionalServicePlanFormModel>;
  };
}

export interface FinancialProductPageState {
  allFormsValid: boolean;
  quotes: Array<QuoteModel>;
  currentQuote: {
    id: string;
    version: string;
    isEditMode: boolean;
  };
  forceValidateAllFormsTrigger: boolean;
  cString: string;
}

const initialState: FinancialProductPageState = {
  allFormsValid: false,
  quotes: [],
  currentQuote: {
    // This will come from API
    id: '100987',
    version: '001',
    isEditMode: false,
  },
  forceValidateAllFormsTrigger: false,
  cString: '',
};

const name = 'financialProductPage';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAllFormsValid: (state, action: PayloadAction<{ allFormsValid: boolean }>) => {
      state.allFormsValid = action.payload.allFormsValid;
    },
    addCurrentQuote: (
      state,
      action: PayloadAction<{
        form: {
          financialProduct: ReduxTrackedForm<FinancialProductFormModel>;
          financialProductSpecification: ReduxTrackedForm<FinancialProductSpecificationFormModel>;
          additionalServicePlan: ReduxTrackedForm<AdditionalServicePlanFormModel>;
        };
      }>
    ) => {
      const currentForms = { ...action.payload.form };

      state.quotes.push({
        id: state.currentQuote.id,
        version: state.currentQuote.version,
        forms: currentForms,
      });
    },
    updateQuote: (
      state,
      action: PayloadAction<{
        form: {
          financialProduct: ReduxTrackedForm<FinancialProductFormModel>;
          financialProductSpecification: ReduxTrackedForm<FinancialProductSpecificationFormModel>;
          additionalServicePlan: ReduxTrackedForm<AdditionalServicePlanFormModel>;
        };
      }>
    ) => {
      const quoteIndex = state.quotes.findIndex((quote) => quote.id === state.currentQuote.id);
      const currentForms = { ...action.payload.form };

      if (quoteIndex !== -1) {
        state.quotes[quoteIndex].forms = currentForms;
      }
    },
    copyQuote: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      const quoteIndex = state.quotes.findIndex((quote) => quote.id === action.payload.id);

      if (quoteIndex !== -1) {
        const quote = state.quotes[quoteIndex];
        const newQuote = { ...quote };

        const simulatedId = `${newQuote.id}${Math.ceil(Math.random() * 10)}`;
        newQuote.id = simulatedId;

        state.quotes.push(newQuote);
      }
    },
    deleteQuote: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      const quoteIndex = state.quotes.findIndex((quote) => quote.id === action.payload.id);

      if (quoteIndex !== -1) {
        state.quotes.splice(quoteIndex, 1);
      }
    },
    loadQuoteForEditing: (
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) => {
      const quoteIndex = state.quotes.findIndex((quote) => quote.id === action.payload.id);

      if (quoteIndex !== -1) {
        const editedQuote = { ...state.quotes[quoteIndex] };

        state.currentQuote = {
          id: editedQuote.id,
          version: editedQuote.version,
          isEditMode: true,
        };
      }
    },
    setCString: (
      state,
      action: PayloadAction<{
        cString: string;
      }>
    ) => {
      state.cString = action.payload.cString;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    areAllFormsValid({ financialProductPage }) {
      return financialProductPage.allFormsValid;
    },
    quotes({ financialProductPage }) {
      return financialProductPage.quotes;
    },
    getCurrentQuote({ financialProductPage }) {
      const quoteIndex = financialProductPage.quotes.findIndex(
        (quote) => quote.id === financialProductPage.currentQuote.id
      );

      if (quoteIndex !== -1) {
        return financialProductPage.quotes[quoteIndex];
      }

      return null;
    },
    currentQuote({ financialProductPage }) {
      return financialProductPage.currentQuote;
    },
    cString({ financialProductPage }) {
      return financialProductPage.cString;
    },
  },
});

export const financialProductPageSlice = slice;
export const financialProductPageGettersDef = gettersDef;
