import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { RootState } from '../../services/redux';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { VehicleTypeSelectFormFieldsModel } from './vehicleTypeSelectPage.model';

interface VehicleTypeSelectState
  extends ReduxStateWithForms<{
    vehicleTypeSelect: ReduxTrackedForm<VehicleTypeSelectFormFieldsModel>;
  }> {}

const vehicleTypeSelectFormFieldsInitialValues: VehicleTypeSelectFormFieldsModel = {
  selectedType: '',
};

const initialState: VehicleTypeSelectState = {
  forms: {
    vehicleTypeSelect: {
      ...createReduxTrackedFormDefaults<VehicleTypeSelectFormFieldsModel>({
        initialValues: vehicleTypeSelectFormFieldsInitialValues,
      }),
      values: {
        ...vehicleTypeSelectFormFieldsInitialValues,
      },
    },
  },
};

const name = 'vehicleTypeSelect';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<VehicleTypeSelectState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

export const selectAllForms = (state: RootState) => state.vehicleTypeSelect.forms;

export const selectForm = createSelector(
  [selectAllForms, (_state: RootState, formName: keyof VehicleTypeSelectState['forms']) => ({ formName })],
  (forms, { formName }) => {
    return forms[formName];
  }
);

const gettersDef = createGettersDefinition({
  reactive: {
    vehicleTypeSelectForm({ vehicleTypeSelect }) {
      return vehicleTypeSelect.forms.vehicleTypeSelect;
    },
  },
});

export const vehicleTypeSelectPageSlice = slice;
export const vehicleTypeSelectPageGettersDef = gettersDef;
