import { getters, store } from '../../services/redux';
import { useSaveSessionMutation } from '../../services/redux/features/api/saveAndContinue.api-slice';

export default function useSaveSession() {
  const { transactionId } = getters.transaction;

  const [saveSession, { isLoading }] = useSaveSessionMutation();

  const handleSaveSession = async () => {
    if (!transactionId) {
      // eslint-disable-next-line no-console
      console.error('Error saving session: missing transactionId');
      return;
    }

    await saveSession({ transactionId, frontendModel: store.getState() });
  };

  return { handleSaveSession, isLoading };
}
