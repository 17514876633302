import { Button, ButtonContainer, Fieldset, FormSection, FormSectionGroup, Layout } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BankDetailsForm } from '../../../../components/BankDetailsForm/BankDetailsForm';
import { ContactAddressForm } from '../../../../components/ContactAddressForm/ContactAddressForm';
import { ContactDetailsForm } from '../../../../components/ContactDetailsForm/ContactDetailsForm';
import { TradingDetailsSIForm } from '../../../../components/TradingDetailsSIForm/TradingDetailsSIForm';
import {
  FormUpdateActionTypeGeneric,
  checkIfSectionIsReadyAndValid,
  getFormSectionId,
  getFormSectionStatusProps,
  getFormsState,
  scrollIntoSection,
  setAllFoldToFalse,
} from '../../../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../../../services/redux';
import { FlowTypeEnum } from '../../../../types/forms';
import useSaveSession from '../../../../utils/useSaveSession/useSaveSession';
import { getSectionTitleForCustomerCurried } from '../../../utils';

enum AvailableSections {
  tradingDetails,
  contactDetails,
  contactAddress,
  bankDetails,
}

const NavigationControls = ({ onNextNavigate }) => {
  return (
    <>
      <Layout right>
        <Layout.Item default="1/6">
          <ButtonContainer right>
            <Button onClick={onNextNavigate}>{t('customerDataPage:nextFormButton')}</Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </>
  );
};

const getSectionTitleForCustomerLocal = getSectionTitleForCustomerCurried(FlowTypeEnum.soleTrader);

export const customerDataSIBusinessSoleTraderUpdateForm = {
  mainApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.tradingDetailsSIForm.updateForm({ formName: 'tradingDetailsSIForm', ...payload });
    actions.contactDetailsForm.updateForm({ formName: 'contactDetailsForm', ...payload });
    actions.contactAddressForm.updateForm({ formName: 'contactAddressForm', ...payload });
    actions.bankDetailsForm.updateForm({ formName: 'bankDetails', ...payload });
  },
};

export const CustomerDataSIBusinessSoleTrader = () => {
  const formSectionDesignator = 'customerDataSIBusinessSoleTraderFormSection';
  const { focusOnError } = useGetters().customerDataPage;
  const [currentSection, setCurrentSection] = useState(AvailableSections.tradingDetails);
  const [pendingSection, setPendingSection] = useState<AvailableSections | null>(
    () => AvailableSections.tradingDetails
  );

  const { handleSaveSession } = useSaveSession();

  // When in Standalone Service Plan user selected Upfront payment for service plan,
  // then we do not need Bank Details
  const isUpfrontPayment =
    getters.standaloneServicePlanForm.standaloneServicePlanValues.paymentType?.toLowerCase() === 'upfront';

  useEffect(() => {
    if (isUpfrontPayment) {
      actions.bankDetailsForm.updateForm({ formName: 'bankDetails', excluded: true });
    }
  }, [isUpfrontPayment]);

  const [foldStatus, setFoldStatus] = useState<Record<number, boolean>>({
    [AvailableSections.tradingDetails]: false,
    [AvailableSections.contactAddress]: false,
    [AvailableSections.contactDetails]: false,
    [AvailableSections.bankDetails]: false,
  });

  const formsState = getFormsState({
    ...useGetters().tradingDetailsSIForm.forms,
    ...useGetters().contactDetailsForm.forms,
    ...useGetters().contactAddressForm.forms,
    ...useGetters().bankDetailsForm.forms,
  });
  const [areAllFormsSettled, setAreAllFormsSettled] = useState(false);
  const areAllFormsValid = Object.entries(formsState).every(([, { isValid, excluded }]) => isValid || excluded);
  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const setActiveSection = (section: AvailableSections) => {
    setCurrentSection(section);
  };

  const formSectionMap = useMemo(() => {
    return {
      [AvailableSections.tradingDetails]: formsState.tradingDetailsSIForm,
      [AvailableSections.contactAddress]: formsState.contactAddressForm,
      [AvailableSections.contactDetails]: formsState.contactDetailsForm,
      [AvailableSections.bankDetails]: formsState.bankDetails,
    };
  }, [formsState]);

  useEffect(() => {
    if (areAllFormsValid) {
      actions.customerDataSIBusinessSoleTrader.setAllFormsValid({ allFormsValid: true });
    } else {
      actions.customerDataSIBusinessSoleTrader.setAllFormsValid({ allFormsValid: false });
    }
  }, [areAllFormsValid]);

  const { currentSectionIsReady, currentSectionIsValid } = checkIfSectionIsReadyAndValid(
    AvailableSections,
    currentSection,
    formSectionMap
  );

  const findFirstSectionToOpen = useCallback((): AvailableSections => {
    return Object.entries(formSectionMap).find(([section]) => {
      const form = formSectionMap[section];
      const props = getFormSectionStatusProps(form);
      return props.error;
    })?.[0] as unknown as AvailableSections;
  }, [formSectionMap]);

  useEffect(() => {
    if (pendingSection === null || !currentSectionIsReady) return;
    if (currentSectionIsValid) setCurrentSection(pendingSection);
    setPendingSection(null);
  }, [currentSection, pendingSection, currentSectionIsReady, currentSectionIsValid]);

  useEffect(
    function focusOnFirstErrorSection() {
      if (focusOnError && areAllFormsSettled) {
        const firstSectionToOpen = findFirstSectionToOpen();
        if (firstSectionToOpen) {
          setCurrentSection(firstSectionToOpen);
          actions.customerDataPage.updateFocusOnError({ state: false });
        }
      }
    },
    [focusOnError, findFirstSectionToOpen, areAllFormsSettled]
  );

  useEffect(
    function openNextActiveSection() {
      const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

      // When available Save And Continue Lead form must not be scrolled out of viewport
      if (!disableAllFormControls) {
        scrollIntoSection(getFormSectionId<AvailableSections>(formSectionDesignator, currentSection));
      }

      const next = { ...resetFoldStatus, [currentSection]: true };

      setFoldStatus(next);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSection]
  );

  const changeFoldStatus = (section: AvailableSections, isOpened: boolean) => {
    const next = { ...foldStatus, [section]: isOpened };
    setFoldStatus(next);
  };

  const closeAllSections = () => {
    const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);
    setFoldStatus(resetFoldStatus);
  };

  useEffect(() => {
    setAreAllFormsSettled(
      Object.entries(formsState).every(([, { isValidating, excluded }]) => !isValidating || excluded)
    );
  }, [formsState]);

  return (
    <FormSectionGroup>
      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.tradingDetails)}
        defaultOpen={foldStatus[AvailableSections.tradingDetails]}
        title={getSectionTitleForCustomerLocal('tradingDetailsSIForm')}
        onClickCapture={() => setActiveSection(AvailableSections.tradingDetails)}
        {...getFormSectionStatusProps(formsState.tradingDetailsSIForm)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.tradingDetails, isOpened);
        }}
      >
        <Fieldset.Row>
          <TradingDetailsSIForm />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.tradingDetailsSIForm.updateForm({ triggerValidation: true, formName: 'tradingDetailsSIForm' });
              setPendingSection(AvailableSections.contactDetails);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.contactDetails)}
        defaultOpen={foldStatus[AvailableSections.contactDetails]}
        title={getSectionTitleForCustomerLocal('contactDetailsForm')}
        onClickCapture={() => setActiveSection(AvailableSections.contactDetails)}
        {...getFormSectionStatusProps(formsState.contactDetailsForm)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.contactDetails, isOpened);
        }}
      >
        <Fieldset.Row>
          <ContactDetailsForm />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.contactDetailsForm.updateForm({ triggerValidation: true, formName: 'contactDetailsForm' });
              setPendingSection(AvailableSections.contactAddress);
            }}
          />
        </Fieldset.Row>
      </FormSection>

      <FormSection
        id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.contactAddress)}
        defaultOpen={foldStatus[AvailableSections.contactAddress]}
        title={getSectionTitleForCustomerLocal('contactAddressForm')}
        onClickCapture={() => setActiveSection(AvailableSections.contactAddress)}
        {...getFormSectionStatusProps(formsState.contactAddressForm)}
        onChange={(isOpened: boolean) => {
          changeFoldStatus(AvailableSections.contactAddress, isOpened);
        }}
      >
        <Fieldset.Row>
          <ContactAddressForm />
        </Fieldset.Row>
        <Fieldset.Row>
          <NavigationControls
            onNextNavigate={() => {
              handleSaveSession();
              actions.contactAddressForm.updateForm({ triggerValidation: true, formName: 'contactAddressForm' });

              if (isUpfrontPayment) {
                closeAllSections();
                setPendingSection(null);
              } else {
                setPendingSection(AvailableSections.bankDetails);
              }
            }}
          />
        </Fieldset.Row>
      </FormSection>

      {!isUpfrontPayment && (
        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.bankDetails)}
          defaultOpen={foldStatus[AvailableSections.bankDetails]}
          title={getSectionTitleForCustomerLocal('bankDetailsForm')}
          onClickCapture={() => setActiveSection(AvailableSections.bankDetails)}
          {...getFormSectionStatusProps(formsState.bankDetails)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.bankDetails, isOpened);
          }}
        >
          <Fieldset.Row>
            <BankDetailsForm />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.bankDetailsForm.updateForm({
                  triggerValidation: true,
                  formName: 'bankDetails',
                });

                closeAllSections();
                setPendingSection(null);
              }}
            />
          </Fieldset.Row>
        </FormSection>
      )}
    </FormSectionGroup>
  );
};
