import i18n from 'i18next';
import { VehicleType } from '../../screens/VehicleSelectPage/vehicleTypeSelectPage.model';
import Yup from '../../services/common/validation';

const numberValidator = (maxLength: number, isRequired: boolean, maxFraction?: number) => {
  let validator = Yup.number()
    .maxTotalDigitLength(maxLength, i18n.t('validation:maxLength', { value: maxLength }))
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null));

  if (isRequired) {
    validator = validator.required(i18n.t('validation:required'));
  }

  if (!(maxFraction === undefined) && maxFraction !== 0 && Number.isInteger(maxFraction)) {
    validator = validator.maxFraction(maxFraction, i18n.t('validation:maximumFraction', { value: maxFraction }));
  } else if (maxFraction === 0) {
    validator = validator.integer(i18n.t('validation:numberInteger'));
  }

  return validator;
};

export const financialProductSpecificationValidationSchema = (vehicleType: VehicleType) => {
  return Yup.object({
    invoicePrice: numberValidator(8, true, 2),
    tradeInValue: numberValidator(8, false, 2),
    regNumberTradeInVehicle: Yup.string().when('tradeInValue', {
      is: (tradeInValue: number | undefined) => !!tradeInValue,
      then: (schema) =>
        schema
          .required(i18n.t('validation:required'))
          .matches(/^[0-9]{2,3}-[A-Z]{1,2}-[0-9]{1,10}$/, i18n.t('validation:invalidData'))
          .max(17, i18n.t('validation:maxLength', { value: 14 })),
      otherwise: (schema) => schema.notRequired(),
    }),
    settlement: numberValidator(8, false, 2),
    brandContribution: numberValidator(8, false, 2),
    cashDeposit: numberValidator(8, false, 2),
    cashDepositSource: Yup.string().when('cashDeposit', {
      is: (cashDeposit: number | undefined) => cashDeposit !== undefined && cashDeposit > 10000,
      then: (schema) => schema.required(i18n.t('validation:required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    cashDepositForm: Yup.string().when('cashDeposit', {
      is: (cashDeposit: number | undefined) => cashDeposit !== undefined && cashDeposit > 10000,
      then: (schema) => schema.required(i18n.t('validation:required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    cashDepositSourceNotes: Yup.string().when('cashDepositSource', {
      is: (cashDepositSource: string | undefined) => cashDepositSource !== undefined && cashDepositSource === 'other',
      then: (schema) => schema.required(i18n.t('validation:required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    cashDepositFormNotes: Yup.string().when('cashDepositForm', {
      is: (cashDepositForm: string | undefined) => cashDepositForm !== undefined && cashDepositForm === 'other',
      then: (schema) => schema.required(i18n.t('validation:required')),
      otherwise: (schema) => schema.notRequired(),
    }),
    totalDeposit: numberValidator(8, false, 2),
    totalDepositPercentage: numberValidator(2, false),
    recordedKm: numberValidator(8, vehicleType === 'used', 0),
    annualKm: numberValidator(99, false, 0),
    grantIfaSeai: numberValidator(8, false, 2),
    vrtAmount: numberValidator(8, false, 2),
    monthlyPayment: numberValidator(6, false, 2),
    totalApr: numberValidator(2, false, 1),
  });
};
