import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../services/redux/storeUtils';
import { UploadedFileModel } from './DocumentUploadPage.model';

export interface DocumentUploadPageState {
  uploadedFiles: Array<UploadedFileModel>;
}

const initialState: DocumentUploadPageState = {
  uploadedFiles: [],
};

function updateUploadedFile(
  files: UploadedFileModel[],
  key: keyof UploadedFileModel,
  keyValue: string,
  updates: Partial<UploadedFileModel>
) {
  const fileIndex = files.findIndex((file) => file[key] === keyValue);
  if (fileIndex !== -1) {
    files[fileIndex] = {
      ...files[fileIndex],
      ...updates,
    };
  }
}

const name = 'documentUploadPage';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    pushFile: (state, action: PayloadAction<{ uploadedFile: UploadedFileModel }>) => {
      const workingCopy: Array<UploadedFileModel> = [...state.uploadedFiles];
      workingCopy.push(action.payload.uploadedFile);

      state.uploadedFiles = workingCopy;
    },
    removeFileById: (state, action: PayloadAction<{ id: string }>) => {
      state.uploadedFiles = state.uploadedFiles.filter((file) => file.id !== action.payload.id);
    },
    updateFileById: (
      state,
      action: PayloadAction<{
        id: string;
        updates: Partial<UploadedFileModel>;
      }>
    ) => {
      updateUploadedFile(state.uploadedFiles, 'id', action.payload.id, action.payload.updates);
    },
    resetState: (_state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

export const gettersDef = createGettersDefinition({
  reactive: {
    uploadedFiles({ documentUploadPage }) {
      return documentUploadPage.uploadedFiles;
    },
  },
});

export const documentUploadPageSlice = slice;
export const documentUploadPageGettersDef = gettersDef;
