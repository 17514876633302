import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import { getEnumValues } from '../../services/common/miscUtils';
import Yup from '../../services/common/validation';
import { TitleEnum } from '../CustomerDetailsForm/customerDetailsForm.model';
import { PersonalDetailsFormAddressModelFields, PersonalDetailsFormModel } from './personalDetailsForm.model';

export const createPersonalDetailsFormValidator = (): ObjectSchema<
  Omit<PersonalDetailsFormModel, PersonalDetailsFormAddressModelFields>
> => {
  return Yup.object({
    title: Yup.string().required(t('validation:required')).oneOf(getEnumValues(TitleEnum)),
    firstName: Yup.string().requiredWithMsg().unaccentedLetters().lengthBetween(0, 64),
    lastName: Yup.string().requiredWithMsg().unaccentedLetters().lengthBetween(0, 64),
    dateOfBirth: Yup.string().required(t('validation:required')),
  });
};
