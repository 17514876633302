import {
  Button,
  ButtonContainer,
  Card,
  Fieldset,
  FormHeading,
  Heading,
  Layout,
  Paragraph,
  UnorderedList,
} from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentUpload from '../../../components/DocumentUpload/DocumentUpload';
import { actions, useGetters } from '../../../services/redux';
import { useNavigate } from '../../../services/routing';
import { CreditCheckStatusEnum } from '../../../types/forms';
import { WizardPagesEnum } from '../newQuotation.slice';
import { DocumentFileTypeEnum } from './DocumentUploadPage.model';
import './style.scss';

const DocumentUploadPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [areFilesValid, setAreFilesValid] = useState(false);

  const { uploadedFiles } = useGetters().documentUploadPage;

  const isFileOfTypeUploaded = (type: DocumentFileTypeEnum): boolean => {
    if (uploadedFiles) {
      return uploadedFiles.some((file) => file.metadata?.type === type);
    }

    return false;
  };

  return (
    <>
      <Card element="div">
        <Fieldset>
          <Fieldset.Row>
            <FormHeading>{t('documentUploadPage:title')}</FormHeading>
          </Fieldset.Row>
          <Fieldset.Row>
            <Heading level={6} className="u-text-left">
              {t('documentUploadPage:subtitle')}
            </Heading>
          </Fieldset.Row>
          <Fieldset.Row>
            <Paragraph className="u-text-left">{t('documentUploadPage:description')}</Paragraph>
            <UnorderedList>
              {['proofOfId', 'proofOfAddress', 'proofOfPPSN'].map((documentType) => (
                <UnorderedList.Item
                  key={documentType}
                  className={`u-text-left ${
                    isFileOfTypeUploaded(DocumentFileTypeEnum[documentType]) ? 'strike-through' : ''
                  }`}
                >
                  {t(`documentUploadPage:documentType.${documentType}`)}
                </UnorderedList.Item>
              ))}
            </UnorderedList>
          </Fieldset.Row>
          <Fieldset.Row>
            <DocumentUpload
              description={t('documentUploadPage:documentUpload.description')}
              supplementaryText={t('documentUploadPage:documentUpload.supplementaryText')}
              uploadButtonText={t('documentUploadPage:documentUpload.uploadButtonText')}
              accept={['.jpg', '.jpeg', '.pdf']}
              fieldsValiditySetter={setAreFilesValid}
            />
          </Fieldset.Row>
        </Fieldset>
      </Card>
      <Layout center className="u-mt">
        <ButtonContainer center>
          <Layout.Item default="1/4">
            <Button
              testId="button_goBack"
              secondary
              full
              onClick={() => {
                actions.newQuotation.wizardPrevPage();
              }}
            >
              {t('usedCarFormSelectorPage:navigation.back')}
            </Button>
          </Layout.Item>

          <Layout.Item default="1/4">
            <Button
              testId="button_continue"
              full
              disabled={!areFilesValid}
              onClick={() => {
                navigate.newQuote(`/${WizardPagesEnum.creditCheck}/${CreditCheckStatusEnum.approved}`);
              }}
            >
              {t('usedCarFormSelectorPage:navigation.continue')}
            </Button>
          </Layout.Item>
        </ButtonContainer>
      </Layout>
    </>
  );
};

export default DocumentUploadPage;
