import React, { FunctionComponent, memo, useState } from 'react';

import { Form, Formik } from 'formik';
import { ObjectSchema } from 'yup';
import { actions, getters, useGetters } from '../../services/redux';
import { AddressFormGeneric } from '../AddressFormGeneric/AddressFormGeneric';
import { getAddressFormValidationSchema } from '../AddressFormGeneric/addressFormGeneric.validator';
import { PersistFormInState, setFieldValueInitial } from '../PersistFormInState/PersistFormInState';
import { ContactAddressFormAddressModelFields, ContactAddressFormModel } from './contactAddressForm.model';
import { ContactAddressFormState } from './contactAddressForm.slice';

const formName = 'contactAddressForm';

const addressFormFieldsToUse: ContactAddressFormAddressModelFields[] = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'town',
  'county',
  'eirCode',
];

// eslint-disable-next-line react/display-name
export const ContactAddressForm: FunctionComponent = memo(() => {
  const [initialValues] = useState(() => getters.contactAddressForm.contactAddressValues);
  const [_setFieldValue, _setSetFieldValue] = useState(setFieldValueInitial<ContactAddressFormModel>);
  const currentValues = useGetters().contactAddressForm.contactAddressValues;
  const shouldValidate = useGetters().contactAddressForm.contactAddressTriggers.validation;
  const shouldReset = useGetters().contactAddressForm.contactAddressTriggers.reset;
  const valuesToResetTo = getters.contactAddressForm.forms.contactAddressForm.initialValues;

  const [validationSchema] = useState<ObjectSchema<ContactAddressFormModel>>(() =>
    getAddressFormValidationSchema(addressFormFieldsToUse)
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      <Form>
        <PersistFormInState<ContactAddressFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          updateForm={actions.contactAddressForm.updateForm}
          shouldReset={shouldReset}
          valuesToResetTo={valuesToResetTo}
        />
        <AddressFormGeneric
          initialFormValue={currentValues}
          formTranslationKey="contactAddressForm"
          fieldsToRender={addressFormFieldsToUse}
        />
      </Form>
    </Formik>
  );
});
