import { Heading, Paragraph } from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actions, getters, useGetters } from '../../services/redux';
import AddressForm from '../AddressForm/AddressForm';
import { AddressFormModel } from '../AddressForm/addressForm.model';
import { AddressFormWithPreviousFormState } from './addressWithPreviousForm.slice';

function _AddressWithPreviousForm<FormName extends keyof AddressFormWithPreviousFormState['forms']>(props: {
  formNameCurrentAddress: FormName;
  formNamePreviousAddress: FormName;
  initialValuesCurrentAddress: AddressFormModel;
  initialValuesPreviousAddress: AddressFormModel;
  valuesToResetToCurrentAddress?: AddressFormModel;
  valuesToResetToPreviousAddress?: AddressFormModel;
  currentValuesCurrentAddress: AddressFormModel;
  currentValuesPreviousAddress: AddressFormModel;
  shouldValidateCurrent: boolean;
  shouldValidatePrevious: boolean;
  isPreviousExcluded: boolean;
  shouldResetCurrent: boolean;
  shouldResetPrevious: boolean;
}) {
  const { t } = useTranslation();

  const [initialValuesCurrentAddress] = useState(() => props.initialValuesCurrentAddress);
  const [initialValuesPreviousAddress, setInitialValuesPreviousAddress] = useState(
    () => props.initialValuesPreviousAddress
  );

  useEffect(
    function ShouldShowPreviousAddressForm() {
      const years = props.currentValuesCurrentAddress?.timeAtAddressYears;
      const yearsNumber = Number(years);

      let shouldPreviousBeExcluded: boolean;

      if (![undefined, null, ''].includes(years) && yearsNumber < 3) {
        shouldPreviousBeExcluded = false;
      } else {
        shouldPreviousBeExcluded = true;
        setInitialValuesPreviousAddress(props.currentValuesPreviousAddress);
      }

      // Adjust form exclusion to local component exclusion
      if (props.isPreviousExcluded !== shouldPreviousBeExcluded) {
        actions.addressWithPreviousForm.updateForm({
          formName: props.formNamePreviousAddress,
          excluded: shouldPreviousBeExcluded,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.currentValuesCurrentAddress, props.formNamePreviousAddress, props.isPreviousExcluded]
  );

  return (
    <>
      <AddressForm
        formName={props.formNameCurrentAddress}
        initialFormValue={initialValuesCurrentAddress}
        shouldValidate={props.shouldValidateCurrent}
        shouldReset={props.shouldResetCurrent}
        isPrevious={false}
        valuesToResetTo={props.valuesToResetToCurrentAddress!}
      />

      {!props.isPreviousExcluded && (
        <>
          <Heading level={5} className="u-mt u-mb-small u-text-left">
            {t('addressForm:previousAddressForm.heading')}
          </Heading>
          <Paragraph className="u-mb u-text-left">{t('addressForm:previousAddressForm.paragraph')}</Paragraph>
          <AddressForm
            formName={props.formNamePreviousAddress}
            initialFormValue={initialValuesPreviousAddress}
            shouldValidate={props.shouldValidatePrevious}
            shouldReset={props.shouldResetPrevious}
            isPrevious
            valuesToResetTo={props.valuesToResetToPreviousAddress!}
          />
        </>
      )}
    </>
  );
}

// eslint-disable-next-line react/display-name
const AddressWithPreviousForm: FunctionComponent<{ isMainApplicant: boolean }> = memo(({ isMainApplicant }) => {
  let formNameCurrentAddress: keyof AddressFormWithPreviousFormState['forms'];
  let formNamePreviousAddress: keyof AddressFormWithPreviousFormState['forms'];
  let initialValuesCurrentAddress: AddressFormModel;
  let initialValuesPreviousAddress: AddressFormModel;
  let valuesToResetToCurrentAddress: AddressFormModel | undefined;
  let valuesToResetToPreviousAddress: AddressFormModel | undefined;
  let currentValuesCurrentAddress: AddressFormModel;
  let currentValuesPreviousAddress: AddressFormModel;
  let shouldValidateCurrent: boolean;
  let shouldValidatePrevious: boolean;
  let shouldResetCurrent: boolean;
  let shouldResetPrevious: boolean;
  let isPreviousExcluded: boolean;

  /* eslint-disable react-hooks/rules-of-hooks */
  if (isMainApplicant) {
    formNameCurrentAddress = 'currentAddress1';
    formNamePreviousAddress = 'previousAddress1';

    initialValuesCurrentAddress = getters.addressWithPreviousForm.currentAddress1Values;
    initialValuesPreviousAddress = getters.addressWithPreviousForm.previousAddress1Values;
    valuesToResetToCurrentAddress = getters.addressWithPreviousForm.forms.currentAddress1.initialValues;
    valuesToResetToPreviousAddress = getters.addressWithPreviousForm.forms.previousAddress1.initialValues;

    isPreviousExcluded = useGetters().addressWithPreviousForm.previousAddress1Excluded;
    currentValuesCurrentAddress = useGetters().addressWithPreviousForm.currentAddress1Values;
    currentValuesPreviousAddress = useGetters().addressWithPreviousForm.previousAddress1Values;
    shouldValidateCurrent = useGetters().addressWithPreviousForm.currentAddress1Triggers.validation;
    shouldValidatePrevious = useGetters().addressWithPreviousForm.previousAddress1Triggers.validation;
    shouldResetCurrent = useGetters().addressWithPreviousForm.currentAddress1Triggers.reset;
    shouldResetPrevious = useGetters().addressWithPreviousForm.previousAddress1Triggers.reset;
  } else {
    formNameCurrentAddress = 'currentAddress2';
    formNamePreviousAddress = 'previousAddress2';

    initialValuesCurrentAddress = getters.addressWithPreviousForm.currentAddress2Values;
    initialValuesPreviousAddress = getters.addressWithPreviousForm.previousAddress2Values;
    valuesToResetToCurrentAddress = getters.addressWithPreviousForm.forms.currentAddress2.initialValues;
    valuesToResetToPreviousAddress = getters.addressWithPreviousForm.forms.previousAddress2.initialValues;

    isPreviousExcluded = useGetters().addressWithPreviousForm.previousAddress2Excluded;
    currentValuesCurrentAddress = useGetters().addressWithPreviousForm.currentAddress2Values;
    currentValuesPreviousAddress = useGetters().addressWithPreviousForm.previousAddress2Values;
    shouldValidateCurrent = useGetters().addressWithPreviousForm.currentAddress2Triggers.validation;
    shouldValidatePrevious = useGetters().addressWithPreviousForm.previousAddress2Triggers.validation;
    shouldResetCurrent = useGetters().addressWithPreviousForm.currentAddress2Triggers.reset;
    shouldResetPrevious = useGetters().addressWithPreviousForm.previousAddress2Triggers.reset;
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  return _AddressWithPreviousForm({
    formNameCurrentAddress,
    formNamePreviousAddress,
    initialValuesCurrentAddress,
    initialValuesPreviousAddress,
    valuesToResetToCurrentAddress,
    valuesToResetToPreviousAddress,
    currentValuesCurrentAddress,
    currentValuesPreviousAddress,
    shouldValidateCurrent,
    shouldValidatePrevious,
    isPreviousExcluded,
    shouldResetCurrent,
    shouldResetPrevious,
  });
});

export default AddressWithPreviousForm;
