export enum CountyEnum {
  carlow = 'Carlow',
  cavan = 'Cavan',
  clare = 'Clare',
  cork = 'Cork',
  donegal = 'Donegal',
  dublin = 'Dublin',
  dublin1 = 'Dublin 1',
  dublin2 = 'Dublin 2',
  dublin3 = 'Dublin 3',
  dublin4 = 'Dublin 4',
}

export interface AddressFormGenericModel {
  addressLine1: string;
  addressLine2: string | null;
  addressLine3: string | null;
  eirCode: string | null;
  town: string;
  county: string;
  residentialStatus: string;
  timeAtAddressYears: string;
  timeAtAddressMonths: string;
}

export type AddressFormGenericModelFields = keyof AddressFormGenericModel;
