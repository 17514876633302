import { Input } from '@vwfs-bronson/bronson-react';
import { useFormikContext } from 'formik';
import i18n from 'i18next';
import get from 'lodash/get';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormField } from '..';
import { localeIdentifier } from '../../config';
import { FormFieldPropsBase } from './fieldset.model';

type NumericInputFormFieldType = 'numeric' | 'money' | 'months' | 'km' | 'percentage';

interface NumericInputFormFieldProps extends FormFieldPropsBase {
  type: NumericInputFormFieldType;
  maximumFractionDigits?: number;
  isSuccess?: boolean;
  isError?: boolean;
  /**
   * Field name or path
   */
  fieldName: string;
}

export const NumericInputFormField: FunctionComponent<NumericInputFormFieldProps> = ({
  labelTranslationKey = '',
  placeholderTranslationKey = '',
  fieldName,
  type,
  isDisabled,
  isSuccess,
  isReadonly,
  isError,
  maximumFractionDigits = 20,
}): React.JSX.Element => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [inputVisualValue, setInputVisualValue] = useState<string>('');

  const formatNumberToLocale = (numString: string) => {
    const number = parseFloat(numString.replace(/[^0-9.-]+/g, ''));

    if (!Number.isNaN(number)) {
      return new Intl.NumberFormat(localeIdentifier, { maximumFractionDigits }).format(number);
    }
    return '';
  };

  const handleChange = (event) => {
    const rawValue: string = event.target.value;
    let sanitizedValue = rawValue;

    if (maximumFractionDigits) {
      // Remove more than one fraction indicator
      sanitizedValue = sanitizedValue.replace(/(\..*)\./g, '$1');
    } else {
      // If fraction set to 0 do not allow fraction at all
      sanitizedValue = sanitizedValue.replace(/[^0-9]+/g, '');
    }

    const formattedValue = formatNumberToLocale(sanitizedValue);

    // Match any cases like 123. 123.0 123.01 123.10 123.100...
    if (sanitizedValue.endsWith('.') || sanitizedValue.endsWith('.0') || /\.(\d)0+$/.test(sanitizedValue)) {
      setInputVisualValue(sanitizedValue);
      return;
    }

    const numericValue = parseFloat(formattedValue.replace(/[^0-9.-]+/g, ''));

    // Int locale formatting overflow protection
    if (numericValue > 99999999999999) {
      setInputVisualValue(sanitizedValue);
      return;
    }

    setInputVisualValue(formattedValue);
    setFieldValue(fieldName, numericValue);
  };

  useEffect(() => {
    const value = get(values, fieldName, undefined);

    // Visual value reset for cases when React will reuse
    // existing instance of the component for another field.
    if (['', undefined, null].includes(value) || Number.isNaN(value)) {
      setInputVisualValue('');
    } else {
      const event = {
        target: {
          name: fieldName,
          value: value.toString(),
        },
      };
      handleChange(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, fieldName]);

  let addonText;
  switch (type) {
    case 'km':
      addonText = 'km';
      break;
    case 'money':
      addonText = '€';
      break;
    case 'months':
      addonText = 'months';
      break;
    case 'percentage':
      addonText = '%';
      break;
    case 'numeric':
      addonText = undefined;
      break;
    default:
      addonText = undefined;
  }

  return (
    <FormField
      type="input"
      name={fieldName}
      testId={fieldName}
      labelText={i18n.t(labelTranslationKey)}
      inputVisualValue={inputVisualValue}
      isDisabled={isDisabled}
      isError={isError}
      isSuccess={isSuccess}
      render={(fieldProps) => {
        return (
          <Input
            {...fieldProps}
            disabled={isDisabled}
            aria-disabled={isDisabled}
            placeholder={i18n.t(placeholderTranslationKey)}
            onChange={handleChange}
            value={inputVisualValue}
            addonText={addonText}
            success={isSuccess}
            readOnly={isReadonly}
            reversed={!!addonText}
            stateIcon={isSuccess || isError}
            error={isError}
          />
        );
      }}
    />
  );
};
