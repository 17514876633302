import _axios from 'axios';

export const axiosInstance = _axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY,
});

export const setAxiosToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['x-auth-token'] = token;
  } else {
    delete axiosInstance.defaults.headers.common['x-auth-token'];
  }
};
