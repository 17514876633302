import { Button, Fieldset, Form, Heading, Layout, Spinner } from '@vwfs-bronson/bronson-react';
import { Formik, FormikHelpers } from 'formik';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formAutocomplete } from '../../config';
import { UsedCarFormTypesEnum } from '../../screens/NewQuotation/UsedCarFormSelectorPage/UsedCarFormSelectorPage.model';
import { VehicleType } from '../../screens/VehicleSelectPage/vehicleTypeSelectPage.model';
import { getMaxFirstRegistrationDate } from '../../services/common/validation';
import { actions, getters, useGetters } from '../../services/redux';
import {
  QuoteCalculateResponse,
  QuoteCalculationServicePlanResponseModel,
  useQuoteCalculateMutation,
} from '../../services/redux/features/api/quoteCalculate.api-slice';
import { DatePickerFormField } from '../Fieldset/DatePickerFormField';
import { NumericInputFormField } from '../Fieldset/NumericInputFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import { UsedCarSelectionFormFields } from '../UsedCarSelectionForm/UsedCarSelectionFormModels';
import AdditionalServicePlanRemoveModal from './AdditionalServicePlanRemoveModal';
import { AdditionalServicePlanFormModel, AdditionalServicePlanFormModelFields } from './additionalServicePlan.model';
import { selectIsAdditionalServicePlanAdded, toggleAdditionalServicePlan } from './additionalServicePlan.slice';
import { additionalServicePlanValidationSchema } from './additionalServicePlanForm.validator';

const typeSafeFormFieldName = (formFieldName: keyof AdditionalServicePlanFormModel) => formFieldName;

const createFormInputs = (() => {
  return {
    firstRegistrationDate: () => (
      <DatePickerFormField
        fieldName={typeSafeFormFieldName('firstRegistrationDate')}
        labelTranslationKey="additionalServicePlan:additionalServicePlanForm.firstRegistrationDate.label"
        placeholderTranslationKey="additionalServicePlan:additionalServicePlanForm.firstRegistrationDate.placeholder"
        testId={typeSafeFormFieldName('firstRegistrationDate')}
        maxDate={getMaxFirstRegistrationDate()}
      />
    ),
    recordedKilometers: () => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('recordedKilometers')}
        labelTranslationKey="additionalServicePlan:additionalServicePlanForm.recordedKilometers.label"
        placeholderTranslationKey="additionalServicePlan:additionalServicePlanForm.recordedKilometers.placeholder"
        type="km"
      />
    ),
    servicePlanName: (servicePlanList) => (
      <SelectFormField
        fieldName={typeSafeFormFieldName('servicePlanName')}
        labelTranslationKey="additionalServicePlan:additionalServicePlanForm.servicePlanName.label"
        placeholderTranslationKey="additionalServicePlan:additionalServicePlanForm.servicePlanName.placeholder"
        testId={typeSafeFormFieldName('servicePlanName')}
        optionList={servicePlanList}
      />
    ),
    paymentType: () => (
      <SelectFormField
        fieldName={typeSafeFormFieldName('paymentType')}
        labelTranslationKey="additionalServicePlan:additionalServicePlanForm.paymentType.label"
        placeholderTranslationKey="additionalServicePlan:additionalServicePlanForm.paymentType.placeholder"
        optionList={[
          {
            label: 'Monthly',
            value: 'monthly',
          },
          {
            label: 'Upfront',
            value: 'upfront',
          },
        ]}
        testId={typeSafeFormFieldName('paymentType')}
      />
    ),
    contractDuration: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('contractDuration')}
        labelTranslationKey="additionalServicePlan:additionalServicePlanForm.contractDuration.label"
        testId={typeSafeFormFieldName('contractDuration')}
        isDisabled
      />
    ),
    paymentTerm: (paymentTermList) => (
      <SelectFormField
        fieldName={typeSafeFormFieldName('paymentTerm')}
        labelTranslationKey="additionalServicePlan:additionalServicePlanForm.paymentTerm.label"
        placeholderTranslationKey="additionalServicePlan:additionalServicePlanForm.paymentTerm.placeholder"
        optionList={paymentTermList}
        testId={typeSafeFormFieldName('paymentTerm')}
      />
    ),
    payment: (data: QuoteCalculateResponse | QuoteCalculationServicePlanResponseModel | undefined) => (
      <NumericInputFormField
        fieldName={typeSafeFormFieldName('payment')}
        type="money"
        maximumFractionDigits={2}
        isReadonly
        isSuccess={data?.isQuoteValid}
        isError={data?.isQuoteValid === false}
      />
    ),
  };
}) satisfies () => Record<AdditionalServicePlanFormModelFields, (...args: any[]) => ReactElement>;

const populateWithUsedCarData = (
  sourceData: AdditionalServicePlanFormModel,
  carData: UsedCarSelectionFormFields
): AdditionalServicePlanFormModel => {
  return {
    ...sourceData,
    firstRegistrationDate: carData?.firstRegistration,
  };
};

interface AdditionalServicePlanProps {
  vehicleType: VehicleType;
  usedCarData: UsedCarSelectionFormFields | null;
  usedCarSelectionMethod: UsedCarFormTypesEnum;
}

export const servicePlanData = [
  { label: 'Audi Summer Service Pack 2023', key: 'audi_summer_service_pack_2023', contractDuration: 12 },
  { label: 'ID.3 Large 232 Pack', key: 'id3_large_232_pack', contractDuration: 36 },
  {
    label: 'SKODA New Services Pack (2 Services)',
    key: 'skoda_new_services_pack_2_services',
    contractDuration: 48,
  },
];

const AdditionalServicePlan: FunctionComponent<AdditionalServicePlanProps> = ({
  vehicleType,
  usedCarData,
  usedCarSelectionMethod,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [calculate, { isLoading, data }] = useQuoteCalculateMutation();

  const formName = 'additionalServicePlan';

  const [initialValues, setInitialValues] = useState(() => getters.additionalServicePlan.additionalServicePlanValues);
  const shouldValidate = useGetters().additionalServicePlan.additionalServicePlanValidationTrigger;
  const { isValid } = useGetters().additionalServicePlan.forms.additionalServicePlan;
  const [isCalculationPending, setIsCalculationPending] = useState(false);

  const [isRemoveOfServicePlanModalVisible, setIsRemoveOfServicePlanModalVisible] = useState(false);

  const isAdditionalServicePlanAdded = useSelector(selectIsAdditionalServicePlanAdded);

  const [inputs /* , updateInputs */] = useState<ReturnType<typeof createFormInputs>>(() => createFormInputs());

  const servicePlanList = servicePlanData.map((servicePlan) => ({ label: servicePlan.label, value: servicePlan.key }));
  servicePlanList.unshift({ label: t('formControls:select.noSelection'), value: '' });

  useEffect(
    function populateForm() {
      if (usedCarSelectionMethod === UsedCarFormTypesEnum.Search && usedCarData) {
        const populatedInitialValue: AdditionalServicePlanFormModel = populateWithUsedCarData(
          initialValues,
          usedCarData
        );

        setInitialValues(populatedInitialValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usedCarData, usedCarSelectionMethod]
  );

  useEffect(
    function toggleExclusionOfServicePlan() {
      actions.additionalServicePlan.updateForm({
        excluded: !isAdditionalServicePlanAdded,
        formName: 'additionalServicePlan',
      });
    },
    [isAdditionalServicePlanAdded]
  );

  useEffect(() => {
    const performCalculation = async () => {
      const { values } = getters.additionalServicePlan.forms.additionalServicePlan;

      const data = (await calculate({
        productType: 'servicePlan',
        paymentType: values.paymentType,
      }).unwrap()) as QuoteCalculationServicePlanResponseModel;

      const updatedFormValue: AdditionalServicePlanFormModel = {
        ...values,
        payment: data.payment,
        isQuoteValid: data.isQuoteValid,
      };

      actions.additionalServicePlan.updateForm({ formName: 'additionalServicePlan', values: updatedFormValue });
      setInitialValues(updatedFormValue);
    };

    if (isCalculationPending && isValid) {
      performCalculation();
    }

    setIsCalculationPending(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalculationPending, isValid, setInitialValues]);

  return (
    <>
      <Spinner busy={isLoading} section>
        <AdditionalServicePlanRemoveModal
          isShown={isRemoveOfServicePlanModalVisible}
          setIsShow={setIsRemoveOfServicePlanModalVisible}
        />
        {initialValues && (
          <Formik
            validationSchema={additionalServicePlanValidationSchema(vehicleType)}
            initialValues={initialValues}
            validateOnMount
            validateOnChange={false}
            validateOnBlur
            onSubmit={async (
              values: AdditionalServicePlanFormModel,
              helpers: FormikHelpers<AdditionalServicePlanFormModel>
            ) => {
              const data = (await calculate({
                productType: 'servicePlan',
                paymentType: values.paymentType,
              }).unwrap()) as QuoteCalculationServicePlanResponseModel;

              helpers.setFieldValue('payment', data.payment);
              helpers.setFieldValue('isQuoteValid', data.isQuoteValid);

              helpers.setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setValues, setFieldValue }) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                const contractDuration = servicePlanData.find(
                  (servicePlan) => servicePlan.key === values.servicePlanName
                )?.contractDuration;
                setFieldValue('contractDuration', contractDuration);
                // eslint-disable-next-line react-hooks/exhaustive-deps
              }, [values.servicePlanName]);

              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                if (usedCarSelectionMethod === UsedCarFormTypesEnum.Search && usedCarData) {
                  const populatedInitialValue: AdditionalServicePlanFormModel = populateWithUsedCarData(
                    initialValues,
                    usedCarData
                  );

                  setValues(populatedInitialValue);
                }
              }, [setValues]);

              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                if (values.paymentType === 'upfront') {
                  setFieldValue('paymentTerm', undefined);
                }
              }, [values.paymentType, setFieldValue]);

              const paymentTermList = [
                { label: t('formControls:select.noSelection'), value: '' },
                { label: '12', value: '12' },
                { label: '24', value: '24' },
                { label: '36', value: '36' },
              ].filter((term) => {
                if (Number(values.paymentTerm) > Number(values.contractDuration)) {
                  setFieldValue('paymentTerm', undefined);
                }

                return Number(term.value) <= Number(values.contractDuration);
              });

              return (
                <>
                  <PersistFormInState
                    formName={formName}
                    updateForm={actions.additionalServicePlan.updateForm}
                    shouldValidate={shouldValidate}
                  />
                  {!isAdditionalServicePlanAdded && (
                    <Button
                      icon="semantic-add"
                      link
                      secondary
                      className="u-m"
                      onClick={() => {
                        dispatch(toggleAdditionalServicePlan());
                      }}
                    >
                      {t('additionalServicePlan:addServicePlanButton')}
                    </Button>
                  )}
                  {isAdditionalServicePlanAdded && (
                    <div className="u-m">
                      <Layout>
                        <Layout.Item default="1/2">
                          <Heading level={6}>{t('additionalServicePlan:title')}</Heading>
                        </Layout.Item>
                        <Layout.Item default="1/2" className="u-text-right">
                          <Button
                            ariaLabel="Close section"
                            icon="semantic-close"
                            className="u-mr-small"
                            round
                            secondary
                            small
                            onClick={() => {
                              setIsRemoveOfServicePlanModalVisible(true);
                            }}
                          />
                        </Layout.Item>
                      </Layout>
                      <Form autoComplete={formAutocomplete} onSubmit={handleSubmit}>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.firstRegistrationDate()}
                            </Layout.Item>
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.recordedKilometers()}
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="6/12">{inputs.servicePlanName(servicePlanList)}</Layout.Item>
                            <Layout.Item default="3/12">
                              <Button secondary full className="u-mt" type="button">
                                {t('additionalServicePlan:additionalServicePlanForm.searchServicePlanButton')}
                              </Button>
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.paymentType()}
                            </Layout.Item>
                            <Layout.Item default="1/3" s="1/1">
                              {inputs.contractDuration()}
                            </Layout.Item>
                            {values.paymentType !== 'upfront' && (
                              <Layout.Item default="1/3" s="1/1">
                                {inputs.paymentTerm(paymentTermList)}
                              </Layout.Item>
                            )}
                          </Layout>
                        </Fieldset.Row>
                        <Fieldset.Row>
                          <Layout>
                            <Layout.Item default="2/3" s="1/1">
                              <Layout>
                                <Layout.Item default="2/5">
                                  <Heading className="u-text-left u-m-none u-mt-small" level={6}>
                                    {values.paymentType === 'monthly' &&
                                      t(`additionalServicePlan:additionalServicePlanForm.monthlyPayment.label`)}
                                    {values.paymentType === 'upfront' &&
                                      t(`additionalServicePlan:additionalServicePlanForm.upfrontPayment.label`)}
                                  </Heading>
                                </Layout.Item>
                                <Layout.Item default="3/5">
                                  <Layout>
                                    <Layout.Item default="4/6" s="1/1">
                                      {inputs.payment(data)}
                                    </Layout.Item>
                                  </Layout>
                                </Layout.Item>
                              </Layout>
                            </Layout.Item>
                            <Layout.Item default="1/3" s="1/1">
                              <Button
                                secondary
                                full
                                type="button"
                                onClick={() => {
                                  actions.additionalServicePlan.updateForm({
                                    triggerValidation: true,
                                    formName: 'additionalServicePlan',
                                  });
                                  setIsCalculationPending(true);
                                }}
                              >
                                {t('additionalServicePlan:additionalServicePlanForm.calculateButton')}
                              </Button>
                            </Layout.Item>
                          </Layout>
                        </Fieldset.Row>
                      </Form>
                    </div>
                  )}
                </>
              );
            }}
          </Formik>
        )}
      </Spinner>
    </>
  );
};

export default AdditionalServicePlan;
