import { InfoIcon, Input } from '@vwfs-bronson/bronson-react';
import { FieldInputProps } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import { FormField } from '..';
import { StatefulMaskedInput } from './StatefulMaskedInput';
import { FormFieldPropsBase } from './fieldset.model';

export interface TextInputFormFieldProps extends FormFieldPropsBase {
  mask?: Array<string | RegExp> | ((value: string) => Array<string | RegExp>);
  /**
   * - Optional function converting masked value to one that should be saved in form.
   * - By default masked value becomes the actual value.
   */
  maskedValueToFormValue?: (value: string) => string;
}

export const TextInputFormField: FunctionComponent<TextInputFormFieldProps> = ({
  labelTranslationKey = '',
  placeholderTranslationKey = '',
  fieldName,
  isDisabled,
  isReadonly,
  optional,
  testId,
  mask,
  maskedValueToFormValue,
  validateOnChange,
  infoIconTranslationKey,
}): React.JSX.Element => {
  return (
    <FormField
      type="input"
      name={fieldName}
      testId={testId ?? fieldName}
      labelText={`${t(labelTranslationKey)}${optional ? t('formControls:optionalLabelAffix') : ''}`}
      validateOnChange={validateOnChange}
      isDisabled={isDisabled}
      infoIcon={infoIconTranslationKey ? <InfoIcon icon="semantic-info">{t(infoIconTranslationKey)}</InfoIcon> : null}
      render={(fieldProps: FieldInputProps<any>) =>
        mask ? (
          <StatefulMaskedInput
            fieldProps={fieldProps}
            fieldName={fieldName}
            mask={mask}
            isDisabled={isDisabled}
            placeholderTranslationKey={placeholderTranslationKey}
            isReadonly={isReadonly}
            maskedValueToFormValue={maskedValueToFormValue}
          />
        ) : (
          <Input
            {...fieldProps}
            disabled={isDisabled}
            aria-disabled={isDisabled}
            placeholder={t(placeholderTranslationKey)}
            readOnly={isReadonly}
          />
        )
      }
    />
  );
};
