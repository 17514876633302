export enum OccupationEnum {
  accountant = 'accountant',
  accountsPerson = 'accountsPerson',
  actor = 'actor',
  actuary = 'actuary',
  adminStaff = 'adminStaff',
  advertising = 'advertising',
  aircraftCrew = 'aircraftCrew',
  aircraftMechanic = 'aircraftMechanic',
  airportEmployee = 'airportEmployee',
  anPostStaff = 'anPostStaff',
  anaesthatist = 'anaesthatist',
  archaeologist = 'archaeologist',
  architect = 'architect',
  army = 'army',
  artist = 'artist',
  assistantManager = 'assistantManager',
  auctioneer = 'auctioneer',
  auditor = 'auditor',
  baker = 'baker',
  bankStaff = 'bankStaff',
  barManager = 'barManager',
  barworker = 'barworker',
  barber = 'barber',
  barrister = 'barrister',
  beautician = 'beautician',
  bookmaker = 'bookmaker',
  broker = 'broker',
  busDriver = 'busDriver',
  butcher = 'butcher',
  carDealer = 'carDealer',
  carSalesman = 'carSalesman',
  careAssistant = 'careAssistant',
  caretaker = 'caretaker',
  carpenter = 'carpenter',
  cashier = 'cashier',
  cateringTrade = 'cateringTrade',
  charityWorker = 'charityWorker',
  chef = 'chef',
  chemist = 'chemist',
  childMinder = 'childMinder',
  chiropodist = 'chiropodist',
  chiropractor = 'chiropractor',
  civilservant = 'civilservant',
  cleaner = 'cleaner',
  clericalOfficer = 'clericalOfficer',
  companyDirector = 'companyDirector',
  companySecretary = 'companySecretary',
  computerSpecialist = 'computerSpecialist',
  constructionWorker = 'constructionWorker',
  consultant = 'consultant',
  contractor = 'contractor',
  courier = 'courier',
  craneDriver = 'craneDriver',
  creditController = 'creditController',
  dentalNurse = 'dentalNurse',
  dentist = 'dentist',
  dermatologist = 'dermatologist',
  despatchrider = 'despatchrider',
  dietician = 'dietician',
  director = 'director',
  discJockey = 'discJockey',
  doctor = 'doctor',
  dressmaker = 'dressmaker',
  driver = 'driver',
  drivingInstructor = 'drivingInstructor',
  electrician = 'electrician',
  engineer = 'engineer',
  estateAgent = 'estateAgent',
  executive = 'executive',
  fabricator = 'fabricator',
  farmer = 'farmer',
  financialConsultant = 'financialConsultant',
  financialSpecialist = 'financialSpecialist',
  firefighter = 'firefighter',
  fisherman = 'fisherman',
  fitter = 'fitter',
  florist = 'florist',
  foreman = 'foreman',
  forestor = 'forestor',
  funeralDirector = 'funeralDirector',
  garda = 'garda',
  gasFitter = 'gasFitter',
  generalOperative = 'generalOperative',
  guard = 'guard',
  gynaecologist = 'gynaecologist',
  hairdresser = 'hairdresser',
  horticulturist = 'horticulturist',
  hospitalStaff = 'hospitalStaff',
  hotelEmployee = 'hotelEmployee',
  housewife = 'housewife',
  inspectorTax = 'inspectorTax',
  insuranceBroker = 'insuranceBroker',
  insuranceStaff = 'insuranceStaff',
  interiorDecorator = 'interiorDecorator',
  interpreter = 'interpreter',
  jeweller = 'jeweller',
  jockey = 'jockey',
  journalist = 'journalist',
  judge = 'judge',
  labourer = 'labourer',
  outdoorworker = 'outdoorworker',
  landscapeGardener = 'landscapeGardener',
  lawyer = 'lawyer',
  lecturer = 'lecturer',
  legalSecretary = 'legalSecretary',
  librarian = 'librarian',
  locksmith = 'locksmith',
  lorryDriver = 'lorryDriver',
  machinist = 'machinist',
  manager = 'manager',
  mechanic = 'mechanic',
  midwife = 'midwife',
  militaryEnlistedNonComm = 'militaryEnlistedNonComm',
  militaryOfficer = 'militaryOfficer',
  milkman = 'milkman',
  mortgageBroker = 'mortgageBroker',
  mortician = 'mortician',
  musician = 'musician',
  negotiator = 'negotiator',
  neurologist = 'neurologist',
  notClassified = 'notClassified',
  nurse = 'nurse',
  occupationIDnotapplicable = 'occupationIDnotapplicable',
  officeStaff = 'officeStaff',
  opsdevelopmentAnalyst = 'opsdevelopmentAnalyst',
  optician = 'optician',
  other = 'other',
  ownerofBusinessNonprof = 'ownerofBusinessNonprof',
  ownerofBusinessProf = 'ownerofBusinessProf',
  panelBeater = 'panelBeater',
  paramedic = 'paramedic',
  parttime = 'parttime',
  personalAssisstant = 'personalAssisstant',
  pestController = 'pestController',
  photographer = 'photographer',
  physiotherapist = 'physiotherapist',
  pilot = 'pilot',
  pipeFitter = 'pipeFitter',
  plantWorker = 'plantWorker',
  plasterer = 'plasterer',
  plumber = 'plumber',
  porter = 'porter',
  priest = 'priest',
  printer = 'printer',
  prisonOfficer = 'prisonOfficer',
  privateInvestigator = 'privateInvestigator',
  probationOfficer = 'probationOfficer',
  productionFactoryWorker = 'productionFactoryWorker',
  professionalFinancial = 'professionalFinancial',
  professionalLegal = 'professionalLegal',
  professionalMedical = 'professionalMedical',
  professionalOther = 'professionalOther',
  propertyDeveloper = 'propertyDeveloper',
  proprietor = 'proprietor',
  psychiatrist = 'psychiatrist',
  psychologist = 'psychologist',
  publicRelationsOfficer = 'publicRelationsOfficer',
  publican = 'publican',
  publisher = 'publisher',
  radiographer = 'radiographer',
  receptionist = 'receptionist',
  recruitmentConsultant = 'recruitmentConsultant',
  reporter = 'reporter',
  retired = 'retired',
  roofer = 'roofer',
  salesIndoor = 'salesIndoor',
  salesoutdoor = 'salesoutdoor',
  scaffolder = 'scaffolder',
  security = 'security',
  semiProfessionalFinancial = 'semiProfessionalFinancial',
  semiProfessionalLegal = 'semiProfessionalLegal',
  semiProfessionalMedical = 'semiProfessionalMedical',
  semiProfessionalOther = 'semiProfessionalOther',
  service = 'service',
  shopAssisstant = 'shopAssisstant',
  shopfitter = 'shopfitter',
  signwriter = 'signwriter',
  siteEngineer = 'siteEngineer',
  socialWorker = 'socialWorker',
  solicitor = 'solicitor',
  speechTherapist = 'speechTherapist',
  steelWorker = 'steelWorker',
  stockController = 'stockController',
  stockbroker = 'stockbroker',
  streetTrader = 'streetTrader',
  student = 'student',
  supervisor = 'supervisor',
  surveyor = 'surveyor',
  systemsAnalyst = 'systemsAnalyst',
  tailor = 'tailor',
  taxConsultant = 'taxConsultant',
  taxiDriver = 'taxiDriver',
  tD = 'tD',
  teacher = 'teacher',
  telesalesPerson = 'telesalesPerson',
  tiler = 'tiler',
  tradesBuildingAndRelated = 'tradesBuildingAndRelated',
  trafficWarden = 'trafficWarden',
  travelAgent = 'travelAgent',
  undertaker = 'undertaker',
  underwriter = 'underwriter',
  unemployed = 'unemployed',
  upholsterer = 'upholsterer',
  vet = 'vet',
  warehouseEmployee = 'warehouseEmployee',
  wasteDisposalOp = 'wasteDisposalOp',
  welder = 'welder',
  welfareOfficer = 'welfareOfficer',
}

export enum EmploymentStatusEnum {
  fullTimePermanent = 'fullTimePermanent',
  fullTimeTemporary = 'fullTimeTemporary',
  partTimePermanent = 'partTimePermanent',
  partTimeTemporary = 'partTimeTemporary',
  contract = 'contract',
  selfEmployed = 'selfEmployed',
  unemployed = 'unemployed',
}

export enum TimeAtEmployerMonthsEnum {
  m0 = '0',
  m1 = '1',
  m2 = '2',
  m3 = '3',
  m4 = '4',
  m5 = '5',
  m6 = '6',
  m7 = '7',
  m8 = '8',
  m9 = '9',
  m10 = '10',
  m11 = '11',
}

export enum TimeAtEmployerYearsEnum {
  y0 = '0',
  y1 = '1',
  y2 = '2',
  y3 = '3',
  y4 = '4',
  y5 = '5',
}

export interface EmploymentDetailsFormModel {
  employmentStatus?: EmploymentStatusEnum;
  occupation?: OccupationEnum;
  employer?: string;
  occupationNotes?: string | null;
  addressLine1?: string;
  addressLine2?: string | null;
  addressLine3?: string | null;
  eirCode?: string;
  town?: string;
  county?: string;
  timeAtEmployerYears?: TimeAtEmployerYearsEnum;
  timeAtEmployerMonths?: TimeAtEmployerMonthsEnum;
}

export type EmploymentDetailsFormModelFields = keyof EmploymentDetailsFormModel;
