import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../services/redux/index';
import { saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';

interface CompareQuotesState {
  selectedQuotes: { [key: string]: boolean };
}

const initialState: CompareQuotesState = {
  selectedQuotes: {},
};

const name = 'compareQuotes';

export const compareQuotesSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetState: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
    saveSelectedQuotes: (state, action: PayloadAction<{ [key: string]: boolean }>) => {
      state.selectedQuotes = action.payload;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

export const selectSelectedQuotes = (state: RootState) => state.compareQuotes.selectedQuotes;

export const { saveSelectedQuotes } = compareQuotesSlice.actions;

export const { resetState } = compareQuotesSlice.actions;

export default compareQuotesSlice.reducer;
