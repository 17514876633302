import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { FinancialProductSpecificationFormModel } from './financialProductSpecificationForm.model';

export interface FinancialProductSpecificationFormState
  extends ReduxStateWithForms<{
    financialProductSpecification: ReduxTrackedForm<FinancialProductSpecificationFormModel>;
  }> {}

const financialProductSpecificationInitialValues: FinancialProductSpecificationFormModel = {
  isQuoteValid: false,
  invoicePrice: 49234.59,
  tradeInValue: 2400,
  regNumberTradeInVehicle: '231-D-123456113',
  settlement: 14750,
  brandContribution: 5000,
  cashDeposit: 5000,
  totalDeposit: 23000,
  cashDepositSource: '',
  cashDepositForm: '',
  cashDepositSourceNotes: 'Sold a vehicle on the used car market',
  cashDepositFormNotes: 'Pay via Cheque',
  totalDepositPercentage: 19,
  recordedKm: 11056,
  annualKm: 20000,
  totalFinalKm: 60000,
  excessKmCharge: 0.06,
  grantIfaSeai: 5000,
  vrtAmount: 6000,
  amountFinanced: 15000,
  gmfvAmount: 15000,
  monthlyPayment: 450.5,
  totalApr: 9.9,
  finalPayment: 150000,
};

const initialState: FinancialProductSpecificationFormState = {
  forms: {
    financialProductSpecification: {
      ...createReduxTrackedFormDefaults<FinancialProductSpecificationFormModel>(),
      values: {
        ...financialProductSpecificationInitialValues,
      },
    },
  },
};

const name = 'financialProductSpecificationForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<FinancialProductSpecificationFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    financialProductSpecificationValidationTrigger({ financialProductSpecificationForm }) {
      return financialProductSpecificationForm.forms.financialProductSpecification.triggers.validation;
    },
    financialProductSpecificationValues({ financialProductSpecificationForm }) {
      return financialProductSpecificationForm.forms.financialProductSpecification.values;
    },
    forms({ financialProductSpecificationForm }) {
      return financialProductSpecificationForm.forms;
    },
  },
});

export const financialProductSpecificationFormSlice = slice;
export const financialProductSpecificationFormGettersDef = gettersDef;
