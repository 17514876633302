import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { FunctionComponent, useEffect, useState, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Spinner } from '@vwfs-bronson/bronson-react';

import { BrowserRouter } from 'react-router-dom';
import initI18n from './services/i18n';
import { store } from './services/redux';
import { getLogosSrc, getPages, getStyles } from './services/brand';

const ConnectedApp = lazy(() => import('./App'));

const Init: FunctionComponent = () => {
  const [loaded, setLoaded] = useState(false);
  const [isReady, setReady] = useState<boolean>(false);
  const [pages, setPages] = useState([] as any[]);

  useEffect(() => {
    Promise.all([getStyles(), initI18n()])
      .then(() => {
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(false);
      });
  }, []);

  useEffect(() => {
    Promise.all([getPages(), getLogosSrc()]).then(([_pages]) => {
      setPages(_pages.default);
      setReady(true);
    });
  }, []);

  return loaded ? (
    <React.Suspense fallback={<Spinner fullPage />}>
      <Provider store={store}>
        <div className="o-root">
          {isReady && (
            <React.Suspense fallback={<Spinner fullPage />}>
              <BrowserRouter>
                <ConnectedApp pages={pages} />
              </BrowserRouter>
            </React.Suspense>
          )}
        </div>
      </Provider>
    </React.Suspense>
  ) : null;
};

function render(): void {
  const rootElement = document.getElementById('root');

  if (!rootElement) throw new Error('No root element found.');

  const root = createRoot(rootElement);
  root.render(<Init />);
}

declare let module: { hot: any };

if (module.hot) {
  module.hot.accept(render);
}

render();
