import { Logo } from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { getLogosSrc } from '../../services/brand';
import { useNavigate } from '../../services/routing';
import { ROUTES, ROUTES_VALUES } from '../../services/routing/routes';

export type HeaderLogoProps = {
  navigationTarget: ROUTES_VALUES;
};

const HeaderLogo: FunctionComponent<HeaderLogoProps> = ({ navigationTarget }) => {
  const [logoSrc, setLogoSrc] = useState({ normal: '', small: '' });
  const navigate = useNavigate();

  useEffect(() => {
    Promise.resolve(getLogosSrc()).then((_logoSrc) => {
      setLogoSrc({
        normal: _logoSrc.logoDefault,
        small: _logoSrc.logoSmall,
      });
    });
  }, []);

  return (
    <Logo
      testId="appHeaderLogo"
      src={logoSrc.normal}
      srcSet={logoSrc.small}
      breakpoint="720px"
      onClick={() => {
        navigate[ROUTES[navigationTarget]]();
      }}
    />
  );
};

export default HeaderLogo;
