import i18n from 'i18next';
import * as Yup from 'yup';

export const financialProductFormValidationSchema = () => {
  return Yup.object({
    productType: Yup.string().required(i18n.t('validation:required')),
    campaign: Yup.string().required(i18n.t('validation:required')),
    term: Yup.string().required(i18n.t('validation:required')),
    vatApplicable: Yup.string().required(i18n.t('validation:required')),
    lastProposalDate: Yup.string().required(i18n.t('validation:required')),
    lastPayoutDate: Yup.string().required(i18n.t('validation:required')),
    cString: Yup.string().required(i18n.t('validation:required')),
  });
};
