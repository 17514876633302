import { CustomerData } from '../../../../types/bus/customerData';
import { FinancialProduct } from '../../../../types/bus/financialProduct';
import { Transaction } from '../../../../types/bus/transaction';
import { VehicleData } from '../../../../types/bus/vehicleData';
import { apiSlice } from './api.api-slice';

export interface SearchVehicleResponse {
  vehicleData: VehicleData;
  financialProduct: FinancialProduct;
  customerData: CustomerData;
  transaction: Transaction;
}

export const transactionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchVehicle: builder.query<SearchVehicleResponse, void>({
      query: () => ({
        url: '/searchVehicle',
      }),
      extraOptions: {
        applyProductPath: false,
      },
    }),
  }),
});

export const { useSearchVehicleQuery } = transactionSlice;
