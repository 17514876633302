import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { CorrespondenceMethodEnum } from '../../types/forms';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { TradingDetailsFormModel } from './tradingDetailsForm.model';

export interface TradingDetailsFormState
  extends ReduxStateWithForms<{
    tradingDetails: ReduxTrackedForm<TradingDetailsFormModel>;
  }> {}

const tradingDetailsInitialValues: TradingDetailsFormModel = {
  entityForm: undefined,
  businessType: undefined,
  businessSubType: undefined,
  institutionalType: undefined,
  enterpriseSize: undefined,
  telephoneType: undefined,
  countryCode: undefined,
  correspondenceMethod: CorrespondenceMethodEnum.email,
  tradingAs: '',
  dateOfIncorporation: undefined,
  vatNumber: '',
  annualProfitCurrentYear: undefined,
  annualProfitPreviousYear: undefined,
  currentBusinessContracts: undefined,
  currentFinancialObligations: undefined,
  businessHistory: undefined,
  phoneNumber: undefined,
  companyEmail: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  eirCode: '',
  town: '',
  county: '',
};

const initialState: TradingDetailsFormState = {
  forms: {
    tradingDetails: {
      ...createReduxTrackedFormDefaults<TradingDetailsFormModel>({ initialValues: tradingDetailsInitialValues }),
      values: {
        ...tradingDetailsInitialValues,
      },
    },
  },
};

const name = 'tradingDetailsForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<TradingDetailsFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    tradingDetailsTriggers({ tradingDetailsForm }) {
      return tradingDetailsForm.forms.tradingDetails.triggers;
    },
    tradingDetailsValues({ tradingDetailsForm }) {
      return tradingDetailsForm.forms.tradingDetails.values;
    },
    forms({ tradingDetailsForm }) {
      return tradingDetailsForm.forms;
    },
  },
});

export const tradingDetailsFormSlice = slice;
export const tradingDetailsFormGettersDef = gettersDef;
