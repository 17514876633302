export enum DocumentFileTypeEnum {
  proofOfId = 'proofOfId',
  proofOfAddress = 'proofOfAddress',
  proofOfPPSN = 'proofOfPPSN',
  other = 'other',
}

export interface UploadedFileModel {
  id: string;
  filename: string;
  size: number;
  isUploaded: boolean;
  isFileValid: boolean; // Is file has metadata
  metadata?: {
    type?: DocumentFileTypeEnum;
  };
}
