import { Combobox } from '@vwfs-bronson/bronson-react';
import { useField, useFormikContext } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormField } from '..';
import { FormFieldPropsBase } from './fieldset.model';

interface SearchableSelectFieldProps extends FormFieldPropsBase {
  optionList: Array<{ key: string; value: string }>;
  defaultValue?: string;
  searchable?: boolean;
}

export const SearchableSelectFormField: FunctionComponent<SearchableSelectFieldProps> = ({
  optionList,
  labelTranslationKey = '',
  placeholderTranslationKey = '',
  isDisabled,
  fieldName,
  testId,
  optional,
  searchable = true,
}): React.JSX.Element => {
  const { setFieldTouched, setFieldValue } = useFormikContext<any>();
  const [field] = useField(fieldName);
  const [internalFieldValue, setInternalFieldValue] = useState({});

  const preselectedValue = optionList.find((o) => o.key === field.value);

  const handleChange = (e: Array<{ key: string; value: string }>) => {
    if (e instanceof Array && e.length) {
      setInternalFieldValue(e[0].key);
    }
  };

  const handleBlur = () => {
    setFieldTouched(fieldName, true, true);
  };

  useEffect(() => {
    setFieldValue(fieldName, internalFieldValue);
  }, [internalFieldValue, setFieldValue, fieldName]);

  useEffect(() => {
    if (preselectedValue) {
      setInternalFieldValue(preselectedValue.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormField
      type="select"
      name={fieldName}
      testId={testId ?? fieldName}
      labelText={`${t(labelTranslationKey)}${optional ? t('formControls:optionalLabelAffix') : ''}`}
      isDisabled={isDisabled}
      render={(fieldProps) => (
        <Combobox
          {...fieldProps}
          id={fieldName}
          name={fieldName}
          disabled={isDisabled}
          testId={testId}
          aria-disabled={isDisabled}
          noOptionsMessage={t('formControls:combobox.optionNotFound')}
          defaultState={
            preselectedValue ? { value: [{ key: preselectedValue.key, value: preselectedValue.value }] } : undefined
          }
          placeholder={t(placeholderTranslationKey)}
          searchable={searchable}
          options={optionList}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {optionList.map((o) => (
            <Combobox.Item optionKey={o.key} optionValue={o.value} key={o.key}>
              {o.value}
            </Combobox.Item>
          ))}
        </Combobox>
      )}
    />
  );
};
