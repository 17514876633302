import { Button, ButtonContainer, Fieldset, FormSection, FormSectionGroup, Layout } from '@vwfs-bronson/bronson-react';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AddressWithPreviousForm from '../../../../components/AddressWithPreviousForm/AddressWithPreviousForm';
import { BankDetailsForm } from '../../../../components/BankDetailsForm/BankDetailsForm';
import { CompanyDetailsForm } from '../../../../components/CompanyDetailsForm/CompanyDetailsForm';
import { CustomerDetailsForm } from '../../../../components/CustomerDetailsForm/CustomerDetailsForm';
import { DriverDetailsForm } from '../../../../components/DriverDetailsForm/DriverDetailsForm';
import { FinancialDetailsForm } from '../../../../components/FinancialDetailsForm/FinancialDetailsForm';
import { LinkedRelationshipsForm } from '../../../../components/LinkedRelationshipsForm/LinkedRelationshipsForm';
import {
  FormUpdateActionTypeGeneric,
  checkIfSectionIsReadyAndValid,
  getFormSectionId,
  getFormSectionStatusProps,
  getFormsState,
  scrollIntoSection,
  setAllFoldToFalse,
} from '../../../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../../../services/redux';
import { FlowTypeEnum } from '../../../../types/forms';
import useSaveSession from '../../../../utils/useSaveSession/useSaveSession';
import { getSectionTitleForCustomerCurried } from '../../../utils';

enum AvailableSections {
  companyDetails,
  driverDetails,
  companyBankAccountDetails,
  partnerDetails1,
  partnerAddress1,
  partnerFinancialDetails1,
  partnerLinkedRelationships1,
  partnerDetails2,
  partnerAddress2,
  partnerFinancialDetails2,
  partnerLinkedRelationships2,
}

const NavigationControls = ({ onNextNavigate }) => {
  return (
    <>
      <Layout right>
        <Layout.Item default="1/6">
          <ButtonContainer right>
            <Button onClick={onNextNavigate}>{t('customerDataPage:nextFormButton')}</Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </>
  );
};

const getSectionTitleForCustomerLocal = getSectionTitleForCustomerCurried(FlowTypeEnum.partnership);

export const customerDataBusinessPartnershipUpdateForm = {
  mainApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.companyDetailsForm.updateForm({ formName: 'companyDetails', ...payload });
    actions.driverDetailsForm.updateForm({ formName: 'driverDetailsForm', ...payload });
    actions.bankDetailsForm.updateForm({ formName: 'bankDetails', ...payload });
    actions.customerDetailsForm.updateForm({ formName: 'customerDetails1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'currentAddress1', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'previousAddress1', ...payload });
    actions.financialDetailsForm.updateForm({ formName: 'financialDetails1', ...payload });
    actions.linkedRelationshipsForm.updateForm({ formName: 'linkedRelationshipsForm1', ...payload });
  },
  coApplicant: (payload: FormUpdateActionTypeGeneric['payload']) => {
    actions.customerDetailsForm.updateForm({ formName: 'customerDetails2', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'currentAddress2', ...payload });
    actions.addressWithPreviousForm.updateForm({ formName: 'previousAddress2', ...payload });
    actions.financialDetailsForm.updateForm({ formName: 'financialDetails2', ...payload });
    actions.linkedRelationshipsForm.updateForm({ formName: 'linkedRelationshipsForm2', ...payload });
  },
};

export const CustomerDataBusinessPartnership = () => {
  const formSectionDesignator = 'customerDataPartnershipFormSection';
  const { focusOnError } = useGetters().customerDataPage;

  const [currentSection, setCurrentSection] = useState(AvailableSections.companyDetails);
  const [pendingSection, setPendingSection] = useState<AvailableSections | null>(
    () => AvailableSections.companyDetails
  );

  const { handleSaveSession } = useSaveSession();

  const [areAllFormsSettled, setAreAllFormsSettled] = useState(false);

  const setActiveSection = (section: AvailableSections) => {
    setCurrentSection(section);
  };

  const formsState = getFormsState({
    ...useGetters().addressWithPreviousForm.forms,
    ...useGetters().customerDetailsForm.forms,
    ...useGetters().financialDetailsForm.forms,
    ...useGetters().linkedRelationshipsForm.forms,
    ...useGetters().bankDetailsForm.forms,
    ...useGetters().companyDetailsForm.forms,
    ...useGetters().driverDetailsForm.forms,
  });

  const areAllFormsValid = Object.entries(formsState).every(([, { isValid, excluded }]) => isValid || excluded);

  useEffect(() => {
    setAreAllFormsSettled(
      Object.entries(formsState).every(([, { isValidating, excluded }]) => !isValidating || excluded)
    );
  }, [formsState]);

  // When in Standalone Service Plan user selected Upfront payment for service plan,
  // then we do not need Bank Details
  const isUpfrontPayment =
    getters.standaloneServicePlanForm.standaloneServicePlanValues.paymentType?.toLowerCase() === 'upfront';

  useEffect(() => {
    if (isUpfrontPayment) {
      actions.bankDetailsForm.updateForm({ formName: 'bankDetails', excluded: true });
    }
  }, [isUpfrontPayment]);

  const [foldStatus, setFoldStatus] = useState<Record<number, boolean>>({
    [AvailableSections.companyDetails]: false,
    [AvailableSections.driverDetails]: false,
    [AvailableSections.companyBankAccountDetails]: false,
    [AvailableSections.partnerDetails1]: false,
    [AvailableSections.partnerAddress1]: false,
    [AvailableSections.partnerFinancialDetails1]: false,
    [AvailableSections.partnerLinkedRelationships1]: false,
    [AvailableSections.partnerDetails2]: false,
    [AvailableSections.partnerAddress2]: false,
    [AvailableSections.partnerFinancialDetails2]: false,
    [AvailableSections.partnerLinkedRelationships2]: false,
  });

  const formSectionMap = useMemo(() => {
    return {
      [AvailableSections.companyDetails]: formsState.companyDetails,
      [AvailableSections.driverDetails]: formsState.driverDetailsForm,
      [AvailableSections.companyBankAccountDetails]: formsState.bankDetails,
      [AvailableSections.partnerDetails1]: formsState.customerDetails1,
      [AvailableSections.partnerAddress1]: [formsState.currentAddress1, formsState.previousAddress1],
      [AvailableSections.partnerFinancialDetails1]: formsState.financialDetails1,
      [AvailableSections.partnerLinkedRelationships1]: formsState.linkedRelationshipsForm1,
      [AvailableSections.partnerDetails2]: formsState.customerDetails2,
      [AvailableSections.partnerAddress2]: [formsState.currentAddress2, formsState.previousAddress2],
      [AvailableSections.partnerFinancialDetails2]: formsState.financialDetails2,
      [AvailableSections.partnerLinkedRelationships2]: formsState.linkedRelationshipsForm2,
    };
  }, [formsState]);

  useEffect(() => {
    actions.customerDataBusinessPartnership.setAllFormsValid({ allFormsValid: areAllFormsValid });
  }, [areAllFormsValid, formsState]);

  const { currentSectionIsReady, currentSectionIsValid } = checkIfSectionIsReadyAndValid(
    AvailableSections,
    currentSection,
    formSectionMap
  );

  const findFirstSectionToOpen = useCallback((): AvailableSections => {
    return Object.entries(formSectionMap).find(([section]) => {
      const form = formSectionMap[section];
      const props = getFormSectionStatusProps(form);
      return props.error;
    })?.[0] as unknown as AvailableSections;
  }, [formSectionMap]);

  useEffect(() => {
    if (pendingSection === null || !currentSectionIsReady) return;
    if (currentSectionIsValid) setCurrentSection(pendingSection);
    setPendingSection(null);
  }, [currentSection, pendingSection, currentSectionIsReady, currentSectionIsValid]);

  useEffect(
    function focusOnFirstErrorSection() {
      if (focusOnError && areAllFormsSettled) {
        const firstSectionToOpen = findFirstSectionToOpen();
        if (firstSectionToOpen) {
          setCurrentSection(firstSectionToOpen);
          actions.customerDataPage.updateFocusOnError({ state: false });
        }
      }
    },
    [focusOnError, findFirstSectionToOpen, areAllFormsSettled]
  );

  const closeAllSections = () => {
    const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

    setFoldStatus(resetFoldStatus);
  };

  useEffect(
    function openNextActiveSection() {
      const resetFoldStatus = setAllFoldToFalse<typeof foldStatus>(foldStatus);

      scrollIntoSection(getFormSectionId<AvailableSections>(formSectionDesignator, currentSection));

      const next = { ...resetFoldStatus, [currentSection]: true };

      setFoldStatus(next);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSection]
  );

  const changeFoldStatus = (section: AvailableSections, isOpened: boolean) => {
    const next = { ...foldStatus, [section]: isOpened };

    setFoldStatus(next);
  };

  return (
    <>
      <FormSectionGroup>
        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.companyDetails)}
          defaultOpen={foldStatus[AvailableSections.companyDetails]}
          title={getSectionTitleForCustomerLocal('companyDetailsForm')}
          onClickCapture={() => setActiveSection(AvailableSections.companyDetails)}
          {...getFormSectionStatusProps(formsState.companyDetails)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.companyDetails, isOpened);
          }}
        >
          <Fieldset.Row>
            <CompanyDetailsForm />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.companyDetailsForm.updateForm({ triggerValidation: true, formName: 'companyDetails' });
                setPendingSection(AvailableSections.driverDetails);
              }}
            />
          </Fieldset.Row>
        </FormSection>

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.driverDetails)}
          defaultOpen={foldStatus[AvailableSections.driverDetails]}
          title={getSectionTitleForCustomerLocal('driverDetailsForm')}
          onClickCapture={() => setActiveSection(AvailableSections.driverDetails)}
          {...getFormSectionStatusProps(formsState.driverDetailsForm)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.driverDetails, isOpened);
          }}
        >
          <Fieldset.Row>
            <DriverDetailsForm />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.driverDetailsForm.updateForm({ triggerValidation: true, formName: 'driverDetailsForm' });

                if (isUpfrontPayment) {
                  setPendingSection(AvailableSections.partnerDetails1);
                } else {
                  setPendingSection(AvailableSections.companyBankAccountDetails);
                }
              }}
            />
          </Fieldset.Row>
        </FormSection>

        {!isUpfrontPayment && (
          <FormSection
            id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.companyBankAccountDetails)}
            defaultOpen={foldStatus[AvailableSections.companyBankAccountDetails]}
            title={getSectionTitleForCustomerLocal('bankDetailsForm')}
            onClickCapture={() => setActiveSection(AvailableSections.companyBankAccountDetails)}
            {...getFormSectionStatusProps(formsState.bankDetails)}
            onChange={(isOpened: boolean) => {
              changeFoldStatus(AvailableSections.companyBankAccountDetails, isOpened);
            }}
          >
            <Fieldset.Row>
              <BankDetailsForm />
            </Fieldset.Row>
            <Fieldset.Row>
              <NavigationControls
                onNextNavigate={() => {
                  handleSaveSession();
                  actions.bankDetailsForm.updateForm({ triggerValidation: true, formName: 'bankDetails' });
                  setPendingSection(AvailableSections.partnerDetails1);
                }}
              />
            </Fieldset.Row>
          </FormSection>
        )}

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partnerDetails1)}
          defaultOpen={foldStatus[AvailableSections.partnerDetails1]}
          title={getSectionTitleForCustomerLocal('customerDetailsForm', true)}
          onClickCapture={() => setActiveSection(AvailableSections.partnerDetails1)}
          {...getFormSectionStatusProps(formsState.financialDetails1)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.partnerDetails1, isOpened);
          }}
        >
          <Fieldset.Row>
            <CustomerDetailsForm isMainApplicant />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.customerDetailsForm.updateForm({ triggerValidation: true, formName: 'customerDetails1' });
                setPendingSection(AvailableSections.partnerAddress1);
              }}
            />
          </Fieldset.Row>
        </FormSection>

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partnerAddress1)}
          defaultOpen={foldStatus[AvailableSections.partnerAddress1]}
          title={getSectionTitleForCustomerLocal('addressForm', true)}
          onClickCapture={() => setActiveSection(AvailableSections.partnerAddress1)}
          {...getFormSectionStatusProps([formsState.currentAddress1, formsState.previousAddress1])}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.partnerAddress1, isOpened);
          }}
        >
          <Fieldset.Row>
            <AddressWithPreviousForm isMainApplicant />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'currentAddress1' });
                actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'previousAddress1' });
                setPendingSection(AvailableSections.partnerFinancialDetails1);
              }}
            />
          </Fieldset.Row>
        </FormSection>

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partnerFinancialDetails1)}
          defaultOpen={foldStatus[AvailableSections.partnerFinancialDetails1]}
          title={getSectionTitleForCustomerLocal('financialDetailsForm', true)}
          onClickCapture={() => setActiveSection(AvailableSections.partnerFinancialDetails1)}
          {...getFormSectionStatusProps(formsState.financialDetails1)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.partnerFinancialDetails1, isOpened);
          }}
        >
          <Fieldset.Row>
            <FinancialDetailsForm isMainApplicant />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.financialDetailsForm.updateForm({ triggerValidation: true, formName: 'financialDetails1' });
                setPendingSection(AvailableSections.partnerLinkedRelationships1);
              }}
            />
          </Fieldset.Row>
        </FormSection>

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partnerLinkedRelationships1)}
          defaultOpen={foldStatus[AvailableSections.partnerLinkedRelationships1]}
          title={getSectionTitleForCustomerLocal('linkedRelationshipsForm', true)}
          onClickCapture={() => setActiveSection(AvailableSections.partnerLinkedRelationships1)}
          {...getFormSectionStatusProps(formsState.linkedRelationshipsForm1)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.partnerLinkedRelationships1, isOpened);
          }}
        >
          <Fieldset.Row>
            <LinkedRelationshipsForm isMainApplicant />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.linkedRelationshipsForm.updateForm({
                  triggerValidation: true,
                  formName: 'linkedRelationshipsForm1',
                });
                setPendingSection(AvailableSections.partnerDetails2);
              }}
            />
          </Fieldset.Row>
        </FormSection>

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partnerDetails2)}
          defaultOpen={foldStatus[AvailableSections.partnerDetails2]}
          title={getSectionTitleForCustomerLocal('customerDetailsForm', false)}
          onClickCapture={() => setActiveSection(AvailableSections.partnerDetails2)}
          {...getFormSectionStatusProps(formsState.customerDetails2)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.partnerDetails2, isOpened);
          }}
        >
          <Fieldset.Row>
            <CustomerDetailsForm isMainApplicant={false} />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.customerDetailsForm.updateForm({ triggerValidation: true, formName: 'customerDetails2' });
                setPendingSection(AvailableSections.partnerAddress2);
              }}
            />
          </Fieldset.Row>
        </FormSection>

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partnerAddress2)}
          defaultOpen={foldStatus[AvailableSections.partnerAddress2]}
          title={getSectionTitleForCustomerLocal('addressForm', false)}
          onClickCapture={() => setActiveSection(AvailableSections.partnerAddress2)}
          {...getFormSectionStatusProps([formsState.currentAddress2, formsState.previousAddress2])}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.partnerAddress2, isOpened);
          }}
        >
          <Fieldset.Row>
            <AddressWithPreviousForm isMainApplicant={false} />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'currentAddress2' });
                actions.addressWithPreviousForm.updateForm({ triggerValidation: true, formName: 'previousAddress2' });
                setPendingSection(AvailableSections.partnerFinancialDetails2);
              }}
            />
          </Fieldset.Row>
        </FormSection>

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partnerFinancialDetails2)}
          defaultOpen={foldStatus[AvailableSections.partnerFinancialDetails2]}
          title={getSectionTitleForCustomerLocal('financialDetailsForm', false)}
          onClickCapture={() => setActiveSection(AvailableSections.partnerFinancialDetails2)}
          {...getFormSectionStatusProps(formsState.financialDetails2)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.partnerFinancialDetails2, isOpened);
          }}
        >
          <Fieldset.Row>
            <FinancialDetailsForm isMainApplicant={false} />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.financialDetailsForm.updateForm({ triggerValidation: true, formName: 'financialDetails2' });
                setPendingSection(AvailableSections.partnerLinkedRelationships2);
              }}
            />
          </Fieldset.Row>
        </FormSection>

        <FormSection
          id={getFormSectionId<AvailableSections>(formSectionDesignator, AvailableSections.partnerLinkedRelationships2)}
          defaultOpen={foldStatus[AvailableSections.partnerLinkedRelationships2]}
          title={getSectionTitleForCustomerLocal('linkedRelationshipsForm', false)}
          onClickCapture={() => setActiveSection(AvailableSections.partnerLinkedRelationships2)}
          {...getFormSectionStatusProps(formsState.linkedRelationshipsForm2)}
          onChange={(isOpened: boolean) => {
            changeFoldStatus(AvailableSections.partnerLinkedRelationships2, isOpened);
          }}
        >
          <Fieldset.Row>
            <LinkedRelationshipsForm isMainApplicant={false} />
          </Fieldset.Row>
          <Fieldset.Row>
            <NavigationControls
              onNextNavigate={() => {
                handleSaveSession();
                actions.linkedRelationshipsForm.updateForm({
                  triggerValidation: true,
                  formName: 'linkedRelationshipsForm2',
                });

                closeAllSections();
                setPendingSection(null);
              }}
            />
          </Fieldset.Row>
        </FormSection>
      </FormSectionGroup>
    </>
  );
};
