import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

export interface User {
  username: string;
}

export interface AuthState {
  isLogged: boolean;
  isPasswordCloseToExpiration: boolean | null;
  user: User | null;
  token: string | null;
}

const initialState: AuthState = {
  isLogged: false,
  user: null,
  isPasswordCloseToExpiration: null,
  token: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logInUser: (
      state,
      action: PayloadAction<{
        user: User;
        isPasswordCloseToExpiration: boolean;
      }>
    ) => {
      state.isLogged = true;
      state.user = action.payload.user;
      state.isPasswordCloseToExpiration = action.payload.isPasswordCloseToExpiration;
    },
    logOutUser: (state) => {
      state.isLogged = false;
      state.isPasswordCloseToExpiration = null;
      state.user = null;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const { logInUser, logOutUser, setToken } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;
export const getIsPasswordCloseToExpiration = (state: RootState) => state.auth.isPasswordCloseToExpiration;
export const getToken = (state: RootState) => state.auth.token;

export default authSlice;
