import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater, omitExcludedForms } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ProductType } from '../../types/forms';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { StandaloneServicePlanModel } from './standaloneServicePlan.model';

export interface StandaloneServicePlanFormState
  extends ReduxStateWithForms<{
    standaloneServicePlan: ReduxTrackedForm<StandaloneServicePlanModel>;
  }> {}

const standaloneServicePlanInitialValues: StandaloneServicePlanModel = {
  productType: ProductType.servicePlan,
  servicePlan: '',
  vatApplicable: 'yes', // Hardcoded to yes per PC-2302
  lastProposalDate: '31/07/2023',
  lastActivationDate: '30/09/2023',
  firstRegistrationDate: '',
  recordedKilometers: undefined,
  paymentType: undefined,
  contractDuration: '',
  paymentTerm: '',
  calculation: undefined,
};

const initialState: StandaloneServicePlanFormState = {
  forms: {
    standaloneServicePlan: {
      ...createReduxTrackedFormDefaults<StandaloneServicePlanModel>({
        initialValues: standaloneServicePlanInitialValues,
      }),
      values: {
        ...standaloneServicePlanInitialValues,
      },
    },
  },
};

const name = 'standaloneServicePlanForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<StandaloneServicePlanFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    standaloneServicePlanValidationTrigger({ standaloneServicePlanForm }) {
      return standaloneServicePlanForm.forms.standaloneServicePlan.triggers.validation;
    },
    standaloneServicePlanValues({ standaloneServicePlanForm }) {
      return standaloneServicePlanForm.forms.standaloneServicePlan.values;
    },
    forms({ standaloneServicePlanForm }) {
      return standaloneServicePlanForm.forms;
    },
    getDataForSubmit(state) {
      return omitExcludedForms({
        ...state.customerDetailsForm.forms,
        ...state.addressWithPreviousForm.forms,
        ...state.addressWithPreviousForm.forms,
        ...state.bankDetailsForm.forms,
      });
    },
  },
});

export const standaloneServicePlanFormSlice = slice;
export const standaloneServicePlanFormGettersDef = gettersDef;
