import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import { FlowTypeCheckerFn, getEnumValues } from '../../services/common/miscUtils';
import { allNaceCodes, allNaceSections } from '../../services/common/naceCodes';
import Yup, {
  checkIfEnterpriseSizeIsOptional,
  maxLandlineNumberLength,
  maxMobileNumberLength,
  maxTextAreaLength,
  minLandlineNumberLength,
  minMobileNumberLength,
  regExEmail,
  regexVat,
} from '../../services/common/validation';
import {
  CorrespondenceMethodEnum,
  CountryCodeEnum,
  EnterpriseSizeEnum,
  EntityFormEnum,
  InstitutionalTypeEnum,
  TelephoneTypeEnum,
} from '../../types/forms';
import {
  CompanyDetailsFormAddressModelFields,
  CompanyDetailsFormModel,
  IdentityTypeEnum,
} from './companyDetailsForm.model';

export const createCompanyDetailsFormValidator = (
  customerCheckerFn: FlowTypeCheckerFn
): ObjectSchema<Omit<CompanyDetailsFormModel, CompanyDetailsFormAddressModelFields>> => {
  const testAnnualProfitCurrentYear = customerCheckerFn('ltdPlc', 'partnership');
  const testAnnualProfitPreviousYear = customerCheckerFn('ltdPlc', 'partnership');
  const testBusinessHistory = customerCheckerFn('other', 'partnership');

  return Yup.object({
    identityType: Yup.string().requiredWithMsg().oneOf(getEnumValues(IdentityTypeEnum)),
    identityNumber: Yup.string().requiredWithMsg().lengthBetween(0, 9),
    companyRegistrationNumber: Yup.string().requiredWithMsg().onlyNumbers().lengthBetween(0, 16),
    registeredPartnershipName: Yup.string().requiredWithMsg().lengthBetween(0, 64),
    entityForm: Yup.string().requiredWithMsg().oneOf(getEnumValues(EntityFormEnum)),
    businessType: Yup.string().requiredWithMsg().oneOf(allNaceSections),
    businessSubType: Yup.string().requiredWithMsg().oneOf(allNaceCodes),
    institutionalType: Yup.string().requiredWithMsg().oneOf(getEnumValues(InstitutionalTypeEnum)),
    enterpriseSize: Yup.string()
      .when(['institutionalType'], {
        is: (institutionalType: InstitutionalTypeEnum) => {
          return checkIfEnterpriseSizeIsOptional(institutionalType);
        },
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required(t('validation:required')),
      })
      .oneOf(getEnumValues(EnterpriseSizeEnum)),
    telephoneType: Yup.string().requiredWithMsg().oneOf(getEnumValues(TelephoneTypeEnum)),
    countryCode: Yup.string().requiredWithMsg().oneOf(getEnumValues(CountryCodeEnum)),
    correspondenceMethod: Yup.string().requiredWithMsg().oneOf(getEnumValues(CorrespondenceMethodEnum)),
    dateOfIncorporation: Yup.string().requiredWithMsg(),
    vatNumber: Yup.string().matches(regexVat, t('validation:invalidVat')).lengthBetween(0, 16).notRequired(),
    // TODO: Use `.shouldBeEmpty(),` (in place of optional) once proper form reset was added.
    annualProfitCurrentYear: testAnnualProfitCurrentYear
      ? Yup.number().requiredWithMsg().validateNumber(16, 2)
      : Yup.number().notRequired(),
    // TODO: Use `.shouldBeEmpty(),` (in place of optional) once proper form reset was added.
    annualProfitPreviousYear: testAnnualProfitPreviousYear
      ? Yup.number().requiredWithMsg().validateNumber(16, 2)
      : Yup.number().notRequired(),
    // TODO: Use `.shouldBeEmpty(),` (in place of optional) once proper form reset was added.
    currentBusinessContracts: Yup.string().requiredWithMsg().lengthBetween(0, maxTextAreaLength),
    businessHistory: testBusinessHistory
      ? Yup.string().requiredWithMsg().lengthBetween(0, maxTextAreaLength)
      : Yup.string().notRequired(),
    // TODO: Use `.shouldBeEmpty(),` (in place of optional) once proper form reset was added.
    phoneNumber: Yup.string()
      .requiredWithMsg()
      .when(['telephoneType'], {
        is: (telephoneType: TelephoneTypeEnum) => {
          return telephoneType === TelephoneTypeEnum.mobile;
        },
        then: (schema) => schema.lengthBetween(minMobileNumberLength, maxMobileNumberLength),
        otherwise: (schema) => schema.lengthBetween(minLandlineNumberLength, maxLandlineNumberLength),
      }),
    companyEmail: Yup.string().requiredWithMsg().matches(regExEmail, t('validation:invalidEmail')),
    contactFirstName: Yup.string().notRequired().unaccentedLetters().lengthBetween(0, 64),
    contactLastName: Yup.string().notRequired().unaccentedLetters().lengthBetween(0, 64),
    contactPosition: Yup.string().notRequired().lengthBetween(0, 64),
    contactCountryCode: Yup.string().requiredWithMsg().oneOf(getEnumValues(CountryCodeEnum)),
    contactPhoneNumber: Yup.string()
      .notRequired()
      .lengthBetween(
        Math.min(minLandlineNumberLength, minMobileNumberLength),
        Math.max(maxLandlineNumberLength, maxMobileNumberLength)
      ),
  });
};
