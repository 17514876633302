import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EqualityFn, useSelector } from 'react-redux';
import { RootState, store } from '.';
import { restoreSession } from './features/session.slice';

type AppSelector = <Selected = unknown>(
  selector: (state: RootState) => Selected,
  equalityFn?: EqualityFn<Selected> | undefined
) => Selected;

const useAppSelector: AppSelector = useSelector;

type AnyFunction<R = any> = (...args: any[]) => R;

type ReactiveGetterWithEqFn<T = any> = {
  handler: (reactiveState: RootState) => T;
  eqFn: (a: T, b: T) => boolean;
};

type ReactiveGettersDefinitions = Record<string, ((reactiveState: RootState) => any) | ReactiveGetterWithEqFn>;

type ReactiveGettersDefinitions2<T extends ReactiveGettersDefinitions> = {
  [k in keyof T]: T[k] extends ReactiveGetterWithEqFn
    ? T[k]['handler'] extends (reactiveState: RootState) => infer U
      ? { handler: T[k]['handler']; eqFn: (a: U, b: U) => boolean }
      : { handler: T[k]['handler']; eqFn: (a: ReturnType<T[k]['handler']>, b: ReturnType<T[k]['handler']>) => boolean }
    : T[k];
};

type StaticGettersDefinitions = Record<string, () => any>;

type GettersDefinition<A extends ReactiveGettersDefinitions, B extends StaticGettersDefinitions> = {
  /**
   * - Reactive getters will allow you to use value either with `useGet` (if you want subscribe to it
   * in React component) or with `get` when you just want to read it in any context outside of React.
   * - Trying to read values through `useGet` outside of React will result in errors.
   * - All reactive getters produce their static counterparts.
   */
  reactive: ReactiveGettersDefinitions2<A>;
  /**
   * - Static getters will allow you to read values with `get` and do it anywhere,
   * but there will not attempt to create subscription.
   * - Static getters do not create their reactive counterparts.
   */
  static?: B;
};

/**
 * - Use it to initialize getters in your root store module.
 * - Initialize your getters _after_ your store is created.
 *
 * @param defs Your getter definitions built with `createGettersDefinition`
 */
export const createGetters = <
  A extends ReactiveGettersDefinitions,
  B extends StaticGettersDefinitions,
  RDefs = GettersDefinition<A, B>['reactive'],
  SDefs = GettersDefinition<A, B>['static'],
  RGetters = {
    readonly [k in keyof RDefs]: RDefs[k] extends ReactiveGetterWithEqFn
      ? ReturnType<RDefs[k]['handler']>
      : RDefs[k] extends AnyFunction
        ? ReturnType<RDefs[k]>
        : never;
  },
  SGetters extends object = {
    readonly [k in keyof SDefs]: SDefs[k] extends (a: any) => any ? ReturnType<SDefs[k]> : never;
  } & RGetters,
>(
  defs: GettersDefinition<A, B>
): {
  /**
   * - `useGet` provides access to reactive getters.
   * - Reactive getters will allow you to subscribe to value in React component.
   * - Trying to read values through `useGet` will result in errors outside of React.
   * - All reactive getters have their static counterparts.
   */
  useGet: RGetters;
  /**
   * - `useGet` provides access to static getters.
   * - Static getters allow you to read values from any context (React or not),
   * but if used in React component they will not create subscription.
   */
  get: SGetters;
} => {
  /* For use in UI and creating subscription to updates */
  const reactiveGetters = {} as RGetters;

  Object.entries(defs.reactive ?? {}).forEach(([getterName, getterObject]) => {
    if ('eqFn' in getterObject) {
      if (typeof getterObject['eqFn'] !== 'function' || typeof getterObject['handler'] !== 'function') {
        throw new Error('Invalid reactive getter definition.');
      }

      Object.defineProperty(reactiveGetters, getterName, {
        get: () => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useAppSelector(getterObject['handler'], getterObject['eqFn']);
        },
      });
    } else {
      Object.defineProperty(reactiveGetters, getterName, {
        get: () => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useAppSelector(getterObject);
        },
      });
    }
    return reactiveGetters;
  });

  /* For use wherever without creating subscriptions */
  const staticGetters = {} as SGetters;

  Object.entries(defs.static ?? {}).forEach(([getterName, getterFn]: [any, any]) => {
    Object.defineProperty(staticGetters, getterName, {
      get: () => {
        return getterFn();
      },
    });
    return staticGetters;
  });
  Object.entries(defs.reactive).forEach(([getterName, getterObjectOrFunction]: [any, any]) => {
    if (getterName in staticGetters) {
      throw new Error('Namespace clash between static and reactive getters.');
    }

    const getterFn = 'eqFn' in getterObjectOrFunction ? getterObjectOrFunction.handler : getterObjectOrFunction;

    Object.defineProperty(staticGetters, getterName, {
      get: () => {
        return getterFn(store.getState());
      },
    });

    return staticGetters;
  });

  return {
    useGet: reactiveGetters,
    get: staticGetters,
  };
};

export const extractOnlyReactiveGetters = <GettersObject extends Record<any, any>>(
  gettersObject: GettersObject
): GettersObject extends Record<'useGet', infer Getters> ? Getters : never => {
  return gettersObject.useGet;
};

export const extractOnlyReactiveGettersNamespaced = <GetterObjectNamespaced extends Record<string, Record<any, any>>>(
  getterObjectNamespaced: GetterObjectNamespaced
): {
  [k in keyof GetterObjectNamespaced]: ReturnType<typeof extractOnlyReactiveGetters<GetterObjectNamespaced[k]>>;
} => {
  return Object.entries(getterObjectNamespaced).reduce((reactiveGetters, [sliceName, sliceGetters]) => {
    return {
      ...reactiveGetters,
      [sliceName]: extractOnlyReactiveGetters(sliceGetters),
    };
  }, {} as any);
};

export const extractOnlyStaticGetters = <GettersObject extends Record<any, any>>(
  gettersObject: GettersObject
): GettersObject extends Record<'get', infer Getters> ? Getters : never => {
  return gettersObject.get;
};

export const extractOnlyStaticGettersNamespaced = <GetterObjectNamespaced extends Record<string, Record<any, any>>>(
  getterObjectNamespaced: GetterObjectNamespaced
): {
  [k in keyof GetterObjectNamespaced]: ReturnType<typeof extractOnlyStaticGetters<GetterObjectNamespaced[k]>>;
} => {
  return Object.entries(getterObjectNamespaced).reduce((staticGetters, [sliceName, sliceGetters]) => {
    return {
      ...staticGetters,
      [sliceName]: extractOnlyStaticGetters(sliceGetters),
    };
  }, {} as any);
};

/**
 * - Use it to define your getters in your store module.
 */
export const createGettersDefinition = <A extends ReactiveGettersDefinitions, B extends StaticGettersDefinitions>(
  defs: GettersDefinition<A, B>
) => defs;

/**
 * - Use it in order to create getter with equality function, for example:
 * ```
 * myValue: withEqFn(
 *   ({ myStoreModule }) => {
 *     return myStoreModule.fieldA.fieldAA.value;
 *   },
 *   (valueBefore, valueAfter) => {
 *     return valueAfter !== valueBefore;
 *   }
 * )
 * ```
 */
export const withEqFn = <A>(handler: ReactiveGetterWithEqFn<A>['handler'], eqFn: ReactiveGetterWithEqFn<A>['eqFn']) => {
  return { handler, eqFn } as ReactiveGetterWithEqFn<ReturnType<typeof handler>>;
};

/**
 * - Wraps actions with `dispatch` so that syntax in tsx files can be shorter.
 * - E.g. instead of `dispatch(newQuotationSlice.actions.wizardNextPage())` you
 * would just use in your component `newQuotationActions.wizardNextPage()`.
 * - Dispatchers would need to be recreated if you reinitialize your store - otherwise
 * they are always valid.
 */
export const createDispatchers = <A extends { [k: string]: (...args: any) => any }>(actions: A) => {
  const _actions = {} as {
    // eslint-disable-next-line no-use-before-define
    [k in keyof A]: (...params: Parameters<A[k]>) => void;
  };

  Object.entries(actions).forEach(([name, actionCreator]: [any, any]) => {
    (_actions as any)[name] = (...actionParams: any) => {
      store.dispatch(actionCreator(...actionParams));
    };
  });

  return _actions;
};

/**
 * Enhances a given reducer builder with a case for rehydrating slice state from a session restore action.
 * Specifically, it handles the `restoreSession` action by checking if the payload contains state
 * for the specified slice name. If the slice's state is found in the payload, it updates the current
 * state of the slice with the payload's state. If not found, it logs a warning message and retains
 * the current state.
 *
 * Keep reducers and slice name in sync, otherwise rehydrated state could not match.
 *
 * @param {ActionReducerMapBuilder<any>} builder - The reducer builder.
 * @param {string} sliceName - The name of the slice for which the state may be restored.
 * @returns {ActionReducerMapBuilder<any>} The modified builder with the added rehydration case.
 */
export const saveAndContinueRehydrateSlice = (
  builder: ActionReducerMapBuilder<any>,
  sliceName: string
): ActionReducerMapBuilder<any> => {
  return builder.addCase(restoreSession, (state, action) => {
    if (action.payload && action.payload[sliceName]) {
      return action.payload[sliceName];
    }

    // eslint-disable-next-line no-console
    console.warn(`No Save And Continue data for ${sliceName} slice`);
    return state;
  });
};
