import { Input } from '@vwfs-bronson/bronson-react';
import i18n from 'i18next';
import React, { FunctionComponent } from 'react';
import { FormField } from '..';
import { UsedCarSearchFormModel } from './UsedCarSearchFormModels';

export interface ReadonlyInputProps {
  name: keyof UsedCarSearchFormModel;
  testId?: string;
}

export const ReadonlyInput: FunctionComponent<ReadonlyInputProps> = ({ name, testId }) => {
  return (
    <FormField
      type="input"
      name={name}
      testId={testId}
      labelText={i18n.t(`usedCarSearchForm:form.formFields.${name}.label`)}
      render={(fieldProps) => <Input {...fieldProps} disabled testId={testId} />}
    />
  );
};

export const BrandFormField: FunctionComponent = () => {
  return <ReadonlyInput name="brand" testId="usedCarSearchForm_brand" />;
};

export const ModelFormField: FunctionComponent = () => {
  return <ReadonlyInput name="model" testId="usedCarSearchForm_model" />;
};

export const VariantFormField: FunctionComponent = () => {
  return <ReadonlyInput name="variant" testId="usedCarSearchForm_variant" />;
};
