import { Form } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import { formAutocomplete } from '../../config';
import { actions } from '../../services/redux';
import { AddressFormGeneric } from '../AddressFormGeneric/AddressFormGeneric';
import { getAddressFormValidationSchema } from '../AddressFormGeneric/addressFormGeneric.validator';
import { AddressWithPreviousModelFields } from '../AddressWithPreviousForm/AddressWithPreviousForm.model';
import { AddressFormWithPreviousFormState } from '../AddressWithPreviousForm/addressWithPreviousForm.slice';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import { AddressFormModel } from './addressForm.model';

const addressFormFieldsToUse: AddressWithPreviousModelFields[] = [
  'addressLine1',
  'addressLine2',
  'addressLine3',
  'eirCode',
  'town',
  'county',
  'residentialStatus',
  'timeAtAddressYears',
  'timeAtAddressMonths',
];

interface AddressFormProps {
  initialFormValue: AddressFormModel;
  valuesToResetTo: AddressFormModel;
  isPrevious?: boolean;
  formName: keyof AddressFormWithPreviousFormState['forms'];
  shouldValidate: boolean;
  shouldReset: boolean;
}

const AddressForm: FunctionComponent<AddressFormProps> = ({
  initialFormValue,
  isPrevious = false,
  formName,
  shouldValidate,
  shouldReset,
  valuesToResetTo,
}) => {
  const formTranslationKey = isPrevious ? 'addressForm:previousAddressForm' : 'addressForm:currentAddressForm';

  return (
    <Formik
      initialValues={initialFormValue}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validationSchema={getAddressFormValidationSchema(addressFormFieldsToUse)}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      <Form autoComplete={formAutocomplete}>
        <PersistFormInState<AddressFormWithPreviousFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          updateForm={actions.addressWithPreviousForm.updateForm}
          shouldReset={shouldReset}
          valuesToResetTo={valuesToResetTo}
        />

        <AddressFormGeneric
          initialFormValue={initialFormValue}
          formTranslationKey={formTranslationKey}
          fieldsToRender={addressFormFieldsToUse}
        />
      </Form>
    </Formik>
  );
};

export default AddressForm;
