import { Button, ButtonContainer, Card, Fieldset, FormHeading, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ApplicationSummary from '../../../components/ApplicationSummary/ApplicationSummary';
import { SelectFormField } from '../../../components/Fieldset/SelectFormField';
import PersistFormInState from '../../../components/PersistFormInState/PersistFormInState';
import SnCInitialization from '../../../components/SnCRestoreSession/SnCInitialisation/SnCInitialization';
import { FlowTypeCheckerFn, useFlowTypeChecker } from '../../../services/common/miscUtils';
import { actions, getters, useGetters } from '../../../services/redux';
import { FlowTypeEnum } from '../../../types/forms';
import useSaveSession from '../../../utils/useSaveSession/useSaveSession';
import { WizardPagesEnum } from '../newQuotation.slice';
import {
  CustomerDataBusinessLtdPlc,
  customerDataBusinessLtdPlcUpdateForm,
} from './CustomerDataBusinessLtdPlc/CustomerDataBusinessLtdPlc';
import {
  CustomerDataBusinessOther,
  customerDataBusinessOtherUpdateForm,
} from './CustomerDataBusinessOther/CustomerDataBusinessOther';
import {
  CustomerDataBusinessPartnership,
  customerDataBusinessPartnershipUpdateForm,
} from './CustomerDataBusinessPartnership/CustomerDataBusinessPartnership';
import CustomerDataBusinessSoleTrader, {
  customerDataBusinessSoleTraderUpdateForm,
} from './CustomerDataBusinessSoleTrader/CustomerDataBusinessSoleTrader';
import CustomerDataPrivate, { customerDataPrivateUpdateForm } from './CustomerDataPrivate/CustomerDataPrivate';
import {
  CustomerDataSIBusinessLtdPlc,
  customerDataSIBusinessLtdPlcUpdateForm,
} from './CustomerDataSIBusinessLtdPlc/CustomerDataSIBusinessLtdPlc';
import {
  CustomerDataSIBusinessOther,
  customerDataSIBusinessOtherUpdateForm,
} from './CustomerDataSIBusinessOther/CustomerDataSIBusinessOther';
import {
  CustomerDataSIBusinessPartnership,
  customerDataSIBusinessPartnershipUpdateForm,
} from './CustomerDataSIBusinessPartnership/CustomerDataSIBusinessPartnership';
import {
  CustomerDataSIBusinessSoleTrader,
  customerDataSIBusinessSoleTraderUpdateForm,
} from './CustomerDataSIBusinessSoleTrader/CustomerDataSIBusinessSoleTrader';
import CustomerDataServicePlan, {
  customerDataServicePlanUpdateForm,
} from './CustomerDataServicePlan/CustomerDataServicePlan';
import { BusinessCustomerTypesEnum } from './customerDataPage.model';
import { CustomerDataPageState } from './customerDataPage.slice';

const customerFormsRenderer = (flowTypeIs: FlowTypeCheckerFn) => {
  return flowTypeIs('financialProduct') ? (
    <>
      {flowTypeIs('soleTrader') && <CustomerDataBusinessSoleTrader />}
      {flowTypeIs('partnership') && <CustomerDataBusinessPartnership />}
      {flowTypeIs('other') && <CustomerDataBusinessOther />}
      {flowTypeIs('ltdPlc') && <CustomerDataBusinessLtdPlc />}
    </>
  ) : (
    <>
      {flowTypeIs('soleTrader') && <CustomerDataSIBusinessSoleTrader />}
      {flowTypeIs('partnership') && <CustomerDataSIBusinessPartnership />}
      {flowTypeIs('other') && <CustomerDataSIBusinessOther />}
      {flowTypeIs('ltdPlc') && <CustomerDataSIBusinessLtdPlc />}
    </>
  );
};

export const submitCustomerDataForms = (flowTypeIs: FlowTypeCheckerFn) => {
  switch (true) {
    case flowTypeIs('consumer') && flowTypeIs('financialProduct'):
      customerDataPrivateUpdateForm.mainApplicant({ triggerValidation: true });
      customerDataPrivateUpdateForm.coApplicant({ triggerValidation: true });
      break;
    case flowTypeIs('soleTrader') && flowTypeIs('financialProduct'):
      customerDataBusinessSoleTraderUpdateForm.mainApplicant({ triggerValidation: true });
      break;
    case flowTypeIs('ltdPlc') && flowTypeIs('financialProduct'):
      customerDataBusinessLtdPlcUpdateForm.mainApplicant({ triggerValidation: true });
      customerDataBusinessLtdPlcUpdateForm.coApplicant({ triggerValidation: true });
      break;
    case flowTypeIs('other') && flowTypeIs('financialProduct'):
      customerDataBusinessOtherUpdateForm.mainApplicant({ triggerValidation: true });
      customerDataBusinessOtherUpdateForm.coApplicant({ triggerValidation: true });
      break;
    case flowTypeIs('partnership') && flowTypeIs('financialProduct'):
      customerDataBusinessPartnershipUpdateForm.mainApplicant({ triggerValidation: true });
      customerDataBusinessPartnershipUpdateForm.coApplicant({ triggerValidation: true });
      break;

    case flowTypeIs('consumer') && flowTypeIs('servicePlan'):
      customerDataServicePlanUpdateForm.mainApplicant({ triggerValidation: true });
      break;
    case flowTypeIs('soleTrader') && flowTypeIs('servicePlan'):
      customerDataSIBusinessSoleTraderUpdateForm.mainApplicant({ triggerValidation: true });
      break;
    case flowTypeIs('ltdPlc') && flowTypeIs('servicePlan'):
      customerDataSIBusinessLtdPlcUpdateForm.mainApplicant({ triggerValidation: true });
      customerDataSIBusinessLtdPlcUpdateForm.firstPerson({ triggerValidation: true });
      customerDataSIBusinessLtdPlcUpdateForm.secondPerson({ triggerValidation: true });
      break;
    case flowTypeIs('other') && flowTypeIs('servicePlan'):
      customerDataSIBusinessOtherUpdateForm.mainApplicant({ triggerValidation: true });
      customerDataSIBusinessOtherUpdateForm.firstPerson({ triggerValidation: true });
      customerDataSIBusinessOtherUpdateForm.secondPerson({ triggerValidation: true });
      break;
    case flowTypeIs('partnership') && flowTypeIs('servicePlan'):
      customerDataSIBusinessPartnershipUpdateForm.mainApplicant({ triggerValidation: true });
      customerDataSIBusinessPartnershipUpdateForm.firstPerson({ triggerValidation: true });
      customerDataSIBusinessPartnershipUpdateForm.secondPerson({ triggerValidation: true });
      break;
    default:
      throw new Error('Unhandled flow type type.');
  }
};

export const excludeAndResetAllCustomerDataForms = () => {
  customerDataPrivateUpdateForm.mainApplicant({ excluded: true, triggerReset: true });
  customerDataBusinessSoleTraderUpdateForm.mainApplicant({ excluded: true, triggerReset: true });
  customerDataBusinessLtdPlcUpdateForm.mainApplicant({ excluded: true, triggerReset: true });
  customerDataBusinessLtdPlcUpdateForm.coApplicant({ excluded: true, triggerReset: true });
  customerDataBusinessOtherUpdateForm.mainApplicant({ excluded: true, triggerReset: true });
  customerDataBusinessOtherUpdateForm.coApplicant({ excluded: true, triggerReset: true });
  customerDataBusinessPartnershipUpdateForm.mainApplicant({ excluded: true, triggerReset: true });
  customerDataBusinessPartnershipUpdateForm.coApplicant({ excluded: true, triggerReset: true });
  customerDataServicePlanUpdateForm.mainApplicant({ excluded: true, triggerReset: true });

  customerDataSIBusinessPartnershipUpdateForm.mainApplicant({ excluded: true, triggerReset: true });
  customerDataSIBusinessPartnershipUpdateForm.firstPerson({ excluded: true, triggerReset: true });
  customerDataSIBusinessPartnershipUpdateForm.secondPerson({ excluded: true, triggerReset: true });
  customerDataSIBusinessOtherUpdateForm.mainApplicant({ excluded: true, triggerReset: true });
  customerDataSIBusinessOtherUpdateForm.firstPerson({ excluded: true, triggerReset: true });
  customerDataSIBusinessOtherUpdateForm.secondPerson({ excluded: true, triggerReset: true });
  customerDataSIBusinessLtdPlcUpdateForm.mainApplicant({ excluded: true, triggerReset: true });
  customerDataSIBusinessLtdPlcUpdateForm.firstPerson({ excluded: true, triggerReset: true });
  customerDataSIBusinessLtdPlcUpdateForm.secondPerson({ excluded: true, triggerReset: true });
};

export const updateFormsExclusion = (flowTypeIs: FlowTypeCheckerFn) => {
  switch (true) {
    case flowTypeIs('consumer') && flowTypeIs('financialProduct'):
      customerDataPrivateUpdateForm.mainApplicant({ excluded: false });
      break;
    case flowTypeIs('soleTrader') && flowTypeIs('financialProduct'):
      customerDataBusinessSoleTraderUpdateForm.mainApplicant({ excluded: false });
      break;
    case flowTypeIs('ltdPlc') && flowTypeIs('financialProduct'):
      customerDataBusinessLtdPlcUpdateForm.mainApplicant({ excluded: false });
      customerDataBusinessLtdPlcUpdateForm.coApplicant({ excluded: true });
      break;
    case flowTypeIs('other') && flowTypeIs('financialProduct'):
      customerDataBusinessOtherUpdateForm.mainApplicant({ excluded: false });
      customerDataBusinessOtherUpdateForm.coApplicant({ excluded: true });
      break;
    case flowTypeIs('partnership') && flowTypeIs('financialProduct'):
      customerDataBusinessPartnershipUpdateForm.mainApplicant({ excluded: false });
      customerDataBusinessPartnershipUpdateForm.coApplicant({ excluded: false });
      break;

    case flowTypeIs('consumer') && flowTypeIs('servicePlan'):
      customerDataServicePlanUpdateForm.mainApplicant({ excluded: false });
      break;
    case flowTypeIs('soleTrader') && flowTypeIs('servicePlan'):
      customerDataSIBusinessSoleTraderUpdateForm.mainApplicant({ excluded: false });
      break;
    case flowTypeIs('ltdPlc') && flowTypeIs('servicePlan'):
      customerDataSIBusinessLtdPlcUpdateForm.mainApplicant({ excluded: false });
      customerDataSIBusinessLtdPlcUpdateForm.firstPerson({ excluded: true });
      customerDataSIBusinessLtdPlcUpdateForm.secondPerson({ excluded: true });
      break;
    case flowTypeIs('other') && flowTypeIs('servicePlan'):
      customerDataSIBusinessOtherUpdateForm.mainApplicant({ excluded: false });
      customerDataSIBusinessOtherUpdateForm.firstPerson({ excluded: true });
      customerDataSIBusinessOtherUpdateForm.secondPerson({ excluded: true });
      break;
    case flowTypeIs('partnership') && flowTypeIs('servicePlan'):
      customerDataSIBusinessPartnershipUpdateForm.mainApplicant({ excluded: false });
      customerDataSIBusinessPartnershipUpdateForm.firstPerson({ excluded: true });
      customerDataSIBusinessPartnershipUpdateForm.secondPerson({ excluded: true });
      break;
    default:
      throw new Error('Unhandled flow type.');
  }
};

export const getCustomerDataForSubmit = (flowTypeIs: FlowTypeCheckerFn) => {
  switch (true) {
    case flowTypeIs('consumer') && flowTypeIs('financialProduct'):
      return getters.customerDataPrivate.getDataForSubmit;
    case flowTypeIs('soleTrader') && flowTypeIs('financialProduct'):
      return getters.customerDataBusinessSoleTrader.getDataForSubmit;
    case flowTypeIs('ltdPlc') && flowTypeIs('financialProduct'):
      return getters.customerDataBusinessLtdPlc.getDataForSubmit;
    case flowTypeIs('other') && flowTypeIs('financialProduct'):
      return getters.customerDataBusinessOther.getDataForSubmit;
    case flowTypeIs('partnership') && flowTypeIs('financialProduct'):
      return getters.customerDataBusinessPartnership.getDataForSubmit;

    case flowTypeIs('consumer') && flowTypeIs('servicePlan'):
      return getters.customerDataServicePlan.getDataForSubmit;
    case flowTypeIs('soleTrader') && flowTypeIs('servicePlan'):
      return getters.customerDataSIBusinessSoleTrader.getDataForSubmit;
    case flowTypeIs('ltdPlc') && flowTypeIs('servicePlan'):
      return getters.customerDataSIBusinessLtdPlc.getDataForSubmit;
    case flowTypeIs('other') && flowTypeIs('servicePlan'):
      return getters.customerDataSIBusinessOther.getDataForSubmit;
    case flowTypeIs('partnership') && flowTypeIs('servicePlan'):
      return getters.customerDataSIBusinessPartnership.getDataForSubmit;
    default:
      throw new Error('Unhandled flow type.');
  }
};

export const useGetCustomerDataFormsValidity = (flowTypeIs: FlowTypeCheckerFn) => {
  const customerDataBusinessSoleTraderValidity = useGetters().customerDataBusinessSoleTrader.areAllFormsValid;
  const customerDataBusinessOtherValidity = useGetters().customerDataBusinessOther.areAllFormsValid;
  const customerDataBusinessLtdPlcValidity = useGetters().customerDataBusinessLtdPlc.areAllFormsValid;
  const customerDataPrivateValidity = useGetters().customerDataPrivate.areAllFormsValid;
  const customerDataBusinessPartnershipValidity = useGetters().customerDataBusinessPartnership.areAllFormsValid;
  const customerDataServicePlanValidity = useGetters().customerDataServicePlan.areAllFormsValid;

  const customerDataSIBusinessPartnershipValidity = useGetters().customerDataSIBusinessPartnership.areAllFormsValid;
  const customerDataSIBusinessOtherValidity = useGetters().customerDataSIBusinessOther.areAllFormsValid;
  const customerDataSIBusinessLtdPlcValidity = useGetters().customerDataSIBusinessLtdPlc.areAllFormsValid;
  const customerDataSIBusinessSoleTraderValidity = useGetters().customerDataSIBusinessSoleTrader.areAllFormsValid;

  switch (true) {
    case flowTypeIs('consumer') && flowTypeIs('financialProduct'):
      return customerDataPrivateValidity;
    case flowTypeIs('soleTrader') && flowTypeIs('financialProduct'):
      return customerDataBusinessSoleTraderValidity;
    case flowTypeIs('ltdPlc') && flowTypeIs('financialProduct'):
      return customerDataBusinessLtdPlcValidity;
    case flowTypeIs('other') && flowTypeIs('financialProduct'):
      return customerDataBusinessOtherValidity;
    case flowTypeIs('partnership') && flowTypeIs('financialProduct'):
      return customerDataBusinessPartnershipValidity;

    case flowTypeIs('consumer') && flowTypeIs('servicePlan'):
      return customerDataServicePlanValidity;
    case flowTypeIs('soleTrader') && flowTypeIs('servicePlan'):
      return customerDataSIBusinessSoleTraderValidity;
    case flowTypeIs('ltdPlc') && flowTypeIs('servicePlan'):
      return customerDataSIBusinessLtdPlcValidity;
    case flowTypeIs('other') && flowTypeIs('servicePlan'):
      return customerDataSIBusinessOtherValidity;
    case flowTypeIs('partnership') && flowTypeIs('servicePlan'):
      return customerDataSIBusinessPartnershipValidity;
    default:
      throw new Error('Unhandled flow type.');
  }
};

const getBusinessCustomerOptions = () => [
  {
    label: t('customerDataPage:businessCustomerType.ltdPlc'),
    value: 'ltdPlc',
  },
  {
    label: t('customerDataPage:businessCustomerType.soleTrader'),
    value: 'soleTrader',
  },
  {
    label: t('customerDataPage:businessCustomerType.partnership'),
    value: 'partnership',
  },
  {
    label: t('customerDataPage:businessCustomerType.other'),
    value: 'other',
  },
];

const getFormHeading = (flowTypeIs: FlowTypeCheckerFn, selectedBusinessCustomerType?: BusinessCustomerTypesEnum) => {
  if (flowTypeIs('consumer')) {
    return t('customerDataPrivate:header:private1');
  }
  if (flowTypeIs('servicePlan')) {
    return t(`customerDataPage:businessCustomerType.${FlowTypeEnum.servicePlan}`);
  }

  return t(`customerDataPage:businessCustomerType.${selectedBusinessCustomerType}`);
};

const CustomerDataPage: React.FunctionComponent = () => {
  const {
    values: initialFormValues,
    values: { selectedBusinessCustomerType },
  } = useGetters().customerDataPage.businessCustomerTypeForm;

  const flowTypeIs = useFlowTypeChecker();
  const [pendingNext, setPendingNext] = useState(false);

  const { handleSaveSession } = useSaveSession();

  const previousBusinessCustomerTypeRef = useRef(selectedBusinessCustomerType);

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  useEffect(() => {
    if (previousBusinessCustomerTypeRef.current !== selectedBusinessCustomerType) {
      excludeAndResetAllCustomerDataForms();
      updateFormsExclusion(flowTypeIs);

      handleSaveSession();
    }

    previousBusinessCustomerTypeRef.current = selectedBusinessCustomerType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowTypeIs]);

  const customerDataValid = useGetCustomerDataFormsValidity(flowTypeIs);

  useEffect(() => {
    if (pendingNext && customerDataValid) {
      actions.newQuotation.wizardNavigateToPage(WizardPagesEnum.amlDocuments);
    } else {
      setPendingNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDataValid, pendingNext]);

  const { snCClosed } = useGetters().snCInitialization;

  return (
    <>
      <Card element="div" className="u-mb">
        <ApplicationSummary isDefaultOpen={false} />
      </Card>

      {!snCClosed && (
        <Card element="div" id="snc-init-lead-form" className="u-mb">
          <SnCInitialization />
        </Card>
      )}

      <Card element="div">
        <Layout>
          <Layout.Item default="1/2">
            <FormHeading>{getFormHeading(flowTypeIs, selectedBusinessCustomerType)}</FormHeading>
          </Layout.Item>
          <Layout.Item default="1/2" className="u-text-right">
            <Button icon="semantic-forward" link simple small>
              {t('customerDataPrivate:viewDpn')}
            </Button>
          </Layout.Item>
        </Layout>

        {flowTypeIs('consumer') && flowTypeIs('financialProduct') && <CustomerDataPrivate />}

        {flowTypeIs('consumer') && flowTypeIs('servicePlan') && <CustomerDataServicePlan />}

        {flowTypeIs('business') && (
          <>
            <Fieldset.Row>
              <Layout center>
                <Layout.Item default="1/4" s="1/1">
                  <Formik
                    initialValues={initialFormValues}
                    onSubmit={
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      () => {}
                    }
                  >
                    <>
                      <PersistFormInState<CustomerDataPageState>
                        formName="businessCustomerTypeForm"
                        updateForm={actions.customerDataPage.updateForm}
                      />
                      <SelectFormField
                        fieldName="selectedBusinessCustomerType"
                        optionList={getBusinessCustomerOptions()}
                        testId="businessCustomerType"
                        isDisabled={disableAllFormControls}
                      />
                    </>
                  </Formik>
                </Layout.Item>
              </Layout>
            </Fieldset.Row>
            {customerFormsRenderer(flowTypeIs)}
          </>
        )}
      </Card>

      <Layout center className="u-mt-xsmall">
        <Layout.Item default="1/2">
          <ButtonContainer center>
            <Button
              testId="button_goBack"
              secondary
              onClick={() => {
                actions.newQuotation.wizardNavigateToPage(WizardPagesEnum.customerTypeSelection);
              }}
            >
              {t('customerDataPage:navigation.back')}
            </Button>

            <Button
              testId="button_continue"
              onClick={() => {
                actions.customerDataPage.updateFocusOnError({ state: true });
                submitCustomerDataForms(flowTypeIs);
                setPendingNext(true);
              }}
            >
              {t('customerDataPage:navigation.continue')}
            </Button>
          </ButtonContainer>
        </Layout.Item>
      </Layout>
    </>
  );
};

export default CustomerDataPage;
