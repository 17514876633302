import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { omitExcludedForms } from '../../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../../services/redux/storeUtils';

export interface customerDataBusinessOtherState {
  allFormsValid: boolean;
  isCoApplicantAdded: boolean;
}

const initialState: customerDataBusinessOtherState = {
  allFormsValid: false,
  isCoApplicantAdded: false,
};

const name = 'customerDataBusinessOther';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAllFormsValid: (state, action: PayloadAction<{ allFormsValid: boolean }>) => {
      state.allFormsValid = action.payload.allFormsValid;
    },
    setIsCoApplicantAdded: (state, action: PayloadAction<boolean>) => {
      state.isCoApplicantAdded = action.payload;
    },
    resetAddedCoApplicants: (state) => {
      state.isCoApplicantAdded = false;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    isCoApplicantAdded({ customerDataBusinessOther }) {
      return customerDataBusinessOther.isCoApplicantAdded;
    },
    areAllFormsValid({ customerDataBusinessOther }) {
      return customerDataBusinessOther.allFormsValid;
    },
    getDataForSubmit(state) {
      return omitExcludedForms({
        ...state.companyDetailsForm.forms,
        ...state.driverDetailsForm.forms,
        ...state.bankDetailsForm.forms,
        ...state.customerDetailsForm.forms,
        ...state.addressWithPreviousForm.forms,
        ...state.linkedRelationshipsForm.forms,
      });
    },
  },
});

export const customerDataBusinessOtherSlice = slice;
export const customerDataBusinessOtherGettersDef = gettersDef;
