import { Fieldset, Layout } from '@vwfs-bronson/bronson-react';
import { Form, Formik } from 'formik';
import React, { FunctionComponent, ReactElement, memo, useState } from 'react';
import { formAutocomplete } from '../../config';
import { actions, getters, useGetters } from '../../services/redux';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState } from '../PersistFormInState/PersistFormInState';
import { DriverDetailsFormModel, DriverDetailsFormModelFields } from './driverDetailsForm.model';
import { DriverDetailsFormState } from './driverDetailsForm.slice';
import { driverDetailsFormSchema } from './driverDetailsForm.validator';

const typeSafeFormFieldName = (formFieldName: keyof DriverDetailsFormModel) => formFieldName;

const createFormInputs = (() => {
  return {
    firstName: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('firstName')}
        labelTranslationKey="driverDetailsForm:fields:firstName:label"
        placeholderTranslationKey="driverDetailsForm:fields:firstName:placeholder"
      />
    ),
    lastName: () => (
      <TextInputFormField
        fieldName={typeSafeFormFieldName('lastName')}
        labelTranslationKey="driverDetailsForm:fields:lastName:label"
        placeholderTranslationKey="driverDetailsForm:fields:lastName:placeholder"
      />
    ),
  };
}) satisfies () => Record<DriverDetailsFormModelFields, (...args: any[]) => ReactElement>;

const formName: keyof DriverDetailsFormState['forms'] = 'driverDetailsForm';

// eslint-disable-next-line react/display-name
export const DriverDetailsForm: FunctionComponent = memo(() => {
  const [initialValues /* , setInitialValues */] = useState(() => getters.driverDetailsForm.driverDetailsFormValues);
  const shouldValidate = useGetters().driverDetailsForm.driverDetailsFormTriggers.validation;
  const shouldReset = useGetters().driverDetailsForm.driverDetailsFormTriggers.reset;

  const [inputs /* , updateInputs */] = useState<ReturnType<typeof createFormInputs>>(() => createFormInputs());

  return (
    <Formik
      validationSchema={driverDetailsFormSchema()}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      <>
        <PersistFormInState<DriverDetailsFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          updateForm={actions.driverDetailsForm.updateForm}
          shouldReset={shouldReset}
          valuesToResetTo={getters.driverDetailsForm.forms.driverDetailsForm.initialValues}
        />

        <Form autoComplete={formAutocomplete}>
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">
                {inputs.firstName()}
              </Layout.Item>
              <Layout.Item default="1/2" s="1/1">
                {inputs.lastName()}
              </Layout.Item>
            </Layout>
          </Fieldset.Row>
        </Form>
      </>
    </Formik>
  );
});
