/* eslint-disable func-names */
import { Fieldset, Layout, UploadItem } from '@vwfs-bronson/bronson-react';
import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentFileTypeEnum } from '../../screens/NewQuotation/DocumentUploadPage/DocumentUploadPage.model';
import { fileSizeFormat } from '../../services/common/fileSizeFormat';
import { actions } from '../../services/redux';
import {
  AmlDocumentUploadResponseModel,
  deleteAmlDocument,
  updateAmlDocument,
  uploadAmlDocument,
} from '../../services/redux/features/api/amlDocument.api';
import { UploadedFile } from '../../types/files';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { documentUploadItemValidationSchema } from './documentUploadItem.validator';

interface DocumentUploadItemProps {
  file: UploadedFile;
  onDeleteSuccess: (...args: any[]) => void;
  validityCallback: (isValid: boolean) => void;
}

const DocumentUploadItem: FunctionComponent<DocumentUploadItemProps> = ({
  file,
  onDeleteSuccess,
  validityCallback,
}) => {
  const { t } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [bytesSent, setBytesSent] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isUploadFailed, setIsUploadFailed] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState<string>();

  const setFailedUploadFlags = () => {
    setIsUploading(false);
    setIsUploadFailed(true);
    setIsUploadSuccess(false);
  };
  const setSuccessUploadFlags = () => {
    setIsUploading(false);
    setIsUploadFailed(false);
    setIsUploadSuccess(true);
  };

  const onUploadProgress = (percentCompleted: number, bytesSent: number, totalBytes: number) => {
    setUploadProgress(percentCompleted);
    setTotalBytes(totalBytes);
    setBytesSent(bytesSent);
  };

  const autoUploadFile = async () => {
    setIsUploading(true);

    let response: AxiosResponse<AmlDocumentUploadResponseModel>;

    try {
      response = await uploadAmlDocument(file, onUploadProgress);

      actions.documentUploadPage.pushFile({
        uploadedFile: {
          filename: file.name,
          id: response.data.id,
          isFileValid: false,
          isUploaded: true,
          size: file.size,
        },
      });

      setUploadedFileId(response.data.id);
      setSuccessUploadFlags();
    } catch {
      setFailedUploadFlags();
    }
  };

  const deleteFile = async () => {
    if (!uploadedFileId) {
      return;
    }
    try {
      await deleteAmlDocument(uploadedFileId);

      actions.documentUploadPage.removeFileById({ id: uploadedFileId });
      onDeleteSuccess();
    } catch (e) {
      throw new Error();
    }
  };

  const previewFile = () => {
    if (file) {
      const blobUrl = URL.createObjectURL(file);
      window.open(blobUrl, '_blank');
    }
  };

  useEffect(() => {
    autoUploadFile();

    return () => {
      actions.documentUploadPage.resetState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Layout.Item default="1/1">
        <Fieldset.Row>
          <Formik
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
            initialValues={{
              documentType: '',
            }}
            validationSchema={documentUploadItemValidationSchema()}
            validateOnBlur
            validateOnMount
          >
            {({ values, isValid }) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              useEffect(() => {
                if (uploadedFileId) {
                  updateAmlDocument(uploadedFileId, values);
                  actions.documentUploadPage.updateFileById({
                    id: uploadedFileId,
                    updates: {
                      metadata: {
                        type: values.documentType as DocumentFileTypeEnum,
                      },
                    },
                  });
                }
              }, [values]);

              validityCallback(isValid);

              return (
                <>
                  <Layout>
                    <Layout.Item default="1/2">
                      <SelectFormField
                        fieldName="documentType"
                        isDisabled={!isUploadSuccess}
                        labelTranslationKey="documentUploadItem:uploadItemForm.documentType.label"
                        optionList={[
                          { label: t('formControls:select.noSelection'), value: '' },
                          {
                            label: 'Proof of ID',
                            value: DocumentFileTypeEnum.proofOfId,
                          },
                          {
                            label: 'Proof of Address',
                            value: DocumentFileTypeEnum.proofOfAddress,
                          },
                          {
                            label: 'Proof of PPSN',
                            value: DocumentFileTypeEnum.proofOfPPSN,
                          },
                          {
                            label: 'Other',
                            value: DocumentFileTypeEnum.other,
                          },
                        ]}
                        testId="documentType"
                      />
                    </Layout.Item>
                  </Layout>
                </>
              );
            }}
          </Formik>
        </Fieldset.Row>
      </Layout.Item>
      <Layout.Item default="1/1">
        <UploadItem
          fileName={file.name}
          fileSize={fileSizeFormat(totalBytes)}
          icon="semantic-file"
          loading={isUploading}
          error={isUploadFailed}
          progressValue={uploadProgress.toString()}
          filesizeProgress={fileSizeFormat(bytesSent)}
          mainButtonProps={{
            'aria-label': t('documentUploadItem.viewButton'),
            title: t('documentUploadItem.viewButton'),
          }}
          onMainButtonClick={previewFile}
          onSecondaryButtonClick={deleteFile}
          secondaryButtonProps={{
            'aria-label': t('documentUploadItem.deleteButton'),
            title: t('documentUploadItem.deleteButton'),
          }}
        />
      </Layout.Item>
    </Layout>
  );
};

export default DocumentUploadItem;
