import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { LinkedRelationshipFormModel } from './linkedRelationshipsForm.model';

export interface LinkedRelationshipsFormState
  extends ReduxStateWithForms<{
    linkedRelationshipsForm1: ReduxTrackedForm<LinkedRelationshipFormModel>;
    linkedRelationshipsForm2: ReduxTrackedForm<LinkedRelationshipFormModel>;
  }> {}

const linkedRelationshipsFormInitialValues: LinkedRelationshipFormModel = {
  majorityInCompanies: '',
  majorityInCompaniesCompanyName: '',
  partnerOfCompanies: '',
  partnerOfCompaniesCompanyName: '',
  customerOfVwfs: '',
  contactNumberOrVehicleRegistrationNumber: '',
};

const initialState: LinkedRelationshipsFormState = {
  forms: {
    linkedRelationshipsForm1: {
      ...createReduxTrackedFormDefaults<LinkedRelationshipFormModel>({
        initialValues: linkedRelationshipsFormInitialValues,
      }),
      values: {
        ...linkedRelationshipsFormInitialValues,
      },
    },
    linkedRelationshipsForm2: {
      ...createReduxTrackedFormDefaults<LinkedRelationshipFormModel>({
        excluded: true,
        initialValues: linkedRelationshipsFormInitialValues,
      }),
      values: {
        ...linkedRelationshipsFormInitialValues,
      },
    },
  },
};

const name = 'linkedRelationshipsForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<LinkedRelationshipsFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    linkedRelationshipsForm1Triggers({ linkedRelationshipsForm }) {
      return linkedRelationshipsForm.forms.linkedRelationshipsForm1.triggers;
    },
    linkedRelationshipsForm2Triggers({ linkedRelationshipsForm }) {
      return linkedRelationshipsForm.forms.linkedRelationshipsForm2.triggers;
    },
    linkedRelationshipsForm1Values({ linkedRelationshipsForm }) {
      return linkedRelationshipsForm.forms.linkedRelationshipsForm1.values;
    },
    linkedRelationshipsForm2Values({ linkedRelationshipsForm }) {
      return linkedRelationshipsForm.forms.linkedRelationshipsForm2.values;
    },
    forms({ linkedRelationshipsForm }) {
      return linkedRelationshipsForm.forms;
    },
  },
});

export const linkedRelationshipsFormSlice = slice;
export const linkedRelationshipsFormGettersDef = gettersDef;
