import { NaceCodes, NaceSections } from '../../services/common/naceCodes';
import {
  CorrespondenceMethodEnum,
  CountryCodeEnum,
  EnterpriseSizeEnum,
  EntityFormEnum,
  InstitutionalTypeEnum,
  TelephoneTypeEnum,
} from '../../types/forms';
import { AddressFormGenericModel } from '../AddressFormGeneric/addressFormGeneric.model';

export enum IdentityTypeEnum {
  cro = 'cro',
  nonTaxIdentity = 'nonTaxIdentity',
}

export interface CompanyDetailsFormAddressModel
  extends Pick<
    Partial<AddressFormGenericModel>,
    'addressLine1' | 'addressLine2' | 'addressLine3' | 'eirCode' | 'town' | 'county'
  > {}

export interface CompanyDetailsFormModel extends CompanyDetailsFormAddressModel {
  identityType?: IdentityTypeEnum;
  identityNumber?: string;
  companyRegistrationNumber?: string;
  registeredPartnershipName?: string;
  entityForm?: EntityFormEnum;
  businessType?: NaceSections;
  businessSubType?: NaceCodes;
  institutionalType?: InstitutionalTypeEnum;
  enterpriseSize?: EnterpriseSizeEnum;
  telephoneType?: TelephoneTypeEnum;
  countryCode?: CountryCodeEnum;
  correspondenceMethod?: CorrespondenceMethodEnum;
  dateOfIncorporation?: string;
  vatNumber?: string | null;
  annualProfitCurrentYear?: number | null;
  annualProfitPreviousYear?: number | null;
  currentBusinessContracts?: string;
  businessHistory?: string | null;
  phoneNumber?: string;
  companyEmail?: string;
  contactFirstName?: string | null;
  contactLastName?: string | null;
  contactPosition?: string | null;
  contactCountryCode?: CountryCodeEnum;
  contactPhoneNumber?: string | null;
}

export type CompanyDetailsFormModelFields = keyof CompanyDetailsFormModel;
export type CompanyDetailsFormAddressModelFields = keyof CompanyDetailsFormAddressModel;
