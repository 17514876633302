import { createSlice } from '@reduxjs/toolkit';
import { getFormUpdater } from '../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../services/redux/storeUtils';
import { CorrespondenceMethodEnum, CountryCodeEnum } from '../../types/forms';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../types/reduxTrackedForm';
import { ContactDetailsFormModel } from './contactDetailsForm.model';

export interface ContactDetailsFormState
  extends ReduxStateWithForms<{
    contactDetailsForm: ReduxTrackedForm<ContactDetailsFormModel>;
  }> {}

const contactDetailsFormInitialValues: ContactDetailsFormModel = {
  title: undefined,
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  invoiceEmailAddress: '',
  countryCode: CountryCodeEnum.ie,
  mobileNumber: '',
  correspondenceMethod: CorrespondenceMethodEnum.email,
};

const initialState: ContactDetailsFormState = {
  forms: {
    contactDetailsForm: {
      ...createReduxTrackedFormDefaults<ContactDetailsFormModel>({ initialValues: contactDetailsFormInitialValues }),
      values: {
        ...contactDetailsFormInitialValues,
      },
    },
  },
};

const name = 'contactDetailsForm';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<ContactDetailsFormState>(),
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    contactDetailsFormTriggers({ contactDetailsForm }) {
      return contactDetailsForm.forms.contactDetailsForm.triggers;
    },
    contactDetailsFormValues({ contactDetailsForm }) {
      return contactDetailsForm.forms.contactDetailsForm.values;
    },
    forms({ contactDetailsForm }) {
      return contactDetailsForm.forms;
    },
  },
});

export const contactDetailsFormSlice = slice;
export const contactDetailsFormGettersDef = gettersDef;
