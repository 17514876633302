import { Footer as BrFooter } from '@vwfs-bronson/bronson-react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '../../types/footer';

export type FooterProps = {
  pages?: Page[];
};

const Footer: FunctionComponent<FooterProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <BrFooter>
        <BrFooter.ModuleMetaNav copyrightText={`${t('app:footer:copyright')} ${new Date().getFullYear()}`} />
      </BrFooter>
    </>
  );
};

export default Footer;
