import React, { FunctionComponent, useEffect, useState } from 'react';

import {
  Button,
  ButtonContainer,
  Fieldset,
  Form,
  FormHeading,
  HeroTeaser,
  Input,
  StatusMessage,
} from '@vwfs-bronson/bronson-react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from '../../services/routing';

import { FormField } from '../../components';
import { MockLoginStateEnum } from '../../resources/mocks/mockLogins';
import Yup from '../../services/common/validation';
import { useLoginMutation } from '../../services/redux/features/api/authentication.api-slice';
import { logInUser } from '../../services/redux/features/auth.slice';

interface LoginFormFields {
  username: string;
  password: string;
}

const LoginPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [generalErrorTranslateKey, setGeneralErrorTranslateKey] = useState('');

  const [loginUser, { isLoading }] = useLoginMutation();

  useEffect(() => {
    document.title = t('loginPage:title');
  }, [t]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t('validation:required')).email(t('validation:invalidEmail')),
    password: Yup.string().required(t('validation:required')),
  });

  const onSubmit = async (values: LoginFormFields) => {
    try {
      setGeneralErrorTranslateKey('');
      const authenticateResult = await loginUser({ login: values.username, password: values.password }).unwrap();

      switch (authenticateResult) {
        case MockLoginStateEnum.SUCCESS:
          dispatch(
            logInUser({
              isPasswordCloseToExpiration: false,
              user: { username: values.username },
            })
          );
          navigate.mockDashboard();
          return;
        case MockLoginStateEnum.TEMPORARY_PASSWORD:
          navigate.changePassword();
          return;
        case MockLoginStateEnum.UPCOMING_EXPIRATION:
          dispatch(
            logInUser({
              isPasswordCloseToExpiration: true,
              user: { username: values.username },
            })
          );
          navigate.mockDashboard();
          return;

        default:
          navigate.error();
      }
    } catch (error) {
      switch (error) {
        case MockLoginStateEnum.FAIL:
          setGeneralErrorTranslateKey(t('loginPage:loginForm:generalErrors:incorrectUsernameOrPassword'));
          return;
        case MockLoginStateEnum.SERVER_ERROR:
          setGeneralErrorTranslateKey(t('loginPage:loginForm:generalErrors:internalServerError'));
          return;
        case MockLoginStateEnum.ACCOUNT_LOCKED:
          setGeneralErrorTranslateKey(t('loginPage:loginForm:generalErrors:lockedAccount'));
          return;
        case MockLoginStateEnum.PASSWORD_EXPIRED:
          setGeneralErrorTranslateKey(t('loginPage:loginForm:generalErrors:passwordExpired'));
          return;
        default:
          navigate.error();
      }
      navigate.error();
    }
  };

  return (
    <>
      <Formik
        initialValues={{ username: '', password: '' } as LoginFormFields}
        validationSchema={validationSchema}
        onSubmit={(values: LoginFormFields) => onSubmit(values)}
      >
        {(formProps) => {
          const { handleSubmit } = formProps;

          return (
            <HeroTeaser
              noImage
              layer={
                <Form onSubmit={handleSubmit}>
                  <Fieldset.Row>
                    <FormHeading>{t('loginPage:loginForm:header')}</FormHeading>
                    <Fieldset.Row>
                      <FormField
                        notion
                        type="input"
                        name="username"
                        labelText={t('loginPage:loginForm:loginInput:label')}
                        render={(fieldProps) => <Input {...fieldProps} />}
                      />
                    </Fieldset.Row>
                    <Fieldset.Row>
                      <FormField
                        notion
                        type="input"
                        name="password"
                        labelText={t('loginPage:loginForm:passwordInput:label')}
                        render={(fieldProps) => <Input {...fieldProps} type="password" />}
                      />
                    </Fieldset.Row>

                    <Fieldset.Row>
                      <ButtonContainer>
                        <Button type="submit" disabled={!formProps.dirty || !formProps.isValid || isLoading}>
                          {t('loginPage:loginForm:loginButton')}
                        </Button>
                      </ButtonContainer>
                    </Fieldset.Row>

                    {generalErrorTranslateKey && (
                      <Fieldset.Row>
                        <StatusMessage status="error" label={t(generalErrorTranslateKey)} />
                      </Fieldset.Row>
                    )}

                    {/* <Fieldset.Row>
                      <a href={ROUTES.resetPassword}>{t('loginPage:loginForm:resetPassword')}</a>
                    </Fieldset.Row> */}
                  </Fieldset.Row>
                </Form>
              }
              layerWidth="small"
              pretitle={null}
              sub={null}
              title={null}
            />
          );
        }}
      </Formik>
    </>
  );
};

export default LoginPage;
