import { configureStore } from '@reduxjs/toolkit';
import {
  additionalServicePlanGettersDef,
  additionalServicePlanSlice,
} from '../../components/AdditionalServicePlan/additionalServicePlan.slice';
import {
  addressWithPreviousFormGettersDef,
  addressWithPreviousFormSlice,
} from '../../components/AddressWithPreviousForm/addressWithPreviousForm.slice';
import {
  bankDetailsFormGettersDef,
  bankDetailsFormSlice,
} from '../../components/BankDetailsForm/bankDetailsForm.slice';
import {
  companyDetailsFormGettersDef,
  companyDetailsFormSlice,
} from '../../components/CompanyDetailsForm/companyDetailsForm.slice';
import compareQuotesReducer from '../../components/CompareQuotes/compareQuotes.slice';
import {
  contactAddressFormGettersDef,
  contactAddressFormSlice,
} from '../../components/ContactAddressForm/contactAddressForm.slice';
import {
  contactDetailsFormGettersDef,
  contactDetailsFormSlice,
} from '../../components/ContactDetailsForm/contactDetailsForm.slice';
import {
  customerDetailsFormGettersDef,
  customerDetailsFormSlice,
} from '../../components/CustomerDetailsForm/customerDetailsForm.slice';
import {
  driverDetailsFormGettersDef,
  driverDetailsFormSlice,
} from '../../components/DriverDetailsForm/driverDetailsForm.slice';
import {
  employmentDetailsFormWithPreviousFormGettersDef,
  employmentDetailsFormWithPreviousFormSlice,
} from '../../components/EmploymentDetailsFormWithPreviousForm/employmentDetailsFormWithPreviousForm.slice';
import {
  financialDetailsFormGettersDef,
  financialDetailsFormSlice,
} from '../../components/FinancialDetailsForm/financialDetailsForm.slice';
import {
  financialProductFormGettersDef,
  financialProductFormSlice,
} from '../../components/FinancialProductForm/financialProductForm.slice';
import {
  financialProductSpecificationFormGettersDef,
  financialProductSpecificationFormSlice,
} from '../../components/FinancialProductSpecificationForm/financialProductSpecificationForm.slice';
import {
  linkedRelationshipsFormGettersDef,
  linkedRelationshipsFormSlice,
} from '../../components/LinkedRelationshipsForm/linkedRelationshipsForm.slice';
import {
  personalDetailsFormGettersDef,
  personalDetailsFormSlice,
} from '../../components/PersonalDetailsForm/personalDetailsForm.slice';
import {
  snCInitializationGettersDef,
  snCInitializationSlice,
} from '../../components/SnCRestoreSession/SnCInitialisation/SnCInitialization.slice';
import {
  standaloneServicePlanFormGettersDef,
  standaloneServicePlanFormSlice,
} from '../../components/StandaloneServicePlan/standaloneServicePlanForm.slice';
import {
  tradingDetailsFormGettersDef,
  tradingDetailsFormSlice,
} from '../../components/TradingDetailsForm/tradingDetailsForm.slice';
import {
  tradingDetailsSIBusinessFormGettersDef,
  tradingDetailsSIBusinessFormSlice,
} from '../../components/TradingDetailsSIBusinessForm/tradingDetailsSIBusinessForm.slice';
import {
  tradingDetailsSIFormGettersDef,
  tradingDetailsSIFormSlice,
} from '../../components/TradingDetailsSIForm/tradingDetailsSIForm.slice';
import {
  customerDataBusinessLtdPlcGettersDef,
  customerDataBusinessLtdPlcSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataBusinessLtdPlc/customerDataBusinessLtdPlc.slice';
import {
  customerDataBusinessOtherGettersDef,
  customerDataBusinessOtherSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataBusinessOther/customerDataBusinessOther.slice';
import {
  customerDataBusinessPartnershipGettersDef,
  customerDataBusinessPartnershipSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataBusinessPartnership/customerDataBusinessPartnership.slice';
import {
  customerDataBusinessSoleTraderGettersDef,
  customerDataBusinessSoleTraderSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataBusinessSoleTrader/customerDataBusinessSoleTrader.slice';
import {
  customerDataPrivateGettersDef,
  customerDataPrivateSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataPrivate/customerDataPrivate.slice';
import {
  customerDataSIBusinessLtdPlcGettersDef,
  customerDataSIBusinessLtdPlcSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataSIBusinessLtdPlc/customerDataSIBusinessLtdPlc.slice';
import {
  customerDataSIBusinessOtherGettersDef,
  customerDataSIBusinessOtherSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataSIBusinessOther/customerDataSIBusinessOther.slice';
import {
  customerDataSIBusinessPartnershipGettersDef,
  customerDataSIBusinessPartnershipSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataSIBusinessPartnership/customerDataSIBusinessPartnership.slice';
import {
  customerDataSIBusinessSoleTraderGettersDef,
  customerDataSIBusinessSoleTraderSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataSIBusinessSoleTrader/customerDataSIBusinessSoleTrader.slice';
import {
  customerDataServicePlanGettersDef,
  customerDataServicePlanSlice,
} from '../../screens/NewQuotation/CustomerDataPage/CustomerDataServicePlan/customerDataServicePlan.slice';
import {
  customerDataPageGettersDef,
  customerDataPageSlice,
} from '../../screens/NewQuotation/CustomerDataPage/customerDataPage.slice';
import {
  customerTypeSelectionPageGettersDef,
  customerTypeSelectionPageSlice,
} from '../../screens/NewQuotation/CustomerTypeSelectionPage/customerTypeSelectionPage.slice';
import {
  documentUploadPageGettersDef,
  documentUploadPageSlice,
} from '../../screens/NewQuotation/DocumentUploadPage/DocumentUploadPage.slice';
import {
  financialProductPageGettersDef,
  financialProductPageSlice,
} from '../../screens/NewQuotation/FinancialProductPage/financialProductPage.slice';
import {
  usedCarFormSelectorPageGettersDef,
  usedCarFormSelectorPageSlice,
} from '../../screens/NewQuotation/UsedCarFormSelectorPage/UsedCarFormSelectorPage.slice';
import { newQuotationGettersDefinition, newQuotationSlice } from '../../screens/NewQuotation/newQuotation.slice';
import {
  productFamilySelectionPageGettersDef,
  productFamilySelectionPageSlice,
} from '../../screens/ProductFamilySelectionPage/productFamilySelectionPage.slice';
import {
  vehicleTypeSelectPageGettersDef,
  vehicleTypeSelectPageSlice,
} from '../../screens/VehicleSelectPage/vehicleTypeSelectPage.slice';
import { apiSlice } from './features/api/api.api-slice';
import authSlice from './features/auth.slice';
import outletReducer from './features/outlet.slice';
import { transactionGettersDef, transactionSlice } from './features/transaction.slice';
import {
  createDispatchers,
  createGetters,
  extractOnlyReactiveGettersNamespaced,
  extractOnlyStaticGettersNamespaced,
} from './storeUtils';

export const store = configureStore({
  reducer: {
    usedCarFormSelectorPage: usedCarFormSelectorPageSlice.reducer,
    financialProductForm: financialProductFormSlice.reducer,
    financialProductPage: financialProductPageSlice.reducer,
    financialProductSpecificationForm: financialProductSpecificationFormSlice.reducer,
    additionalServicePlan: additionalServicePlanSlice.reducer,
    vehicleTypeSelect: vehicleTypeSelectPageSlice.reducer,
    newQuotation: newQuotationSlice.reducer,
    compareQuotes: compareQuotesReducer,
    auth: authSlice.reducer,
    outlets: outletReducer,
    customerTypeSelectionPage: customerTypeSelectionPageSlice.reducer,
    productFamilySelectionPage: productFamilySelectionPageSlice.reducer,
    customerDataPage: customerDataPageSlice.reducer,
    customerDetailsForm: customerDetailsFormSlice.reducer,
    financialDetailsForm: financialDetailsFormSlice.reducer,
    tradingDetailsForm: tradingDetailsFormSlice.reducer,
    tradingDetailsSIForm: tradingDetailsSIFormSlice.reducer,
    contactAddressForm: contactAddressFormSlice.reducer,
    tradingDetailsSIBusinessForm: tradingDetailsSIBusinessFormSlice.reducer,
    contactDetailsForm: contactDetailsFormSlice.reducer,
    personalDetailsForm: personalDetailsFormSlice.reducer,
    companyDetailsForm: companyDetailsFormSlice.reducer,
    standaloneServicePlanForm: standaloneServicePlanFormSlice.reducer,
    bankDetailsForm: bankDetailsFormSlice.reducer,
    customerDataPrivate: customerDataPrivateSlice.reducer,
    customerDataBusinessSoleTrader: customerDataBusinessSoleTraderSlice.reducer,
    customerDataBusinessOther: customerDataBusinessOtherSlice.reducer,
    customerDataBusinessLtdPlc: customerDataBusinessLtdPlcSlice.reducer,
    customerDataSIBusinessPartnership: customerDataSIBusinessPartnershipSlice.reducer,
    customerDataSIBusinessSoleTrader: customerDataSIBusinessSoleTraderSlice.reducer,
    customerDataSIBusinessOther: customerDataSIBusinessOtherSlice.reducer,
    customerDataSIBusinessLtdPlc: customerDataSIBusinessLtdPlcSlice.reducer,
    employmentDetailsFormWithPreviousForm: employmentDetailsFormWithPreviousFormSlice.reducer,
    linkedRelationshipsForm: linkedRelationshipsFormSlice.reducer,
    driverDetailsForm: driverDetailsFormSlice.reducer,
    addressWithPreviousForm: addressWithPreviousFormSlice.reducer,
    customerDataBusinessPartnership: customerDataBusinessPartnershipSlice.reducer,
    customerDataServicePlan: customerDataServicePlanSlice.reducer,
    documentUploadPage: documentUploadPageSlice.reducer,
    snCInitialization: snCInitializationSlice.reducer,
    transaction: transactionSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

export const actions = {
  newQuotation: createDispatchers(newQuotationSlice.actions),
  customerTypeSelectionPage: createDispatchers(customerTypeSelectionPageSlice.actions),
  productFamilySelectionPage: createDispatchers(productFamilySelectionPageSlice.actions),
  financialProductForm: createDispatchers(financialProductFormSlice.actions),
  financialProductPage: createDispatchers(financialProductPageSlice.actions),
  financialProductSpecificationForm: createDispatchers(financialProductSpecificationFormSlice.actions),
  additionalServicePlan: createDispatchers(additionalServicePlanSlice.actions),
  vehicleTypeSelect: createDispatchers(vehicleTypeSelectPageSlice.actions),
  usedCarFormSelectorPage: createDispatchers(usedCarFormSelectorPageSlice.actions),
  customerDataPage: createDispatchers(customerDataPageSlice.actions),
  customerDetailsForm: createDispatchers(customerDetailsFormSlice.actions),
  financialDetailsForm: createDispatchers(financialDetailsFormSlice.actions),
  tradingDetailsForm: createDispatchers(tradingDetailsFormSlice.actions),
  tradingDetailsSIForm: createDispatchers(tradingDetailsSIFormSlice.actions),
  contactAddressForm: createDispatchers(contactAddressFormSlice.actions),
  tradingDetailsSIBusinessForm: createDispatchers(tradingDetailsSIBusinessFormSlice.actions),
  contactDetailsForm: createDispatchers(contactDetailsFormSlice.actions),
  personalDetailsForm: createDispatchers(personalDetailsFormSlice.actions),
  companyDetailsForm: createDispatchers(companyDetailsFormSlice.actions),
  standaloneServicePlanForm: createDispatchers(standaloneServicePlanFormSlice.actions),
  bankDetailsForm: createDispatchers(bankDetailsFormSlice.actions),
  customerDataPrivate: createDispatchers(customerDataPrivateSlice.actions),
  customerDataBusinessSoleTrader: createDispatchers(customerDataBusinessSoleTraderSlice.actions),
  customerDataBusinessOther: createDispatchers(customerDataBusinessOtherSlice.actions),
  customerDataBusinessLtdPlc: createDispatchers(customerDataBusinessLtdPlcSlice.actions),
  customerDataSIBusinessPartnership: createDispatchers(customerDataSIBusinessPartnershipSlice.actions),
  customerDataSIBusinessSoleTrader: createDispatchers(customerDataSIBusinessSoleTraderSlice.actions),
  customerDataSIBusinessOther: createDispatchers(customerDataSIBusinessOtherSlice.actions),
  customerDataSIBusinessLtdPlc: createDispatchers(customerDataSIBusinessLtdPlcSlice.actions),
  employmentDetailsFormWithPreviousForm: createDispatchers(employmentDetailsFormWithPreviousFormSlice.actions),
  linkedRelationshipsForm: createDispatchers(linkedRelationshipsFormSlice.actions),
  driverDetailsForm: createDispatchers(driverDetailsFormSlice.actions),
  addressWithPreviousForm: createDispatchers(addressWithPreviousFormSlice.actions),
  customerDataBusinessPartnership: createDispatchers(customerDataBusinessPartnershipSlice.actions),
  customerDataServicePlan: createDispatchers(customerDataServicePlanSlice.actions),
  documentUploadPage: createDispatchers(documentUploadPageSlice.actions),
  auth: createDispatchers(authSlice.actions),
  snCInitialization: createDispatchers(snCInitializationSlice.actions),
  transaction: createDispatchers(transactionSlice.actions),
};

const _getters = {
  newQuotation: { ...createGetters(newQuotationGettersDefinition) },
  customerTypeSelectionPage: { ...createGetters(customerTypeSelectionPageGettersDef) },
  productFamilySelectionPage: { ...createGetters(productFamilySelectionPageGettersDef) },
  financialProductForm: { ...createGetters(financialProductFormGettersDef) },
  financialProductPage: { ...createGetters(financialProductPageGettersDef) },
  financialProductSpecificationForm: { ...createGetters(financialProductSpecificationFormGettersDef) },
  additionalServicePlan: { ...createGetters(additionalServicePlanGettersDef) },
  vehicleTypeSelectPage: { ...createGetters(vehicleTypeSelectPageGettersDef) },
  usedCarFormSelectorPage: { ...createGetters(usedCarFormSelectorPageGettersDef) },
  customerDataPage: { ...createGetters(customerDataPageGettersDef) },
  customerDetailsForm: { ...createGetters(customerDetailsFormGettersDef) },
  financialDetailsForm: { ...createGetters(financialDetailsFormGettersDef) },
  tradingDetailsForm: { ...createGetters(tradingDetailsFormGettersDef) },
  tradingDetailsSIForm: { ...createGetters(tradingDetailsSIFormGettersDef) },
  contactAddressForm: { ...createGetters(contactAddressFormGettersDef) },
  tradingDetailsSIBusinessForm: { ...createGetters(tradingDetailsSIBusinessFormGettersDef) },
  contactDetailsForm: { ...createGetters(contactDetailsFormGettersDef) },
  personalDetailsForm: { ...createGetters(personalDetailsFormGettersDef) },
  companyDetailsForm: { ...createGetters(companyDetailsFormGettersDef) },
  standaloneServicePlanForm: { ...createGetters(standaloneServicePlanFormGettersDef) },
  bankDetailsForm: { ...createGetters(bankDetailsFormGettersDef) },
  customerDataPrivate: { ...createGetters(customerDataPrivateGettersDef) },
  customerDataBusinessSoleTrader: { ...createGetters(customerDataBusinessSoleTraderGettersDef) },
  customerDataBusinessOther: { ...createGetters(customerDataBusinessOtherGettersDef) },
  customerDataBusinessLtdPlc: { ...createGetters(customerDataBusinessLtdPlcGettersDef) },
  customerDataSIBusinessPartnership: { ...createGetters(customerDataSIBusinessPartnershipGettersDef) },
  customerDataSIBusinessSoleTrader: { ...createGetters(customerDataSIBusinessSoleTraderGettersDef) },
  customerDataSIBusinessOther: { ...createGetters(customerDataSIBusinessOtherGettersDef) },
  customerDataSIBusinessLtdPlc: { ...createGetters(customerDataSIBusinessLtdPlcGettersDef) },
  employmentDetailsFormWithPreviousForm: { ...createGetters(employmentDetailsFormWithPreviousFormGettersDef) },
  linkedRelationshipsForm: { ...createGetters(linkedRelationshipsFormGettersDef) },
  driverDetailsForm: { ...createGetters(driverDetailsFormGettersDef) },
  addressWithPreviousForm: { ...createGetters(addressWithPreviousFormGettersDef) },
  customerDataBusinessPartnership: { ...createGetters(customerDataBusinessPartnershipGettersDef) },
  customerDataServicePlan: { ...createGetters(customerDataServicePlanGettersDef) },
  documentUploadPage: { ...createGetters(documentUploadPageGettersDef) },
  snCInitialization: { ...createGetters(snCInitializationGettersDef) },
  transaction: { ...createGetters(transactionGettersDef) },
};

const _staticGetters = extractOnlyStaticGettersNamespaced(_getters);
const _reactiveGetters = extractOnlyReactiveGettersNamespaced(_getters);

/**
 * - Reactive getters for use in React components.
 */
export const useGetters = () => _reactiveGetters;
/**
 * - Static getters for use anywhere (can also be used in React if you don't want to
 * subscribe to value and just read it ad-hoc).
 */
export const getters = _staticGetters;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ActionKeyType = keyof typeof actions;
export type GettersKeyType = keyof typeof _getters;
