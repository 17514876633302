import { apiSlice } from './api.api-slice';

export interface SearchAddressResponse {
  label: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  state: string;
  county: string;
  city: string;
  district: string;
  street: string;
  postalCode: string;
  houseNumber: string;
}

export const searchAddressSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddress: builder.query<SearchAddressResponse, { query: string }>({
      query: ({ query }) => `/searchAddress?query=${encodeURIComponent(query)}`,
      extraOptions: {
        applyProductPath: false,
      },
    }),
  }),
});

export const { useGetAddressQuery } = searchAddressSlice;
