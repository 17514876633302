import { Button, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import { t } from 'i18next';
import React, { FunctionComponent, ReactElement, memo, useEffect, useMemo, useState } from 'react';
import { formAutocomplete } from '../../config';
import { createOptionsList, createYesNoOptionsList, useFlowTypeChecker } from '../../services/common/miscUtils';
import {
  getMaxBirthDate,
  getMinBirthDate,
  landlineNumberMask,
  maxLandlineNumberLength,
  maxMobileNumberLength,
  mobileNumberMask,
} from '../../services/common/validation';
import { actions, getters, useGetters } from '../../services/redux';
import { CorrespondenceMethodEnum, CountryCodeEnum, TelephoneTypeEnum } from '../../types/forms';
import { DatePickerFormField } from '../Fieldset/DatePickerFormField';
import { SelectFormField } from '../Fieldset/SelectFormField';
import { TextInputFormField } from '../Fieldset/TextInputFormField';
import { PersistFormInState, setFieldValueInitial } from '../PersistFormInState/PersistFormInState';
import {
  CcrTypeEnum,
  CustomerDetailsFormModel,
  CustomerDetailsFormModelFields,
  GenderEnum,
  MaritalStatusEnum,
  TitleEnum,
} from './customerDetailsForm.model';
import { CustomerDetailsFormState } from './customerDetailsForm.slice';
import { createCustomerDetailsFormValidator } from './customerDetailsForm.validator';

const createFormInputs = ((disableAllFormControls: boolean) => {
  return {
    title: () => (
      <SelectFormField
        fieldName="title"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:title:label"
        optionList={createOptionsList(TitleEnum, 'customerDetailsForm', 'title')}
      />
    ),
    gender: () => (
      <SelectFormField
        fieldName="gender"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:gender:label"
        optionList={createOptionsList(GenderEnum, 'customerDetailsForm', 'gender')}
      />
    ),
    maritalStatus: () => (
      <SelectFormField
        fieldName="maritalStatus"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:maritalStatus:label"
        optionList={createOptionsList(MaritalStatusEnum, 'customerDetailsForm', 'maritalStatus')}
      />
    ),
    makeIndemnifier: () => (
      <SelectFormField
        fieldName="makeIndemnifier"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:makeIndemnifier:label"
        optionList={createYesNoOptionsList('customerDetailsForm', 'makeIndemnifier')}
      />
    ),
    firstName: () => (
      <TextInputFormField
        fieldName="firstName"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:firstName:label"
        placeholderTranslationKey="customerDetailsForm:fields:firstName:placeholder"
      />
    ),
    lastName: () => (
      <TextInputFormField
        fieldName="lastName"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:lastName:label"
        placeholderTranslationKey="customerDetailsForm:fields:lastName:placeholder"
      />
    ),
    middleName: () => (
      <TextInputFormField
        fieldName="middleName"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:middleName:label"
        placeholderTranslationKey="customerDetailsForm:fields:middleName:placeholder"
        optional
      />
    ),
    maidenName: (isFemale: boolean, isOrWasMarried: boolean) => (
      <TextInputFormField
        fieldName="maidenName"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:maidenName:label"
        placeholderTranslationKey="customerDetailsForm:fields:maidenName:placeholder"
        optional={!isFemale || !isOrWasMarried}
      />
    ),
    dateOfBirth: () => (
      <DatePickerFormField
        fieldName="dateOfBirth"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:dateOfBirth:label"
        placeholderTranslationKey="customerDetailsForm:fields:dateOfBirth:placeholder"
        maxDate={getMaxBirthDate()}
        minDate={getMinBirthDate()}
      />
    ),
    email: () => (
      <TextInputFormField
        fieldName="email"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:email:label"
        placeholderTranslationKey="customerDetailsForm:fields:email:placeholder"
        validateOnChange
      />
    ),
    telephoneType: () => (
      <SelectFormField
        fieldName="telephoneType"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:telephoneType:label"
        optionList={createOptionsList(TelephoneTypeEnum, 'customerDetailsForm', 'telephoneType')}
      />
    ),
    phoneNumber: (selectedMobileTelephoneType: boolean) => {
      return selectedMobileTelephoneType ? (
        <TextInputFormField
          fieldName="phoneNumber"
          isDisabled={disableAllFormControls}
          labelTranslationKey="customerDetailsForm:fields:mobileNumber:label"
          placeholderTranslationKey="customerDetailsForm:fields:mobileNumber:placeholder"
          mask={mobileNumberMask}
          maskedValueToFormValue={(maskedValue) => maskedValue.replace(/[^\d]/g, '')}
        />
      ) : (
        <TextInputFormField
          fieldName="phoneNumber"
          isDisabled={disableAllFormControls}
          labelTranslationKey="customerDetailsForm:fields:telephoneNumber:label"
          placeholderTranslationKey="customerDetailsForm:fields:telephoneNumber:placeholder"
          mask={landlineNumberMask}
          maskedValueToFormValue={(maskedValue) => maskedValue.replace(/[^\d]/g, '')}
        />
      );
    },
    countryCode: () => (
      <SelectFormField
        fieldName="countryCode"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:countryCode:label"
        optionList={createOptionsList(CountryCodeEnum, 'customerDetailsForm', 'countryCode')}
      />
    ),
    correspondenceMethod: () => (
      <SelectFormField
        fieldName="correspondenceMethod"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:correspondenceMethod:label"
        optionList={createOptionsList(CorrespondenceMethodEnum, 'customerDetailsForm', 'correspondenceMethod')}
      />
    ),
    ccrType: () => (
      <SelectFormField
        fieldName="ccrType"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:ccrType:label"
        optionList={createOptionsList(CcrTypeEnum, 'customerDetailsForm', 'ccrType')}
      />
    ),
    ccrId: () => (
      <TextInputFormField
        fieldName="ccrId"
        isDisabled={disableAllFormControls}
        labelTranslationKey="customerDetailsForm:fields:ccrId:label"
        placeholderTranslationKey="customerDetailsForm:fields:ccrId:placeholder"
      />
    ),
  };
}) satisfies (
  disableAllFormControls: boolean
) => Record<CustomerDetailsFormModelFields, (...args: any[]) => ReactElement>;

function _CustomerDetailsForm<FormName extends keyof CustomerDetailsFormState['forms']>(props: {
  formName: FormName;
  initialValues: CustomerDetailsFormModel;
  currentValues: CustomerDetailsFormModel;
  shouldValidate: boolean;
  valuesToResetTo: CustomerDetailsFormModel;
  shouldReset: boolean;
}) {
  const [initialValues /* , setInitialValues */] = useState(() => props.initialValues);
  const [_setFieldValue, _setSetFieldValue] = useState(setFieldValueInitial<CustomerDetailsFormModel>);
  const { currentValues, valuesToResetTo, shouldValidate, shouldReset, formName } = props;

  const disableAllFormControls = !useGetters().snCInitialization.snCSubmitted;

  const inputs = useMemo(() => createFormInputs(disableAllFormControls), [disableAllFormControls]);

  const flowTypeIs = useFlowTypeChecker();

  const isFemale = currentValues.gender === GenderEnum.female;
  const isOrWasMarried = [MaritalStatusEnum.married, MaritalStatusEnum.divorced, MaritalStatusEnum.separated].includes(
    currentValues.maritalStatus! /* Can be undefined but it's safe to ignore it */
  );

  const selectedMobileTelephoneType = currentValues.telephoneType === TelephoneTypeEnum.mobile;
  const selectedLandlineTelephoneType = currentValues.telephoneType === TelephoneTypeEnum.landline;

  // Reset phone number then phone type changed.
  useEffect(() => {
    if (selectedMobileTelephoneType) {
      _setFieldValue('phoneNumber', currentValues.phoneNumber?.slice(0, maxMobileNumberLength));
    } else if (selectedLandlineTelephoneType) {
      _setFieldValue('phoneNumber', currentValues.phoneNumber?.slice(0, maxLandlineNumberLength));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues.telephoneType]);

  return (
    <Formik
      validationSchema={createCustomerDetailsFormValidator(flowTypeIs)}
      initialValues={initialValues}
      onSubmit={(_, { setSubmitting }) => {
        setSubmitting(false);
      }}
      validateOnMount
      validateOnChange={false}
      validateOnBlur
    >
      {/* Disable prettier to keep template compact and readable. */}
      {/* prettier-ignore */}
      <Form  autoComplete={formAutocomplete}>
        <PersistFormInState<CustomerDetailsFormState>
          formName={formName}
          shouldValidate={shouldValidate}
          shouldReset={shouldReset}
          updateForm={actions.customerDetailsForm.updateForm}
          setFormFieldSetter={_setSetFieldValue}
          valuesToResetTo={valuesToResetTo}
        />

        <Fieldset.Row>
          <Layout>
            <Layout.Item default='5/12' s='1/1'>
              <Button
                type='button'
                icon="address-book"
                secondary
                disabled={disableAllFormControls}
              >
                {t('customerDetailsForm:searchForExistingCustomer')}
              </Button>
            </Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/4" s="1/1">{inputs.title()}</Layout.Item>
            <Layout.Item default="1/4" s="1/1">{inputs.gender()}</Layout.Item>
            {flowTypeIs('consumer', 'soleTrader', 'partnership') && (
              <Layout.Item default="1/2" s="1/1">{inputs.maritalStatus()}</Layout.Item>
            )}
            {flowTypeIs('other', 'ltdPlc') && (
              <Layout.Item default="1/2" s="1/1">{inputs.makeIndemnifier()}</Layout.Item>
            )}
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.firstName()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.lastName()}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.middleName()}</Layout.Item>
            {flowTypeIs('consumer', 'soleTrader', 'partnership', 'servicePlan') && (
              <Layout.Item default="1/2" s="1/1">{inputs.maidenName(isFemale, isOrWasMarried)}</Layout.Item>
            )}
          </Layout>
        </Fieldset.Row>

        <Fieldset.Row>
          <Layout>
            <Layout.Item default="1/2" s="1/1">{inputs.dateOfBirth()}</Layout.Item>
            <Layout.Item default="1/2" s="1/1">{inputs.email()}</Layout.Item>
          </Layout>
        </Fieldset.Row>

        {flowTypeIs('consumer') && (
          <>
            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/4" s="1/1">{inputs.ccrType()}</Layout.Item>
                <Layout.Item default="1/4" s="1/1">{inputs.ccrId()}</Layout.Item>
                <Layout.Item default="2/12" s="1/1">{inputs.countryCode()}</Layout.Item>
                <Layout.Item default="4/12" s="1/1">{inputs.phoneNumber(true)}</Layout.Item>
              </Layout>
            </Fieldset.Row>

            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/2" s="1/1">{inputs.correspondenceMethod()}</Layout.Item>
              </Layout>
            </Fieldset.Row>
          </>
        )}

        {flowTypeIs('soleTrader') && !flowTypeIs('consumer')  && (
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">{inputs.ccrType()}</Layout.Item>
              <Layout.Item default="1/2" s="1/1">{inputs.ccrId()}</Layout.Item>
            </Layout>
          </Fieldset.Row>
        )}
        
        {flowTypeIs('soleTrader', 'partnership', 'other', 'ltdPlc') && !flowTypeIs('consumer') && (
          <Fieldset.Row>
            <Layout>
              <Layout.Item default="1/2" s="1/1">{inputs.correspondenceMethod()}</Layout.Item>
              <Layout.Item default="2/12" s="1/1">{inputs.countryCode()}</Layout.Item>
              <Layout.Item default="4/12" s="1/1">{inputs.phoneNumber(true)}</Layout.Item>
            </Layout>
          </Fieldset.Row>
        )}

        {flowTypeIs('servicePlan') && (
          <>
            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/2" s="1/1">{inputs.correspondenceMethod()}</Layout.Item>
              </Layout>
            </Fieldset.Row>

            <Fieldset.Row>
              <Layout>
                <Layout.Item default="1/2" s="1/1">{inputs.telephoneType()}</Layout.Item>
                <Layout.Item default="2/12" s="1/1">{inputs.countryCode()}</Layout.Item>
                <Layout.Item default="4/12" s="1/1">{inputs.phoneNumber(selectedMobileTelephoneType)}</Layout.Item>
              </Layout>
            </Fieldset.Row>
          </>
        )}
      </Form>
    </Formik>
  );
}

// eslint-disable-next-line react/display-name
export const CustomerDetailsForm: FunctionComponent<{ isMainApplicant: boolean }> = memo(({ isMainApplicant }) => {
  let formName;
  let initialValues;
  let currentValues;
  let shouldValidate;
  let shouldReset;
  let valuesToResetTo;

  if (isMainApplicant) {
    formName = 'customerDetails1';
    initialValues = getters.customerDetailsForm.customerDetails1Values;
    valuesToResetTo = getters.customerDetailsForm.forms.customerDetails1.initialValues;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    currentValues = useGetters().customerDetailsForm.customerDetails1Values;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldValidate = useGetters().customerDetailsForm.customerDetails1Triggers.validation;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldReset = useGetters().customerDetailsForm.customerDetails1Triggers.reset;
  } else {
    formName = 'customerDetails2';
    initialValues = getters.customerDetailsForm.customerDetails2Values;
    valuesToResetTo = getters.customerDetailsForm.forms.customerDetails2.initialValues;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    currentValues = useGetters().customerDetailsForm.customerDetails2Values;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldValidate = useGetters().customerDetailsForm.customerDetails2Triggers.validation;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    shouldReset = useGetters().customerDetailsForm.customerDetails2Triggers.reset;
  }

  return _CustomerDetailsForm({ formName, initialValues, currentValues, shouldValidate, shouldReset, valuesToResetTo });
});
