export enum BusinessCustomerTypesEnum {
  ltdPlc = 'ltdPlc',
  soleTrader = 'soleTrader',
  partnership = 'partnership',
  other = 'other',
}

export interface BusinessCustomerTypeFormModel {
  selectedBusinessCustomerType?: BusinessCustomerTypesEnum;
}
