import { t } from 'i18next';
import { FlowTypeEnum } from '../types/forms';

/**
 * - Provides title for the component/section/form under condition
 * that translation file has title information formatted in a certain way.
 * - See example translation formatting in customerDetailsForm.json under key 'title'.
 */
export const getSectionTitleForCustomer = (
  sectionTranslationKey: string,
  flowType: FlowTypeEnum,
  /**
   * There can be data of up to 2 people present in Customer Data forms
   * for business customers.
   * isMainApplicant means the title is for the first of those people.
   */
  isMainApplicant?: boolean
) => {
  let mainApplicantAffix = '';
  if (isMainApplicant !== undefined) {
    mainApplicantAffix = isMainApplicant ? '1' : '2';
  }

  if (flowType === FlowTypeEnum.consumer) {
    return t(`${sectionTranslationKey}:title:private${mainApplicantAffix}`);
  }

  return t(`${sectionTranslationKey}:title:${flowType}${mainApplicantAffix}`);
};

/**
 * - getSectionTitleForCustomer curried for simplicity.
 * @see {@link getSectionTitleForCustomer} for details.
 */
export const getSectionTitleForCustomerCurried = (flowType: FlowTypeEnum) => {
  /**
   * - Provide `isMainApplicant` only when title for form in question
   * has 2 permutations for given customerType.
   */
  return (sectionTranslationKey: string, isMainApplicant?: boolean) => {
    return getSectionTitleForCustomer(sectionTranslationKey, flowType, isMainApplicant);
  };
};
