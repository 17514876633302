import { Upload, UploadContainer } from '@vwfs-bronson/bronson-react';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { UploadedFile } from '../../types/files';
import DocumentUploadItem from '../DocmentUploadItem/DocumentUploadItem';

interface DocumentUploadProps {
  description: string;
  disabledText?: string;
  supplementaryText: string;
  uploadButtonText: string;
  isDisabled?: boolean;
  accept: Array<string>;
  fieldsValiditySetter: (areValid: boolean) => void;
}

const DocumentUpload: FunctionComponent<DocumentUploadProps> = ({
  description,
  supplementaryText,
  uploadButtonText,
  isDisabled,
  disabledText,
  accept,
  fieldsValiditySetter,
}) => {
  const [filesList, setFileList] = useState<Set<File>>(() => new Set<File>());
  const [validFilesList] = useState(() => new Set<File>());
  const [areAllFilesValid, setAreAllFilesValid] = useState<boolean>(false);

  const addToFileList = (file: File) => {
    filesList.add(file);

    setFileList(new Set(filesList));
  };
  const removeFromFileList = (file: File) => {
    filesList.delete(file);

    setFileList(new Set(filesList));
  };

  const validityCallback = (file: UploadedFile, isValid: boolean) => {
    if (isValid) {
      validFilesList.add(file);
    } else {
      validFilesList.delete(file);
    }

    setAreAllFilesValid(validFilesList.size === filesList.size);
  };

  const fileChangedHandler = (event: ChangeEvent<HTMLInputElement>, isMultiple = false): void => {
    const target = event.target as HTMLInputElement;
    const { files }: { files: FileList | null } = target;

    let array = Array.prototype.slice.call(files);

    if (files) {
      array = isMultiple && !!filesList ? array?.concat(filesList) : array;

      array.forEach((f) => addToFileList(f));
    }
  };

  useEffect(() => {
    fieldsValiditySetter(areAllFilesValid);
  }, [areAllFilesValid, fieldsValiditySetter]);

  return (
    <>
      <UploadContainer
        upload={
          <Upload
            buttonText={uploadButtonText}
            descriptionText={description}
            descriptionSupplementaryText={supplementaryText}
            disabledText={disabledText}
            disabled={isDisabled}
            onChange={fileChangedHandler}
            accept={accept.join(',')}
          />
        }
      >
        {Array.from(filesList).map((file: UploadedFile) => {
          return (
            <UploadContainer.Item key={`${file.name}_${file.lastModified}}}`}>
              <DocumentUploadItem
                file={file}
                onDeleteSuccess={() => removeFromFileList(file)}
                validityCallback={(isValid: boolean) => validityCallback(file, isValid)}
              />
            </UploadContainer.Item>
          );
        })}
      </UploadContainer>
    </>
  );
};

export default DocumentUpload;
