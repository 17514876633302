import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UsedCarSearchFormModel } from '../../../components/UsedCarSearchForm/UsedCarSearchFormModels';
import { UsedCarSelectionFormFields as UsedCarSelectionFormModel } from '../../../components/UsedCarSelectionForm/UsedCarSelectionFormModels';
import { getFormUpdater } from '../../../services/common/miscUtils';
import { createGettersDefinition, saveAndContinueRehydrateSlice } from '../../../services/redux/storeUtils';
import { ReduxStateWithForms, ReduxTrackedForm, createReduxTrackedFormDefaults } from '../../../types/reduxTrackedForm';
import { UsedCarFormTypesEnum } from './UsedCarFormSelectorPage.model';

const mockedUsedCarSearchResult: UsedCarSelectionFormModel = {
  brand: 'Audi',
  chassisNumber: 'WVWZZZAUZLW009960',
  registrationNumber: '231-D-123456',
  firstRegistration: '01/04/2023',
  model: '3 Series',
  modelYear: '231',
  recordedMileage: '11056',
  trim: 'Sport',
  variant: 'BMW 320d M Sport Saloon',
  vatApplicable: 'yes',
  vehicleClass: 'Car',
};

export interface UsedCarFormSelectorPageState
  extends ReduxStateWithForms<{
    usedCarSelection: ReduxTrackedForm<UsedCarSelectionFormModel>;
    usedCarSearch: ReduxTrackedForm<UsedCarSearchFormModel>;
  }> {
  usedCarSearchResult: UsedCarSelectionFormModel | null;
  selectedMethod: UsedCarFormTypesEnum;
}

const usedCarSearchFormInitialValues: UsedCarSearchFormModel = {
  brand: '',
  model: '',
  registrationNumber: '',
  variant: '',
};

const usedCarSelectionFormInitialValues: UsedCarSelectionFormModel = {
  vehicleClass: 'car',
  vatApplicable: 'no',
  brand: '',
  model: '',
  modelYear: '',
  trim: '',
  variant: '',
  registrationNumber: '',
  chassisNumber: '',
  firstRegistration: '',
  recordedMileage: '',
};

const initialState: UsedCarFormSelectorPageState = {
  forms: {
    usedCarSearch: {
      ...createReduxTrackedFormDefaults<UsedCarSearchFormModel>({ initialValues: usedCarSearchFormInitialValues }),
      values: {
        ...usedCarSearchFormInitialValues,
      },
    },
    usedCarSelection: {
      ...createReduxTrackedFormDefaults<UsedCarSelectionFormModel>({
        initialValues: usedCarSelectionFormInitialValues,
      }),
      values: {
        ...usedCarSelectionFormInitialValues,
      },
    },
  },
  usedCarSearchResult: mockedUsedCarSearchResult,
  selectedMethod: UsedCarFormTypesEnum.Search,
};

const name = 'usedCarFormSelectorPage';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateForm: getFormUpdater<UsedCarFormSelectorPageState>(),
    changeCarSearchMethod: (
      state,
      action: PayloadAction<{
        selectedMethod: UsedCarFormTypesEnum;
      }>
    ) => {
      state.selectedMethod = action.payload.selectedMethod;
    },
    updateSearchResults: (
      state,
      action: PayloadAction<{
        data: UsedCarSelectionFormModel | null;
      }>
    ) => {
      state.usedCarSearchResult = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    saveAndContinueRehydrateSlice(builder, name);
  },
});

const gettersDef = createGettersDefinition({
  reactive: {
    usedCarSearchForm({ usedCarFormSelectorPage }) {
      return usedCarFormSelectorPage.forms.usedCarSearch;
    },
    usedCarSelectionForm({ usedCarFormSelectorPage }) {
      return usedCarFormSelectorPage.forms.usedCarSelection;
    },
    usedCarSearchResult({ usedCarFormSelectorPage }) {
      return usedCarFormSelectorPage.usedCarSearchResult;
    },
    usedCarSearchSelectedMethod({ usedCarFormSelectorPage }) {
      return usedCarFormSelectorPage.selectedMethod;
    },
  },
});

export const usedCarFormSelectorPageSlice = slice;
export const usedCarFormSelectorPageGettersDef = gettersDef;
