import { t } from 'i18next';
import { ObjectSchema } from 'yup';
import Yup from '../../services/common/validation';
import { LinkedRelationshipFormModel } from './linkedRelationshipsForm.model';

export const linkedRelationshipsFormSchema = (): ObjectSchema<LinkedRelationshipFormModel> => {
  return Yup.object({
    majorityInCompanies: Yup.string().required(t('validation:required')),
    majorityInCompaniesCompanyName: Yup.string().when('majorityInCompanies', {
      is: (majorityInCompanies: string) => majorityInCompanies === 'yes',
      then: (schema) => schema.required(t('validation:required')).max(255, t('validation:maxLength', { value: 255 })),
      otherwise: (schema) => schema.notRequired(),
    }),
    partnerOfCompanies: Yup.string().required(t('validation:required')),
    partnerOfCompaniesCompanyName: Yup.string().when('partnerOfCompanies', {
      is: (partnerOfCompanies: string) => partnerOfCompanies === 'yes',
      then: (schema) => schema.required(t('validation:required')).max(255, t('validation:maxLength', { value: 255 })),
      otherwise: (schema) => schema.notRequired(),
    }),
    customerOfVwfs: Yup.string().required(t('validation:required')),
    contactNumberOrVehicleRegistrationNumber: Yup.string().when('customerOfVwfs', {
      is: (customerOfVwfs: string) => customerOfVwfs === 'yes',
      then: (schema) =>
        schema
          .required(t('validation:required'))
          .max(255, t('validation:maxLength', { value: 255 }))
          .matches(/^[a-zA-Z0-9 ]*$/, t('validation:onlyLettersAndNumbers')),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
};
